
export const book_kaleidoscope_trans = {
    '7014': {
        'en': "Midi dress in electric blue crepe, with a flattering funnel neck and front opening. 3/4 sleeves with back fastening and an invisible zip.",
        'es': "Vestido midi en crepé azul eléctrico, con favorecedor cuello chimenea y abertura delantera. Mangas 3/4 y se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito midi in crepe blu elettrico, con un bel collo importante e apertura frontale. Maniche a 3/4 e chiusura dietro con zip invisibile.", 
        'ca': "Vestit midi en crepè blau elèctric, amb afavoridor coll xemeneia i obertura davantera. Mànigues 3/4 i es corda per l'esquena amb cremallera invisible.",
    },
    '7015': {
        'en': "Short pale pink crepe dress with a boat neckline and folded scarf detail hanging at the waist that cuts and flatters the figure. Presented in 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Vestido corto en crepé rosa palo, escote barco y detalle de pañuelo plegado colgando en cintura que corta y estiliza la figura. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito corto in crêpe rosa pallido, scollo a barchetta e dettaglio foulard piegato appeso in vita che taglia e stilizza la figura. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Vestit curt en crepè rosa pal, escot vaixell i detall de mocador plegat penjant a la cintura que talla i estilitza la figura. Presentat amb màniga 3/4, es corda per l'esquena amb cremallera invisible.",
    },
    '7016': {
        'en': "Elegant short blue crepe dress with boat neckline and peplum with pleats at the waist. Shown with 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Elegante vestido corto en crepe azul, escote barco y péplum con pliegues en la cintura. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Elegante abito corto in crêpe azzurro con scollo a barchetta e peplo con pieghe in vita. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Elegant vestit curt en crep blau, escot vaixell i pèplum amb plecs a la cintura. Presentat amb màniga 3/4, s'encorda per l'esquena amb cremallera invisible.",
    },
    '7017': {
        'en': "Blue crepe midi dress with fancy puffed sleeves. Pictured with 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Vestido midi en crepé azul y manga abullonada fantasía. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito midi in crêpe azzurro con maniche ampie alla fine. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Vestit midi en crepè blau i màniga amb fantasia. Presentat amb màniga 3/4, s'encorda per l'esquena amb cremallera invisible.",
    },
    '7018': {
        'en': "Charming midi dress in silver crepe, featuring a funnel neck and pretty bow detail on the cuffs. Shown here with 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Encantador vestido midi en crepe color plata, cuello chimenea y bonito detalle de lazo en los puños. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Affascinante abito midi in crêpe colore argento, collo a imbuto e grazioso fiocco sui polsini. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Encantador vestit midi en crep color plata, coll xemeneia i bonic detall de llaç als punys. Presentat amb màniga 3/4, s'encorda per l'esquena amb cremallera invisible.",
    },
    '7019': {
        'en': "Midi dress in mint green crepe, rectangular neckline and cape skirt. Shown here with 3/4 sleeves and cuffs, it fastens at the back with an invisible zipper.",
        'es': "Vestido midi en crepé verde menta, escote rectangular y falda a capa. Presentado en manga 3/4 con puño, se abrocha por la espalda con cremallera invisible. ",
        'fr': "",
        'it': "Abito midi in crêpe verde menta, scollo rettangolare e gonna a strati. Presentato con maniche a 3/4 con polsini, si chiude sul retro con una cerniera invisibile.", 
        'ca': "Vestit midi en crepè verda menta, escot rectangular i faldilla a capa. Presentat amb màniga 3/4 amb puny, s'encorda per l'esquena amb cremallera invisible.",
    },
    '7020': {
        'en': "Beautiful short dress in mint crepe with a wrap neck. Teresa Ripoll's iconic neck lifts the shoulders and lengthens the body, flattering and enhancing the feminine silhouette. It has 3/4 sleeves and fastens at the back with an invisible zip.",
        'es': "Precioso vestido corto en crepe verde menta con cuello envolvente. El icónico cuello de Teresa Ripoll levanta los hombros y alarga el cuerpo, favoreciendo y realzando la silueta femenina. Tiene mangas 3/4 y se abrocha en la espalda con cremallera invisible.",
        'fr': "",
        'it': "Bellissimo abito corto in crepe verde menta con collo avvolgente. L'iconico collo di Teresa Ripoll solleva le spalle e allunga il corpo, valorizzando la silhouette femminile. Ha maniche a 3/4 e si chiude dietro con una zip invisibile.", 
        'ca': "Bonic vestit curt de crepe verd menta amb coll envolvent. L’emblemàtic coll de Teresa Ripoll aixeca les espatlles i allarga el cos, estilitzant i realçant la silueta femenina. Té mànigues 3/4 i es tanca a la part posterior amb una cremallera invisible.",
    },
    '7021': {
        'en': "Short bougainvillea crepe dress with a rectangular neckline and peplum that lengthens and stylizes the feminine silhouette. Shown here with 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Vestido corto en crepé bugambilia, escote rectangular y peplum que alarga y estiliza la silueta femenina. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito corto in crêpe bouganville con scollo rettangolare e peplo che allunga e stilizza la silhouette femminile. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Vestit curt en crepè bugambilia, escot rectangular i pèplum que allarga i estilitza la silueta femenina. Presentat amb màniga 3/4, s'encorda per l'esquena amb cremallera invisible.",
    },
    '7022': {
        'en': "Spectacular bougainvillea crepe midi dress, neckline with asymmetrical lapel and flared skirt. Presented with 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Espectacular vestido midi en crepé bugambilia, escote con solapa asimétrica y falda con vuelo. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Spettacolare abito midi in crepe bouganville, scollo con rever asimmetrico e gonna svasata. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Espectacular vestit midi en crepè bugambilia, escot amb solapa asimètrica i faldilla amb vol. Presentat amb màniga 3/4, s'encorda per l'esquena amb cremallera invisible.",
    },
    '7023': {
        'en': "Pretty short cherry crepe dress, with the iconic Teresa Ripoll neckline and slimming side pieces at the waist with open darts. Shown with 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Bonito vestido corto en crepé cereza, con icónico cuello Teresa Ripoll y estilizadoras piezas laterales en cintura con pinzas abiertas. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Grazioso abito corto in crêpe ciliegia, con l'iconica scollatura di Teresa Ripoll e parti laterali snellenti in vita con pinces aperte. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Bonic vestit curt en crepé cirera, amb icònic coll Teresa Ripoll i estilitzadores peces laterals a la cintura amb pinces obertes. Presentat amb màniga 3/4, s'encorda per l'esquena amb cremallera invisible.",
    },
    '7024': {
        'en': "Two-piece crepe jacket and pants in cherry tones. The jacket has a pretty panel around the neckline and fastens at the front with a belt.",
        'es': "Dos piezas de chaqueta y pantalón en crepé en tonos cereza. La chaqueta tiene una bonita pieza alrededor del escote y se abrocha por la parte delantera ajustada con un cinturón.",
        'fr': "",
        'it': "Giacca e pantaloni in crêpe due pezzi nei toni ciliegia. La giacca ha un bel pannello intorno alla scollatura e si allaccia sul davanti con una cintura.", 
        'ca': "Dues peces de jaqueta i pantalons en crepè en tons cirera. La jaqueta té una bonica peça al voltant de l'escot i es corda per la part davantera ajustada amb un cinturó.",
    },
    '7025': {
        'en': "Short red crepe dress with puff sleeves and elastic cuffs and flared skirt. Here with 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Vestido corto en crepé rojo, manga abullonada con puño elástico y corte de falda evasé. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito corto in crêpe rosso, maniche a sbuffo con polsino elastico e gonna dal taglio svasato. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Vestit curt en crepè vermell, màniga amb puny elàstic i tall de faldilla evasé. Presentat amb màniga 3/4, s'encorda per l'esquena amb cremallera invisible.",
    },
    '7026': {
        'en': "Flattering short dress in coral crepe, with a straight neckline and spectacular 1/2 lapel collar. Presented in 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Favorecedor vestido corto en crepé color coral, con escote recto y espectacular 1/2 cuello solapa. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Raffinato abito corto in crêpe corallo, con scollo dritto e spettacolare collo a 1/2 revers. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Afavoridor vestit curt en crepè color coral, amb escot recte i espectacular 1/2 coll solapa. Presentat amb màniga 3/4, s'encorda per l'esquena amb cremallera invisible.",
    },
    '7027': {
        'en': "Two piece outfit comprising blouse and pants in the flattering coral crepe. The blouse is semi-fitted and with draped pieces on the shoulders. Shown here  with 3/4 sleeves, it fastens at the back with an invisible zip.",
        'es': "Dos piezas de blusa y pantalón en el favorecedor crepé coral. La blusa es semi-entallada y con unas piezas drapeadas en los hombros estilizando la linea. Presentado en manga 3/4, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Due pezzi. Blusa e pantalone en crepe corallo. La blusa è semiaderente e con pezzi drappeggiati sulle spalle, che stilizzano la linea. Presentato con maniche a 3/4, si chiude sul retro con una zip invisibile.", 
        'ca': "Dues peces de brusa i pantalons en l'afavoridor crepè coral. La brusa és semi-entallada i amb unes peces drapejades a les espatlles estilitzant la línia. Presentat amb màniga 3/4, s'encorda per l'esquena amb cremallera invisible.",
    },
}
