import {useState, useEffect} from 'react'

export const useMobile = () => {
    const [mobile, setMobile] = useState(document.documentElement.clientWidth < 868) // window.innerWidth

    useEffect(() => {
      window.addEventListener('resize', () => {
        setMobile(document.documentElement.clientWidth < 768) // window.innerWidth
      })
      // eslint-disable-next-line
    }, [])

    return mobile 
}
