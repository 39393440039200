import { book_paris_trans } from "../../i18n/book_paris_trans";


export const book_paris = [
    {
        title: '2219',

        light: '/img/2021/collection/light/2219-CH-F-V.jpg',
        back_light: '/img/2021/collection/light/2219-CH-F-V-Back.jpg',
        front_light: '/img/2021/collection/light/2219-CH-F-V-Front.jpg',
        detail_light: '/img/2021/collection/light/2219-CH-F-V-detall.jpg',

        hight: '/img/2021/collection/high/2219-CH-F-V.jpg',
        back_hight: '/img/2021/collection/high/2219-CH-F-V-Back.jpg',
        front_hight: '/img/2021/collection/high/2219-CH-F-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/2219-CH-F-V-Detall.jpg',

        video: '/img/2021/collection/videos/2219-CH-F-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[2219],
    },
    {
        title: '2220',

        light: '/img/2021/collection/light/2220-BL-PA.jpg',
        back_light: '/img/2021/collection/light/2220-BL-Back.jpg',
        front_light: '/img/2021/collection/light/2220-BL-Front.jpg',
        detail_light: '/img/2021/collection/light/2220-BL-Detall.jpg',

        hight: '/img/2021/collection/high/2220-BL-PA.jpg',
        back_hight: '/img/2021/collection/high/2220-BL-Back.jpg',
        front_hight: '/img/2021/collection/high/2220-BL-Front.jpg',
        detail_hight: '/img/2021/collection/high/2220-BL-Detall.jpg',

        video: '/img/2021/collection/videos/2220-BL-PA-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[2220],
    },
    {
        title: '3725',

        light: '/img/2021/collection/light/3725-V.jpg',
        back_light: '/img/2021/collection/light/3725-V-Back.jpg',
        front_light: '/img/2021/collection/light/3725-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3725-V-Detall.jpg',

        hight: '/img/2021/collection/high/3725-V.jpg',
        back_hight: '/img/2021/collection/high/3725-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3725-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3725-V-Detall.jpg',

        video: '/img/2021/collection/videos/3725-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3725],
    },
    /*{
        title: '3726',

        light: '/img/2021/collection/light/3726-V.jpg',
        back_light: '/img/2021/collection/light/3726-V-Back.jpg',
        front_light: '/img/2021/collection/light/3726-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3726-V-Detall.jpg',

        hight: '/img/2021/collection/high/3726-V.jpg',
        back_hight: '/img/2021/collection/high/3726-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3726-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3726-V-Detall.jpg',

        video: '/img/2021/collection/videos/3726-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
    },
    {
        title: '3727',

        light: '/img/2021/collection/light/3727-V.jpg',
        back_light: '/img/2021/collection/light/3727-V-Back.jpg',
        front_light: '/img/2021/collection/light/3727-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3727-V-Detall.jpg',

        hight: '/img/2021/collection/high/3727-V.jpg',
        back_hight: '/img/2021/collection/high/3727-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3727-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3727-V-Detall.jpg',

        video: '/img/2021/collection/videos/3727-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
    },*/
    {
        title: '3728',

        light: '/img/2021/collection/light/3728-V.jpg',
        back_light: '/img/2021/collection/light/3728-V-Back.jpg',
        front_light: '/img/2021/collection/light/3728-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3728-V-Detall.jpg',

        hight: '/img/2021/collection/high/3728-V.jpg',
        back_hight: '/img/2021/collection/high/3728-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3728-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3728-V-Detall.jpg',

        video: '/img/2021/collection/videos/3728-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3728],
    },
    {
        title: '3729',

        light: '/img/2021/collection/light/3729-PO-V.jpg',
        back_light: '/img/2021/collection/light/3729-V-Back.jpg',
        front_light: '/img/2021/collection/light/3729-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3729-PO-V-Detall.jpg',

        hight: '/img/2021/collection/high/3729-PO-V.jpg',
        back_hight: '/img/2021/collection/high/3729-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3729-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3729-PO-V-Detall.jpg',

        video: '/img/2021/collection/videos/3729-PO-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3729],
    },
    {
        title: '3730',

        light: '/img/2021/collection/light/3730-V.jpg',
        back_light: '/img/2021/collection/light/3730-V-Back.jpg',
        front_light: '/img/2021/collection/light/3730-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3730-V-Detall.jpg',

        hight: '/img/2021/collection/high/3730-V.jpg',
        back_hight: '/img/2021/collection/high/3730-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3730-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3730-V-Detall.jpg',

        video: '/img/2021/collection/videos/3730-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3730],
    },
    {
        title: '3731',

        light: '/img/2021/collection/light/3731-V.jpg',
        back_light: '/img/2021/collection/light/3731-V-Back.jpg',
        front_light: '/img/2021/collection/light/3731-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3731-V-Detall.jpg',

        hight: '/img/2021/collection/high/3731-V.jpg',
        back_hight: '/img/2021/collection/high/3731-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3731-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3731-V-Detall.jpg',

        video: '/img/2021/collection/videos/3731-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3731],
    },
    {
        title: '3732',

        light: '/img/2021/collection/light/3732-V.jpg',
        back_light: '/img/2021/collection/light/3732-V-Back.jpg',
        front_light: '/img/2021/collection/light/3732-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3732-V-Detall.jpg',

        hight: '/img/2021/collection/high/3732-V.jpg',
        back_hight: '/img/2021/collection/high/3732-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3732-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3732-V-Detall.jpg',

        video: '/img/2021/collection/videos/3732-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3732],
    },
    {
        title: '3733',

        light: '/img/2021/collection/light/3733-V.jpg',
        back_light: '/img/2021/collection/light/3733-V-Back.jpg',
        front_light: '/img/2021/collection/light/3733-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3733-V-Detall.jpg',

        hight: '/img/2021/collection/high/3733-V.jpg',
        back_hight: '/img/2021/collection/high/3733-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3733-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3733-V-Detall.jpg',

        video: '/img/2021/collection/videos/3733-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3733],
    },
    {
        title: '3734',

        light: '/img/2021/collection/light/3734-V.jpg',
        back_light: '/img/2021/collection/light/3734-V-Back.jpg',
        front_light: '/img/2021/collection/light/3734-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3734-V-Detall.jpg',

        hight: '/img/2021/collection/high/3734-V.jpg',
        back_hight: '/img/2021/collection/high/3734-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3734-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3734-V-Detall.jpg',

        video: '/img/2021/collection/videos/3734-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3734],
    },
    {
        title: '3735',

        light: '/img/2021/collection/light/3735-V.jpg',
        back_light: '/img/2021/collection/light/3735-V-Back.jpg',
        front_light: '/img/2021/collection/light/3735-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3735-V-Detall.jpg',

        hight: '/img/2021/collection/high/3735-V.jpg',
        back_hight: '/img/2021/collection/high/3735-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3735-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3735-V-Detall.jpg',

        video: '/img/2021/collection/videos/3735-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3735],
    },
    {
        title: '3736',

        light: '/img/2021/collection/light/3736-V.jpg',
        back_light: '/img/2021/collection/light/3736-V-Back.jpg',
        front_light: '/img/2021/collection/light/3736-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3736-V-Detall.jpg',

        hight: '/img/2021/collection/high/3736-V.jpg',
        back_hight: '/img/2021/collection/high/3736-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3736-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3736-V-Detall.jpg',

        video: '/img/2021/collection/videos/3736-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3736],
    },
    {
        title: '3737',

        light: '/img/2021/collection/light/3737-V.jpg',
        back_light: '/img/2021/collection/light/3737-V-Back.jpg',
        front_light: '/img/2021/collection/light/3737-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3737-V-Detall.jpg',

        hight: '/img/2021/collection/high/3737-V.jpg',
        back_hight: '/img/2021/collection/high/3737-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3737-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3737-V-Detall.jpg',

        video: '/img/2021/collection/videos/3737-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3737],
    },
    {
        title: '3738',

        light: '/img/2021/collection/light/3738-V.jpg',
        back_light: '/img/2021/collection/light/3738-V-Back.jpg',
        front_light: '/img/2021/collection/light/3738-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3738-V-Detall.jpg',

        hight: '/img/2021/collection/high/3738-V.jpg',
        back_hight: '/img/2021/collection/high/3738-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3738-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3738-V-Detall.jpg',

        video: '/img/2021/collection/videos/3738-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3738],
    },
    {
        title: '3739',

        light: '/img/2021/collection/light/3739-V.jpg',
        back_light: '/img/2021/collection/light/3739-V-Back.jpg',
        front_light: '/img/2021/collection/light/3739-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3739-V-Detall.jpg',

        hight: '/img/2021/collection/high/3739-V.jpg',
        back_hight: '/img/2021/collection/high/3739-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3739-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3739-V-Detall.jpg',

        video: '/img/2021/collection/videos/3739-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3739],
    },
    {
        title: '3740',

        light: '/img/2021/collection/light/3740-V-SF-CI.jpg',
        back_light: '/img/2021/collection/light/3740-V-SF-CI-Back.jpg',
        front_light: '/img/2021/collection/light/3740-V-SF-CI-Front.jpg',
        detail_light: '/img/2021/collection/light/3740-V-SF-CI-Detall.jpg',

        hight: '/img/2021/collection/high/3740-V-SF-CI.jpg',
        back_hight: '/img/2021/collection/high/3740-V-SF-CI-Back.jpg',
        front_hight: '/img/2021/collection/high/3740-V-SF-CI-Front.jpg',
        detail_hight: '/img/2021/collection/high/3740-V-SF-CI-Detall.jpg',

        video: '/img/2021/collection/videos/3740-V-SF-CI-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3740],
    },
    {
        title: '3741',

        light: '/img/2021/collection/light/3741-V.jpg',
        back_light: '/img/2021/collection/light/3741-V-Back.jpg',
        front_light: '/img/2021/collection/light/3741-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3741-V-Detall.jpg',

        hight: '/img/2021/collection/high/3741-V.jpg',
        back_hight: '/img/2021/collection/high/3741-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3741-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3741-V-Detall.jpg',

        video: '/img/2021/collection/videos/3741-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3741],
    },
    {
        title: '3742',

        light: '/img/2021/collection/light/3742-CH-V.jpg',
        back_light: '/img/2021/collection/light/3742-V-Back.jpg',
        front_light: '/img/2021/collection/light/3742-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3742-CH-V-Detall.jpg',

        hight: '/img/2021/collection/high/3742-CH-V.jpg',
        back_hight: '/img/2021/collection/high/3742-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3742-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3742-CH-V-Detall.jpg',

        video: '/img/2021/collection/videos/3742-CH-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3742],
    },
    {
        title: '3743',

        light: '/img/2021/collection/light/3743-PO-V.jpg',
        back_light: '/img/2021/collection/light/3743-PO-V-Back.jpg',
        front_light: '/img/2021/collection/light/3743-PO-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3743-PO-V-Detall.jpg',

        hight: '/img/2021/collection/high/3743-PO-Vjpg',
        back_hight: '/img/2021/collection/high/3743-PO-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3743-PO-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3743-PO-V-Detall.jpg',

        video: '/img/2021/collection/videos/3743-PO-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3743],
    },
    {
        title: '3744',

        light: '/img/2021/collection/light/3744-V.jpg',
        back_light: '/img/2021/collection/light/3744-V-Back.jpg',
        front_light: '/img/2021/collection/light/3744-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3744-V-Detall.jpg',

        hight: '/img/2021/collection/high/3744-V.jpg',
        back_hight: '/img/2021/collection/high/3744-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3744-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3744-V-Detall.jpg',

        video: '/img/2021/collection/videos/3744-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3744],
    },
    {
        title: '3745',

        light: '/img/2021/collection/light/3745-V.jpg',
        back_light: '/img/2021/collection/light/3745-V-Back.jpg',
        front_light: '/img/2021/collection/light/3745-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3745-V-Detall.jpg',

        hight: '/img/2021/collection/high/3745-V.jpg',
        back_hight: '/img/2021/collection/high/3745-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3745-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3745-V-Detall.jpg',

        video: '/img/2021/collection/videos/3745-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3745],
    },
    {
        title: '3746',

        light: '/img/2021/collection/light/3746-V.jpg',
        back_light: '/img/2021/collection/light/3746-V-Back.jpg',
        front_light: '/img/2021/collection/light/3746-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3746-V-Detall.jpg',

        hight: '/img/2021/collection/high/3746-V.jpg',
        back_hight: '/img/2021/collection/high/3746-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3746-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3746-V-Detall.jpg',

        video: '/img/2021/collection/videos/3746-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3746],
    },
    {
        title: '3747',

        light: '/img/2021/collection/light/3747-V.jpg',
        back_light: '/img/2021/collection/light/3747-V-Back.jpg',
        front_light: '/img/2021/collection/light/3747-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3747-V-Detall.jpg',

        hight: '/img/2021/collection/high/3747-V.jpg',
        back_hight: '/img/2021/collection/high/3747-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3747-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3747-V-Detall.jpg',

        video: '/img/2021/collection/videos/3747-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3747],
    },
    {
        title: '3748',

        light: '/img/2021/collection/light/3748-V.jpg',
        back_light: '/img/2021/collection/light/3748-V-Back.jpg',
        front_light: '/img/2021/collection/light/3748-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3748-V-Detall.jpg',

        hight: '/img/2021/collection/high/3748-V.jpg',
        back_hight: '/img/2021/collection/high/3748-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3748-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3748-V-Detall.jpg',

        video: '/img/2021/collection/videos/3748-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3748],
    },
    /*{
        title: '3749',

        light: '/img/2021/collection/light/3749-V.jpg',
        back_light: '/img/2021/collection/light/3749-V-Back.jpg',
        front_light: '/img/2021/collection/light/3749-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3749-V-Detall.jpg',

        hight: '/img/2021/collection/high/3749-V.jpg',
        back_hight: '/img/2021/collection/high/3749-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3749-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3749-V-Detall.jpg',

        video: '/img/2021/collection/videos/3749-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
    },*/
    {
        title: '3750',

        light: '/img/2021/collection/light/3750-CH-V.jpg',
        back_light: '/img/2021/collection/light/3750-V-Back.jpg',
        front_light: '/img/2021/collection/light/3750-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3750-V-Detall.jpg',

        hight: '/img/2021/collection/high/3750-CH-V.jpg',
        back_hight: '/img/2021/collection/high/3750-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3750-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3750-V-Detall.jpg',

        video: '/img/2021/collection/videos/3750-CH-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3750],
    },
    {
        title: '3751',

        light: '/img/2021/collection/light/3751-V.jpg',
        back_light: '/img/2021/collection/light/3751-V-Back.jpg',
        front_light: '/img/2021/collection/light/3751-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3751-V-Detall.jpg',

        hight: '/img/2021/collection/high/3751-V.jpg',
        back_hight: '/img/2021/collection/high/3751-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3751-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3751-V-Detall.jpg',

        video: '/img/2021/collection/videos/3751-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3751],
    },
    {
        title: '3752',

        light: '/img/2021/collection/light/3752-V.jpg',
        back_light: '/img/2021/collection/light/3752-V-Back.jpg',
        front_light: '/img/2021/collection/light/3752-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3752-V-Detall.jpg',

        hight: '/img/2021/collection/high/3752-V.jpg',
        back_hight: '/img/2021/collection/high/3752-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3752-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3752-V-Detall.jpg',

        video: '/img/2021/collection/videos/3752-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3752],
    },
    {
        title: '3753',

        light: '/img/2021/collection/light/3753-CH-V.jpg',
        back_light: '/img/2021/collection/light/3753-CH-V-Back.jpg',
        front_light: '/img/2021/collection/light/3753-CH-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3753-CH-V-Detall.jpg',

        hight: '/img/2021/collection/high/3753-CH-V.jpg',
        back_hight: '/img/2021/collection/high/3753-CH-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3753-CH-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3753-CH-V-Detall.jpg',

        video: '/img/2021/collection/videos/3753-CH-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3753],
    },
    {
        title: '3754',

        light: '/img/2021/collection/light/3754-V.jpg',
        back_light: '/img/2021/collection/light/3754-V-Back.jpg',
        front_light: '/img/2021/collection/light/3754-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3754-V-Detall.jpg',

        hight: '/img/2021/collection/high/3754-V.jpg',
        back_hight: '/img/2021/collection/high/3754-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3754-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3754-V-Detall.jpg',

        video: '/img/2021/collection/videos/3754-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[3754],
    },
    /*{
        title: '3755',

        light: '/img/2021/collection/light/3755-V.jpg',
        back_light: '/img/2021/collection/light/3755-V-Back.jpg',
        front_light: '/img/2021/collection/light/3755-V-Front.jpg',
        detail_light: '/img/2021/collection/light/3755-V-Detall.jpg',

        hight: '/img/2021/collection/high/3755-V.jpg',
        back_hight: '/img/2021/collection/high/3755-V-Back.jpg',
        front_hight: '/img/2021/collection/high/3755-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/3755-V-Detall.jpg',

        video: '/img/2021/collection/videos/3755-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
    },*/
    {
        title: '6180',

        light: '/img/2021/collection/light/6180-A-V.jpg',
        back_light: '/img/2021/collection/light/6180-V-Back.jpg',
        front_light: '/img/2021/collection/light/6180-V-Front.jpg',
        detail_light: '/img/2021/collection/light/6180-V-Detall.jpg',

        hight: '/img/2021/collection/high/6180-A-V.jpg',
        back_hight: '/img/2021/collection/high/6180-V-Back.jpg',
        front_hight: '/img/2021/collection/high/6180-V-Front.jpg',
        detail_hight: '/img/2021/collection/high/6180-V-Detall.jpg',

        video: '/img/2021/collection/videos/6180-A-V-Teresa-Ripoll-clip.mp4',

        //border: '10px solid rgb(200,0,200)', // ImageGallery.js
        //shadow: 'none',
        trans: book_paris_trans[6180],
    },
]