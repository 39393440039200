

export const appBarT = {
    cocktail: {
        'ca': 'Coleccions Festa',
        'es': 'Colecciones Fiesta',
        'en': 'Cocktail Collections',
        "fr": "Collections Cocktail",
        "it": "Collezioni Festa", 
    },
    rainbow: {
        'ca': 'Coleccions Rainbow',
        'es': 'Colecciones Rainbow',
        'en': 'Rainbow Collections',
        "fr": "Collections Rainbow",
        "it": "Collezioni Rainbow", 
    },
    wedding: {
        'ca': 'Núvia',
        'es': 'Novia',
        'en': 'Wedding',
        "fr": "Mariage",
        "it": "Sposa",
    },
    campaign: {
        'ca': 'Campanya',
        'es': 'Campaña',
        'en': 'Campaign',
        "fr": "Campagne",
        "it": "Campagna",
    },
    films: {
        'ca': 'Films',
        'es': 'Films',
        'en': 'Films',
        'fr': 'Films',
        'it': 'Film',
    },
    history: {
        'ca': 'Història',
        'es': 'Historia',
        'en': 'History',
        'fr': 'l\'histoire',
        'it': 'Storia',
    },
    contact: {
        "ca": "Contacte",
        "es": "Contacto",
        "en": "Contact",
        "fr": "Contact",
        "it": "Contatto",
    },    
    storeLocator: {
        "ca": "Punts de venta",
        "es": "Puntos de venta",
        "en": "Store locator",
        "fr": "Localisateur de magasins",
        "it": "Punti di vendita",
    },
    language: {
        "es": 'Español',
        "en": 'English',
        "it": 'Italiano',
        //"fr": 'Francais',
        "ca": 'Català',
    },
}