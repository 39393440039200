import {book_chroma_trans} from '../../i18n/book_chroma.trans'

export const book_chroma = [
    {
        title: '7028',

        light: '/img/2024/light/Fotos/7028-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7028-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7028-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7028-v-teresaripoll.jpg',

        video: '/img/2024/movies/7028-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7028],
    },
    {
        title: '7029',

        light: '/img/2024/light/Fotos/7029-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7029-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7029-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7029-v-teresaripoll.jpg',

        video: '/img/2024/movies/7029-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(231, 224, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7029],
    },
    {
        title: '7030',

        light: '/img/2024/light/Fotos/7030-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7030-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7030-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7030-v-teresaripoll.jpg',

        video: '/img/2024/movies/7030-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(215, 210, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7030],
    },
    {
        title: '7031',

        light: '/img/2024/light/Fotos/7031-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7031-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7031-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7031-v-teresaripoll.jpg',

        video: '/img/2024/movies/7031-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(176, 208, 245)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7031],
    },
    {
        title: '7032',

        light: '/img/2024/light/Fotos/7032-bl-pa-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7032-bl-pa-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7032-bl-pa-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7032-bl-pa-teresaripoll.jpg',

        video: '/img/2024/movies/7032-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(235, 206, 164)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7032],
    },
    {
        title: '7033',

        light: '/img/2024/light/Fotos/7033-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7033-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7033-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7033-v-teresaripoll.jpg',

        video: '/img/2024/movies/7033-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(224, 164, 171)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7033],
    },
    {
        title: '7034',

        light: '/img/2024/light/Fotos/7034-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7034-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7034-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7034-v-teresaripoll.jpg',

        video: '/img/2024/movies/7034-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(238, 202, 202)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7034],
    },
    {
        title: '7035',

        light: '/img/2024/light/Fotos/7035-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7035-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7035-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7035-v-teresaripoll.jpg',

        video: '/img/2024/movies/7035-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(171, 182, 237)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7035],
    },
    {
        title: '7036',

        light: '/img/2024/light/Fotos/7036-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7036-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7036-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7036-v-teresaripoll.jpg',

        video: '/img/2024/movies/7036-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(210, 214, 215)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7036],
    },
    {
        title: '7037',

        light: '/img/2024/light/Fotos/7037-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7037-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7037-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7037-v-teresaripoll.jpg',

        video: '/img/2024/movies/7037-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(250, 182, 182)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7037],
    },
    {
        title: '7038',

        light: '/img/2024/light/Fotos/7038-bl-pa-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7038-bl-pa-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7038-bl-pa-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7038-bl-pa-teresaripoll.jpg',

        video: '/img/2024/movies/7038-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(154, 178, 224)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7038],
    },
    {
        title: '7039',

        light: '/img/2024/light/Fotos/7039-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7039-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7039-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7039-v-teresaripoll.jpg',

        video: '/img/2024/movies/7039-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7039],
    },
    {
        title: '7040',

        light: '/img/2024/light/Fotos/7040-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7040-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7040-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7040-v-teresaripoll.jpg',

        video: '/img/2024/movies/7040-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(231, 224, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7040],
    },
    {
        title: '7041',

        light: '/img/2024/light/Fotos/7041-v-teresaripoll.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/7041-v-teresaripoll.jpg',

        hight: '/img/2024/hight/Fotos/7041-v-teresaripoll.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/7041-v-teresaripoll.jpg',

        video: '/img/2024/movies/7041-v-teresaripoll-ss24-clip.mp4',

        border: '8px solid rgb(215, 210, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_chroma_trans[7041],
    },
]