import React, { useState, useEffect } from 'react'
import { lang } from '../i18n/i18n'
import { i18n } from '../i18n/Atelier.trans'
import * as FA from 'react-fontawesome'
import { useMobile } from '../hooks/useMobile'
import { useForm } from '../hooks/useForm'
import { validateEmail } from '../helpers/validators'
import { sendMail } from '../helpers/sendMail'

export const AtelierForm = ({isVisible, onClose}) => {
    const mobile = useMobile()
    const [formMessage, setFormMessage] = useState('')
    const [visible, setVisible] = useState(isVisible)
    const [FORM_WIDTH, setFormWidth] = useState(mobile ? 300 : 400)

    const [ values, handleInputChange ] = useForm({
      name:"",
      email:"",
      phone:"",
      message:"",
    })

    const [errors, setErrors] = useState({
      email: "", 
      message: "",
    })

    useEffect(() => {
      window.addEventListener('resize', () => {
        setFormWidth(mobile ? 300 : 400)
        console.log(FORM_WIDTH)
      })
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setVisible(isVisible)
    }, [isVisible])

    const onSubmit = (e) => {
      e.preventDefault()
      const cleanErrors = {email:"", message: ""} 
      setErrors(cleanErrors)
      // Validations
      if (! validateEmail(values.email)) setErrors({...errors, email:"Enter a valid email."})
  
      // Send message if no errors
      if ( JSON.stringify(errors) === JSON.stringify(cleanErrors)) {
        sendMail("atelier", values)
        setFormMessage(i18n['MESSAGE-SENT'][lang])
      } 
    }

    return (
        <div style={{
            ...styles.container, 
            width: FORM_WIDTH,
            left: visible ? window.innerWidth * 0.5 - FORM_WIDTH * 0.5 : -1000
        }}>
            <div 
                style={styles.btnClose}
                onClick={ () => onClose() }
            ><FA name="times"/> </div>
            <form onSubmit={onSubmit}>	
              <div style={styles.divField}>           
                <label for="name" style={styles.label}>{i18n.NAME[lang]}:</label>
                <input name="name" type="text"  id="atelier-name" required  onChange={handleInputChange}/>
              </div>
              <div style={styles.divField}> 
                <label for="email" style={styles.label}>Email:</label>
                <input name="email" type="text" id="atelier-email" required  onChange={handleInputChange} style={errors.email === "" ? styles.inputField : styles.inputFieldError} />
              </div>
              <div style={styles.divField}> 
                <label for="phone" style={styles.label}>{i18n.PHONE[lang]}:</label>
                <input name="phone" type="text"  id="atelier-phone" onChange={handleInputChange}/>
              </div>
              <div style={styles.divField}> 
                <label for="message" style={styles.label}>{i18n.MESSAGE[lang]}:</label>
                <textarea name="message" cols="5" rows="3"  id="atelier-message" required onChange={handleInputChange}></textarea>
              </div>
    
              <div>
                <input type="checkbox" name="accept-policy" value="accept-policy" required/>
                <label for="accept-policy" style={styles.label}>
                  &ensp;
                  {i18n["ACCEPT-POLICY"][lang]}
                  <a href="/" id="atelier-privacy-policy"> {i18n["PRIVACY-POLICY"][lang]}</a>
                </label>
              </div>
    
              <input type="submit" style={styles.btnSend} value={i18n.SEND_MESSAGE[lang]} />
            </form>	
            <p id="contact-form-message" style={{textAlign:'center', color:'green'}}>{formMessage}</p>
        </div>
    )
}

const styles = {
    container: {
        height: 400,
        backgroundColor: 'rgba(200,200,200,0.9)',
        position: 'absolute',
        top: window.innerHeight * 0.5 - 175,
        //left: window.innerWidth * 0.5 - FORM_WIDTH * 0.5,
        padding: 15,
        transition: 'left 1s ease',
    },
    btnClose: {
        position: 'absolute',
        right: 0,
        top: 0,
        backgroundColor: 'transparent',
        display: 'flex',
        width: 30,
        height: 30,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    divField: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginTop: 3,
        fontSize: 10,
    },
    btnSend: {
        position: 'absolute',
        width: '100%',
        backgroundColor: 'orange',
        border: 0,
        bottom: 20,
        left: 0,
    },
    inputField: {

    },
    inputFieldError: {
      backgroundColor: '#eee',
      height: 30,
      padding: 5,
      marginTop: -10,
  
      border: '1px solid red',
      borderColor: 'red',
      borderRadius: 10,
    },
}
