

export const book_rainbow_trans = {
    '7000': {
        'en': 'Short semi-fitted blue crepe dress with beautiful bow detail on the shoulders that lengthen the body and stylise the silhouette. It has French sleeves and fastens at the back with an invisible zip.',
        'es': 'Vestido corto semi-entallado de crepe azul con bonito detalle de lazo en los hombros que alarga el cuerpo y estiliza la silueta. Tiene mangas francesas y se abrocha en la espalda con cremallera invisible.',
        'fr': "Robe courte semi-ajustée en crêpe bleu avec un joli détail de nœud sur les épaules qui allonge le corps et stylise la silhouette. Cette robe a des manches françaises et se ferme dans le dos avec una fermeture eclair invisible.",
        'it': "Abito corto in crepe blu semiaderente con bellissimo dettaglio di fiocco sulle spalle che allungano il corpo e stilizzano la silhouette. Ha le maniche alla francese e si chiude dietro con una zip invisibile.", 
        'ca': 'Vestit curt de crepe blau semi ajustat amb bonic detall de llaç a les espatlles que allarga el cos i estilitza la silueta. Té mànigues franceses i es tanca a la part posterior amb una cremallera invisible.',
    },
    '7001':{
        'en': 'Short dress in turquoise crepe. The neckline features a cut at the centre which is very elegant and flattering for all sizes. The 3/4 sleeve has an opening at the end to allow it to be folded back. It has a back fastening with an invisible zip.',
        'es': 'Vestido corto en crepe turquesa. El escote presenta un corte en el centro muy elegante y favorecedor para todas las tallas. La manga 3/4 tiene una abertura en el extremo para permitir que se pliegue. Tiene cierre trasero con cremallera invisible.',
        'fr': 'Robe courte en crêpe turquoise. Le décolleté présente une échancrure au centre qui est très élégante et flatteuse pour toutes les tailles. La manche 3/4 a une ouverture à son extrêmité pour permettre de la replier. Et la robe se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Abito corto in crepe turchese. La scollatura presenta un taglio al centro che è molto elegante e lusinghiero per tutte le taglie. La manica 3/4 ha una apertura all’estremità per poterle piegare. Ha una chiusura posteriore con zip invisibile.',
        'ca': 'Vestit curt de crepe turquesa. L\'escot presenta un tall al centre, molt elegant i afavoridor per a totes les talles. La màniga 3/4 té una obertura al final per permetre que es plegui. Té una subjecció posterior amb una cremallera invisible.',
    },
    '7003': {
        'en': 'Beautiful short dress in red crepe with a wrap neck. Teresa Ripoll\'s iconic neck lifts the shoulders and lengthens the body, flattering and enhancing the feminine silhouette. It has 3/4 sleeves and fastens at the back with an invisible zip.',
        'es': 'Precioso vestido corto en crepe rojo con cuello envolvente. El icónico cuello de Teresa Ripoll levanta los hombros y alarga el cuerpo, favoreciendo y realzando la silueta femenina. Tiene mangas 3/4 y se abrocha en la espalda con cremallera invisible.',
        'fr': 'Belle robe courte en crêpe rouge avec un col cache-cœur. Le col emblématique de Teresa Ripoll elève les épaules et allonge le corps, favorisant et mettant en valeur la silhouette féminine. Il a des manches 3/4 et elle se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Bellissimo abito corto in crepe rosso con collo avvolgente. L\'iconico collo di Teresa Ripoll solleva le spalle e allunga il corpo, valorizzando la silhouette femminile. Ha maniche a 3/4 e si chiude dietro con una zip invisibile.',
        'ca': 'Bonic vestit curt de crepe vermell amb coll envolvent. L\'emblemàtic coll de Teresa Ripoll aixeca les espatlles i allarga el cos, estilitzant i realçant la silueta femenina. Té mànigues 3/4 i es tanca a la part posterior amb una cremallera invisible.',
    },
    '7004':{
        'en': 'Flattering short crepe dress in silver tones with a split wrap-around neckline. This collar is a variation of the iconic Teresa Ripoll collar that highlights the female silhouette by lifting the shoulders and lengthening the body. It has 3/4 sleeves and fastens at the back with an invisible zip.',
        'es': 'Bonito vestido corto de crepé en tonos plateados con cuello envolvente dividido. Este cuello es una variación del icónico cuello Teresa Ripoll que resalta la silueta femenina levantando los hombros y alargando el cuerpo. Tiene mangas 3/4 y se abrocha en la espalda con cremallera invisible.',
        'fr': 'Robe courte flatteuse en crêpe dans des tons argentés avec une encolure cache-cœur fendue. Ce col est une déclinaison de l\'iconique col Teresa Ripoll qui met en valeur la silhouette féminine en soulevant les épaules et en allongeant le corps. Cette robe a des manches 3/4 et se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Elegante abito corto in crepe nei toni dell\'argento con scollatura avvolgente divisa. Questo colletto è una variante dell\'iconico colletto Teresa Ripoll che mette in risalto la silhouette femminile sollevando le spalle e allungando il corpo. Ha maniche a 3/4 e si chiude dietro con una zip invisibile.',
        'ca': 'Afavoridor vestit curt de crepe en tons platejats, amb un escot envolvent dividit. Aquest coll és una variació de l’emblemàtic coll Teresa Ripoll que ressalta la silueta femenina aixecant les espatlles i allargant el cos. Té mànigues 3/4 i es tanca a la part posterior amb una cremallera invisible.',
    },
    '7005': {
        'en': 'Glamorous short dress in pale pink crepe with natural marabou feather cuffs. The cut of the skirt is in A line, the sleeve is 3/4 length and it fastens at the back with an invisible zip.',
        'es': 'Glamuroso vestido corto de crepé rosa palo con puños de plumas de marabú natural. El corte de la falda es evasé, la manga es de largo 3/4 y se abrocha en la espalda con cremallera invisible.',
        'fr': 'Robe courte glamour en crêpe rose pâle avec poignets en plumes de marabout naturel. La coupe de la jupe est en A, la manche est 3/4 et elle se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Abito corto glamour in crepe rosa pallido con polsini in piume di marabù naturali. Il taglio della gonna è ad A, la manica è a 3/4 e si chiude dietro con una zip invisibile.',
        'ca': 'Glamorós vestit curt de crepe rosa pàl·lid amb punys de ploma de marabú natural. El tall de la faldilla és en evassé, la màniga és de llarg 3/4i es corda a la part posterior amb una cremallera invisible.',
    },
    '7006': {
        'en': 'Sophisticated short dress in stone-coloured crepe. This is another of Teresa Ripoll\'s iconic collars and it has a small bow detail. The sleeve is 3/4 length and fastens at the back with an invisible zip.',
        'es': 'Sofisticado vestido corto en crepé color piedra. Este es otro de los cuellos icónicos de Teresa Ripoll con el detalle de un pequeño lazo. La manga es de largo 3/4 y se abrocha en la espalda con una cremallera invisible.',
        'fr': 'Robe courte raffinée en crêpe couleur pierre, avec un autre des cols emblématiques de Teresa Ripoll, avec un petit nœud. La manche est 3/4 et elle se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Sofisticato abito corto in crepe color pietra. Questo è un altro dei colletti iconici di Teresa Ripoll e presenta un piccolo dettaglio a fiocco. La manica è a 3/4 e si chiude dietro con una zip invisibile.',
        'ca': 'Sofisticat vestit curt de crepe color pedra. Aquest és un altre dels icònics colls de Teresa Ripoll amb un petit detall de llaç. La màniga és de llarg 3/4 i es corda a la part posterior amb una cremallera invisible.',
    },
    '7007': {
        'en': 'Short dress in electric blue crepe, with a neck that falls in a V at the back. 3/4 sleeves with elegant detail on the cuffs. It fastens at the back with an invisible zip.',
        'es': 'Vestido corto en crepé azul eléctrico, con cuello que cae en V en la espalda. Mangas 3/4 con elegante detalle en los puños. Se abrocha por la espalda con cremallera invisible.',
        'fr': 'Robe courte en crêpe bleu électrique, avec un col qui tombe en V dans le dos. Manches 3/4 avec détail élégant sur les poignets. Elle se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Abito corto in crepe blu elettrico, con collo che scende a V sul retro. Maniche a 3/4 con elegante dettaglio sui polsini. Si chiude dietro con una zip invisibile.',
        'ca': 'Vestit curt de crepe blau elèctric, amb un coll que cau en V a la part posterior. Mànigues 3/4 amb detalls elegants als punys. Es corda a la part posterior amb una cremallera invisible.',
    },
    '7008': {
        'en': 'Midi length dress in bougainvillea crepe. It has one of the iconic Teresa Ripoll collars and a flattering panel at the waist. It has 3/4 sleeves and fastens at the back with an invisible zip.',
        'es': 'Vestido midi en crepe bugambilia. Tiene uno de los cuellos icónicos de Teresa Ripoll y una favorecedora pieza en cintura. Tiene mangas 3/4 y se abrocha en la espalda con cremallera invisible.',
        'fr': 'Robe mi-longue en crêpe bougainvillier, avec un des cols emblématiques Teresa Ripoll et une bande qui entoure et favorise la ceinture. Les manches sont 3/4 et elle se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Abito midi in crepe di bouganville. Ha uno degli iconici colletti Teresa Ripoll e un lusinghiero pannello in vita. Ha maniche a 3/4 e si chiude dietro con una zip invisibile.',
        'ca': 'Vestit llarg llarg en crep de color buganvilla. Té un dels icònics colls Teresa Ripoll i una afavoridora peça a la cintura. Té mànigues 3/4 i es tanca a la part posterior amb una cremallera invisible.',
    },
    '7010': {
        'en': 'Elegant short dress in pink crepe. The low-bust cut and the V-shaped piece at the waist soften and help to soften the curves of the bust. The sleeve is ¾ length and fastens at the back with an invisible zip.',
        'es': 'Elegante vestido corto en crepe rosa. El corte bajo pecho y la pieza en forma de V suaviza y acompañan las curvas del busto. La manga es de largo ¾ y se abrocha en la espalda con una cremallera invisible.',
        'fr': 'Robe courte élégante en crêpe rose. La coupe en V sous la poitrine adoucit et accompagne les courbes du buste. Les manches sont 3/4 et celle ci se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Elegante abito corto in crepe rosa. Il taglio a busto basso e il pezzo a V in vita ammorbidiscono e aiutano ad ammorbidire le curve del busto. La manica è lunga ¾ e si chiude dietro con una zip invisibile.',
        'ca': 'Elegant vestit curt de crepè rosa. El tall sota pit i la peça en forma de V a la cintura suavitzen i acompanyen les corbes del bust. La màniga té una longitud de ¾ i es corda a la part posterior amb una cremallera invisible.',
    },
    '7012': {
        'en': 'Spectacular midi length dress in cherry crepe with natural marabou feather detail on the shoulders. It has ¾ length sleeves and fastens at the back with an invisible zip.',
        'es': 'Espectacular vestido de largo midi en crepe cereza con detalle de pluma natural de marabú en los hombros. Lo acompañan una manga francesa y se abrocha por la espalda con cremallera invisible.',
        'fr': 'Spectaculaire robe mi-longue en crêpe cerise avec détail de plumes de marabout naturel sur les épaules et des manches françaises. Elle se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Tutto Belissimo abito midi in crepe ciliegia con dettaglio di piume di marabù naturali sulle spalle. Ha maniche a e si chiude dietro con una zip invisibile.',
        'ca': 'Espectacular vestit midi de crep de color cirera amb detall de ploma de marabú natural als hombros. Té mànigues de llarg ¾ i es tanca a la part posterior amb una cremallera invisible.',
    },
    '7013': {
        'en': 'Midi length dress in green crepe, semi-fitted and with a square back neckline. The 3/4 sleeve has a subtle slightly puffed shoulder with an opening on the cuff to allow it to be turned back. It fastens at the rear with an invisible zip.',
        'es': 'Vestido midi en crepé verde, semi entallado y con escote cuadrado en la espalda. La manga 3/4 tiene un hombro sutil y ligeramente abombado con una abertura en el puño para permitir que se le dé la vuelta. Se abrocha en la espalda con cremallera invisible.',
        'fr': 'Robe mi-longue en crêpe vert, semi-ajustée et avec un décolleté carré dans le dos. La manche 3/4 a une épaule légèrement bouffante avec une ouverture sur le poignet pour lui permettre de se retourner. Elle se ferme dans la dos avec una fermeture eclair invisible.',
        'it': 'Abito midi in crepe verde, semiaderente e con scollo quadrato dietro. La manica a 3/4 ha una sottile spalla leggermente a sbuffo con un apertura sul polsino per consentire di risvoltarle. Si chiude dietro con una zip invisibile.',
        'ca': 'Vestit de llarg midí en crepé verd, semi-ajustat i amb generós escot a l’esquena. La màniga 3/4 té l’hombro arrugat amb una obertura al puny per permetre portar-la girada. Es fixa a la part posterior amb una cremallera invisible.',
    }
}

    