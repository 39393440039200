
export const bookn_sweet = [
    {
        light:"/img/wedding/novia18/a/14t copia.jpg", 
        hight:"/img/wedding/novia18/c/14t copia.jpg", 
        title:"8062"
    },
    {
        light:"/img/wedding/novia18/a/13t copia.jpg", 
        hight:"/img/wedding/novia18/c/13t copia.jpg", 
        title:"8062"
    },
    {
        light:"/img/wedding/novia18/a/1t copia.jpg",  
        hight:"/img/wedding/novia18/c/1t copia.jpg",  
        title:"8054"
    },
    {
        light:"/img/wedding/novia18/a/3t copia.jpg",  
        hight:"/img/wedding/novia18/c/3t copia.jpg",  
        title:"8054"
    },
    {
        light:"/img/wedding/novia18/a/4t copia.jpg",  
        hight:"/img/wedding/novia18/c/4t copia.jpg",  
        title:"8058"
    },
    {
        light:"/img/wedding/novia18/a/6t copia.jpg",  
        hight:"/img/wedding/novia18/c/6t copia.jpg",  
        title:"8058"
    },
    {
        light:"/img/wedding/novia18/a/17t copia.jpg", 
        hight:"/img/wedding/novia18/c/17t copia.jpg", 
        title:"8056"
    },
    {
        light:"/img/wedding/novia18/a/9t copia.jpg",  
        hight:"/img/wedding/novia18/c/9t copia.jpg",  
        title:"8055"
    },
    {
        light:"/img/wedding/novia18/a/8t copia.jpg",  
        hight:"/img/wedding/novia18/c/8t copia.jpg",  
        title:"8055"
    },
    {
        light:"/img/wedding/novia18/a/11t copia.jpg", 
        hight:"/img/wedding/novia18/c/11t copia.jpg", 
        title:"8054BL_8053PA"
    },
    {
        light:"/img/wedding/novia18/a/18t copia.jpg", 
        hight:"/img/wedding/novia18/c/18t copia.jpg", 
        title:"8057"
    },
    {
        light:"/img/wedding/novia18/a/19t copia.jpg", 
        hight:"/img/wedding/novia18/c/19t copia.jpg", 
        title:"8057"
    },
    {
        light:"/img/wedding/novia18/a/21t copia.jpg", 
        hight:"/img/wedding/novia18/c/21t copia.jpg", 
        title:"8050"
    },
    {
        light:"/img/wedding/novia18/a/24t copia.jpg", 
        hight:"/img/wedding/novia18/c/24t copia.jpg", 
        title:"8053BL_8054F"
    },
    {
        light:"/img/wedding/novia18/a/25t copia.jpg", 
        hight:"/img/wedding/novia18/c/25t copia.jpg", 
        title:"8061BL_8056F"
    },
    {
        light:"/img/wedding/novia18/a/26t copia.jpg", 
        hight:"/img/wedding/novia18/c/26t copia.jpg", 
        title:"8061BL_8056F"
    }

]