import {book_timeless_trans} from '../../i18n/book_timeless.trans'

export const book_timeless = [
    {
        title: '2225',

        light: '/img/2024/light/Fotos/2225-bl-f-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/2225-bl-f-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/2225-bl-f-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/2225-bl-f-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/2225-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[2225],
    },
    {
        title: '2226',

        light: '/img/2024/light/Fotos/2226-bl-pa-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/2226-bl-pa-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/2226-bl-pa-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/2226-bl-pa-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/2226-bl-pa-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[2226],
    },
    {
        title: '2227',

        light: '/img/2024/light/Fotos/2227-bl-f-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/2227-bl-f-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/2227-bl-f-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/2227-bl-f-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/2227-bl-f-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[2227],
    },
    {
        title: '2228',

        light: '/img/2024/light/Fotos/2228-ch-bl-pa-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/2228-ch-bl-pa-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/2228-ch-bl-pa-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/2228-ch-bl-pa-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/2228-ch-bl-pa-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[2228],
    },
    {
        title: '2229',

        light: '/img/2024/light/Fotos/2229-ch-f-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/2229-ch-f-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/2229-ch-f-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/2229-ch-f-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/2229-ch-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[2229],
    },
    {
        title: '3788',

        light: '/img/2024/light/Fotos/3788-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3788-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3788-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3788-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3788-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3788],
    },
    {
        title: '3789',

        light: '/img/2024/light/Fotos/3789-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3789-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3789-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3789-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3789-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3789],
    },
    {
        title: '3790',

        light: '/img/2024/light/Fotos/3790-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3790-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3790-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3790-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3790-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3790],
    },
    {
        title: '3791',

        light: '/img/2024/light/Fotos/3791-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3791-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3791-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3791-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3791-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3791],
    },
    {
        title: '3792',

        light: '/img/2024/light/Fotos/3792-ch-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3792-ch-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3792-ch-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3792-ch-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3792-ch-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3792],
    },
    {
        title: '3793',

        light: '/img/2024/light/Fotos/3793-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3793-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3793-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3793-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3793-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3793],
    },
    {
        title: '3794',

        light: '/img/2024/light/Fotos/3794-ch-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3794-ch-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3794-ch-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3794-ch-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3794-ch-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3794],
    },
    {
        title: '3795',

        light: '/img/2024/light/Fotos/3795-ca-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3795-ca-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3795-ca-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3795-ca-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3795-ca-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3795],
    },
    {
        title: '3796',

        light: '/img/2024/light/Fotos/3796-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3796-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3796-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3796-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3796-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3796],
    },
    {
        title: '3797',

        light: '/img/2024/light/Fotos/3797-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3797-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3797-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3797-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3797-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3797],
    },
    {
        title: '3798',

        light: '/img/2024/light/Fotos/3798-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3798-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3798-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3798-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3798-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3798],
    },
    {
        title: '3799',

        light: '/img/2024/light/Fotos/3799-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3799-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3799-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3799-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3799-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3799],
    },
    {
        title: '3800',

        light: '/img/2024/light/Fotos/3800-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3800-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3800-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3800-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3800-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3800],
    },
    {
        title: '3801',

        light: '/img/2024/light/Fotos/3801-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3801-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3801-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3801-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3801-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3801],
    },
    {
        title: '3802',

        light: '/img/2024/light/Fotos/3802-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3802-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3802-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3802-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3802-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3802],
    },
    {
        title: '3803',

        light: '/img/2024/light/Fotos/3803-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3803-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3803-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3803-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3803-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3803],
    },
    {
        title: '3804',

        light: '/img/2024/light/Fotos/3804-ch-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3804-ch-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3804-ch-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3804-ch-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3804-ch-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3804],
    },
    {
        title: '3804',

        light: '/img/2024/light/Fotos/3804-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3804-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3804-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3804-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3804-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3804],
    },
    {
        title: '3805',

        light: '/img/2024/light/Fotos/3805-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3805-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3805-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3805-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3805-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3805],
    },
    {
        title: '3806',

        light: '/img/2024/light/Fotos/3806-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3806-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3806-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3806-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3806-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3806],
    },
    {
        title: '3807',

        light: '/img/2024/light/Fotos/3807-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3807-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3807-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3807-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3807-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3807],
    },
    {
        title: '3808',

        light: '/img/2024/light/Fotos/3808-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3808-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3808-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3808-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3808-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3808],
    },
    {
        title: '3809',

        light: '/img/2024/light/Fotos/3809-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3809-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3809-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3809-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3809-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3809],
    },
    {
        title: '3810',

        light: '/img/2024/light/Fotos/3810-ca-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3810-ca-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3810-ca-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3810-ca-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3810-ca-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3810],
    },
    {
        title: '3811',

        light: '/img/2024/light/Fotos/3811-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/3811-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/3811-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/3811-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/3811-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[3811],
    },
    {
        title: '6182',

        light: '/img/2024/light/Fotos/6182-a-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/6182-a-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/6182-a-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/6182-a-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/6182-a-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[6182],
    },
    {
        title: '6182',

        light: '/img/2024/light/Fotos/6182-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/6182-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/6182-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/6182-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/6182-a-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[6182],
    },
    {
        title: '6183',

        light: '/img/2024/light/Fotos/6183-a-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/6183-a-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/6183-a-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/6183-a-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/6183-a-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[6183],
    },
    {
        title: '6183',

        light: '/img/2024/light/Fotos/6183-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/6183-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/6183-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/6183-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/6183-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[6183],
    },
    {
        title: '6184',

        light: '/img/2024/light/Fotos/6184-a-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/6184-a-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/6184-a-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/6184-a-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/6184-a-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[6184],
    },
    {
        title: '6185',

        light: '/img/2024/light/Fotos/6185-a-v-teresaripoll-ss24.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2024/light/Detalls/6185-a-v-teresaripoll-ss24.jpg',

        hight: '/img/2024/hight/Fotos/6185-a-v-teresaripoll-ss24.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2024/hight/Detalls/6185-a-v-teresaripoll-ss24.jpg',

        video: '/img/2024/movies/6185-a-v-teresaripoll-ss24-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_timeless_trans[6185],
    },
]