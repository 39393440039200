import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { i18n } from '../i18n/Contact.trans'
import { lang } from '../i18n/i18n'
import { TextWrite } from '../components/TextWrite'
import { useForm } from '../hooks/useForm'
import { validateEmail } from '../helpers/validators'
import { useMobile } from '../hooks/useMobile'
import { sendMail } from '../helpers/sendMail'

export const Contact = () => {
  const mobile = useMobile()
  const [formMessage, setFormMessage] = useState('')
  const [ values, handleInputChange ] = useForm({
    name:"",
    email:"",
    phone:"",
    message:"",
  })

  const [errors, setErrors] = useState({
    email: "",
    message: "",
  })

  const onSubmit = (e) => {
    e.preventDefault()
    const cleanErrors = {email:"", message: ""} 
    setErrors(cleanErrors)
    // Validations
    if (! validateEmail(values.email)) setErrors({...errors, email:"Enter a valid email."})

    // Send message if no errors
    if ( JSON.stringify(errors) === JSON.stringify(cleanErrors)) {
      sendMail("contact", values)
      setFormMessage(i18n['MESSAGE-SENT'][lang])
    } 
  }

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div style={styles.container}>
      <div style={{
        display: 'flex',
        width: mobile ? '90%' : '70%',
        flexDirection: mobile ? 'column' : 'row', 
        maxWidth: 1000,
        marginTop: mobile ? 100 : 0,
        backgroundColor: 'transparent',
      }}>

      <div id="div-contact-address" style={{width: mobile ? '95%' : '45%', display:'flex', alignItems: 'center', marginBottom: 50}}>
        <div>
        <h5 style={{fontWeight:'bold'}}> TERESA RIPOLL </h5><br />
        <p style={{color:"#666"}}>
          <span style={{color:"black", fontWeight:'bold'}}>{i18n.COMPANY[lang]}</span>: <br />
          Maria Ripoll S.L. <br />
          C/Dr.Alfons Torrent,4<br />
          17403 Sant Hilari Sacalm<br />
          Girona (Spain)
        </p>
        <h6><a href="mailto:info@teresaripoll.com">info@teresaripoll.com</a></h6>
        </div>
      </div>

      <div id="div-contact-form" style={{width: mobile ? '95%' : '45%',}}>

        <p style={styles.contactText}>{i18n.CONTACT[lang]}</p>
        {/* Per enviar el correu: helpers/email.js -> sendMail(), no utilitzar: <!-- action="enviar.php" -->*/}
        <form onSubmit={onSubmit} id="contact-form" name="Contact form">
          <input type="hidden" name="contact" />

          <div style={styles.fieldDiv}>
            {/*<label for="name">{i18n.NAME[lang]}</label>*/}
            <TextWrite text={i18n.NAME[lang]} style={styles.labels}/>
            <input name="name" type="text" style={styles.inputField} onChange={handleInputChange} required />
          </div>

          <div style={styles.fieldDiv}>
            {/*<label for="email">Email:</label>*/}
            <TextWrite text="Email" style={styles.labels}/>
            <input name="email" type="text" style={errors.email === "" ? styles.inputField : styles.inputFieldError} onChange={handleInputChange} required />
            {errors.email !== "" && <p style={{color:'red', fontSize:12}}>{errors.email}</p>}
          </div>

          <div style={styles.fieldDiv}>
            {/*<label for="phone">{i18n.PHONE[lang]}</label>*/}
            <TextWrite text={i18n.PHONE[lang]} style={styles.labels}/>
            <input name="phone" type="text" style={styles.inputField} onChange={handleInputChange} />
          </div>

          <div style={styles.fieldDiv}>
            {/*<label for="message">{i18n.MESSAGE[lang]}</label>*/}
            <TextWrite text={i18n.MESSAGE[lang]} style={styles.labels}/>
            <textarea name="message" cols="45" rows="5" className="campo2" onChange={handleInputChange} required></textarea>
          </div>

          <div>
            <input type="checkbox" name="accept-policy" value="accept-policy" required />
            <label for="accept-policy">
              &ensp;
              {i18n["ACCEPT-POLICY"][lang]}
              {/*<a href="/" id="privacy-policy"> {i18n["PRIVACY-POLICY"][lang]}</a>*/}
              <Link to='/privacypolicy' style={styles.link}> {i18n["PRIVACY-POLICY"][lang]}</Link>
            </label>
          </div>

          <div style={styles.fieldDiv}>
            <input type="submit" name="contact-enviar" style={styles.submitBtn} value={i18n['SEND-MESSAGE'][lang]} />
          </div>
        </form>

        <p id="contact-form-message" style={{textAlign:'center', color:'green'}}>{formMessage}</p>

      </div>

      </div>
    </div>
  )
}


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: window.innerHeight,
  },
  fieldDiv: {
    display: 'flex',
    flexDirection: 'column',
  },
  contactText: {
    fontSize: 35,
  },
  labels: {
    fontSize:17,
    color: '#555',
    marginTop: 10,
  },
  inputField: {
    backgroundColor: '#eee',
    height: 40,
    padding: 5,
    marginTop: -13,

    border: 0,
  },
  inputFieldError: {
    backgroundColor: '#eee',
    height: 40,
    padding: 5,
    marginTop: -10,

    border: '1px solid red',
    borderColor: 'red',
    borderRadius: 10,
  },
  submitBtn: {
    backgroundColor: '#0073d1',
    color: 'white',
    border: 0,
    padding: 10,
    marginTop: 20,
  },
}
