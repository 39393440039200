import React, {useEffect, useState} from 'react'
import { LoadingImg } from './LoadingImg'
import * as FA from 'react-fontawesome'

var imageWidth = 400 // hight: 1200 x 2000

export const ImageZoom = ({card, scroll, onClick, selectedImage}) => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.scrollBy(0, window.scrollY * -1)
        /*setTimeout(() => {
            window.scrollBy(imageWidth, 0)
        }, 200)*/
    }, [card])

    const onClose = () => {
        onClick()
        setTimeout(() => {
            //console.log(`x: ${scroll.x}, y: ${scroll.y}`)
            window.scrollTo(scroll.x, scroll.y)
        }, 200)
    }

    const endLoading = () => {
        imageWidth = selectedImage > 0 ? 100 : 400
        window.scrollBy(imageWidth, 0);
        setLoading(false)
    }

    return (
        <div style={{...styles.container, height: selectedImage > 0 ? 1000 : 2000}}>
            <div style={styles.close} onClick={onClose}><FA name="close"/></div>
            <div style={styles.title}>{card.title}</div>
            <img src={card.hight} alt={card.title} style={styles.img} onLoad={ endLoading }/>
            { loading  && <LoadingImg/>}
        </div>
    )
}


const styles = {
    container: {
        position: 'absolute',
        width: '100%',
        //height: '100%',
        //minHeight: 2000,
        top:0,
        left:0,
        zIndex: 1000000,
        backgroundColor: 'white',
    },
    loading: {
        position: 'absolute',
        top: document.documentElement.clientHeight * 0.5,
    },
    img: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        // width: 50%,
        height: '100%',
    },
    close: {
        position: 'fixed',
        top: 20,
        right: 20,
        backgroundColor: 'rgba(0,0,0,.5)',
        color: 'white',
        padding: 15,        
    },
    title: {
        position: 'fixed',
        top: 20,
        right: 65,
        backgroundColor: 'rgba(0,0,0,.5)',
        color: 'white',
        padding: 15,
    }
}