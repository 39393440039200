import React from 'react'

export const LoadingImg = () => {
    return (
        <img 
        src="/img/loading.gif" 
        style={{
            ...styles.loading,
            left: document.documentElement.clientWidth * 0.5 - 37.5,
        }}
        alt="loading"/>
    )
}

const styles = {
    loading: {
        position: 'fixed',
        top: document.documentElement.clientHeight * 0.5,
        width: 75,
        height: 75,
        zIndex: 1000,
    },
}
