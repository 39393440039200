import React, { useState } from 'react'
import { LoadingImgAbsolute } from './LoadingImgAbsolute'

export const ImageCardWithLoading = ({card, containerWidth, setZoomImageUrl, showTitle=false}) => {
    const [loading, setLoading] = useState(true)
    const [titleSize, setTitleSize] = useState(window.innerWidth >= 768 ? window.innerWidth / 40 : 25)
    return (
        <div
        style={{ 
          width: containerWidth, 
          boxShadow: card.shadow == null ? '3px 3px 10px #bbb' : card.shadow, 
          padding: 10, 
          border: card.border == null ? 'none' : card.border,
          position: 'relative'
        }}
        onClick={() => setZoomImageUrl(card)}
      >
        { showTitle && <span style={{paddingLeft:5, color:'#ddd', fontFamily:'courier', fontWeight:'bold', fontSize: titleSize}}>{ card.title }</span>}
        <img style={{ width: '100%' }} src={card.light} alt={card.title} loading="lazy" onLoad={() => setLoading(false)}/>
        { loading  && <LoadingImgAbsolute/>}
      </div>
    )
}
