import React from 'react'

export const PrivacyPolicy = () => {
  return (
    <div style={ styles.container }>
      <h2>POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES</h2>
      <br />
      <br />

      <h3>Alcance de la política de protección de datos personales</h3>
      <br />

      <p>Esta política de protección de datos de carácter personal abarca cualquier tratamiento de datos que pueda producirse al navegar o interaccionar por cualquiera de nuestras páginas web o las redes sociales en las que podamos tener presencia (Facebook, Twitter, Instagram, etc.). Al final de este documento se especifica información especial en el caso del uso de las redes sociales. También se aplicará a cualquier procedimiento interno de la empresa que requiera la recogida de datos, ya sea en formularios en formato papel o cualquier otro sistema.</p>
      <p>En cualquiera de estos medios, usted puede consultar información, y en algunos casos rellenar formularios, contestar encuestas, participar en concursos, realizar consultas, enviar fotografías, realizar comentarios, etc.… y, por lo tanto, proporcionarnos información de datos de carácter personal. Todos los tratamientos están sujetos a nuestra política de privacidad.</p>
      <br />
      <br />

      <h3>¿Quién es el responsable del Tratamiento de sus datos personales?</h3>
      <br />

      <p>En cumplimiento de lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril del 2016, informamos, que los datos de carácter personal que nos proporcione serán tratados por <b>MARIA RIPOLL SLU</b>, con domicilio social <b>C/ Dr. Alfons Torrent, 4 - Sant Hilari Sacalm (Girona)</b> CIF <b>B17143124</b>, Correo electrónico <b>info@teresaripoll.com</b></p>   
      <br />
      <br />

      <h3>¿Quién es el Delegado de Protección de Datos Personales? (en caso de nombrar Delegado, si  no es obligatorio)</h3>
      <br />

      <p>El Delegado de Protección de Datos Personales es la persona encargada de proteger el derecho fundamental a la protección de datos personales en <b>MARIA RIPOLL SLU</b> y se encarga del cumplimiento de la normativa de protección de datos. Podrá contactar con el Delegado de Protección de Datos Personales en la siguiente dirección: <b>info@teresaripoll.com</b>.</p>
      <br />
      <br />

      <h3>¿Qué Datos Personales se recogen y finalidad de los mismos?</h3>
      <br />

      <p>Detallamos, los datos personales que gestiona, así como sus finalidades.</p>

      <table style={{ ...styles.table, ...styles.blackBorder }}>
        <thead>
          <tr>
            <th style={ styles.th }> Apartado</th>
            <th style={ styles.th }> Datos</th>
            <th style={ styles.th }> Finalidad y legitimación</th>
            <th style={ styles.th }> Destinatarios</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={ styles.firstTd }>Contacto</td>
            <td style={ styles.td }>MARIA RIPOLL SLU<br />info@teresaripoll.com</td>
            <td style={ styles.td }>Responder a su consulta legitimado por su solicitud previa.</td>
            <td style={ styles.td }>Nuestra empresa.</td>
          </tr>
          <tr>
            <td style={ styles.firstTd }>Facebook</td>
            <td style={ styles.td }></td>
            <td style={ styles.td }></td>
            <td style={ styles.td }></td>
          </tr>
          <tr>
            <td style={ styles.firstTd }>Instagram</td>
            <td style={ styles.td }></td>
            <td style={ styles.td }></td>
            <td style={ styles.td }></td>
          </tr>
          <tr>
            <td style={ styles.firstTd }>Google Analytics</td>
            <td style={ styles.td }></td>
            <td style={ styles.td }></td>
            <td style={ styles.td }></td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Su consentimiento puede ser revocado en cualquier momento. <b>MARIA RIPOLL SLU</b> utiliza herramientas para analizar perfiles, comportamientos o preferencias de los usuarios y posteriormente realizar campañas dirigidas en función de estos datos.</p>
      <br />
      <br />

      <h3>¿Por qué razón utilizamos sus datos personales?</h3>
      <br />

      <p>
        A continuación, le explicamos la base legal que nos permite tratar sus datos personales.
        <ol>
          <li>En cumplimiento de una relación contractual </li> 
          <li>Cuando nos da su consentimiento.</li>
          <li>Por interés legítimo de EMPRESA para mostrarle servicios, productos e iniciativas que puedan interesarle </li>
          <li>Cumplimiento de obligaciones impuestas por Ley.</li>
        </ol>
        Les recordamos que puede revocar su consentimiento en cualquier momento en la siguiente dirección: <b>info@teresaripoll.com</b>.
      </p>
      <br />
      <br />

      <h3>Publicaciones de fotos o videos en la página web o en las redes sociales</h3>
      <br />

      <p>Para la publicación de fotografías o videos de nuestros trabajadores, clientes, usuarios o menores en la web o redes sociales, al igual que para recopilar datos para realizar un concurso o tratar cualquier otro dato personal, previamente obtenemos el consentimiento previo, explícito, indiscutible e informado del titular que, en el caso de los menores de 14 años, se otorga por los padres o tutores legales.</p>
      <p>El tratamiento de estas fotos, acata el principio de proporcionalidad, es decir, solo se tratan estrictamente para la finalidad para la que se ha prestado el consentimiento.</p>
      <br />
      <br />

      <h3>Datos no pertinentes</h3>
      <br />

      <p><b>MARIA RIPOLL SLU</b> advierte al usuario de que, salvo la existencia de una representación legalmente constituida, ningún usuario puede utilizar la identidad de otra persona y comunicar sus datos personales, por lo que el usuario en todo momento deberá tener en cuenta que solo puede proporcionar datos personales correspondientes a su propia identidad y que sean adecuados, pertinentes, actuales, exactos y verdaderos</p>
      <br />
      <br />

      <h3>¿Cuánto tiempo conservaremos sus datos?</h3>
      <br />

      <p><b>MARIA RIPOLL SLU</b> guarda sus Datos Personales mientras sea necesario para el fin por el que fueron recopilados. Si la directriz es distinta a la indicada en esta política se detalla en el documento pertinente.</p>
      <br />
      <br />

      <h3>¿A quién comunicaremos sus datos?</h3>
      <br />

      <p><b>MARIA RIPOLL SLU</b> solamente cederá sus datos en aquellos servicios en los que se indica y en los casos previstos por una ley. No se realizarán transferencias internacionales de sus datos personales.</p>
      <p>En el caso de las redes sociales, toda la información y contenidos publicados por el usuario serán objeto de comunicación y compartidos con el resto de los usuarios que consulten esta red social, por la propia naturaleza del servicio. </p>
      <br />
      <br />

      <h3>Medidas de seguridad</h3>
      <br />

      <p><b>MARIA RIPOLL SLU</b> asegura la absoluta confidencialidad y privacidad de los datos personales recogidos y por ello se han adoptado medidas esenciales de seguridad para evitar la alteración, pérdida, tratamiento o acceso no autorizado y garantizar así su integridad y seguridad. </p>
      <p>El Usuario se compromete a hacer un uso diligente y a no poner a la disposición de terceros su nombre de usuario y contraseña, así mismo se compromete a llevar a disposición de la empresa cualquier, robo, pérdida o riesgo de acceso de terceros a su usuario.</p>
      <br />
      <br />

      <h3>¿Cuáles son sus derechos cuando nos facilita sus datos personales?</h3>
      <br />

      <table style={{ ...styles.table, ...styles.blackBorder }}>
        <thead>
          <tr>
            <th style={ styles.th }> Derecho</th>
            <th style={ styles.th }> Contenido</th>
            <th style={ styles.th }> Canal de atención</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={ styles.firstTd }>Acceso</td>
            <td style={ styles.td }>Podrá consultar los datos personales gestionados por <b>MARIA RIPOLL SLU</b>.</td>
            <td style={ styles.td }></td>
          </tr>
          <tr>
            <td style={ styles.firstTd }>Rectificación</td>
            <td style={ styles.td }>Podrá modificar sus datos personales cuando sean inexactos.</td>
            <td style={ styles.td }></td>
          </tr>
          <tr>
            <td style={ styles.firstTd }>Supresión</td>
            <td style={ styles.td }>Podrá solicitar la eliminación de sus datos personales</td>
            <td style={ styles.td }></td>
          </tr>
          <tr>
            <td style={ styles.firstTd }>Oposición</td>
            <td style={ styles.td }>Podrá solicitar que no se traten sus datos personales.</td>
            <td style={ styles.td }><b>info@teresaripoll.com</b></td>
          </tr>
          <tr>
            <td style={ styles.firstTd }>Limitación del tratamiento</td>
            <td style={ styles.td }>
              Podrás solicitar la limitación al tratamiento de tus datos en los siguientes casos:
              <ul>
                <li>Mientras se comprueba la impugnación de la exactitud de tus datos</li>
                <li>Cuando <b>MARIA RIPOLL SLU</b>.  no necesite tratar sus datos, pero usted los necesites para el ejercicio o defensa de reclamaciones.</li>
              </ul>
            </td>
            <td style={ styles.td }></td>
          </tr>
          <tr>
            <td style={ styles.firstTd }>Portabilidad</td>
            <td style={ styles.td }>Podrá recibir en formato electrónico los datos que nos haya facilitado en formato electrónico</td>
            <td style={ styles.td }></td>
          </tr>
        </tbody>
      </table>
      <br />
      <ul>
        <li>Si considera que no hemos tratado sus datos personales de acuerdo con la normativa puedes contactar con el Delegado de Protección de Datos en la dirección <b>info@teresaripoll.com</b>. <br />No obstante, podrá presentar una reclamación ante la Agencia Española de Protección de Datos (www.agpd.es).</li>
        <li>Para ejercitar sus derechos, acompañe su solicitud una copia de su DNI o documento acreditativo de tu identidad.</li>
        <li>El ejercicio de sus derechos es gratuito.</li>
      </ul>
      <br />
      <br />

      <h3>Redes sociales</h3>
      <br />

      <p>El funcionamiento de las redes sociales no está bajo el control de <b>MARIA RIPOLL SLU</b>., y por lo tanto la información que publique será compartida por todos los usuarios que las consulten. Así mismo, estas redes permiten la interacción con otros usuarios, y por lo tanto, a continuación, se detallan unas premisas que debe tener en cuenta.</p>
      <p>El objeto de la utilización de las redes sociales es dar visibilidad y difusión de los productos que desarrolla <b>MARIA RIPOLL SLU</b>. </p>
      <p>En principio, se aplica la misma política de protección de datos especificada en este documento, en cuanto a la recogida, tratamiento y cesión de datos.</p>
      <p>El usuario debe comprometerse a:</p>
      <ul>
        <li>No publicar informaciones que no respondan a los requisitos de veracidad, interés público y respeto a la dignidad de las personas. En especial, el usuario tendrá que evitar toda conducta que pueda atentar contra el principio de no discriminación por razones de sexo, raza, religión, ideología o cualquier otro circunstancia personal o social, y contra la intimidad, el honor y la propia imagen, siendo el responsable final sobre la veracidad y legalidad de los contenidos que publique</li>
        <li>No grabar ni publicar imágenes, vídeos o cualquier otro tipo de registro sin el consentimiento de los afectados</li>
      </ul>
      <p><b>MARIA RIPOLL SLU</b> no se identifica con las opiniones expresadas por otros ni con la ideología de los perfiles con los que tenga amistad en ninguna red social.</p>
      <p><b>MARIA RIPOLL SLU</b> se reserva el derecho de eliminar de sus redes sociales cualquier información publicada por otros que vulnere la legalidad, incite a hacerlo, o contenga mensajes que atenten contra la dignidad de personas o instituciones. Así como de bloquear o denunciar el perfil autor de estos mensajes.</p>
      <p>Recomendaciones a los usuarios/las usuarias</p>
      <ul>
        <li>Revise y lea las condiciones generales de uso y la política de privacidad de la red social en el momento de registrarse.</li>
        <li>Aprenda las posibilidades de configuración y uso que la red ofrezca.</li>
        <li>Configure adecuadamente el grado de privacidad del perfil de usuario en la red social.</li>
        <li>No publique información excesiva sobre su vida personal y familiar.</li>
        <li>Tenga cuidado con la publicación de contenidos audiovisuales y gráficos en su perfil, especialmente si se alojarán imágenes relativas a terceras personas.</li>
        <li>No ofrezca datos de terceros sin el previo consentimiento de éstos.</li>
      </ul>
      <p>Las redes sociales gestionadas por EMPRESA son:</p>
      <table style={{ ...styles.table, ...styles.blackBorder, width:'50%' }}>
        <thead>
          <tr>
            <th style={ styles.th }> Red</th>
            <th style={ styles.th }> Dirección </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={ styles.firstTd }>Facebook</td>
            <td style={ styles.td }>
              <a href="https://www.facebook.com/pages/TERESA-RIPOLL/157731090964337" style={styles.social} target="_blank" rel="noreferrer">Facebook - Teresa Ripoll</a>
            </td>
          </tr>
          <tr>
            <td style={ styles.firstTd }>Instagram</td>
            <td style={ styles.td }>
              <a href="https://www.instagram.com/teresaripoll.oficial/" style={styles.social} target="_blank" rel="noreferrer">Instagram - Teresa Ripoll</a>
            </td>
          </tr>
        </tbody>
      </table>
      <br />      

    </div>
  )
}


const styles = {
  container: {
      paddingTop: 125,
      paddingLeft: 50,
      paddingRight: 50,
  },
  table: {
    width: '100%',
    border: '1px solid black',
  },
  th: {
    backgroundColor: '#cce6fc',
    border: '1px solid black',
    padding: 5,
  },
  firstTd: {
    backgroundColor: '#eee',
    fontWeight: 'bold',
    border: '1px solid black',
    padding: 5,
  },
  td: {
    padding: 5,
    border: '1px solid black',
  },
}
