import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


/*
npm outdated
npm update

npx create-react-app tr2023-npx
https://stackoverflow.com/questions/69575813/vulnerabilities-with-create-react-app-react-js
npm audit --production

Vite
Error: Minified React error #130; vite build
https://github.com/vitejs/vite/discussions/7040

https://www.npmjs.com/package/react-swipe
https://bootstrap-4-react.com
https://www.npmjs.com/package/react-fontawesome  - cal posar el CDN al index.html
https://github.com/ReactTraining/react-router
https://github.com/react-bootstrap/react-router-bootstrap
https://animate.style/#documentation

https://onaircode.com/awesome-react-photo-gallery-components/
https://bashooka.com/coding/react-zoom-image-components/

Autoplay:
https://blog.videojs.com/autoplay-best-practices-with-video-js/
https://stackoverflow.com/questions/19521667/disable-fullscreen-iphone-video

Installs:
npm install --save bootstrap-4-react
npm install react-router --save
npm install react-router-dom --save  
npm install -S react-router-bootstrap
npm install react swipe-js-iso react-swipe --save

npm install --save react-fontawesome
npm i intersection-observer

Latest versions: 
npm install -g npm-check-updates
ncu -u
npm install
*/