import React, { useState, useEffect } from 'react'


export const GotoTopBtn = ({fn}) => {
    const [display, setDisplay] = useState('hidden') // none

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            // console.log(window.scrollY);
            if ( window.scrollY > 115 ) {
              setDisplay('visible')  // block
            } else {
              setDisplay('hidden') // none
            }
        })
    }, [])

    const goToTopClick = () => {
        window.scrollTo(0, 0)
        if (fn) {
          fn()
        }
    }

    return (
        <div 
            style={{...styles.button, visibility:display }}
            onClick={goToTopClick}
        >
            <div style={styles.arrow}></div>
        </div>
    )
}


const styles = {
    button: {
        position: 'fixed', 
        bottom: 60,
        right: 30,
        width: 60, 
        height: 40, 
        background: 'rgba(200,200,200,.5)',
        borderRadius: 7,
        //display: 'none',
        cursor: 'pointer',
    },
    arrow: {
        borderTop: '6px solid black',
        borderLeft: '6px solid black',
        transform: 'rotate(45deg)',
        width: 17,
        height: 17,
        margin: '0 auto',
        marginTop: 14,
    }
}
