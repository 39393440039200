import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import 'intersection-observer'

export const CardContent = ({title, image, description, route, routeName}) => {
    const [mobile, setMobile] = useState( window.innerWidth < 768 )

    useEffect(() => {
        // change icons on resize if need
        window.addEventListener('resize', () => {
            setMobile( window.innerWidth < 768 )
        })
        // Detect Element is visible to show animation
        const element = document.getElementById(route)
        var observer = new IntersectionObserver(function(entries) {
            if(entries[0].isIntersecting === true) {
                // console.log('Element is fully visible in screen')
                element.classList.add('animate__fadeInDown')
                /*setTimeout(() => {
                    document.getElementById('card-text-div').classList.remove('animate__fadeInDown')
                }, 1000)*/
            }
        }, { threshold: [1] })
        
        observer.observe(element)
        // eslint-disable-next-line
    }, [])

    return (
        <div style={{ ...styles.content, width: mobile ? '98%' : '48%'}} >
            <img style={{width:'100%', opacity:1, borderRadius: 5}} src={image} alt=""></img>
            <div style={{...styles.textDiv, top: mobile ? '30%' : '30%', opacity: 0}} className="animate__animated" id={route}>
                <p style={{...styles.text, fontWeight:'bold', color:'#333', fontSize: mobile ? 26 : 36}}>{title}</p>
                {/*<p style={{...styles.text, color:'#666', fontSize: mobile ? 16 : 26}}>{description}</p>*/}
                <p style={styles.text}>
                    <Link to={route}>{routeName}</Link>
                </p>
            </div>
        </div>
    )
}

const styles = {
    content: {
        position:'relative', 
        marginTop:15,
        borderRadius: 15,
        boxShadow: '1px 1px 10px #eee',
        //overflow: 'hidden',
    },
    textDiv: {
        position: 'absolute',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0)',
        padding: '30px 0',
    },
    text: {
        textAlign: 'center',
        width: '100%',
    },
}

/*
https://usefulangle.com/post/113/javascript-detecting-element-visible-during-scroll
https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
https://stackoverflow.com/questions/59074487/reference-error-cant-find-variable-intersectionobserver
https://www.npmjs.com/package/intersection-observer

*/


