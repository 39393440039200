import React from 'react'
import { Link } from 'react-router-dom'

import { useMobile } from '../hooks/useMobile'
import { lang } from '../i18n/i18n'
import { i18n2 } from '../i18n/CookieAlert.trans'
import { setCookie } from '../helpers/utils'


export const CookieAlert = ({onAcceptCookie}) => {
    const isMobile = useMobile()
    return (
        <div style={styles.divBlack}>
            <div style={styles.divWhite}>
                <p style={isMobile ? styles.pMessageMobile : styles.pMessageDesktop}>
                    {i18n2.cookie_message[lang]}
                    <Link to='/cookiespolicy'>{i18n2.cookie_politics[lang]} </Link>
                    {i18n2.click_on_link[lang]}
                </p>
                <div 
                    style={styles.btnAccept}
                    onClick={ () => {
                        setCookie("cookie-accepted", "1", 365)
                        onAcceptCookie()
                    } }
                >{i18n2.btn_accept_cookies[lang]}</div>
            </div>
        </div>
    )
}


const styles = {
    divBlack: {
        position: 'fixed',
        top: 0,
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: 'rgba(0,0,0,0.7)',
    },
    divWhite: {
        position: 'fixed', 
        bottom: 0,
        left: 0,
        width: '100%', 
        height: '30%', 
        background: 'white',
    },
    pMessageMobile: {
        margin: '20px 40px 20px 40px',
        fontSize: '0.7em',
    },
    pMessageDesktop: {
        textAlign: 'center',
        margin: '40px',
    },
    btnAccept: {
        background: 'black',
        color: 'white',
        margin:'0 auto',
        width: '150px',
        height: '45px',
        textAlign: 'center',
        paddingTop: '10px',
        cursor: 'pointer',        
    }
}
