import React, { useRef, useEffect, useState } from 'react'

export const TextAnim = ({ 
  text, 
  colors=['red', 'green', 'blue', 'yellow', 'purple'],
  textStyle={},
  divStyle={},
  interval=100,
}) => {

  const index = useRef(1)
  const [lettersShowing, setLettersShowing] = useState([])

  const animLetter = () => {
    if (index.current < text.length ) {
        setTimeout(() => {
            animLetter()
        }, interval)
    }
    setLettersShowing( text.substring(0, index.current).split('') )
    index.current += 1
  }

  useEffect(() => {
      animLetter()
      // eslint-disable-next-line
  }, [text])

  return (
    <div style={{...styles.container, ...divStyle}}>
      {lettersShowing.map( (letter, i) => 
        <span 
          style={{color:colors[i % colors.length], minWidth:7, ...textStyle}}
          className='animate__animated animate__fadeInDown'
        >{letter}</span>)}
    </div>
  )
}


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  }
}
