import {book_rainbow_trans} from '../../i18n/book_rainbow.trans'

export const book_rainbow = [
    {
        title: '7000',

        light: '/img/2021/rainbow/light/7000-V.jpg',
        back_light: '/img/2021/rainbow/light/7000-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7000-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7000-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7000-V.jpg',
        back_hight: '/img/2021/rainbow/high/7000-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7000-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7000-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7000-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7000],
    },
    {
        title: '7001',

        light: '/img/2021/rainbow/light/7001-V.jpg',
        back_light: '/img/2021/rainbow/light/7001-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7001-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7001-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7001-V.jpg',
        back_hight: '/img/2021/rainbow/high/7001-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7001-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7001-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7001-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(231, 224, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7001],
    },
    {
        title: '7003',

        light: '/img/2021/rainbow/light/7003-V.jpg',
        back_light: '/img/2021/rainbow/light/7003-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7003-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7003-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7003-V.jpg',
        back_hight: '/img/2021/rainbow/high/7003-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7003-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7003-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7003-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(215, 210, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7003],
    },
    {
        title: '7004',

        light: '/img/2021/rainbow/light/7004-V.jpg',
        back_light: '/img/2021/rainbow/light/7004-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7004-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7004-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7004-V.jpg',
        back_hight: '/img/2021/rainbow/high/7004-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7004-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7004-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7004-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(176, 208, 245)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7004],
    },
    {
        title: '7005',

        light: '/img/2021/rainbow/light/7005-V.jpg',
        back_light: '/img/2021/rainbow/light/7005-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7005-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7005-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7005-V.jpg',
        back_hight: '/img/2021/rainbow/high/7005-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7005-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7005-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7005-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(235, 206, 164)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7005],
    },
    {
        title: '7006',

        light: '/img/2021/rainbow/light/7006-V.jpg',
        back_light: '/img/2021/rainbow/light/7006-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7006-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7006-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7006-V.jpg',
        back_hight: '/img/2021/rainbow/high/7006-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7006-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7006-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7006-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(224, 164, 171)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7006],
    },
    {
        title: '7007',

        light: '/img/2021/rainbow/light/7007-V.jpg',
        back_light: '/img/2021/rainbow/light/7007-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7007-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7007-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7007-V.jpg',
        back_hight: '/img/2021/rainbow/high/7007-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7007-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7007-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7007-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(238, 202, 202)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7007],
    },
    {
        title: '7008',

        light: '/img/2021/rainbow/light/7008-V.jpg',
        back_light: '/img/2021/rainbow/light/7008-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7008-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7008-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7008-V.jpg',
        back_hight: '/img/2021/rainbow/high/7008-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7008-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7008-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7008-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(171, 182, 237)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7008],
    },
    {
        title: '7010',

        light: '/img/2021/rainbow/light/7010-V.jpg',
        back_light: '/img/2021/rainbow/light/7010-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7010-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7010-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7010-V.jpg',
        back_hight: '/img/2021/rainbow/high/7010-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7010-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7010-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7010-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(210, 214, 215)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7010],
    },
    {
        title: '7012',

        light: '/img/2021/rainbow/light/7012-V.jpg',
        back_light: '/img/2021/rainbow/light/7012-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7012-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7012-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7012-V.jpg',
        back_hight: '/img/2021/rainbow/high/7012-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7012-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7012-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7012-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(250, 182, 182)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7012],
    },
    {
        title: '7013',

        light: '/img/2021/rainbow/light/7013-V.jpg',
        back_light: '/img/2021/rainbow/light/7013-V-Back.jpg',
        front_light: '/img/2021/rainbow/light/7013-V-Front.jpg',
        detail_light: '/img/2021/rainbow/light/7013-V-Detall.jpg',

        hight: '/img/2021/rainbow/high/7013-V.jpg',
        back_hight: '/img/2021/rainbow/high/7013-V-Back.jpg',
        front_hight: '/img/2021/rainbow/high/7013-V-Front.jpg',
        detail_hight: '/img/2021/rainbow/high/7013-V-Detall.jpg',

        video: '/img/2021/rainbow/videos/7013-V-Teresa-Ripoll-clip.mp4',

        border: '8px solid rgb(154, 178, 224)', // ImageGallery.js
        shadow: 'none',
        trans: book_rainbow_trans[7013],
    },
]