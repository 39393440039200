import {book_danube_trans} from '../../i18n/book_danube.trans'

export const book_danube = [
    {
        title: '2221',

        light: '/img/2023/danube/light/2221-bl-fa.jpg',
        back_light: '/img/2023/danube/light/2221-bl-fa-back.jpg',
        front_light: '/img/2023/danube/light/2221-bl-fa-front.jpg',
        detail_light: '/img/2023/danube/light/2221-bl-fa-detall.jpg',

        hight: '/img/2023/danube/high/2221-bl-fa.jpg',
        back_hight: '/img/2023/danube/high/2221-bl-fa-back.jpg',
        front_hight: '/img/2023/danube/high/2221-bl-fa-front.jpg',
        detail_hight: '/img/2023/danube/high/2221-bl-fa-detall.jpg',

        video: '/img/2023/danube/videos/2221-bl-f-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[2221],
    },
    {
        title: '2222',

        light: '/img/2023/danube/light/2222-bl-pa.jpg',
        back_light: '/img/2023/danube/light/2222-bl-pa-back.jpg',
        front_light: '/img/2023/danube/light/2222-bl-pa-front.jpg',
        detail_light: '/img/2023/danube/light/2222-bl-pa-detall.jpg',

        hight: '/img/2023/danube/high/2222-bl-pa.jpg',
        back_hight: '/img/2023/danube/high/2222-bl-pa-back.jpg',
        front_hight: '/img/2023/danube/high/2222-bl-pa-front.jpg',
        detail_hight: '/img/2023/danube/high/2222-bl-pa-detall.jpg',

        video: '/img/2023/danube/videos/2222-bl-pa-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[2222],
    },
    {
        title: '2223',

        light: '/img/2023/danube/light/2223-bl-pa.jpg',
        back_light: '/img/2023/danube/light/2223-bl-pa-back.jpg',
        front_light: '/img/2023/danube/light/2223-bl-pa-front.jpg',
        detail_light: '/img/2023/danube/light/2223-bl-pa-detall.jpg',

        hight: '/img/2023/danube/high/2223-bl-pa.jpg',
        back_hight: '/img/2023/danube/high/2223-bl-pa-back.jpg',
        front_hight: '/img/2023/danube/high/2223-bl-pa-front.jpg',
        detail_hight: '/img/2023/danube/high/2223-bl-pa-detall.jpg',

        video: '/img/2023/danube/videos/2223-bl-pa-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[2223],
    },
    {
        title: '2224',

        light: '/img/2023/danube/light/2224-ch-pa.jpg',
        back_light: '/img/2023/danube/light/2224-ch-pa-back.jpg',
        front_light: '/img/2023/danube/light/2224-ch-pa-front.jpg',
        detail_light: '/img/2023/danube/light/2224-ch-pa-detall.jpg',

        hight: '/img/2023/danube/high/2224-ch-pa.jpg',
        back_hight: '/img/2023/danube/high/2224-ch-pa-back.jpg',
        front_hight: '/img/2023/danube/high/2224-ch-pa-front.jpg',
        detail_hight: '/img/2023/danube/high/2224-ch-pa-detall.jpg',

        video: '/img/2023/danube/videos/2224-ch-pa-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[2224],
    },
    {
        title: '3758',

        light: '/img/2023/danube/light/3758-ch-v.jpg',
        back_light: '/img/2023/danube/light/3758-ch-v-back.jpg',
        front_light: '/img/2023/danube/light/3758-ch-v-front.jpg',
        detail_light: '/img/2023/danube/light/3758-ch-v-detall.jpg',

        hight: '/img/2023/danube/high/3758-ch-v.jpg',
        back_hight: '/img/2023/danube/high/3758-ch-v-back.jpg',
        front_hight: '/img/2023/danube/high/3758-ch-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3758-ch-v-detall.jpg',

        video: '/img/2023/danube/videos/3758-ch-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3758],
    },
    {
        title: '3759',

        light: '/img/2023/danube/light/3759-v.jpg',
        back_light: '/img/2023/danube/light/3759-v-back.jpg',
        front_light: '/img/2023/danube/light/3759-v-front.jpg',
        detail_light: '/img/2023/danube/light/3759-v-detall.jpg',

        hight: '/img/2023/danube/high/3759-v.jpg',
        back_hight: '/img/2023/danube/high/3759-v-back.jpg',
        front_hight: '/img/2023/danube/high/3759-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3759-v-detall.jpg',

        video: '/img/2023/danube/videos/3759-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3759],
    },
    {
        title: '3760',

        light: '/img/2023/danube/light/3760-v.jpg',
        back_light: '/img/2023/danube/light/3760-v-back.jpg',
        front_light: '/img/2023/danube/light/3760-v-front.jpg',
        detail_light: '/img/2023/danube/light/3760-v-detall.jpg',

        hight: '/img/2023/danube/high/3760-v.jpg',
        back_hight: '/img/2023/danube/high/3760-v-back.jpg',
        front_hight: '/img/2023/danube/high/3760-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3760-v-detall.jpg',

        video: '/img/2023/danube/videos/3760-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3760],
    },
    {
        title: '3761',

        light: '/img/2023/danube/light/3761-v.jpg',
        back_light: '/img/2023/danube/light/3761-v-back.jpg',
        front_light: '/img/2023/danube/light/3761-v-front.jpg',
        detail_light: '/img/2023/danube/light/3761-v-detall.jpg',

        hight: '/img/2023/danube/high/3761-v.jpg',
        back_hight: '/img/2023/danube/high/3761-v-back.jpg',
        front_hight: '/img/2023/danube/high/3761-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3761-v-detall.jpg',

        video: '/img/2023/danube/videos/3761-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3761],
    },
    {
        title: '3762',

        light: '/img/2023/danube/light/3762-v.jpg',
        back_light: '/img/2023/danube/light/3762-v-back.jpg',
        front_light: '/img/2023/danube/light/3762-v-front.jpg',
        detail_light: '/img/2023/danube/light/3762-v-detall.jpg',

        hight: '/img/2023/danube/high/3762-v.jpg',
        back_hight: '/img/2023/danube/high/3762-v-back.jpg',
        front_hight: '/img/2023/danube/high/3762-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3762-v-detall.jpg',

        video: '/img/2023/danube/videos/3762-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3762],
    },
    {
        title: '3763',

        light: '/img/2023/danube/light/3763-v.jpg',
        back_light: '/img/2023/danube/light/3763-v-back.jpg',
        front_light: '/img/2023/danube/light/3763-v-front.jpg',
        detail_light: '/img/2023/danube/light/3763-v-detall.jpg',

        hight: '/img/2023/danube/high/3763-v.jpg',
        back_hight: '/img/2023/danube/high/3763-v-back.jpg',
        front_hight: '/img/2023/danube/high/3763-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3763-v-detall.jpg',

        video: '/img/2023/danube/videos/3763-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3763],
    },
    {
        title: '3764',

        light: '/img/2023/danube/light/3764-v.jpg',
        back_light: '/img/2023/danube/light/3764-v-back.jpg',
        front_light: '/img/2023/danube/light/3764-v-front.jpg',
        detail_light: '/img/2023/danube/light/3764-v-detall.jpg',

        hight: '/img/2023/danube/high/3764-v.jpg',
        back_hight: '/img/2023/danube/high/3764-v-back.jpg',
        front_hight: '/img/2023/danube/high/3764-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3764-v-detall.jpg',

        video: '/img/2023/danube/videos/3764-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3764],
    },
    {
        title: '3765',

        light: '/img/2023/danube/light/3765-v.jpg',
        back_light: '/img/2023/danube/light/3765-v-back.jpg',
        front_light: '/img/2023/danube/light/3765-v-front.jpg',
        detail_light: '/img/2023/danube/light/3765-v-detall.jpg',

        hight: '/img/2023/danube/high/3765-v.jpg',
        back_hight: '/img/2023/danube/high/3765-v-back.jpg',
        front_hight: '/img/2023/danube/high/3765-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3765-v-detall.jpg',

        video: '/img/2023/danube/videos/3765-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3765],
    },
    {
        title: '3767',

        light: '/img/2023/danube/light/3767-v.jpg',
        back_light: '/img/2023/danube/light/3767-v-back.jpg',
        front_light: '/img/2023/danube/light/3767-v-front.jpg',
        detail_light: '/img/2023/danube/light/3767-v-detall.jpg',

        hight: '/img/2023/danube/high/3767-v.jpg',
        back_hight: '/img/2023/danube/high/3767-v-back.jpg',
        front_hight: '/img/2023/danube/high/3767-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3767-v-detall.jpg',

        video: '/img/2023/danube/videos/3767-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3767],
    },
    {
        title: '3768',

        light: '/img/2023/danube/light/3768-v.jpg',
        back_light: '/img/2023/danube/light/3768-v-back.jpg',
        front_light: '/img/2023/danube/light/3768-v-front.jpg',
        detail_light: '/img/2023/danube/light/3768-v-detall.jpg',

        hight: '/img/2023/danube/high/3768-v.jpg',
        back_hight: '/img/2023/danube/high/3768-v-back.jpg',
        front_hight: '/img/2023/danube/high/3768-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3768-v-detall.jpg',

        video: '/img/2023/danube/videos/3768-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3768],
    },
    {
        title: '3770',

        light: '/img/2023/danube/light/3770-v.jpg',
        back_light: '/img/2023/danube/light/3770-v-back.jpg',
        front_light: '/img/2023/danube/light/3770-v-front.jpg',
        detail_light: '/img/2023/danube/light/3770-v-detall.jpg',

        hight: '/img/2023/danube/high/3770-v.jpg',
        back_hight: '/img/2023/danube/high/3770-v-back.jpg',
        front_hight: '/img/2023/danube/high/3770-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3770-v-detall.jpg',

        video: '/img/2023/danube/videos/3770-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3770],
    },
    {
        title: '3771',

        light: '/img/2023/danube/light/3771-v.jpg',
        back_light: '/img/2023/danube/light/3771-v-back.jpg',
        front_light: '/img/2023/danube/light/3771-v-front.jpg',
        detail_light: '/img/2023/danube/light/3771-v-detall.jpg',

        hight: '/img/2023/danube/high/3771-v.jpg',
        back_hight: '/img/2023/danube/high/3771-v-back.jpg',
        front_hight: '/img/2023/danube/high/3771-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3771-v-detall.jpg',

        video: '/img/2023/danube/videos/3771-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3771],
    },
    {
        title: '3772',

        light: '/img/2023/danube/light/3772-v.jpg',
        back_light: '/img/2023/danube/light/3772-v-back.jpg',
        front_light: '/img/2023/danube/light/3772-v-front.jpg',
        detail_light: '/img/2023/danube/light/3772-v-detall.jpg',

        hight: '/img/2023/danube/high/3772-v.jpg',
        back_hight: '/img/2023/danube/high/3772-v-back.jpg',
        front_hight: '/img/2023/danube/high/3772-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3772-v-detall.jpg',

        video: '/img/2023/danube/videos/3772-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3772],
    },
    {
        title: '3773',

        light: '/img/2023/danube/light/3773-v.jpg',
        back_light: '/img/2023/danube/light/3773-v-back.jpg',
        front_light: '/img/2023/danube/light/3773-v-front.jpg',
        detail_light: '/img/2023/danube/light/3773-v-detall.jpg',

        hight: '/img/2023/danube/high/3773-v.jpg',
        back_hight: '/img/2023/danube/high/3773-v-back.jpg',
        front_hight: '/img/2023/danube/high/3773-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3773-v-detall.jpg',

        video: '/img/2023/danube/videos/3773-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3773],
    },
    {
        title: '3774',

        light: '/img/2023/danube/light/3774-v.jpg',
        back_light: '/img/2023/danube/light/3774-v-back.jpg',
        front_light: '/img/2023/danube/light/3774-v-front.jpg',
        detail_light: '/img/2023/danube/light/3774-v-detall.jpg',

        hight: '/img/2023/danube/high/3774-v.jpg',
        back_hight: '/img/2023/danube/high/3774-v-back.jpg',
        front_hight: '/img/2023/danube/high/3774-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3774-v-detall.jpg',

        video: '/img/2023/danube/videos/3774-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3774],
    },
    {
        title: '3775',

        light: '/img/2023/danube/light/3775-v.jpg',
        back_light: '/img/2023/danube/light/3775-v-back.jpg',
        front_light: '/img/2023/danube/light/3775-v-front.jpg',
        detail_light: '/img/2023/danube/light/3775-v-detall.jpg',

        hight: '/img/2023/danube/high/3775-v.jpg',
        back_hight: '/img/2023/danube/high/3775-v-back.jpg',
        front_hight: '/img/2023/danube/high/3775-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3775-v-detall.jpg',

        video: '/img/2023/danube/videos/3775-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3775],
    },
    {
        title: '3777',

        light: '/img/2023/danube/light/3777-v.jpg',
        back_light: '/img/2023/danube/light/3777-v-back.jpg',
        front_light: '/img/2023/danube/light/3777-v-front.jpg',
        detail_light: '/img/2023/danube/light/3777-v-detall.jpg',

        hight: '/img/2023/danube/high/3777-v.jpg',
        back_hight: '/img/2023/danube/high/3777-v-back.jpg',
        front_hight: '/img/2023/danube/high/3777-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3777-v-detall.jpg',

        video: '/img/2023/danube/videos/3777-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3777],
    },
    {
        title: '3778',

        light: '/img/2023/danube/light/3778-v.jpg',
        back_light: '/img/2023/danube/light/3778-v-back.jpg',
        front_light: '/img/2023/danube/light/3778-v-front.jpg',
        detail_light: '/img/2023/danube/light/3778-v-detall.jpg',

        hight: '/img/2023/danube/high/3778-v.jpg',
        back_hight: '/img/2023/danube/high/3778-v-back.jpg',
        front_hight: '/img/2023/danube/high/3778-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3778-v-detall.jpg',

        video: '/img/2023/danube/videos/3778-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3778],
    },
    {
        title: '3780',

        light: '/img/2023/danube/light/3780-v.jpg',
        back_light: '/img/2023/danube/light/3780-v-back.jpg',
        front_light: '/img/2023/danube/light/3780-v-front.jpg',
        detail_light: '/img/2023/danube/light/3780-v-detall.jpg',

        hight: '/img/2023/danube/high/3780-v.jpg',
        back_hight: '/img/2023/danube/high/3780-v-back.jpg',
        front_hight: '/img/2023/danube/high/3780-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3780-v-detall.jpg',

        video: '/img/2023/danube/videos/3780-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3780],
    },
    {
        title: '3781',

        light: '/img/2023/danube/light/3781-v.jpg',
        back_light: '/img/2023/danube/light/3781-v-back.jpg',
        front_light: '/img/2023/danube/light/3781-v-front.jpg',
        detail_light: '/img/2023/danube/light/3781-v-detall.jpg',

        hight: '/img/2023/danube/high/3781-v.jpg',
        back_hight: '/img/2023/danube/high/3781-v-back.jpg',
        front_hight: '/img/2023/danube/high/3781-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3781-v-detall.jpg',

        video: '/img/2023/danube/videos/3781-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3781],
    },
    {
        title: '3782',

        light: '/img/2023/danube/light/3782-v.jpg',
        back_light: '/img/2023/danube/light/3782-v-back.jpg',
        front_light: '/img/2023/danube/light/3782-v-front.jpg',
        detail_light: '/img/2023/danube/light/3782-v-detall.jpg',

        hight: '/img/2023/danube/high/3782-v.jpg',
        back_hight: '/img/2023/danube/high/3782-v-back.jpg',
        front_hight: '/img/2023/danube/high/3782-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3782-v-detall.jpg',

        video: '/img/2023/danube/videos/3782-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3782],
    },
    {
        title: '3783',

        light: '/img/2023/danube/light/3783-ch-v.jpg',
        back_light: '/img/2023/danube/light/3783-ch-v-back.jpg',
        front_light: '/img/2023/danube/light/3783-ch-v-front.jpg',
        detail_light: '/img/2023/danube/light/3783-ch-v-detall.jpg',

        hight: '/img/2023/danube/high/3783-ch-v.jpg',
        back_hight: '/img/2023/danube/high/3783-ch-v-back.jpg',
        front_hight: '/img/2023/danube/high/3783-ch-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3783-ch-v-detall.jpg',

        video: '/img/2023/danube/videos/3783-ch-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3783],
    },
    {
        title: '3784',

        light: '/img/2023/danube/light/3784-v.jpg',
        back_light: '/img/2023/danube/light/3784-v-back.jpg',
        front_light: '/img/2023/danube/light/3784-v-front.jpg',
        detail_light: '/img/2023/danube/light/3784-v-detall.jpg',

        hight: '/img/2023/danube/high/3784-v.jpg',
        back_hight: '/img/2023/danube/high/3784-v-back.jpg',
        front_hight: '/img/2023/danube/high/3784-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3784-v-detall.jpg',

        video: '/img/2023/danube/videos/3784-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3784],
    },
    {
        title: '3785',

        light: '/img/2023/danube/light/3785-v.jpg',
        back_light: '/img/2023/danube/light/3785-v-back.jpg',
        front_light: '/img/2023/danube/light/3785-v-front.jpg',
        detail_light: '/img/2023/danube/light/3785-v-detall.jpg',

        hight: '/img/2023/danube/high/3785-v.jpg',
        back_hight: '/img/2023/danube/high/3785-v-back.jpg',
        front_hight: '/img/2023/danube/high/3785-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3785-v-detall.jpg',

        video: '/img/2023/danube/videos/3785-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3785],
    },
    {
        title: '3786',

        light: '/img/2023/danube/light/3786-v.jpg',
        back_light: '/img/2023/danube/light/3786-v-back.jpg',
        front_light: '/img/2023/danube/light/3786-v-front.jpg',
        detail_light: '/img/2023/danube/light/3786-v-detall.jpg',

        hight: '/img/2023/danube/high/3786-v.jpg',
        back_hight: '/img/2023/danube/high/3786-v-back.jpg',
        front_hight: '/img/2023/danube/high/3786-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3786-v-detall.jpg',

        video: '/img/2023/danube/videos/3786-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3786],
    },
    {
        title: '3787',

        light: '/img/2023/danube/light/3787-v.jpg',
        back_light: '/img/2023/danube/light/3787-v-back.jpg',
        front_light: '/img/2023/danube/light/3787-v-front.jpg',
        detail_light: '/img/2023/danube/light/3787-v-detall.jpg',

        hight: '/img/2023/danube/high/3787-v.jpg',
        back_hight: '/img/2023/danube/high/3787-v-back.jpg',
        front_hight: '/img/2023/danube/high/3787-v-front.jpg',
        detail_hight: '/img/2023/danube/high/3787-v-detall.jpg',

        video: '/img/2023/danube/videos/3787-v-teresaripoll-ss23-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_danube_trans[3787],
    },
]