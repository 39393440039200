import React, { useEffect } from 'react'

export const SectionTitle = ({title}) => {

    useEffect(() => {
        // La class: animate_fadeInUp dona conflicte amb el menú de bootstrap per això millor fer el remove
        const el = document.getElementById('pcrSectionTitleText')
        if (el) el.classList.add('animate__fadeInUp')
        setTimeout(() => {
            if (el) el.classList.remove('animate__fadeInUp')
        }, 1000)
    }, [title])

    return (
        <div style={style.container}>
            <div style={style.line}></div>
            <p 
                id="pcrSectionTitleText"
                style={style.title} 
                className='animate__animated'
            >{title}</p>
            <div style={style.line}></div>
        </div>
    )
}

const style = {
    container: {
        width: '100%',
        //paddingTop: 30,
        //paddingBottom: 30,
        padding:30,
    },
    line: {
        width: '80%',
        height: 1,
        backgroundColor: '#aaa',
        margin: '0 auto',
    },
    title: {
        fontSize: 35,
        color: '#555',
        paddingTop: 30,
        paddingBottom: 30,
        textAlign: 'center',
        zIndex: '0 !important',
    }
}
