import React from 'react'

export const LoadingImgAbsolute = () => {
    return (
        <img 
        src="/img/loading.gif" 
        style={{
            ...styles.loading,
        }}
        alt="loading"/>
    )
}

const styles = {
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -37.5,
        marginLeft: -37.5,
        width: 75,
        height: 75,
        //zIndex: 10,
    },
}
