// Decide language
var userLang = navigator.language || navigator.userLanguage;

// Si hi ha galeta de llenguatge té prioritat
const langStorage = window.sessionStorage.getItem('lang');
if (langStorage) {
  userLang = langStorage;
}

// Assignem la llengua a la variable de sistema.
export let lang = 'en';
if ( userLang.includes('ca') ) {
  lang = 'ca';
} else if ( userLang.includes('es') ) {
  lang = 'es';
} else if ( userLang.includes('en') ) {
  lang = 'en';
} else if ( userLang.includes('it') ) {
  lang = 'it';
} /* else if ( userLang.includes('fr') ) {
  lang = 'fr';
} */

// Selects new language and reload the page
export const selectLanguage = (language) => {
    console.log("utils:languageSelected", language)
    window.sessionStorage.setItem('lang', language)
    lang = language
    if (window.location.href.includes('teresaripoll')) {
      window.location.href = "https://teresaripoll.com"
    } else {
      window.location.reload()
    }
}

export const getCountryByLang = () => {
  switch (lang) {
    case 'es':
      return 'SPAIN'
    case 'it':
      return 'ITALY'
    case 'ca': 
      return 'SPAIN'
    case 'fr':
      return 'FRANCE'
    default:
      return 'UNITED KINGDOM'
  }
}

