import React, {useEffect} from 'react'
import { cookiesPolicyT } from '../i18n/CookiesPolicy.trans'
import { lang } from '../i18n/i18n'

export const CookiesPolicy = () => {
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <div style={styles.container}>
            <br/><br/>
            <h3>{cookiesPolicyT.COOKIES_POLICY[lang]}</h3><br/>
            <p>{cookiesPolicyT.COOKIES_POLICY_DESC[lang]}</p><br/><br/>

            <h3>{cookiesPolicyT.COOKIES_USED[lang]}</h3><br/>
            <p>{cookiesPolicyT.COOKIES_USED_DESC[lang]}</p><br/><br/>

            <h3>{cookiesPolicyT.ADDITIONAL_NOTES[lang]}</h3><br/>
            <p>{cookiesPolicyT.ADDITIONAL_NOTES_DESC[lang]}</p><br/>
        </div>
    )
}

const styles = {
    container: {
        paddingTop: 75,
        paddingLeft: 50,
        paddingRight: 50,
    },
    legalbig: {
    },
    legalmedium: {
    },
}
