
export const i18n2 = {
    // c o o k i e s
    cookie_message: { 
      "ca": "Aquesta web utilitza cookies per oferir-li la millor experiència d'us. Si continua navegant està acceptant aquestes cookies i la nostra ", 
      "es": "Este sitio web utiliza cookies para que usted tenga la mejor experiencia de usuario. Si continúa navegando está dando su consentimiento para la aceptación de las mencionadas cookies y la aceptación de nuestra ", 
      "en": "This site uses cookies for your use experience. If you continue surfing the web your give us the consent to use thees cookies and accepting our ",
      "fr": "Ce site utilise des cookies pour votre exp&eacute;rience d'utilisation. Si vous continuez &agrave; naviguer sur le Web, vous nous autorisez &agrave; utiliser ces cookies et &agrave; accepter notre ",
      "it": "Questo sito Web utilizza i cookie per offrirti la migliore esperienza utente. Se continui a navigare, dai il tuo consenso per l'accettazione dei suddetti cookie e l'accettazione dei nostri "
    },
    cookie_politics: {
      "ca": "política de cookies", 
      "es": "política de cookies", 
      "en": "cookies policy",
      "fr": "politique de cookies",
      "it": "politica dei cookie"
    },
    click_on_link: {
      "ca": "prem sobre l'enllaç per més informació", 
      "es": "pinche el enlace para mayor información", 
      "en": "click on link to get more information",
      "fr": "cliquez sur le lien pour obtenir plus d'informations",
      "it": "clicca sul link per maggiori informazioni"
    },
    btn_accept_cookies: { 
      "ca": "D'acord", 
      "es": "Aceptar", 
      "en": "Agree",
      "fr": "Se mettre d'accord",
      "it": "Accettare"
    },
}