export const historyT = {
    // h i s t o r y
    maria_ripoll_in_paris: { 
      "ca": "MARIA RIPOLL A PARÍS", 
      "es": "MARIA RIPOLL EN PARÍS", 
      "en": "MARIA RIPOLL IN PARÍS",
      "fr": "MARIA RIPOLL À PARÍS",
      "it": "MARIA RIPOLL A PARIGI"
    },
    maria_ripoll_in_paris_desc: { 
      "ca": "La Maria estudia tècniques de patronatge i disseny a parís, col·laborant amb Jean Patou i Pierre Cardin.", 
      "es": "Maria estudia en París técnica en patronaje y diseño y colabora con Jean Patou y Pierre Cardin.", 
      "en": "Maria studies pattern making techniques and design in Paris and collaborates with Jean Patou and Pierre Cardin.",
      "fr": "Maria &eacute;tudie les techniques de mod&eacute;lisme et de design &agrave; Paris et collabore avec Jean Patou et Pierre Cardin.",
      "it": "Maria ha studiato design e pattern design a Parigi e ha collaborato con Jean Patou e Pierre Cardin."
    },
    teresa_ripoll_in_paris: { 
      "ca": "TERESA RIPOLL A PARÍS", 
      "es": "TERESA RIPOLL EN PARÍS", 
      "en": "TERESA RIPOLL IN PARÍS",
      "fr": "TERESA RIPOLL À PARÍS",
      "it": "TERESA RIPOLL A PARIGI"
    },
    teresa_ripoll_in_paris_desc: { 
      "ca": "La seva germana Teresa s'incorpora col·laborant també amb Pierre Cardin a París.", 
      "es": "Su hermana Teresa se incorpora,colabora también con Pierre Cardin en París.", 
      "en": "Her sister Teresa joins her, also collaborating with Pierre Cardin in Paris.",
      "fr": "Sa soeur Teresa la rejoint, collaborant &eacute;galement avec Pierre Cardin &agrave; Paris.",
      "it": "Sua sorella Teresa si unisce, collabora anche con Pierre Cardin a Parigi."
    },
    they_create_the_company: { 
      "ca": "CREEN L'EMPRESA", 
      "es": "CREAN LA EMPRESA", 
      "en": "THEY CREATE THE COMPANY",
      "fr": "ELLES CR&Eacute;ENT LA COMPAGNIE",
      "it": "CREA L'AZIENDA"
    },
    they_create_the_company_desc: { 
      "ca": "Les germanes decideixen crear la seva pròpia firma en un poble de muntanya a la zona de Girona.", 
      "es": "Las hermanas deciden crear su propia firma en un pueblo de montaña en la zona de Gerona.", 
      "en": "The sisters decide to create their own company in a mountain village in the Girona area.",
      "fr": "Les deux s&#339;urs d&eacute;cident de cr&eacute;er leur propre entreprise dans un village de montagne de la r&eacute;gion de G&eacute;rone.",
      "it": "Le sorelle decidono di creare la propria azienda in un villaggio di montagna nella zona di Gerona."
    },
    death_of_maria_ripoll: { 
      "ca": "MORT DE MARIA RIPOLL", 
      "es": "MUERE MARIA RIPOLL", 
      "en": "DEATH OF MARIA RIPOLL",
      "fr": "D&Eacute;C&Egrave;S DE MARIA RIPOLL",
      "it": "DIE MARIA RIPOLL"
    },
    death_of_maria_ripoll_desc: { 
      "ca": "Mort de la gran dissenyadora Maria Ripoll, cofundadora de l'empresa.", 
      "es": "Muerte de la gran diseñadora Maria Ripoll, cofundadora de la empresa.", 
      "en": "Death of the great fashion designer, Maria Ripoll, cofounder on the company.",
      "fr": "D&eacute;c&egrave;s de la grande cr&eacute;atrice de mode Maria Ripoll, cofondatrice de la soci&eacute;t&eacute;.",
      "it": "Morte della grande stilista Maria Ripoll, co-fondatrice dell'azienda."
    },
    introduction_of_the_trb: { 
      "ca": "INTRODUCCIÓ DE LA MARCA TERESA RIPOLL", 
      "es": "INTRODUCCIÓN DE LA MARCA TERESA RIPOLL", 
      "en": "INTRODUCTION OF THE TERESA RIPOLL BRAND",
      "fr": "INTRODUCTION DE LA MARQUE TERESA RIPOLL",
      "it": "INTRODUZIONE DEL MARCHIO TERESA RIPOLL"
    },
    expansion_in_europe: { 
      "ca": "EXPANSIÓ DE LA MARCA A EUROPA", 
      "es": "EXPANSIÓN DE MARCA EN EUROPA", 
      "en": "EXPANSION OF THE BRAND IN EUROPE",
      "fr": "EXPANSION DE LA MARQUE EN EUROPE",
      "it": "ESPANSIONE DEL MARCHIO IN EUROPA"
    },
    international_expansion: { 
      "ca": "INICI EXPANSIÓ INTERNACIONAL", 
      "es": "INICIO EXPANSIÓN INTERNACIONAL", 
      "en": "START OF INTERNATIONAL EXPANSION",
      "fr": "D&Eacute;BUT DE L&rsquo;EXPANSION INTERNATIONALE",
      "it": "HOME ESPANSIONE INTERNAZIONALE"
    },
    wedding_collection: { 
      "ca": "LLANÇAMENT DE LA LÍNIA DE NÚVIA", 
      "es": "LANZAMIENTO DE LA LINEA DE NOVIA", 
      "en": "FIRST WEDDING COLLECTION RELEASE",
      "fr": "PREMIERE COLLECTION DE MARIAGE",
      "it": "LANCIO DELLA LINEA SPOSA"
    },
    years_of_design_45: { 
      "ca": "45 ANYS DE DISSENY I INNOVACIó", 
      "es": "45 AÑOS DE DISEÑO E INNOVACION", 
      "en": "45 YEARS OF DESIGN AND INNOVATION",
      "fr": "45 ANNÊES DE CREATION ET D'INNOVATION",
      "it": "45 ANNI DI DESIGN E INNOVAZIONE"
    },
    opening_of_the_atelier_in_barcelona: {
      "ca": "OBERTURA DE L'ATELIER A BARCELONA",
      "en": "OPENING OF THE ATELIER IN BARCELONA,",
      "es": "APERTURA DEL ATELIER EN BARCELONA",
      "fr": "OUVERTURE DE L'ATELIER À BARCELONE",
      "it": "APERTURA DELL'ATELIER A BARCELLONA"
    },
    of_love_for_fashion_50 : {
      "ca": "50 ANYS D’AMOR PER LA MODA",   
      "en": "50 OF LOVE FOR FASHION",
      "es": "50 AÑOS DE AMOR POR LA MODA",
      "fr": "50 ANS D’AMOUR POUR LA MODE",
      "it": "50 ANNI DI AMORE PER LA MODA"
    },
  }