

export const book_party_one = [
    // FUCSIA
    {
        title:"3614",
        light:"/img/cocktail/fiesta19/a/35_19-3614.jpg",
        hight:"/img/cocktail/fiesta19/c/35_19-3614.jpg",
    },
    {
        title:"3594",
        light:"/img/cocktail/fiesta19/a/37_19-3594.jpg",
        hight:"/img/cocktail/fiesta19/c/37_19-3594.jpg",
    },
    {
        title:"3614CH",
        light:"/img/cocktail/fiesta19/a/36B_19-3614CH.jpg",
        hight:"/img/cocktail/fiesta19/c/36B_19-3614CH.jpg",
    },
    {
        title:"3615",
        light:"/img/cocktail/fiesta19/a/36A_19-3615.jpg",
        hight:"/img/cocktail/fiesta19/c/36A_19-3615.jpg",
    },
    {
        title:"3592",
        light:"/img/cocktail/fiesta19/a/42_19-3592.jpg",
        hight:"/img/cocktail/fiesta19/c/42_19-3592.jpg",
    },
    {
        title:"3613",
        light:"/img/cocktail/fiesta19/a/49_19-3613.jpg",
        hight:"/img/cocktail/fiesta19/c/49_19-3613.jpg",
    },

    // VERMELL
    {
        title:"3602",
        light:"/img/cocktail/fiesta19/a/41_19-3602.jpg",
        hight:"/img/cocktail/fiesta19/c/41_19-3602.jpg",
    },
    {
        title:"3611",
        light:"/img/cocktail/fiesta19/a/50_19-3611.jpg",
        hight:"/img/cocktail/fiesta19/c/50_19-3611.jpg",
    },
    {
        title:"3610",
        light:"/img/cocktail/fiesta19/a/51_19-3610.jpg",
        hight:"/img/cocktail/fiesta19/c/51_19-3610.jpg",
    },
    {
        title:"3607",
        light:"/img/cocktail/fiesta19/a/52_19-3607.jpg",
        hight:"/img/cocktail/fiesta19/c/52_19-3607.jpg",
    },

    // DORADO
    {
        title:"3638",
        light:"/img/cocktail/fiesta19/a/24_19-3638.jpg",
        hight:"/img/cocktail/fiesta19/c/24_19-3638.jpg",
    },
    {
        title:"3593",
        light:"/img/cocktail/fiesta19/a/02_19-3593.jpg",
        hight:"/img/cocktail/fiesta19/c/02_19-3593.jpg",
    },
    {
        title:"3637",
        light:"/img/cocktail/fiesta19/a/03_19-3637.jpg",
        hight:"/img/cocktail/fiesta19/c/03_19-3637.jpg",
    },
    {
        title:"3606",
        light:"/img/cocktail/fiesta19/a/01_19-3606.jpg",
        hight:"/img/cocktail/fiesta19/c/01_19-3606.jpg",
    },

    {
        title:"3631",
        light:"/img/cocktail/fiesta19/a/14_19-3631.jpg",
        hight:"/img/cocktail/fiesta19/c/14_19-3631.jpg",
    },
    {
        title:"3630",
        light:"/img/cocktail/fiesta19/a/15_19-3630.jpg",
        hight:"/img/cocktail/fiesta19/c/15_19-3630.jpg",
    },

    // ROSA BROCAT
    {
        title:"3622",
        light:"/img/cocktail/fiesta19/a/08_19-3622.jpg",
        hight:"/img/cocktail/fiesta19/c/08_19-3622.jpg",
    },
    {
        title:"3623",
        light:"/img/cocktail/fiesta19/a/09_19-3623.jpg",
        hight:"/img/cocktail/fiesta19/c/09_19-3623.jpg",
    },

    {
        title:"3624",
        light:"/img/cocktail/fiesta19/a/05A_19-3624.jpg", 
        hight:"/img/cocktail/fiesta19/c/05A_19-3624.jpg", 
    },
    {
        title:"3624",
        light:"/img/cocktail/fiesta19/a/05B-19-3624.jpg", 
        hight:"/img/cocktail/fiesta19/c/05B-19-3624.jpg", 
    },
    {
        title:"6175",
        light:"/img/cocktail/fiesta19/a/06A_19-6175.jpg", 
        hight:"/img/cocktail/fiesta19/c/06A_19-6175.jpg", 
    },
    {
        title:"6175",
        light:"/img/cocktail/fiesta19/a/06B_19-6175.jpg", 
        hight:"/img/cocktail/fiesta19/c/06B_19-6175.jpg", 
    },
    {
        title:"3603",
        light:"/img/cocktail/fiesta19/a/07B_19-3603.jpg", 
        hight:"/img/cocktail/fiesta19/c/07B_19-3603.jpg", 
    },
    {
        title:"3603",
        light:"/img/cocktail/fiesta19/a/07A_19-3603.jpg", 
        hight:"/img/cocktail/fiesta19/c/07A_19-3603.jpg", 
    },

    {
        title:"3626",
        light:"/img/cocktail/fiesta19/a/25_19-3626.jpg",  
        hight:"/img/cocktail/fiesta19/c/25_19-3626.jpg",  
    },
    {
        title:"3632",
        light:"/img/cocktail/fiesta19/a/27_19-3632.jpg",  
        hight:"/img/cocktail/fiesta19/c/27_19-3632.jpg",  
    },
    {
        title:"3598",
        light:"/img/cocktail/fiesta19/a/04_19-3598.jpg",  
        hight:"/img/cocktail/fiesta19/c/04_19-3598.jpg",  
    },
    {
        title:"3605",
        light:"/img/cocktail/fiesta19/a/10_19-3605.jpg",  
        hight:"/img/cocktail/fiesta19/c/10_19-3605.jpg",  
    },
    {
        title:"3604",
        light:"/img/cocktail/fiesta19/a/43_19-3604.jpg",  
        hight:"/img/cocktail/fiesta19/c/43_19-3604.jpg",  
    },
    {
        title:"3664",
        light:"/img/cocktail/fiesta19/a/11_19-3664.jpg",  
        hight:"/img/cocktail/fiesta19/c/11_19-3664.jpg",  
    },

    {
        title:"2215",
        light:"/img/cocktail/fiesta19/a/12_19-2215.jpg",  
        hight:"/img/cocktail/fiesta19/c/12_19-2215.jpg",  
    },
    {
        title:"3627",
        light:"/img/cocktail/fiesta19/a/13_19-3627.jpg",  
        hight:"/img/cocktail/fiesta19/c/13_19-3627.jpg",  
    }

]