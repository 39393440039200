

export const salesPointT = {
    "SALES_POINTS": { 
      "ca": "PUNTS DE VENTA", 
      "es": "PUNTOS DE VENTA", 
      "en": "SALE POINTS",
      "fr": "PPOINTS DE VENTE",
      "it": "PUNTI VENDITA"
    },
    "COUNTRIES_SELECTOR": { 
      "ca": "PAÏSOS", 
      "es": "PAISES", 
      "en": "COUNTRIES",
      "fr": "PAR PAYS",
      "it": "PAESI"
    },
    "NEAREST_SHOP": {
      "ca": "Troba la teva botiga més propera:", 
      "es": "Encuentra tu tienda mas cercana:", 
      "en": "Find your nearest store:",
      "fr": "Trouvez votre magasin le plus proche:",
      "it": "Trova il negozio più vicino:"
    }
  }