import React, { useState } from 'react'
import { BtnBack } from '../components/BtnBack'
import { ImageCardWithLoading } from '../components/ImageCardWithLoading'
import { SectionTitle } from '../components/SectionTitle'


export const Preview = ({ data, title }) => {
  const [containerWidth, setContainerWidth] = useState(window.innerWidth >= 768 ? '25%' : '50%')
  const [video, setVideo] = useState('')
  const [scroll, setScroll] = useState(0)

  const onShowViedeo = (video) => {
    setScroll(window.scrollY)
    setVideo(video)
  }

  const onBtnBack = () => {
    console.log(scroll)
    if (scroll !== 0) setTimeout(() => window.scrollTo(0, scroll), 100)
    setVideo('')
  }

  return (
    <div>
      <div style={{ ...styles.video, display: video !== '' ? 'flex' : 'none' }}>
        <BtnBack onClose={onBtnBack} />
        {
          containerWidth === '50%'
            ? <video
              style={{ width: '100%' }}
              //ref={video}
              //controls=""
              //autoplay="autoplay"
              playsInline
              webkitPlaysinline
              autoplay="autoplay"
              loop
              muted
              src={video}
              type="video/mp4"
            ></video>
            : <video
              style={{ height: '80%', paddingLeft: 3, paddingTop: 3, paddingBottom: 3, border: "1px solid grey" }}
              controls=""
              //autoplay
              autoplay="autoplay"
              loop
              muted
              src={video}
              type="video/mp4"
            ></video>
        }
      </div>
      <div style={{
        ...styles.container,
        paddingLeft: containerWidth === '25%' ? 50 : 5,
        paddingRight: containerWidth === '25%' ? 50 : 5,
        display: video === '' ? 'block' : 'none'
      }}>
        <SectionTitle title={ title } />
        <div style={styles.body}>
          {
            data.map(card =>
              <ImageCardWithLoading
                key={card.title}
                card={card}
                containerWidth={containerWidth}
                showTitle={true}
                setZoomImageUrl={() => { onShowViedeo(card.video) }}
              />
            )
          }
        </div>
      </div>

    </div>
  )
}


const styles = {
  container: {
    paddingTop: 75,
    paddingLeft: 50,
    paddingRight: 50,
  },
  body: {
    backgroundColor: '#fff',
    //boxShadow: '-3px -3px 10px #ccc',
    minHeight: 500,
    paddingTop: 30,
    //paddingLeft: 50,
    //paddingRight: 50,

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  video: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    //top: 75,
    paddingTop: 50,
    left: 0,
    width: '100%',
    height: '100%',
    //backgroundColor: 'rgba(0,0,0,1)',
    backgroundColor: 'white',
    zIndex: 1,
  },
}