import React, { useState, useEffect, useRef } from 'react'
import { useWindowResize } from '../hooks/useWindowResize'
import { BtnBack } from './BtnBack'
import ReactSwipe from 'react-swipe'
import { ImageZoom } from './ImageZoom'
import {lang} from '../i18n/i18n'

export const DetailMobileSwipe = ({card, onClose}) => {
    const [selectedImage, setSelectedImage] = useState(0)
    const winSize = useWindowResize()
    const SQUARE = winSize.width / 5
    const [zoomCard, setZoomCard] = useState('')
    const video = useRef()

    // Swipe
    //let reactSwipeEl;

    const assets = [
        {asset: card.light, type:"image", hight: card.hight},
        {asset: card.front_light, type:"image", hight: card.front_hight},
        {asset: card.back_light, type:"image", hight: card.back_hight},
        {asset: card.detail_light, type:"image", hight: card.detail_hight},
        {asset: card.video, type:"video", poster: card.light},
    ]
    // Si no hi ha Davant ni Darrere, esborrem els elements 1 i 2
    if (card.front_light === '') {
        assets.splice(1, 2)
        // afegim dos items sense dades
        //assets.push({asset: '', type:"image", hight: ''})
        //assets.push({asset: '', type:"image", hight: ''})
    }

    useEffect(() => {
        if (selectedImage === assets.length - 1) video.current.play()
    }, [selectedImage])

    useEffect(() => {
        setTimeout(() => {
            if (zoomCard === '') window.scrollTo(0,0)
        }, 200)
        
    }, [zoomCard])

    return (
        zoomCard !== '' 
        ? <ImageZoom card={zoomCard} scroll={{ x: window.scrollX, y: window.scrollY }} selectedImage={selectedImage} onClick={() => setZoomCard('')} />
        : <div style={styles.container}>
            <BtnBack onClose={onClose}/>
            <div style={{width: winSize.width, height:winSize.height-SQUARE * 2, overflow:'hidden'}}>
                <ReactSwipe
                    className="carousel"
                    //childCount={assets.length}
                    swipeOptions={{ 
                        continuous: false, 
                        startSlide: selectedImage,
                        //swiping: ()=>console.log("Swiping"),
                        callback: (index, elem) => setSelectedImage(index),
                        //transitionEnd: () => console.log("Transition End"),
                    }}
                    //ref={el => (reactSwipeEl = el)}
                >
                    {assets.map( (elem, i) => <div style={{width: winSize.width, height:winSize.height-SQUARE * 2, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        {assets[i].type === "image"
                            ? <img 
                                src={assets[i].asset} 
                                alt="" 
                                style={{objectFit:'contain', maxHeight:winSize.height-SQUARE * 2}}
                                onClick={() => setZoomCard({hight:elem.hight, title:card.title})}
                              />
                            : <video 
                                style={{width:'100%'}}
                                ref={video}
                                //controls=""
                                //autoplay="autoplay"
                                playsInline
                                webkitPlaysinline
                                loop
                                muted 
                                src={assets[i].asset}
                                type="video/mp4" 
                            ></video>}
                        </div>
                    )}
                </ReactSwipe>
            </div>
            <div style={styles.imageMenuDiv}>
                <div style={{width:assets.length * SQUARE, display:'flex', flexDirection:'row'}}>
                    {assets.map( (el, i) => 
                        <div style={{
                            ...styles.imageBoxDiv, 
                            width: SQUARE, 
                            height: SQUARE, 
                            borderColor: i === selectedImage ? "black" : "#aaa",
                            zIndex: i === selectedImage ? 1 : 0,
                        }}>
                            {
                                el.type === "video" 
                                ? <div 
                                    style={{height:'100%', width:'100%', position:'relative', display:'flex'}}
                                    onClick={() => setSelectedImage(i)}
                                  >
                                    <video src={el.asset} style={{width:'100%'}} poster={el.poster}/>
                                    <img src="/img/2021/video-play-button.png" style={{width:'50%', position:'absolute', top:SQUARE * 0.25, left:SQUARE * 0.25}} alt=""/>
                                  </div>
                                : <img src={el.asset} alt="" style={{height:'100%', width:'auto'}} onClick={() => setSelectedImage(i)}/>
                            }
                        </div>
                    )}
                </div>
            </div>
            <div style={{ padding: '20px 30px', }}>
                <h4 style={{fontSize:25, fontWeight:800, textAlign:'center', color: '#333'}}>MODEL {card.title}</h4>
                <p style={{fontWeight:200, lineHeight: '35px', fontSize: 18, letterSpacing: 1, marginTop: 20}}>
                    {card.trans && card.trans[lang]}
                </p>
            </div>
        </div>
    )
}

const styles = {
    container: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        width:'100%',
        overflow:'hidden',
    },
    imageMenuDiv: {
        display:'flex',
        flexDirection:'row',
        width:'100%',
        overflow:'hidden',
    },
    imageBoxDiv: {
        display:'flex',
        justifyContent:'center',
        border: '1px solid #aaa',
        marginLeft: -1,
    }
}
