// https://unicode-table.com/en/html-entities/

export const legalNoticeT = {
    "AVISO_LEGAL_SITE": {
      "ca": "AVÍS LEGAL SOBRE LA WEB", 
      "es": "AVISO LEGAL DEL SITIO WEB", 
      "en": "LEGAL NOTICE ON WEBSITE",
      "fr": "AVIS JURIDIQUE SUR LE SITE WEB",
      "it": "AVVISO LEGALE SUL SITO"
    },
    "WELCOME": {
      "ca": "Benvingut a la pàgina web de MARIA RIPOLL, S.L. A continuació li exposem les condicions d'ús d'aquesta pàgina web. La navegació per aquesta pàgina web li atorga el rol d'usuari de la mateixa i, per tant, accepta les clàusules detallades en aquest document.", 
      "es": "Bienvenido a la página web de MARIA RIPOLL, S.L.	A continuación le exponemos las condiciones de uso de esta página WEB. La navegación por esta página WEB le otorga el rol de usuario de la misma y, por tanto, acepta las cláusulas detalladas en este documento.", 
      "en": "Welcome to the website of MARIA RIPOLL, S.L. Below we expose the conditions of use of this web page. Browsing this web page gives you the role of user thereof and, therefore, accepts the clauses detailed in this document.",
      "fr": "Bienvenue sur le site de MARIA RIPOLL, S.L. Nous exposons ci-dessous les conditions d'utilisation de cette page Web. La navigation sur cette page Web vous en donne le r\u00F4le d'utilisateur et accepte donc les clauses d\u00E9taill\u00E9es dans ce document.",
      "it": "Benvenuti nel sito web di MARIA RIPOLL, S.L. Di seguito esponiamo le condizioni di utilizzo di questa pagina web. La navigazione in questa pagina web ti d\u00E0 il ruolo di utente di esso e, pertanto, accetta le clausole dettagliate in questo documento."
    },
    "IDENTIFICATION": {
      "ca": "1- IDENTIFICACIÓ", 
      "es": "1- IDENTIFICACIÓN", 
      "en": "1- IDENTIFICATION",
      "fr": "1- IDENTIFICATION",
      "it": "1- IDENTIFICAZIONE"
    },
    "IDENTIFICATION_DESC": {
      "ca": "De conformitat amb el que disposa l'article 10 de la Llei 34/2002, de 11 de juliol, de Serveis de Societat de la Informació i Comerç Electrònic, s'informa que www.teresaripoll.com és un domini de l'empresa MARIA RIPOLL, S.L. amb domicili social al carrer Dr. Alfons Torrent, 4, 17403 Sant Hilari Sacalm, Girona, Spain i amb telèfon +34972868208. La societat es denomina MARIA RIPOLL, S.L. amb CIF: B-17143124 que figura inscrita al Registre Mercantil de Girona, Full 929, Foli 188, Tom 626, Llibre 43, Secció 2ª.", 
      "es": "De conformidad con lo que dispone el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de Sociedad de la Información y Comercio Electrónico, se informa que www.teresaripoll.com es un dominio de la empresa MARIA RIPOLL, S.L. con domicilio social en Calle Dr. Alfons Torrent, 4, 17403 Sant Hilari Sacalm, Girona, Spain y con teléfono +34972868208. La sociedad se denomina MARIA RIPOLL, S.L. con CIF: B-17143124 que figura inscrita en el Registro Mercantil de Girona, Hoja 929, Folio 188, Tomo 626, Libro 43, Sección 2ª.", 
      "en": "In accordance with the provisions of article 10 of Law 34/2002, of July 11, Services of Information Society and Electronic Commerce, it is reported that www.teresaripoll.com is a domain of the company MARIA RIPOLL, S.L. with registered office at Calle Dr. Alfons Torrent, 4, 17403 Sant Hilari Sacalm, Girona, Spain and with telephone +34972868208. The company is called MARIA RIPOLL, S.L. with CIF: B-17143124 which is registered in the Mercantile Register of Girona, Sheet 929, Folio 188, Volume 626, Book 43, Section 2.",
      "fr": "Conform\u00E9ment aux dispositions de l'article 10 de la loi n \u00b0 34/2002 du 11 juillet relative aux services de la soci\u00E9t\u00E9 de l'information et au commerce \u00E9lectronique, il est signal\u00E9 que www.teresaripoll.com est un domaine de la soci\u00E9t\u00E9 MARIA RIPOLL, S.L. dont le si\u00E8ge social est situ\u00E9 Calle Dr. Alfons Torrent, 4, 17403 Sant Hilari Sacalm, G\u00E9rone, Espagne et par t\u00E9l\u00E9phone au +34972868208. La soci\u00E9t\u00E9 s'appelle MARIA RIPOLL, S.L. avec CIF: B-17143124 qui est inscrit au registre du commerce de G\u00E9rone, feuille 929, folio 188, volume 626, livre 43, section 2",
      "it": "In conformit\u00E0 con le disposizioni dell'articolo 10 della legge 34/2002, dell'11 luglio, Servizi di societ\u00E0 dell'informazione e commercio elettronico, \u00E8 stato riferito che www.teresaripoll.com \u00E8 un dominio della societ\u00E0 MARIA RIPOLL, S.L. con sede legale in Calle Dr. Alfons Torrent, 4, 17403 Sant Hilari Sacalm, Girona, Spagna e con telefono +34972868208. La societ\u00E0 si chiama MARIA RIPOLL, S.L. con CIF: B-17143124 che \u00E8 registrato nel Registro Mercantile di Girona, Foglio 929, Folio 188, Volume 626, Libro 43, Sezione 2."
    },
    "NORMAS": {
      "ca": "2- NORMES D'UTILITZACIÓ", 
      "es": "2- NORMAS DE UTILIZACIÓN", 
      "en": "2- NORMS OF UTILIZATION",
      "fr": "2- NORMES D'UTILISATION",
      "it": "2- NORME DI UTILIZZO"
    },
    "NORMAS_DESC1": {
      "ca": "L'Usuari es compromet a fer un ús adequat i lícit del lloc Web així com dels continguts i serveis, de conformitat amb la legislació aplicable en cada moment, la moral i bons costums generalment acceptats i l'ordre públic.", 
      "es": "El Usuario se compromete a hacer un uso adecuado y lícito del Sitio Web así como de los contenidos y servicios, de conformidad con la legislación aplicable en cada momento,  la moral y buenas costumbres generalmente aceptadas y  el orden público.", 
      "en": "The User agrees to make an appropriate and lawful use of the Website as well as the contents and services, in accordance with the applicable legislation at all times, morality and generally accepted good customs and public order.",
      "fr": "L'utilisateur s'engage \u00E0 faire un usage appropri\u00E9 et licite du site Web, ainsi que du contenu et des services, conform\u00E9ment \u00E0 la l\u00E9gislation en vigueur, \u00E0 tout moment, aux bonnes moeurs et aux bonnes coutumes et \u00E0 l'ordre public g\u00E9n\u00E9ralement accept\u00E9s.",
      "it": "L'Utente si impegna a fare un uso appropriato e lecito del Sito Web, nonch\u00E9 i contenuti e i servizi, in conformit\u00E0 con la legislazione applicabile in ogni momento, la moralit\u00E0 e le buone abitudini generalmente accettate e l'ordine pubblico."
    },
    "NORMAS_DESC2": {
      "ca": "Queda prohibit realitzar, per la seva banda, qualsevol acci\u00F3 que pugui provocar alteracions dels continguts d'aquesta WEB o un mal funcionament de la mateixa, incloent la introducci\u00F3 de virus o similars.", 
      "es": "Queda prohibido realizar, por su parte, cualquier acción que pueda provocar alteraciones de los contenidos de esta WEB o un mal funcionamiento de la misma, incluyendo la introducción de virus o similares.", 
      "en": "It is forbidden to carry out, for its part, any action that may cause alterations to the contents of this WEB or a malfunction of it, including the introduction of viruses or the like.",
      "fr": "Il est interdit d'effectuer, pour sa part, toute action pouvant entra\u00EEner une modification du contenu de ce site Web, un dysfonctionnement de celui-ci, y compris l'introduction de virus ou autres.",
      "it": "\u00E8 vietato eseguire, da parte sua, qualsiasi azione che possa causare alterazioni del contenuto di questo WEB o un suo malfunzionamento, inclusa l'introduzione di virus o simili."
    },
    "INTELECTUAL_PROPERTY": {
      "ca": "3- PROPIETAT INTEL·LECTUAL", 
      "es": "3- PROPIEDAD INTELECTUAL", 
      "en": "3- INTELLECTUAL PROPERTY",
      "fr": "3- PROPRI\u00E9T\u00E9 INTELLECTUELLE",
      "it": "3- PROPRIET\u00E0 INTELLETTUALE"
    },
    "INTELECTUAL_PROPERTY_DESC1": {
      "ca": "Les pàgines del WEB i la informació o els elements que conté, inclouen textos, documents, fotografies, dibuixos, representacions gràfiques, bases de dades, vídeos i també logotips, marques, noms comercials, o altres signes distintius, estan protegits per drets d' propietat intel·lectual o industrial, dels quals MARIA RIPOLL, SL o les empreses del seu grup són titulars o llicenciatàries legítimes.", 
      "es": "Las páginas de la WEB y la información o los elementos que contiene, incluyen textos, documentos, fotografías, dibujos, representaciones gráficas, bases de datos, vídeos y también logotipos, marcas, nombres comerciales, u otros signos distintivos, están protegidos por derechos de propiedad intelectual o industrial, de los cuales MARIA RIPOLL, S.L. o las empresas de su grupo son titulares o licenciatarias legítimas.", 
      "en": "The pages of the WEBSITE and the information or the elements it contains, include texts, documents, photographs, drawings, graphic representations, databases, videos and also logos, trademarks, trade names, or other distinctive signs, are protected by copyright. intellectual or industrial property, of which MARIA RIPOLL, SL or the companies in your group are legitimate owners or licensees.",
      "fr": "Les pages du SITE WEB et les informations ou les \u00E9l\u00E9ments qu\u2019il contient comprennent des textes, des documents, des photographies, des dessins, des repr\u00E9sentations graphiques, des bases de donn\u00E9es, des vid\u00E9os ainsi que des logos, marques, noms commerciaux ou autres signes distinctifs, sont prot\u00E9g\u00E9s par le droit d'auteur. propri\u00E9t\u00E9 intellectuelle ou industrielle, dont MARIA RIPOLL, SL ou les soci\u00E9t\u00E9s de votre groupe sont des propri\u00E9taires l\u00E9gitimes ou des licenci\u00E9s.",
      "it": "Le pagine del SITO WEB e le informazioni o gli elementi in esso contenuti, inclusi testi, documenti, fotografie, disegni, rappresentazioni grafiche, database, video e anche loghi, marchi, nomi commerciali o altri segni distintivi sono protetti da copyright. propriet\u00E0 intellettuale o industriale, di cui MARIA RIPOLL, SL o le societ\u00E0 del tuo gruppo sono legittimi proprietari o licenziatari."
    },
    "INTELECTUAL_PROPERTY_DESC2": {
      "ca": "Queda prohibida qualsevol modalitat d'explotació, incloent qualsevol tipus de reproducció, distribució, cessió a tercers, comunicació pública i transformació, mitjançant qualsevol tipus de suport i mitjà, sense l'autorització prèvia i expressa.", 
      "es": "Queda prohibida cualquier modalidad de explotación, incluyendo cualquier tipo de reproducción, distribución, cesión a terceros, comunicación pública y transformación, mediante cualquier tipo de apoyo y medio, sin la autorización previa y expresa.", 
      "en": "Any type of exploitation is prohibited, including any type of reproduction, distribution, assignment to third parties, public communication and transformation, through any type of support and medium, without prior and express authorization.",
      "fr": "Tout type d'exploitation est interdit, y compris tout type de reproduction, distribution, cession \u00E0 des tiers, communication publique et transformation, par quelque support que ce soit, sur quelque support que ce soit, sans autorisation pr\u00E9alable et expresse.",
      "it": "Qualsiasi tipo di sfruttamento \u00E8 vietato, incluso qualsiasi tipo di riproduzione, distribuzione, cessione a terzi, comunicazione e trasformazione pubblica, attraverso qualsiasi tipo di supporto e supporto, senza previa ed esplicita autorizzazione."
    },
    "RESPONSABILITY": {
      "ca": "4- RESPONSABILITAT", 
      "es": "4- RESPONSABILIDAD", 
      "en": "4- RESPONSIBILITY",
      "fr": "4- RESPONSABILIT\u00E9",
      "it": "4- RESPONSABILIT\u00E0"
    },
    "RESPONSABILITY_DESC1": {
      "ca": "La nostra intenci\u00F3 \u00E9s la d'oferir un lloc WEB que ofereixi un funcionament continuat i de m\u00E0xima qualitat.", 
      "es": "Nuestra intención es la de ofrecer un sitio WEB que ofrezca un funcionamiento continuado y de máxima calidad.", 
      "en": "Our intention is to offer a WEB site that offers a continuous operation of the highest quality.",
      "fr": "Notre intention est de proposer un site WEB offrant un fonctionnement continu de la plus haute qualit\u00E9.",
      "it": "La nostra intenzione \u00E8 quella di offrire un sito WEB che offra un funzionamento continuo di altissima qualit\u00E0."
    },
    "RESPONSABILITY_DESC2": {
      "ca": "De tota manera, l'informem que no podem garantir l'acc\u00E9s continuat, i que les p\u00E0gines es puguin trobar impedides, o interrompudes per factors o circumst\u00E0ncies alienes a la nostra voluntat.", 
      "es": "De todas formas, le informamos que no podemos garantizar el acceso continuado, y que las páginas se puedan encontrar impedidas, o interrumpidas por factores o circunstancias ajenas a nuestra voluntad.", 
      "en": "In any case, we inform you that we can not guarantee continued access, and that the pages may be impeded, or interrupted by factors or circumstances beyond our control.",
      "fr": "Dans tous les cas, nous vous informons que nous ne pouvons pas garantir un acc\u00E8s continu et que les pages peuvent \u00EAtre g\u00EAn\u00E9es ou interrompues par des facteurs ou des circonstances ind\u00E9pendants de notre volont\u00E9.",
      "it": "In ogni caso, ti informiamo che non possiamo garantire un accesso continuo e che le pagine potrebbero essere impedite o interrotte da fattori o circostanze al di fuori del nostro controllo."
    },
    "RESPONSABILITY_DESC3": {
      "ca": "MARIA RIPOLL, S.L. no és responsable de la informació i altres continguts integrats en espais o pàgines web de tercers accessibles mitjançant enllaços, hipervincles o links.", 
      "es": "MARIA RIPOLL, S.L. no es responsable de la información y otros contenidos integrados en espacios o páginas web de terceros accesibles mediante enlaces, hipervínculos o links.", 
      "en": "MARIA RIPOLL, S.L. is not responsible for the information and other content integrated in spaces or web pages of third parties accessible through links, hyperlinks or links.",
      "fr": "MARIA RIPOLL, S.L. n'est pas responsable des informations et autres contenus int\u00E9gr\u00E9s dans des espaces ou des pages Web de tiers accessibles via des liens, des hyperliens ou des liens.",
      "it": "MARIA RIPOLL, S.L. non \u00E8 responsabile per le informazioni e altri contenuti integrati in spazi o pagine web di terze parti accessibili tramite link, collegamenti ipertestuali o link."
    },
    "RESPONSABILITY_DESC4": {
      "ca": "MARIA RIPOLL, S.L. no es fa responsable dels perjudicis que es poguessin derivar, entre altres, de:", 
      "es": "MARIA RIPOLL, S.L. no se hace responsable de los perjuicios que se pudieran derivar, entre otros, de:", 
      "en": "MARIA RIPOLL, S.L. is not responsible for the damages that may arise, among others, from:",
      "fr": "MARIA RIPOLL, S.L. n'est pas responsable des dommages qui pourraient r\u00E9sulter, entre autres, de:",
      "it": "MARIA RIPOLL, S.L. non \u00E8 responsabile per i danni che possono derivare, tra gli altri, da:"
    },
    "RESPONSABILITY_DESC5": {
      "ca": "- Interfer\u00E8ncies, interrupcions, fallades, omissions, avaries telef\u00F2niques, retards, bloquejos o desconnexions en el funcionament del sistema electr\u00F2nic, motivades per defici\u00E8ncies, sobrec\u00E0rregues i errors en les l\u00EDnies i xarxes de telecomunicacions", 
      "es": "- Interferencias, interrupciones, fallos, omisiones, averías telefónicas, retrasos, bloqueos o desconexiones en el funcionamiento del sistema electrónico, motivadas por deficiencias, sobrecargas y errores en las líneas y redes de telecomunicaciones", 
      "en": "- Interferences, interruptions, failures, omissions, telephone breakdowns, delays, blockages or disconnections in the operation of the electronic system, caused by deficiencies, overloads and errors in telecommunications lines and networks",
      "fr": "- Interf\u00E9rences, interruptions, d\u00E9faillances, omissions, pannes t\u00E9l\u00E9phoniques, retards, blocages ou d\u00E9connexions dans le fonctionnement du syst\u00E8me \u00E9lectronique, caus\u00E9s par des d\u00E9faillances, des surcharges et des erreurs dans les lignes et les r\u00E9seaux de t\u00E9l\u00E9communication",
      "it": "- Interferenze, interruzioni, guasti, omissioni, guasti telefonici, ritardi, blocchi o disconnessioni nel funzionamento del sistema elettronico, causate da carenze, sovraccarichi ed errori nelle linee e nelle reti di telecomunicazione"
    },
    "RESPONSABILITY_DESC6": {
      "ca": "- Intromissions il\u00B7leg\u00EDtimes mitjan\u00C7ant l'\u00FAs de programes malignes de qualsevol tipus ia trav\u00E9s de qualsevol mitj\u00E0 de comunicaci\u00F3.", 
      "es": "- Intromisiones ilegítimas mediante el uso de programas malignos de cualquier tipo y a través de cualquier medio de comunicación.", 
      "en": "- Illegitimate intromissions through the use of malicious programs of any kind and through any means of communication.",
      "fr": "- Des intromissions ill\u00E9gitimes via l'utilisation de programmes malveillants de tout type et par tout moyen de communication.",
      "it": "- Le intromissioni illegittime attraverso l'uso di programmi maligni di qualsiasi tipo e attraverso qualsiasi mezzo di comunicazione."
    },
    "RESPONSABILITY_DESC7": {
      "ca": "- Ús indegut o inadequat del lloc web.", 
      "es": "- Uso indebido o inadecuado del Sitio Web.", 
      "en": "- Improper or inappropriate use of the Website.",
      "fr": "- Utilisation inappropri\u00E9e ou inappropri\u00E9e du site Web.",
      "it": "- Uso improprio o inappropriato del sito web."
    },
    "RESPONSABILITY_DESC8": {
      "ca": "- Errors produ\u00EFts per un mal funcionament del navegador o per l'\u00FAs de versions no actualitzades del mateix.", 
      "es": "- Errores producidos por un mal funcionamiento del navegador o por el uso de versiones no actualizadas del mismo.", 
      "en": "- Errors caused by a bad functioning of the browser or by the use of non-updated versions of it.",
      "fr": "- Erreurs caus\u00E9es par un mauvais fonctionnement du navigateur ou par l'utilisation de versions non mises \u00E0 jour de celui-ci.",
      "it": "- Errori causati da un cattivo funzionamento del browser o dall'uso di versioni non aggiornate di esso."
    },
    "RESPONSABILITY_DESC9": {
      "ca": "EL usuari, en cas de causar danys i perjudicis per un \u00FAs il\u00B7l\u00EDcit o incorrecte d'aquest lloc WEB, podr\u00E0 ser reclamat dels danys o perjudicis causats.", 
      "es": "EL usuario, en caso de causar daños y perjuicios por un uso ilícito o incorrecto de este sitio WEB, podrá ser reclamado de los daños o perjuicios causados.", 
      "en": "The user, in case of causing damages for an unlawful or incorrect use of this website, may be claimed for any damages or losses caused.",
      "fr": "L'utilisateur, en cas de dommages caus\u00E9s par une utilisation ill\u00E9gale ou incorrecte de ce site, peut \u00EAtre r\u00E9clam\u00E9 pour tous dommages ou pr\u00E9judices caus\u00E9s.",
      "it": "L'utente, in caso di danni causati da un uso illecito o scorretto di questo sito Web, pu\u00F2 essere richiesto per eventuali danni o perdite causati."
    },
    "RESPONSABILITY_DESC10": {
      "ca": "Aix\u00ED mateix, l'usuari respondr\u00E0 davant de qualsevol dany i perjudici, que es derivi de l'\u00FAs per la seva part de \u0022robots\u0022, \u0022spiders\u0022, ... o eines similars emprades per tal de demanar o extreure dades o de qualsevol altra actuaci\u00F3 per part seva que imposi una c\u00E0rrega irraonable sobre el funcionament del lloc web.", 
      "es": "Asimismo, el usuario responderá frente a cualquier daño  y perjuicio, que se derive del uso por su parte de “robots”, “spiders”, … o herramientas similares empleadas con el fin de recabar o extraer datos o de cualquier otra actuación por su parte que imponga una carga irrazonable sobre el funcionamiento del Sitio Web.", 
      "en": "Likewise, the user shall be liable for any loss or damage arising from the use of \"robots\", \"spiders\", ... or similar tools used for the purpose of collecting or extracting data or any other action on their part. that imposes an unreasonable burden on the operation of the Website.",
      "fr": "De m\u00EAme, l'utilisateur sera responsable de tout dommage r\u00E9sultant de l'utilisation de \u0022robots\u0022, \u0022araign\u00E9es\u0022, ... ou d'outils similaires utilis\u00E9s dans le but de collecter ou d'extraire des donn\u00E9es ou toute autre action de leur part. qui impose un fardeau d\u00E9raisonnable sur le fonctionnement du site Web.",
      "it": "Allo stesso modo, l'utente sar\u00E0 responsabile per eventuali perdite o danni derivanti dall'uso di \u0022robot\u0022, \u0022spider\u0022, ... o strumenti simili utilizzati allo scopo di raccogliere o estrarre dati o qualsiasi altra azione da parte loro. che impone un onere irragionevole sul funzionamento del sito web."
    },
    "PRIVACITY": {
      "ca": "5 - POL\u00EDTICA DE PRIVACITAT I DE PROTECCI\u00F3 DE DADES", 
      "es": "5 - POLÍTICA DE PRIVACIDAD Y DE PROTECCIÓN DE DATOS", 
      "en": "5 - PRIVACY AND DATA PROTECTION POLICY",
      "fr": "5 - POLITIQUE DE CONFIDENTIALIT\u00E9 ET DE PROTECTION DES DONN\u00E9ES",
      "it": "5 - POLITICA SULLA PRIVACY E PROTEZIONE DEI DATI"
    },
    "PRIVACITY_DESC1": {
      "ca": "En la navegaci\u00F3 per la nostra WEB \u00E9s possible que es recopilin de vost\u00E8 certes dades de car\u00E0cter personal, necessaris per a la prestaci\u00F3 dels serveis sol\u00B7licitats.", 
      "es": "En la navegación por nuestra WEB es posible que se recopilen de usted ciertos datos de carácter personal, necesarios para la prestación de los servicios solicitados.", 
      "en": "When browsing our WEB it is possible that certain personal data, necessary for the provision of the requested services, are collected from you.",
      "fr": "Lors de la navigation sur notre site Web, il est possible que certaines donn\u00E9es personnelles n\u00E9cessaires \u00E0 la fourniture des services demand\u00E9s soient collect\u00E9es aupr\u00E8s de vous.",
      "it": "Durante la navigazione sul nostro WEB \u00E8 possibile che alcuni dati personali, necessari per la fornitura dei servizi richiesti, vengano raccolti da voi."
    },
    "PRIVACITY_DESC2": {
      "ca": "De conformitat amb el que disposa la Llei Org\u00E0nica 15/1999 de 13 de desembre, de Protecci\u00F3 de Dades de Car\u00E0cter Personal, l'informem que les dades subministrades per vost\u00E8. En algun dels apartats anteriors, quedaran incorporades en un fitxer automatitzat, titularitat de MARIA RIPOLL, SL amb la finalitat de prestar els serveis que ens sol\u00B7licita.", 
      "es": "De conformidad con lo que dispone la Ley Orgánica 15/1999 de 13 de diciembre, de Protección de Datos de Carácter Personal, le informamos que los datos suministrados por Vd. en alguno de los apartados anteriores, quedarán incorporados en un fichero automatizado, titularidad de MARIA RIPOLL, S.L. con la finalidad de prestar los servicios que nos solicita.", 
      "en": "In accordance with the provisions of the Organic Law 15/1999 of December 13, Protection of Personal Data, we inform you that the data provided by you in any of the previous sections, will be incorporated into an automated file, owned by MARIA RIPOLL, SL in order to provide the services you request.",
      "fr": "Conform\u00E9ment aux dispositions de la loi organique 15/1999 du 13 d\u00E9cembre sur la protection des donn\u00E9es personnelles, nous vous informons que les donn\u00E9es que vous avez fournies dans les sections pr\u00E9c\u00E9dentes seront incorpor\u00E9es dans un fichier automatis\u00E9 appartenant \u00E0 MARIA RIPOLL, SL afin de fournir les services que vous demandez.",
      "it": "In conformit\u00E0 con le disposizioni della Legge organica 15/1999 del 13 dicembre, Protezione dei dati personali, ti informiamo che i dati da te forniti in una qualsiasi delle precedenti sezioni, saranno incorporati in un file automatizzato, di propriet\u00E0 di MARIA RIPOLL, SL al fine di fornire i servizi richiesti."
    },
    "PRIVACITY_DESC3": {
      "ca": "La informació personal recollida és emmagatzemada en una base de dades propietat de MARIA RIPOLL, SL, que assumeix les mesures d'àmbit tècnic, organitzatiu i de seguretat que garanteixen la confidencialitat i integritat de la informació d'acord amb el que disposa la Llei Orgànica 15 / 1999 de 13 de desembre, de protecció de dades de caràcter personal, i la resta de legislació aplicable.", 
      "es": "La información personal recogida es almacenada en una base de datos propiedad de MARIA RIPOLL, S.L., quien asume las medidas de ámbito técnico, organizativo y de seguridad que garantizan la confidencialidad e integridad de la información de conformidad con lo que dispone la Ley Orgánica 15/1999 de 13 de diciembre, de Protección de Datos de Carácter Personal, y resto de legislación aplicable.", 
      "en": "The personal information collected is stored in a database owned by MARIA RIPOLL, SL, which assumes the technical, organizational and security measures that guarantee the confidentiality and integrity of the information in accordance with the Organic Law 15 / 1999 of December 13, Protection of Personal Data, and other applicable legislation.",
      "fr": "Les informations personnelles collect\u00E9es sont stock\u00E9es dans une base de donn\u00E9es appartenant \u00E0 MARIA RIPOLL, SL, qui applique les mesures techniques, organisationnelles et de s\u00E9curit\u00E9 qui garantissent la confidentialit\u00E9 et l'int\u00E9grit\u00E9 des informations en conformit\u00E9 avec la Loi Organique 15 / 13 d\u00E9cembre 1999, Protection des donn\u00E9es personnelles, et autres lois applicables.",
      "it": "Le informazioni personali raccolte sono archiviate in un database di propriet\u00E0 di MARIA RIPOLL, SL, che si assume le misure tecniche, organizzative e di sicurezza che garantiscono la riservatezza e l'integrit\u00E0 delle informazioni in conformit\u00E0 con la legge organica 15 / 1999 del 13 dicembre, Protezione dei dati personali e altra legislazione applicabile."
    },
    "PRIVACITY_DESC4": { 
      "ca": "Vè. Ens autoritza a enviar-li ofertes de productes i serveis que poguéssim considerar del seu interès, sempre relacionades amb la nostra activitat, excepte en el cas en què Vè. Ens manifesti el contrari, mitjançant comunicació escrita dirigida a MARIA RIPOLL, S.L. o bé enviant un correu electrònic a info@teresaripoll.com.", 
      "es": "Vd. nos autoriza a enviarle ofertas de productos y servicios que pudiésemos considerar de su interés, siempre relacionadas con nuestra actividad, excepto en el caso en que Vd. nos manifieste lo contrario, mediante comunicación escrita dirigida a MARIA RIPOLL, S.L. o bien enviando un correo electrónico a info@teresaripoll.com.", 
      "en": "You authorize us to send you offers of products and services that we may consider of your interest, always related to our activity, except in the case that you indicate otherwise, by written communication addressed to MARIA RIPOLL, S.L. or by sending an email to info@teresaripoll.com.",
      "fr": "Vous nous autorisez \u00E0 vous envoyer des offres de produits et services que nous pourrons consid\u00E9rer comme \u00E9tant de votre int\u00E9r\u00EAt, toujours en relation avec notre activit\u00E9, sauf dans le cas o\u00F9 vous indiquez autre chose, par communication \u00E9crite adress\u00E9e \u00E0 MARIA RIPOLL, S.L. ou en envoyant un email \u00E0 info@teresaripoll.com.",
      "it": "Ci autorizzi ad inviarti offerte di prodotti e servizi che potremmo considerare di tuo interesse, sempre relativi alla nostra attivit\u00E0, tranne nel caso in cui tu indichi diversamente, mediante comunicazione scritta indirizzata a MARIA RIPOLL, S.L. o inviando una mail a info@teresaripoll.com."
    },
    "PRIVACITY_DESC5": { 
      "ca": "En qualsevol moment vost\u00E8. Pot exercir el dret a accedir, rectificar i, si escau, cancel\u00B7lar les dades de car\u00E0cter personal, mitjan\u00C7ant comunicaci\u00F3 escrita dirigida a MARIA RIPOLL, S.L. Tamb\u00E9 pot contactar amb nosaltres enviant un correu electr\u00F2nic a info@teresaripoll.com.", 
      "es": "En cualquier momento Vd. puede ejercer el derecho a acceder, rectificar y, en su caso, cancelar los datos de carácter personal , mediante comunicación escrita dirigida a MARIA RIPOLL, S.L. También puede contactar con nosotros enviando un correo electrónico a info@teresaripoll.com.", 
      "en": "At any time you may exercise the right to access, rectify and, where appropriate, cancel personal data, by written communication addressed to MARIA RIPOLL, S.L. You can also contact us by sending an email to info@teresaripoll.com.",
      "fr": "Vous pouvez \u00E0 tout moment exercer votre droit d'acc\u00E8s, de rectification et, le cas \u00E9ch\u00E9ant, d'annulation des donn\u00E9es personnelles par communication \u00E9crite adress\u00E9e \u00E0 MARIA RIPOLL, S.L. Vous pouvez \u00E9galement nous contacter en envoyant un courrier \u00E9lectronique \u00E0 info@teresaripoll.com.",
      "it": "In qualsiasi momento è possibile esercitare il diritto di accesso, rettifica e, ove appropriato, cancellare i dati personali, mediante comunicazione scritta indirizzata a MARIA RIPOLL, S.L. Puoi anche contattarci inviando una mail a info@teresaripoll.com."
    },
    "PRIVACITY_DESC6": {
      "ca": "Preguem ens comuniqui qualsevol modificació de les seves dades de caràcter personal per tal que la informació estigui en tot moment actualitzada i no contingui errors. Així mateix, amb l'acceptació d'aquest avís legal, reconeix que la informació i les dades personals recollides són exactes i veraces.", 
      "es": "Rogamos nos comunique cualquier modificación de sus datos de carácter personal a fin de que la información esté en todo momento actualizada y no contenga errores. Asimismo, con la aceptación de este aviso legal, reconoce que la información y los datos personales recabados son exactos y veraces.", 
      "en": "Please inform us of any modification of your personal data so that the information is updated at all times and does not contain errors. Likewise, with the acceptance of this legal notice, you acknowledge that the information and personal data collected are accurate and truthful.",
      "fr": "Veuillez nous informer de toute modification de vos donn\u00E9es personnelles afin que les informations soient mises \u00E0 jour \u00E0 tout moment et ne contiennent pas d'erreurs. De m\u00EAme, avec l'acceptation de cet avis juridique, vous reconnaissez que les informations et les donn\u00E9es personnelles collect\u00E9es sont exactes et v\u00E9ridiques.",
      "it": "Vi preghiamo di informarci di qualsiasi modifica dei vostri dati personali in modo che le informazioni siano aggiornate in ogni momento e non contengano errori. Allo stesso modo, con l'accettazione di questo avviso legale, l'utente riconosce che le informazioni e i dati personali raccolti sono accurati e veritieri."
    },
    "COOKIE_USAGE": {
      "ca": "6 - \u00FAs de cookies", 
      "es": "6 – Uso de cookies.", 
      "en": "6 - Improper or inappropriate use of the Website.",
      "fr": "6 - Utilisation inappropri\u00E9e ou inappropri\u00E9e du site Web.",
      "it": "6 - Uso improprio o inappropriato del sito web."
    },
    "COOKIE_USAGE_DESC": {
      "ca": "Ús de cookiesMARIA RIPOLL, S.L. mitjançant la seva web, utilitzarà cookies quan l'usuari navegui per la nostra web. Les cookies s'associen únicament amb un usuari anònim i el seu ordinador i no proporcionen referències que permetin deduir el nom i cognoms de l'Usuari. L'usuari té l'opció d'impedir la generació de cookies, mitjançant la selecció de la corresponent opció al seu programa navegador.", 
      "es": "Uso de cookiesMARIA RIPOLL, S.L. mediante su web, utilizará cookies cuando el usuario navegue por nuestra web. Las cookies se asocian únicamente con un usuario anónimo y su ordenador y no proporcionan referencias que permitan deducir el nombre y apellidos del Usuario. El usuario tiene la opción de impedir la generación de cookies, mediante la selección de la correspondiente opción en su programa navegador.", 
      "en": "Use of cookiesMARIA RIPOLL, S.L. Through its website, it will use cookies when the user navigates through our website. The cookies are only associated with an anonymous user and his computer and do not provide references that allow to deduce the name and surnames of the User. The user has the option to prevent the generation of cookies, by selecting the corresponding option in their browser program.",
      "fr": "Utilisation de cookiesMARIA RIPOLL, S.L. \u00E0 travers son site Web, il utilisera des cookies lorsque l'utilisateur naviguera sur notre site Web. Les cookies sont uniquement associ\u00E9s \u00E0 un utilisateur anonyme et \u00E0 son ordinateur et ne fournissent pas de r\u00E9f\u00E9rences permettant de d\u00E9duire le nom et les pr\u00E9noms de l'utilisateur. L'utilisateur a la possibilit\u00E9 d'emp\u00EAcher la g\u00E9n\u00E9ration de cookies en s\u00E9lectionnant l'option correspondante dans son programme de navigation.",
      "it": "Uso dei cookieMARIA RIPOLL, S.L. Attraverso il suo sito Web, utilizzer\u00E0 i cookie quando l'utente naviga attraverso il nostro sito web. I cookie sono associati ad un utente anonimo e al suo computer e non forniscono riferimenti che consentano di dedurre il nome e i cognomi dell'Utente. L'utente ha la possibilit\u00E0 di impedire la generazione di cookie, selezionando l'opzione corrispondente nel proprio programma browser."
    },
    "LEGISLATION": {
      "ca": "7- Legislaci\u00F3", 
      "es": "7– Legislación", 
      "en": "7- Legislation",
      "fr": "7- L\u00E9gislation",
      "it": "7- Legislazione"
    },
    "LEGISLATION_DESC": { 
      "ca": "Qualsevol controv\u00E8rsia que sorgeixi o tingui relaci\u00F3 amb l'\u00FAs de la p\u00E0gina web ser\u00E0 sotmesa a la jurisdicci\u00F3 no exclusiva dels jutjats i tribunals espanyols.", 
      "es": "Cualquier controversia que surja o guarde relación con el uso de la página web será sometida a la jurisdicción no exclusiva de los juzgados y tribunales españoles.", 
      "en": "Cualquier controversia que surja o guarde relación con el uso de la página web será sometida a la jurisdicción no exclusiva de los juzgados y tribunales españoles.",
      "fr": "Toute controverse li\u00E9e \u00E0 l'utilisation du site Web sera soumise \u00E0 la juridiction non exclusive des cours et tribunaux espagnols.",
      "it": "Qualsiasi controversia derivante o correlata all'uso del sito web sar\u00E0 soggetta alla giurisdizione non esclusiva dei tribunali e dei tribunali spagnoli."
    },
  }