import {book_kaleidoscope_trans} from '../../i18n/book_kaleidoscope.trans'

export const book_kaleidoscope = [
    {
        title: '7014',

        light: '/img/2023/kaleidoscope/light/7014-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7014-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7014-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7014-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7014-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7014-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7014-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7014-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7014-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7014],
    },
    {
        title: '7015',

        light: '/img/2023/kaleidoscope/light/7015-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7015-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7015-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7015-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7015-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7015-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7015-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7015-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7015-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(231, 224, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7015],
    },
    {
        title: '7016',

        light: '/img/2023/kaleidoscope/light/7016-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7016-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7016-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7016-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7016-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7016-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7016-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7016-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7016-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(215, 210, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7016],
    },
    {
        title: '7017',

        light: '/img/2023/kaleidoscope/light/7017-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7017-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7017-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7017-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7017-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7017-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7017-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7017-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7017-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(176, 208, 245)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7017],
    },
    {
        title: '7018',

        light: '/img/2023/kaleidoscope/light/7018-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7018-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7018-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7018-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7018-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7018-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7018-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7018-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7018-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(235, 206, 164)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7018],
    },
    {
        title: '7019',

        light: '/img/2023/kaleidoscope/light/7019-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7019-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7019-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7019-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7019-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7019-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7019-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7019-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7019-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(224, 164, 171)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7019],
    },
    {
        title: '7020',

        light: '/img/2023/kaleidoscope/light/7020-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7020-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7020-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7020-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7020-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7020-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7020-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7020-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7020-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(238, 202, 202)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7020],
    },
    {
        title: '7021',

        light: '/img/2023/kaleidoscope/light/7021-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7021-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7021-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7021-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7021-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7021-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7021-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7021-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7021-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(171, 182, 237)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7021],
    },
    {
        title: '7022',

        light: '/img/2023/kaleidoscope/light/7022-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7022-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7022-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7022-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7022-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7022-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7022-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7022-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7022-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(210, 214, 215)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7022],
    },
    {
        title: '7023',

        light: '/img/2023/kaleidoscope/light/7023-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7023-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7023-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7023-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7023-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7023-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7023-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7023-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7023-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(250, 182, 182)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7023],
    },
    {
        title: '7024',

        light: '/img/2023/kaleidoscope/light/7024-ch-pa.jpg',
        back_light: '/img/2023/kaleidoscope/light/7024-pa-front.jpg',
        front_light: '/img/2023/kaleidoscope/light/7024-ch-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7024-ch-pa-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7024-ch-pa.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7024-pa-front.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7024-ch-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7024-ch-pa-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7024-ch-pa-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(154, 178, 224)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7024],
    },
    {
        title: '7025',

        light: '/img/2023/kaleidoscope/light/7025-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7025-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7025-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7025-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7025-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7025-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7025-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7025-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7025-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7025],
    },
    {
        title: '7026',

        light: '/img/2023/kaleidoscope/light/7026-v.jpg',
        back_light: '/img/2023/kaleidoscope/light/7026-v-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7026-v-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7026-v-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7026-v.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7026-v-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7026-v-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7026-v-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7026-v-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(231, 224, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7026],
    },
    {
        title: '7027',

        light: '/img/2023/kaleidoscope/light/7027-bl-pa.jpg',
        back_light: '/img/2023/kaleidoscope/light/7027-bl-pa-back.jpg',
        front_light: '/img/2023/kaleidoscope/light/7027-bl-pa-front.jpg',
        detail_light: '/img/2023/kaleidoscope/light/7027-bl-pa-detall.jpg',

        hight: '/img/2023/kaleidoscope/high/7027-bl-pa.jpg',
        back_hight: '/img/2023/kaleidoscope/high/7027-bl-pa-back.jpg',
        front_hight: '/img/2023/kaleidoscope/high/7027-bl-pa-front.jpg',
        detail_hight: '/img/2023/kaleidoscope/high/7027-bl-pa-detall.jpg',

        video: '/img/2023/kaleidoscope/videos/7027-bl-pa-teresaripoll-ss23-clip.mp4',

        border: '8px solid rgb(215, 210, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_kaleidoscope_trans[7027],
    },
]