import React, { useState, useEffect } from 'react'
import { BtnBack } from './BtnBack'
import { ImageZoom } from './ImageZoom'
import {lang} from '../i18n/i18n'


const PADDING = 40

export const DetailDesktop = ({card, onClose}) => {
    const [zoomCard, setZoomCard] = useState('')

    useEffect(() => {
      window.scrollTo(0,0)
    }, [])

    return (
      zoomCard !== '' 
        ? <ImageZoom card={zoomCard} scroll={{ x: window.scrollX, y: window.scrollY }} onClick={() => setZoomCard('')} />
        : <div style={styles.descDiv}>
            <BtnBack onClose={onClose}/>
            <div style={styles.assetsDiv}>

              <div style={styles.mannequinDiv}>
                  <img src={card.light} alt="" style={{width:'49%', padding: PADDING}} onClick={() => setZoomCard({hight:card.hight, title:card.title})}/>
                  <img src={card.detail_light} alt="" style={{width:'49%', padding: PADDING, borderLeft: "2px solid #ddd"}} onClick={() => setZoomCard({hight:card.detail_hight, title:card.title})}/>
              </div>

              { 
                card.back_light != '' &&
                <div style={styles.mannequinDiv}>
                    <img src={card.back_light} alt="" style={{width:'49%', padding:PADDING}} onClick={() => setZoomCard({hight:card.back_hight, title:card.title})}/>
                    <img src={card.front_light} alt="" style={{width:'49%', padding:PADDING, borderLeft: "2px solid #ddd"}} onClick={() => setZoomCard({hight:card.front_hight, title:card.title})}/>
                </div>
              }

              <div style={styles.videoDiv}>
                  <video 
                      style={{/*height:'50%',*/ paddingLeft:3, paddingTop:3, paddingBottom:3}}
                      controls="" 
                      //autoplay
                      autoplay="autoplay"
                      loop
                      muted 
                      src={card.video}
                      type="video/mp4" 
                  ></video>
              </div>

            </div>

            <div style={styles.textDiv}>
                <h4 style={{fontSize:35, fontWeight:'bold'}}>MODEL {card.title}</h4>
                <p style={{fontWeight:'lighter'}}>
                    {card.trans && card.trans[lang]}
                </p>
            </div>
        </div>
    )
}

const styles = {
    descDiv: {
        display: 'flex',
        flexDirection: 'row',
        padding: 5,
        paddingTop: 12,
    },
    assetsDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '63%',
        backgroundColor: 'transparent',
        overflow: 'hidden',
    },
    videoDiv: {
      width:"100%", 
      display:'flex', 
      justifyContent:'center', 
      //marginBottom:3, 
      border:'2px solid #ddd',
    },
    secondImagesDiv: {
      display:'flex', 
      flexDirection:'row', 
      alignItems:'center',
      border:'2px solid #ddd',
    },
    mannequinDiv: {
      display:'flex', 
      flexDirection:'row', 
      justifyContent:'space-around', 
      alignItems:'center', 
      width:'100%', 
      border:'2px solid #ddd',
      //borderBottom: 'none',
      margin:0,
      padding:0,
      marginBottom:-2,
    },
    textDiv: {
      width:'33%',
      padding:40,
    },
}
