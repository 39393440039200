import React from 'react'
import { Link } from 'react-router-dom'
import * as FA from 'react-fontawesome'
import { lang } from '../i18n/i18n'

const i18n = {
    "AVISO_LEGAL": {
      "ca": "AVIS LEGAL", 
      "es": "AVISO LEGAL", 
      "en": "LEGAL NOTICE",
      "fr": "MENTION L&Eacute;GALE",
      "it": "AVVISO LEGALE"
    }
}

export const Footer = () => {
    return (
        <div>
            <div style={styles.marginTop}></div>
            <div style={styles.container}>
                <p>
                    <a href="https://www.facebook.com/pages/TERESA-RIPOLL/157731090964337" style={styles.social} target="_blank" rel="noreferrer">
                        <FA name="facebook-square"  style={{marginRight: 25}}/>
                    </a>
                    
                    <a href="https://www.instagram.com/teresaripoll.oficial/" style={styles.social} target="_blank" rel="noreferrer">
                        <FA name="instagram"/>
                    </a>
                </p>
                <p>
                    <Link to='/legal' style={styles.link}>{i18n.AVISO_LEGAL[lang]}</Link>, 
                    <Link to='/cookiespolicy' style={styles.link}> COOKIES</Link></p>
                <p>
                    COPYRIGHT © TERESA RIPOLL 2024<br/>
                    <a href="http://www.freepik.com" target="_blank" rel="noreferrer" style={styles.freepikBanner}>Designed by Fwstudio / Freepik</a>
                </p>
            </div>
        </div>
    )
}


const styles = {
    marginTop: {
        height: 50,
        backgroundColor: 'white',
    },
    container: {
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: '#212529',
        width: '100%',
        height: 200,
        color: 'white',
    },
    link: {
        color: 'white',
        fontSize: 12,
    },
    social: {
        color: 'white',
        fontSize: 24,
    },
    freepikBanner: {
        color: 'white',
        fontSize: 8,
        paddingTop: -15,
    }
}