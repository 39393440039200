import React, { useEffect } from 'react'
import Bootstrap, { Carousel, BImg } from 'bootstrap-4-react'
import { Link } from 'react-router-dom'

export const MyCarousel = ({id, images, titles}) => {

    useEffect(() => {
        window.setTimeout(() => Bootstrap.carousel(`#${id}`), 2000);
    }, [id])

    return (
        <Carousel w="100" id={`${id}`} >
          {/* 
          <Carousel.Indicators>
            { images.map( (image, index) => 
                <Carousel.Indicator target={`#${id}`} to={`${index}`} active={index===0} />
            )}
          </Carousel.Indicators>
          */}
          <Carousel.Inner>
            { images.map( (image, index) => 
                <Carousel.Item active={index===0} key={`key-${index}`}>
                    <Link to='/legal'>
                      <BImg display="block" w="100" src={`img/carousel/${image}`} style={{ width: '100%'}} />
                      <Carousel.Caption display="md-block">
                        <h3 style={{color:'rgba(0,0,0,0.5)', fontSize:'2.5em'}}>{titles[index]}</h3>
                      </Carousel.Caption>
                    </Link>
                </Carousel.Item>
            )}
          </Carousel.Inner>
          {/*
          <Carousel.Prev href={`#${id}`}>
            <Carousel.Prev.Icon />
          </Carousel.Prev>
          <Carousel.Next href={`#${id}`}>
            <Carousel.Next.Icon />
          </Carousel.Next>
           */}
        </Carousel>
    )
}
