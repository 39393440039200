

export const bookn_roaring20s = [
    {
        title:"8064",
        light:"/img/wedding/novia19/a/8064a.jpg", 
        hight:"/img/wedding/novia19/c/8064a.jpg", 
    },
    {
        title:"8064",
        light:"/img/wedding/novia19/a/8064b.jpg", 
        hight:"/img/wedding/novia19/c/8064b.jpg", 
    },
    {
        title:"8065",
        light:"/img/wedding/novia19/a/8065a.jpg", 
        hight:"/img/wedding/novia19/c/8065a.jpg", 
    },
    {
        title:"8065",
        light:"/img/wedding/novia19/a/8065b.jpg", 
        hight:"/img/wedding/novia19/a/8065b.jpg", 
    },
    {
        title:"8066",
        light:"/img/wedding/novia19/a/8066a.jpg", 
        hight:"/img/wedding/novia19/c/8066a.jpg", 
    },
    {
        title:"8066",
        light:"/img/wedding/novia19/a/8066c.jpg", 
        hight:"/img/wedding/novia19/c/8066c.jpg", 
    },
    {
        title:"8066",
        light:"/img/wedding/novia19/a/8066b.jpg", 
        hight:"/img/wedding/novia19/c/8066b.jpg", 
    },
    {
        title:"8067",
        light:"/img/wedding/novia19/a/8067a.jpg", 
        hight:"/img/wedding/novia19/c/8067a.jpg", 
    },
    {
        title:"8067",
        light:"/img/wedding/novia19/a/8067c.jpg", 
        hight:"/img/wedding/novia19/c/8067c.jpg", 
    },
    {
        title:"8067",
        light:"/img/wedding/novia19/a/8067b.jpg", 
        hight:"/img/wedding/novia19/c/8067b.jpg", 
    },
    {
        title:"8068",
        light:"/img/wedding/novia19/a/8068a.jpg", 
        hight:"/img/wedding/novia19/c/8068a.jpg", 
    },
    {
        title:"8068",
        light:"/img/wedding/novia19/a/8068b.jpg", 
        hight:"/img/wedding/novia19/c/8068b.jpg", 
    },
    {
        title:"8069",
        light:"/img/wedding/novia19/a/8069a.jpg", 
        hight:"/img/wedding/novia19/c/8069a.jpg", 
    },
    {
        title:"8069",
        light:"/img/wedding/novia19/a/8069b.jpg", 
        hight:"/img/wedding/novia19/c/8069b.jpg", 
    },
    {
        title:"8070",
        light:"/img/wedding/novia19/a/8070a.jpg", 
        hight:"/img/wedding/novia19/c/8070a.jpg", 
    },
    {
        title:"8070",
        light:"/img/wedding/novia19/a/8070b.jpg", 
        hight:"/img/wedding/novia19/c/8070b.jpg", 
    },
    {
        title:"8071",
        light:"/img/wedding/novia19/a/8071a.jpg", 
        hight:"/img/wedding/novia19/c/8071a.jpg", 
    },
    {
        title:"8071",
        light:"/img/wedding/novia19/a/8071b.jpg", 
        hight:"/img/wedding/novia19/c/8071b.jpg", 
    },
    {
        title:"8072",
        light:"/img/wedding/novia19/a/8072a.jpg", 
        hight:"/img/wedding/novia19/c/8072a.jpg", 
    },
    {
        title:"8072",
        light:"/img/wedding/novia19/a/8072b.jpg", 
        hight:"/img/wedding/novia19/c/8072b.jpg", 
    },
    {
        title:"8073",
        light:"/img/wedding/novia19/a/8073a.jpg", 
        hight:"/img/wedding/novia19/c/8073a.jpg", 
    },
    {
        title:"8073",
        light:"/img/wedding/novia19/a/8073b.jpg", 
        hight:"/img/wedding/novia19/c/8073b.jpg", 
    },
    {
        title:"8073",
        light:"/img/wedding/novia19/a/8073c.jpg", 
        hight:"/img/wedding/novia19/c/8073c.jpg", 
    },
    {
        title:"8073",
        light:"/img/wedding/novia19/a/8073d.jpg", 
        hight:"/img/wedding/novia19/c/8073d.jpg", 
    },
    {
        title:"8075",
        light:"/img/wedding/novia19/a/8075a.jpg", 
        hight:"/img/wedding/novia19/c/8075a.jpg", 
    },
    {
        title:"8076",
        light:"/img/wedding/novia19/a/8076a.jpg", 
        hight:"/img/wedding/novia19/c/8076a.jpg", 
    },
    {
        title:"8076",
        light:"/img/wedding/novia19/a/8076c.jpg", 
        hight:"/img/wedding/novia19/c/8076c.jpg", 
    },
    {
        title:"8076",
        light:"/img/wedding/novia19/a/8076b.jpg", 
        hight:"/img/wedding/novia19/c/8076b.jpg", 
    },
    {
        title:"8077",
        light:"/img/wedding/novia19/a/8077a.jpg", 
        hight:"/img/wedding/novia19/c/8077a.jpg", 
    },
    {
        title:"8077",
        light:"/img/wedding/novia19/a/8077c.jpg", 
        hight:"/img/wedding/novia19/c/8077c.jpg", 
    },
    {
        title:"8077",
        light:"/img/wedding/novia19/a/8077b.jpg", 
        hight:"/img/wedding/novia19/c/8077b.jpg", 
    },
    {
        title:"8078",
        light:"/img/wedding/novia19/a/8078a.jpg", 
        hight:"/img/wedding/novia19/c/8078a.jpg", 
    },
    {
        title:"8078",
        light:"/img/wedding/novia19/a/8078b.jpg", 
        hight:"/img/wedding/novia19/c/8078b.jpg", 
    }

]