
import { SectionTitle } from '../components/SectionTitle'
import { promoTrans } from '../i18n/promo.trans'
import { lang } from '../i18n/i18n'
import { CardPromo } from '../components/CardPromo'
import { useMobile } from '../hooks/useMobile'

export const Promo = () => {
  const isMobile = useMobile()

  return (
    <div style={styles.container}>
      <div style={styles.subContainer}>
        <div style={styles.body}>
          <SectionTitle title={promoTrans.title[lang]} />
          <div style={styles.content}>
            <p style={isMobile ? {} : {textAlign: "center"}}>{promoTrans.text[lang]}</p>
            {
              promoTrans.sections.map((card, index) => <CardPromo key={index} data={card} index={index}/>)
            }
            <p>{promoTrans.footer[lang]}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    paddingTop: 55,
    display: 'flex',
    justifyContent: 'center',
  },
  subContainer: {
    maxWidth: 1200,
  },
  body: {
    backgroundColor: '#fff',
    boxShadow: '-3px -3px 10px #ccc',
    minHeight: 500,
    paddingBottom: 55,
  },
  content: {
    margin: 'auto',
    width: '75%',
  },
}