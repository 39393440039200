
export const i18n = {
    "CONTACT": {
      "ca": "Contacte", 
      "es": "Contacto", 
      "en": "Contact",
      "fr": "Contact",
      "it": "Contatto"
    },
    "COMPANY": { 
      "ca": "Empresa", 
      "es": "Empresa", 
      "en": "Company",
      "fr": "Entreprise",
      "it": "Societ&agrave;"
    },
    "NAME": { 
      "ca": "Nom", 
      "es": "Nombre", 
      "en": "Name",
      "fr": "Nom et Prenom",
      "it": "Nome"
    },
    "PHONE": {
      "ca": "Telèfon", 
      "es": "Tléfono", 
      "en": "Phone",
      "fr": "Téléphone",
      "it": "Telefono"
    },
    "MESSAGE": { 
      "ca": "Missatge", 
      "es": "Mensaje", 
      "en": "Message",
      "fr": "Message",
      "it": "Messaggio"
    },
    "SEND-MESSAGE": { 
      "ca": "Enviar Missatge", 
      "es": "Enviar Mensaje", 
      "en": "Send Message",
      "fr": "Envoyer le message",
      "it": "Invia un messaggio"
    },
    "ACCEPT-POLICY": {
      "ca": "He llegit l'avís legal i accepto la", 
      "es": "He leido el aviso legal y acepto la", 
      "en": "I have read the legal notice and accept the",
      "fr": "J'ai lu les mentions légales et accepte les",
      "it": "Ho letto le note legali e accetto il"
    },
    "PRIVACY-POLICY": {
      "ca": "política de privacitat", 
      "es": "política de privacidad", 
      "en": "Privacy Policy",
      "fr": "Politique de confidentialité",
      "it": "politica sulla privacy"
    },
    "MESSAGE-SENT": {
      "ca": "Missatge enviat correctament", 
      "es": "Mensaje enviado con exito", 
      "en": "Message successfully sent",
      "fr": "Message envoyé avec succès",
      "it": "Messaggio inviato con successo"
    }
  }