
export const bookn_dreams = [
    {
        light:"/img/wedding/novia/a/01A-8012.jpg", 
        hight:"/img/wedding/novia/c/01A-8012.jpg", 
        title:"8012"
    },
    {
        light:"/img/wedding/novia/a/01B-8012.jpg", 
        hight:"/img/wedding/novia/c/01B-8012.jpg", 
        title:"8012"
    },
    {
        light:"/img/wedding/novia/a/01C-8012.jpg", 
        hight:"/img/wedding/novia/c/01C-8012.jpg", 
        title:"8012"
    },
    {
        light:"/img/wedding/novia/a/02A-8013.jpg", 
        hight:"/img/wedding/novia/c/02A-8013.jpg", 
        title:"8013"
    },
    {
        light:"/img/wedding/novia/a/02B-8013.jpg", 
        hight:"/img/wedding/novia/c/02B-8013.jpg", 
        title:"8013"
    },
    {
        light:"/img/wedding/novia/a/z2.jpg",       
        hight:"/img/wedding/novia/b/z2.jpg",       
        title:""
    },
    {
        light:"/img/wedding/novia/a/03A-8014.jpg", 
        hight:"/img/wedding/novia/c/03A-8014.jpg", 
        title:"8014"
    },
    {
        light:"/img/wedding/novia/a/03B-8014.jpg", 
        hight:"/img/wedding/novia/c/03B-8014.jpg", 
        title:"8014"
    },
    {
        light:"/img/wedding/novia/a/03C-8014.jpg", 
        hight:"/img/wedding/novia/c/03C-8014.jpg", 
        title:"8014"
    },
    {
        light:"/img/wedding/novia/a/04A-8015.jpg", 
        hight:"/img/wedding/novia/c/04A-8015.jpg", 
        title:"8015"
    },
    {
        light:"/img/wedding/novia/a/04B-8015.jpg", 
        hight:"/img/wedding/novia/c/04B-8015.jpg", 
        title:"8015"
    },
    {
        light:"/img/wedding/novia/a/05A-8017.jpg", 
        hight:"/img/wedding/novia/c/05A-8017.jpg", 
        title:"8017"
    },
    {
        light:"/img/wedding/novia/a/05B-8017.jpg", 
        hight:"/img/wedding/novia/c/05B-8017.jpg", 
        title:"8017"
    },
    {
        light:"/img/wedding/novia/a/05C-8017.jpg", 
        hight:"/img/wedding/novia/c/05C-8017.jpg", 
        title:"8017"
    },
    {
        light:"/img/wedding/novia/a/06A-8018.jpg", 
        hight:"/img/wedding/novia/c/06A-8018.jpg", 
        title:"8018"
    },
    {
        light:"/img/wedding/novia/a/z1.jpg",       
        hight:"/img/wedding/novia/b/z1.jpg",       
        title:""
    },
    {
        light:"/img/wedding/novia/a/06B-8018.jpg", 
        hight:"/img/wedding/novia/c/06B-8018.jpg", 
        title:"8018"
    },
    {
        light:"/img/wedding/novia/a/07A-8019.jpg", 
        hight:"/img/wedding/novia/c/07A-8019.jpg", 
        title:"8019"
    },
    {
        light:"/img/wedding/novia/a/07B-8019.jpg", 
        hight:"/img/wedding/novia/c/07B-8019.jpg", 
        title:"8019"
    },
    {
        light:"/img/wedding/novia/a/07C-8019.jpg", 
        hight:"/img/wedding/novia/c/07C-8019.jpg", 
        title:"8019"
    },
    {
        light:"/img/wedding/novia/a/08A-8020.jpg", 
        hight:"/img/wedding/novia/c/08A-8020.jpg", 
        title:"8020"
    },
    {
        light:"/img/wedding/novia/a/08B-8020.jpg", 
        hight:"/img/wedding/novia/c/08B-8020.jpg", 
        title:"8020"
    },
    {
        light:"/img/wedding/novia/a/09A-8021.jpg", 
        hight:"/img/wedding/novia/c/09A-8021.jpg", 
        title:"8021"
    },
    {
        light:"/img/wedding/novia/a/09B-8021.jpg", 
        hight:"/img/wedding/novia/c/09B-8021.jpg", 
        title:"8021"
    },
    {
        light:"/img/wedding/novia/a/10A-8022.jpg", 
        hight:"/img/wedding/novia/c/10A-8022.jpg", 
        title:"8022"
    },
    {
        light:"/img/wedding/novia/a/z4.jpg",       
        hight:"/img/wedding/novia/b/z4.jpg",       
        title:""
    },
    {
        light:"/img/wedding/novia/a/10B-8022.jpg", 
        hight:"/img/wedding/novia/c/10B-8022.jpg", 
        title:"8022"
    },
    {
        light:"/img/wedding/novia/a/10C-8022.jpg", 
        hight:"/img/wedding/novia/c/10C-8022.jpg", 
        title:"8022"
    },
    {
        light:"/img/wedding/novia/a/11A-8023.jpg", 
        hight:"/img/wedding/novia/c/11A-8023.jpg", 
        title:"8023"
    },
    {
        light:"/img/wedding/novia/a/11B-8023.jpg", 
        hight:"/img/wedding/novia/c/11B-8023.jpg", 
        title:"8023"
    },
    {
        light:"/img/wedding/novia/a/11C-8023.jpg", 
        hight:"/img/wedding/novia/c/11C-8023.jpg", 
        title:"8023"
    },
    {
        light:"/img/wedding/novia/a/12A-8024.jpg", 
        hight:"/img/wedding/novia/c/12A-8024.jpg", 
        title:"8024"
    },
    {
        light:"/img/wedding/novia/a/12B-8024.jpg", 
        hight:"/img/wedding/novia/c/12B-8024.jpg", 
        title:"8024"
    },
    {
        light:"/img/wedding/novia/a/13A-8026.jpg", 
        hight:"/img/wedding/novia/c/13A-8026.jpg", 
        title:"8026"
    },
    {
        light:"/img/wedding/novia/a/13B-8026.jpg", 
        hight:"/img/wedding/novia/c/13B-8026.jpg", 
        title:"8026"
    },
    {
        light:"/img/wedding/novia/a/z3.jpg",       
        hight:"/img/wedding/novia/b/z3.jpg",       
        title:""
    },
    {
        light:"/img/wedding/novia/a/14A-8027.jpg", 
        hight:"/img/wedding/novia/c/14A-8027.jpg", 
        title:"8027"
    },
    {
        light:"/img/wedding/novia/a/14B-8027.jpg", 
        hight:"/img/wedding/novia/c/14B-8027.jpg", 
        title:"8027"
    },
    {
        light:"/img/wedding/novia/a/15A-8025.jpg", 
        hight:"/img/wedding/novia/c/15A-8025.jpg", 
        title:"8025"
    },
    {
        light:"/img/wedding/novia/a/15B-8025.jpg", 
        hight:"/img/wedding/novia/c/15B-8025A.jpg",
        title:"8025"
    }

]