/*
    '': {
        'en': "",
        'es': "",
        'fr': "",
        'it': "", 
        'ca': "",
    },
*/

export const book_timeless_trans = {
    '2225': {
        'en': "This beautiful two-piece dress is a work of art in yellow and coral gradient. The blouse is adorned with elegant rhinestones and natural ostrich feathers in tone, giving it a touch of sophistication and glamour. The blouse fastens at the back and has 3/4 sleeves, making it perfect for any occasion. The long skirt is made of delicate coral crepe, giving it an elegant and fluid look.",
        'es': "Este hermoso vestido de dos piezas es una obra de arte en degradado amarillo y coral. La blusa está adornada con elegante pedrería y pluma natural de avestruz al tono, lo que le da un toque de sofisticación y glamour. La blusa se abrocha en la espalda y tiene mangas 3/4, lo que la hace perfecta para cualquier ocasión. La falda larga está hecha de delicado crepe coral, lo que le da un aspecto elegante y fluido.",
        'fr': "",
        'it': "Questo bellissimo vestito a due pezzi è un’opera d’arte in gradiente giallo e corallo. La blusa è adornata con eleganti pietre e piume di struzzo naturali in tono, conferendole un tocco di sofisticazione e glamour. La blusa si chiude sul retro e ha maniche 3/4, rendendola perfetta per qualsiasi occasione. La lunga gonna è realizzata in delicato crepe corallo, conferendole un aspetto elegante e fluido.", 
        'ca': "Aquest bonic vestit de dues peces és una obra d’art en degradat groc i coral. La blusa està adornada amb elegant pedreria i ploma natural d’estruc al to, el que li dóna un toc de sofisticació i glamour. La blusa es tanca a l’esquena i té mànigues 3/4, el que la fa perfecta per a qualsevol ocasió. La llarga faldilla està feta de delicat crep coral, el que li dóna un aspecte elegant i fluid.",
    },
    '2226': {
        'en': "This youthful blouse and pants set is made of bougainvillea and petrol green crepe, a bold and modern combination. The asymmetry of the body and the diagonal cuts with topstitching stylize the figure, while the turtleneck and short sleeves provide a unique character. The pants come in a fresh 3/4 length, but are also very elegant in full length. It fastens at the back with an invisible zip.",
        'es': "Este conjunto juvenil de blusa y pantalón está confeccionado en crepe bugambilia y verde petróleo, una combinación atrevida y moderna. La asimetría del cuerpo y los cortes en diagonal con prespuntes estilizan la figura, mientras que el cuello chimenea y las mangas cortas le proporcionan un carácter único. El pantalón se presenta en un fresco",
        'fr': "",
        'it': "Questo giovane set di blusa e pantaloni è realizzato in crepe bouganville e verde petrolio, una combinazione audace e moderna. L’asimmetria del corpo e i tagli diagonali con impunture stilizzano la figura, mentre il collo alto e le maniche corte conferiscono un carattere unico. I pantaloni sono presentati in una fresca lunghezza 3/4, ma sono anche molto eleganti in lunghezza intera. Si chiude sul retro con una zip invisibile.", 
        'ca': "Aquest conjunt juvenil de blusa i pantaló està confeccionat en crep bugambília i verd petroli, una combinació atrevida i moderna. L’asimetria del cos i els talls en diagonal amb repunts estilitzen la figura, mentre que el coll de xemeneia i les mànigues curtes li proporcionen un caràcter únic. El pantaló es presenta en una fresca longitud 3/4, però també és molt elegant en longitud completa. Es tanca per la part posterior amb una cremallera invisible.",
    },
    '2227': {
        'en': "This light set of French lace in navy blue and silk organza in oil tones is a sophisticated and elegant combination. The fluid layered skirt in silk organza and the 3/4 sleeve give it a touch of originality. The blouse is fastened crossed at the front with an organza bow detail. This set is ideal for the hottest days, as it is very fresh and light.",
        'es': "Este conjunto ligero de blonda francesa en azul marino y organza de seda en tonos petróleo es una combinación sofisticada y elegante. La fluida falda a capa en la organza de seda y la manga 3/4 le dan un toque de originalidad. La blusa se abrocha cruzada por la parte de delante con un detalle de lazo en organza. Este conjunto es ideal para los días de más calor, ya que es muy fresco y ligero.",
        'fr': "",
        'it': "Questo leggero set di pizzo francese in blu navy e organza di seta in toni di petrolio è una combinazione sofisticata ed elegante. La gonna fluida a strati in organza di seta e la manica 3/4 le danno un tocco di originalità. La blusa si chiude incrociata sul davanti con un dettaglio di fiocco in organza. Questo set è ideale per i giorni più caldi, poiché è molto fresco e leggero.", 
        'ca': "Aquest conjunt lleuger de blonda francesa en blau marí i organza de seda en tons petroli és una combinació sofisticada i elegant. La fluida faldilla a capes en l’organza de seda i la màniga 3/4 li donen un toc d’originalitat. La blusa es corda creuada per la part de davant amb un detall de llaç en organza. Aquest conjunt és ideal per als dies de més calor, ja que és molt fresc i lleuger.",
    },
    '2228': {
        'en': "This crepe navy blue and beige jacket, blouse, and pants set is an elegant and sophisticated option for any occasion. The jacket combines vertical panels in both tones that stylise the figure, while the sleeveless beige blouse has a V-neckline and a contrasting navy neckline and armhole. The straight navy pants provide the point of modernity and sophistication to the set.",
        'es': "Este conjunto de chaqueta, blusa y pantalón en crepé azul marino y crudo es una opción elegante y sofisticada para cualquier ocasión. La chaqueta combina paneles verticales en los dos tonos que estilizan la figura, mientras que la blusa en crudo y sin mangas tiene un escote a pico y un vivo en escote y sisa a contraste en marino. El pantalón marino recto proporciona el punto de modernidad y sofisticación al conjunto.",
        'fr': "",
        'it': "Questo set di giacca, camicetta e pantaloni in crepe blu navy e beige è un’opzione elegante e sofisticata per qualsiasi occasione. La giacca combina pannelli verticali in entrambi i toni che stilizzano la figura, mentre la camicetta beige senza maniche ha una scollatura a V e una scollatura e un giromanica in contrasto in blu navy. I pantaloni dritti blu navy forniscono il punto di modernità e sofisticazione all’insieme.", 
        'ca': "Aquest conjunt de jaqueta, blusa i pantaló en crepé blau marí i cru és una opció elegant i sofisticada per a qualsevol ocasió. La jaqueta combina panells verticals en els dos tons que estilitzen la figura, mentre que la blusa en cru i sense mànigues té un escot en pic i un viu en escot i contorn de braç a contrast en marí. El pantaló marí recte proporciona el punt de modernitat i sofisticació al conjunt.",
    },
    '2229': {
        'en': "Discover this jacket and skirt set, a jewel that reminisces the elegance of the 60s. The jacket, crafted in mikado and featuring the distinctive Teresa Ripoll tie-neck, enhances every silhouette. Its unique sleeves, designed with cuts for elegant cuff folds, allow it to be worn as a chic blouse. Complementing this, the ankle-length skirt, masterfully made in natural silk chiffon, falls in fluid layers, adding a final touch of exquisite and light sophistication.",
        'es': "Descubre este conjunto de chaqueta y falda, una joya que rememora la elegancia de los años 60. La chaqueta, confeccionada en mikado y caracterizada por su distintivo cuello con lazada al estilo Teresa Ripoll, realza cada silueta. Sus mangas únicas, diseñadas con cortes para doblar en puños elegantes, permiten su uso como una blusa chic. Acompañando, la falda tobillera, magistralmente confeccionada en gasa de seda natural, cae en capas fluidas, aportando un toque final de sofisticación ligera y exquisita.",
        'fr': "",
        'it': "Scopri questo insieme di giacca e gonna, un gioiello che ricorda l'eleganza degli anni '60. La giacca, realizzata in mikado e caratterizzata dal distintivo colletto con fiocco alla Teresa Ripoll, valorizza ogni silhouette. Le sue maniche uniche, progettate con tagli per eleganti risvolti ai polsi, permettono di indossarla come una blusa chic. Completando il tutto, la gonna alla caviglia, magistralmente realizzata in chiffon di seta naturale, cade in strati fluidi, aggiungendo un tocco finale di sofisticata leggerezza ed esclusività.", 
        'ca': "Descobreix aquest conjunt de jaqueta i faldilla, una joia que rememora l'elegància dels anys 60. La jaqueta, confeccionada en mikado i amb el distintiu coll amb llaç al estil de Teresa Ripoll, realça cada silueta. Les seves mànigues úniques, dissenyades amb talls per a doblegar en punys elegants, permeten utilitzar-la com una blusa xic. Acompanyant, la faldilla al tormell, mestralment confeccionada en gasa de seda natural, cau en capes fluides, aportant un toc final de sofisticació lleugera i exquisida.",
    },
    '3788': {
        'en': "This charming dress masterfully blends exquisite brocade in gold and pink tones with the finesse and delicacy of natural silk chiffon in the same pink shades. The brocaded bodice accentuates the figure with an asymmetrical peplum adding a touch of sophistication. The neck, adorned with elegantly folded chiffon, perfectly complements the long skirt, made of the same soft chiffon, featuring a bold and seductive leg slit. It fastens comfortably at the back with an invisible zip.",
        'es': "Este encantador vestido fusiona magistralmente un exquisito brocado en dorado y tonos de rosa con la finura y delicadeza de la gasa de seda natural en los mismos matices rosados. El diseño del cuerpo, con brocado, destaca la figura gracias a un peplum asimétrico que añade un toque de sofisticación. El cuello, adornado con elegante gasa plegada, complementa perfectamente la falda larga, confeccionada en la misma gasa suave, que presenta un corte audaz y seductor para revelar la pierna. Se cierra cómodamente en la espalda con una cremallera invisible.",
        'fr': "",
        'it': "Questo incantevole vestito fonde magistralmente un squisito broccato in tonalità oro e rosa con la finezza e delicatezza del chiffon di seta naturale negli stessi toni di rosa. Il corpetto broccato accentua la figura con un peplum asimmetrico che aggiunge un tocco di sofisticatezza. Il collo, adornato con chiffon piegato elegantemente, complementa perfettamente la gonna lunga, realizzata nello stesso chiffon morbido, che presenta un audace e seducente spacco per la gamba. Si chiude comodamente sul retro con una zip invisibile.", 
        'ca': "Aquest vestit encantador combina magistralment un exquisit brocat en tons daurats i rosa amb la finesa i delicadesa de la gasa de seda natural en els mateixos matisos rosats. El cos brocat accentua la figura amb un peplum asimètric que afegeix un toc de sofisticació. El coll, adornat amb gasa elegantment plegada, complementa perfectament la faldilla llarga, confeccionada amb la mateixa gasa suau, que presenta una obertura atrevida i seductora per a la cama. Es tanca còmodament al darrere amb una cremallera invisible.",
    },
    '3789': {
        'en': "This enchanting long dress, crafted in powdered pink mikado, is a work of art in itself. It features a beautiful bow-shaped collar, made in brocade with gold and pink tones. The distinctive collar, a Teresa Ripoll style design, meticulously worked, enhances and beautifies the figure, complemented by the vertical seams of the dress, culminating in an elegant side slit to subtly reveal the leg. With short sleeves, this dress fastens in the back with an invisible zip, providing a neat and sophisticated finish.",
        'es': "Este encantador vestido largo confeccionado en mikado rosa empolvado, es una obra de arte en sí mismo. Luce un hermoso cuello en forma de lazo, elaborado en brocado con tonalidades de oro y rosa. El distintivo cuello, un diseño al estilo Teresa Ripoll, meticulosamente trabajado, realza y embellece la figura, complementado por las costuras verticales del vestido, culminando en un elegante corte lateral para revelar sutilmente la pierna. Con mangas cortas, este vestido cierra en la espalda con una cremallera invisible, aportando un acabado pulcro y sofisticado",
        'fr': "",
        'it': "Questo incantevole vestito lungo, realizzato in mikado rosa polveroso, è un'opera d'arte in sé. Presenta un bellissimo collo a forma di fiocco, lavorato in broccato con tonalità d'oro e rosa. Il distintivo collo, un design nello stile di Teresa Ripoll, meticolosamente lavorato, valorizza ed abbellisce la figura, completato dalle cuciture verticali del vestito che culminano in un elegante taglio laterale per rivelare sottilmente la gamba. Con maniche corte, questo vestito si chiude sul retro con una zip invisibile, fornendo un rifinitura pulita e sofisticata.", 
        'ca': "Aquest encantador vestit llarg, confeccionat en mikado rosa empolvorat, és una obra d'art en si mateix. Llueix un bell coll en forma de llaç, elaborat en brocat amb tonalitats d'or i rosa. El distintiu coll, un disseny a l'estil de Teresa Ripoll, meticulosament treballat, realça i embelleix la figura, complementat per les costures verticals del vestit que culminen en un elegant tall lateral per revelar subtilment la cama. Amb mànigues curtes, aquest vestit es tanca al darrere amb una cremallera invisible, aportant un acabat net i sofisticat.",
    },
    '3790': {
        'en': "This dazzling dress in pink tones features a top richly adorned with gemstones, making it a textile jewel. The sleeves, decorated with natural ostrich feathers, add a touch of luxury and dynamism. The long, flowing skirt is crafted in soft natural silk chiffon, complemented by a draped chiffon belt. The dress fits perfectly with a discreet invisible zip at the back, combining elegance and comfort in a perfect piece for special occasions.",
        'es': "Este deslumbrante vestido en tonos rosa presenta un top ricamente adornado con pedrería, convirtiéndolo en una joya textil. Las mangas, decoradas con plumas naturales de avestruz, añaden un toque de lujo y dinamismo. La falda, fluida y larga, está confeccionada en suave gasa de seda natural, complementada por un cinturón drapeado de gasa. El vestido se ajusta perfectamente gracias a una discreta cremallera invisible en la espalda, combinando elegancia y comodidad en una pieza perfecta para ocasiones especiales.",
        'fr': "",
        'it': "Questo abbagliante abito nei toni del rosa presenta un top riccamente adornato di pietre preziose, rendendolo un gioiello tessile. Le maniche, decorate con piume naturali di struzzo, aggiungono un tocco di lusso e dinamismo. La gonna lunga e fluida è realizzata in morbido chiffon di seta naturale, completata da una cintura drappeggiata di chiffon. L'abito si adatta perfettamente con una discreta zip invisibile sul retro, combinando eleganza e comfort in un pezzo perfetto per occasioni speciali.", 
        'ca': "Aquest vestit deslumbrant en tons de rosa presenta un top ricament adornat amb pedres precioses, convertint-lo en una joia tèxtil. Les mànigues, decorades amb plomes naturals d'estruç, afegeixen un toc de luxe i dinamisme. La faldilla llarga i fluida està confeccionada en suau gasa de seda natural, complementada per un cinturó drapejat de gasa. El vestit s'ajusta perfectament amb una discreta cremallera invisible al darrere, combinant elegància i comoditat en una peça perfecta per a ocasions especials.",
    },
    '3791': {
        'en': "This elegant midi dress is crafted in coral-pink crepe. Its silhouette is defined by horizontal bias cuts in shiny crepe, a classic detail from the Teresa Ripoll brand, subtly shaping the figure. It features an invisible flesh-coloured tulle transparency at the shoulders, adding a touch of elegance and femininity. The design is completed with 3/4 sleeves and an invisible zip closure at the back, blending comfort and sophistication.",
        'es': "Este elegante vestido midi está confeccionado en crepé de color coral-rosa. Su silueta se define con bieses horizontales en crepé brillante, un detalle clásico de la marca Teresa Ripoll, que estiliza la figura con sutileza. Destaca por su transparencia de tul invisible en color carne en los hombros, aportando un toque de elegancia y feminidad. Completa el diseño una manga de 3/4 y un cierre en la espalda con cremallera invisible, fusionando comodidad y sofisticación.",
        'fr': "",
        'it': "Questo elegante abito midi è realizzato in crepe color corallo-rosa. La sua silhouette è definita da tagli obliqui orizzontali in crepe lucido, un dettaglio classico del marchio Teresa Ripoll, che modella sottilmente la figura. Presenta una trasparenza di tulle invisibile color carne sulle spalle, aggiungendo un tocco di eleganza e femminilità. Il design è completato con maniche a 3/4 e una chiusura con zip invisibile sul retro, unendo comfort e sofisticazione.", 
        'ca': "Aquest elegant vestit midi està confeccionat en crep de color coral-rosa. La seva silueta es defineix amb biaixos horitzontals en crep brillant, un detall clàssic de la marca Teresa Ripoll, que estilitza la figura amb subtilitat. Destaca per la seva transparència en tul invisible color carn als hombros, aportant un toc d'elegància i feminitat. Completa el disseny una màniga de 3/4 i un tancament a l'esquena amb cremallera invisible, fusionant comoditat i sofisticació.",
    },
    '3792': {
        'en': "This jacket and dress set, crafted in luxurious fuchsia double satin, epitomises elegance and style. The jacket, featuring the exclusive Teresa Ripoll collar, has ingenious cuts in the sleeves allowing for refined cuffs, adding a unique touch. It fastens at the center with fancy closures, adding sophistication. The short dress stands out with its design that leaves the shoulders bare, a nod to modern femininity. It fits perfectly at the back with an invisible zip, ensuring a flawless silhouette. This set is a bold and chic choice for any special event, blending tradition with a contemporary twist.",
        'es': "Este conjunto de chaqueta y vestido, confeccionado en un lujoso doble raso fucsia, es la definición de elegancia y estilo. La chaqueta, con su exclusivo cuello Teresa Ripoll, presenta cortes ingeniosos en las mangas que permiten formar puños refinados, aportando un detalle único. Se abrocha en el centro con cierres fantasía, que añaden un toque de sofisticación. El vestido, de corte corto, destaca por su diseño que deja los hombros al descubierto, un guiño a la feminidad moderna. Se ajusta perfectamente en la espalda con una cremallera invisible, asegurando una silueta impecable. Este conjunto es una opción audaz y chic para cualquier evento especial, fusionando la tradición con un toque contemporáneo.",
        'fr': "",
        'it': "Questo insieme di giacca e vestito, realizzato in lussuoso raso doppio fucsia, è la definizione di eleganza e stile. La giacca, con il suo esclusivo colletto Teresa Ripoll, presenta tagli ingegnosi sulle maniche che permettono di formare polsini raffinati, aggiungendo un tocco unico. Si chiude al centro con chiusure fantasiose, aggiungendo sofisticazione. L'abito corto si distingue per il suo design che lascia le spalle scoperte, un cenno alla femminilità moderna. Si adatta perfettamente sul retro con una zip invisibile, assicurando una silhouette impeccabile. Questo insieme è una scelta audace e chic per qualsiasi evento speciale, fondendo la tradizione con un tocco contemporaneo.", 
        'ca': "Aquest conjunt de jaqueta i vestit, confeccionat en un luxós satí doble fúcsia, és la definició d'elegància i estil. La jaqueta, amb el seu exclusiu coll Teresa Ripoll, presenta talls enginyosos a les mànigues que permeten formar punys refinats, afegint un toc únic. Es tanca al centre amb tancaments fantasia, afegint sofisticació. El vestit curt destaca pel seu disseny que deixa els hombros al descobert, una subtil referencia a la feminitat moderna. S'ajusta perfectament al darrere amb una cremallera invisible, assegurant una silueta impecable. Aquest conjunt és una opció audaç i xic per a qualsevol esdeveniment especial, fusionant la tradició amb un toc contemporani.",
    },
    '3793': {
        'en': "This feminine short dress, made from striking fuchsia and cream piqué-mikado, perfectly embodies modern elegance. Its design features bare shoulders, adding a touch of sensuality. The updated version of the Teresa Ripoll collar, with meticulous pleating, exquisitely shapes the figure. With short sleeves, the dress offers a sophisticated and comfortable silhouette. It fastens at the back with a zip, ensuring a perfect fit. Ideal for special occasions, this dress blends tradition with a contemporary touch, making the wearer feel unique and elegant.",
        'es': "Este femenino vestido corto, confeccionado en un atractivo piqué-mikado que combina tonos fucsia y crudo, es una representación perfecta de la elegancia moderna. Su diseño deja los hombros al descubierto, aportando un toque de sensualidad. La versión actualizada del cuello Teresa Ripoll, con un trabajo meticuloso de pliegues, estiliza la figura de manera exquisita. Con mangas cortas, el vestido ofrece una silueta sofisticada y cómoda. Se abrocha en la espalda con una cremallera, asegurando un ajuste perfecto. Ideal para ocasiones especiales, este vestido combina la tradición con un toque contemporáneo, haciendo que quien lo lleve se sienta única y elegante.",
        'fr': "",
        'it': "Questo femminile abito corto, realizzato in piqué-mikado fucsia e crema, incarna perfettamente l'eleganza moderna. Il suo design lascia le spalle scoperte, aggiungendo un tocco di sensualità. La versione aggiornata del colletto Teresa Ripoll, con un lavoro meticoloso di pieghe, modella squisitamente la figura. Con maniche corte, l'abito offre una silhouette sofisticata e comoda. Si chiude sul retro con una zip, garantendo una vestibilità perfetta. Ideale per occasioni speciali, questo abito unisce la tradizione con un tocco contemporaneo, facendo sentire chi lo indossa unica ed elegante.", 
        'ca': "Aquest femení vestit curt, confeccionat en piqué-mikado fúcsia i cru, encarna perfectament l'elegància moderna. El seu disseny deixa els hombros al descobert, afegint un toc de sensualitat. La versió actualitzada del coll Teresa Ripoll, amb un treball meticulós de plecs, estilitza exquisidament la figura. Amb mànigues curtes, el vestit ofereix una silueta sofisticada i còmoda. Es tanca al darrere amb una cremallera, assegurant un ajust perfecte. Ideal per a ocasions especials, aquest vestit combina la tradició amb un toc contemporani, fent que qui el porti se senti única i elegant.",
    },
    '3794': {
        'en': "This evocative ensemble, inspired by the 60s look, consists of a jacket and dress. Crafted in coral mikado with fuchsia accents, it offers a vibrant color contrast. The jacket, with its chic short cut and stylish short sleeves, features lapels, facings, buttons, and cuffs in striking fuchsia. The A-line dress, ankle-length, boasts a prominent sweetheart neckline and short Japanese sleeves. Cut under the bust, it showcases a distinctive fuchsia detail. It fastens comfortably at the back with a zip, completing a set that merges modern sophistication with a nostalgic nod to the sixties",
        'es': "Este evocador conjunto, inspirado en el look de los años 60, compuesto por una chaqueta y un vestido, irradia un encanto vintage. Confeccionado en mikado coral y acentuado con detalles en fucsia, ofrece un contraste de colores vibrante. La chaqueta, de corte corto y con elegantes mangas cortas, presenta solapas, vistas, botones y puños en un llamativo fucsia. El vestido evasé, de largo tobillero, cuenta con un llamativo escote corazón y mangas cortas japonesas. Cortado bajo el pecho, luce un detalle distintivo en fucsia. Se cierra cómodamente en la espalda con una cremallera, completando un conjunto que combina la sofisticación moderna con un nostálgico aire sesentero.",
        'fr': "",
        'it': "Questo evocativo insieme, ispirato al look degli anni '60, composto da una giacca e un vestito. Realizzato in mikado corallo con dettagli fucsia, offre un contrasto di colori vivace. La giacca, con il suo elegante taglio corto e maniche corte raffinate, presenta colletti, facciate, bottoni e polsini in un accattivante fucsia. L'abito a trapezio, lungo alla caviglia, vanta un importante scollo a cuore e maniche corte giapponesi. Tagliato sotto il seno, sfoggia un distintivo dettaglio fucsia. Si chiude comodamente sul retro con una zip, completando un insieme che unisce la sofisticazione moderna con un nostalgico richiamo agli anni '60.", 
        'ca': "Aquest evocador conjunt, inspirat en el look dels anys 60, compost per una jaqueta i un vestit. Confeccionat en mikado coral amb detalls fúcsia, ofereix un contrast de colors vibrant. La jaqueta, amb el seu xic tall curt i mànigues curtes estiloses, presenta solapes, vistes, botons i punys en un bonic fúcsia. El vestit evasé, de llarg al tormell, llueix un important escot cor amb mànigues curtes japoneses. Tallat sota el pit, mostra un detall distintiu en fúcsia. Es tanca còmodament al darrere amb una cremallera, completant un conjunt que combina la sofisticació moderna amb un aire nostàlgic dels seixanta",
    },
    '3795': {
        'en': "This stunning strapless long dress is a true work of art, crafted with gemstones in a chromatic gradient from coral to yellow. The skirt, made in exquisite coral-toned silk organza, features a strategic cut that subtly suggests the leg, adding a touch of sensuality. The asymmetrical design below the hip streamlines the figure, bringing modern sophistication. The dress is perfectly complemented by a matching coral silk organza shawl, making it an ideal choice for gala events where style and elegance are paramount.",
        'es': "Este impresionante vestido largo palabra de honor es una auténtica obra de arte, confeccionado con pedrería en un degradado cromático que va del coral al amarillo. La falda, realizada en una exquisita organza de seda de tono coral, presenta un corte estratégico que insinúa sutilmente la pierna, añadiendo un toque de sensualidad. El diseño asimétrico por debajo de la cadera estiliza la figura, aportando una sofisticación moderna. El vestido se complementa perfectamente con un chal a juego en organza de seda coral, lo que lo convierte en una elección ideal para eventos de gala, donde el estilo y la elegancia son esenciales.",
        'fr': "",
        'it': "Questo impressionante abito lungo senza spalline è un vero e proprio capolavoro, realizzato con pietre preziose in un gradiente cromatico che va dal corallo al giallo. La gonna, fatta in squisita organza di seta color corallo, presenta un taglio strategico che suggerisce sottilmente la gamba, aggiungendo un tocco di sensualità. Il design asimmetrico sotto il fianco modella la figura, portando una sofisticazione moderna. L'abito è perfettamente completato da uno scialle abbinato in organza di seta corallo, rendendolo una scelta ideale per eventi di gala dove stile ed eleganza sono fondamentali.", 
        'ca': "Aquest impressionant vestit llarg paraula d'honor és una autèntica obra d'art, confeccionat amb pedreria en un degradat cromàtic que va del corall al groc. La faldilla, feta en una exquisida organza de seda de to corall, presenta un tall estratègic que insinua subtilment la cama, afegint un toc de sensualitat. El disseny asimètric sota la cadera estilitza la figura, aportant una sofisticació moderna. El vestit es complementa perfectament amb un xal a joc en organza de seda corall, convertint-lo en una elecció ideal per a esdeveniments de gala, on l'estil i l'elegància són essencials.",
    },
    '3796': {
        'en': "This light lavender dress, with a body crafted in crepe cut through shiny crepe bias, is designed to accentuate the waist. The sleeves and skirt, made of light natural silk gauze in the same tone, add an air of elegance and fluidity. The illusion of bare shoulders is achieved with flesh-coloured tulle that forms the upper part of the dress and its transparency. It fastens comfortably at the back with an invisible zip, making this dress a sophisticated and subtly seductive choice for special occasions.",
        'es': "Este ligero vestido en tono lavanda, con un cuerpo confeccionado en crepé cortado a través de bieses en crepé brillante, destaca por su capacidad para acentuar la cintura. Las mangas y la falda, hechas en una gaza de seda natural ligera del mismo tono, añaden un aire de elegancia y fluidez. La ilusión de hombros descubiertos se logra con el tul color carne que forma la parte superior del vestido y su transparencia. Se abrocha cómodamente en la espalda con una cremallera invisible, haciendo de este vestido una opción sofisticada y sutilmente seductora para ocasiones especiales.",
        'fr': "",
        'it': "Questo leggero abito lavanda, con il corpo realizzato in crepe tagliato attraverso sbiechi in crepe lucido, è progettato per accentuare la vita. Le maniche e la gonna, fatte in leggera garza di seta naturale dello stesso tono, aggiungono un'aria di eleganza e fluidità. L'illusione di spalle scoperte è ottenuta con il tulle color carne che forma la parte superiore dell'abito e la sua trasparenza. Si chiude comodamente sul retro con una zip invisibile, rendendo questo abito una scelta sofisticata e sottilmente seducente per occasioni speciali.", 
        'ca': "Aquest lleuger vestit lavanda, amb un cos confeccionat en crep tallat a través de biaixos en crep lluent, està dissenyat per accentuar la cintura. Les mànigues i la faldilla, fetes en lleugera gasa de seda natural del mateix to, afegeixen un aire d'elegància i fluïdesa. La il·lusió de hombros descoberts s'aconsegueix amb el tul color carn que forma la part superior del vestit i la seva transparència. Es tanca còmodament al darrere amb una cremallera invisible, fent d'aquest vestit una opció sofisticada i subtilment seductora per a ocasions especials.",
    },
    '3797': {
        'en': "This flattering dress, made in lavender crepe that combines glossy and matte textures, is a perfect example of elegance and style. With 3/4 sleeves and a tiered ankle-length skirt, the design creates a fluid and feminine silhouette. The body of the dress, achieving a streamlined effect, is enhanced with a meticulously pleated belt. The standout detail is a beautiful hand-cut and painted organza flower, adorning the pleated body. It fastens with a zip at the back, ensuring a perfect fit and flawless finish. This sophisticated dress is ideal for special occasions.",
        'es': "Este favorecedor vestido, confeccionado en crepe lavanda que combina texturas brillantes y mates, es un ejemplo perfecto de elegancia y estilo. Con manga 3/4 y una falda con corte a capa de largo tobillero, el diseño crea una silueta fluida y femenina. El cuerpo del vestido, que logra un efecto estilizado, se realza con un cinturón de pliegues meticulosamente trabajado. El detalle más destacado es una hermosa flor de organza, cortada y pintada completamente a mano, que adorna el cuerpo también trabajado con pliegues. Se cierra con una cremallera en la espalda, asegurando un ajuste perfecto y un acabado impecable. Este vestido es una pieza sofisticada, ideal para ocasiones especiales.",
        'fr': "",
        'it': "Questo lusinghiero abito, realizzato in crepe lavanda che combina texture lucide e opache, è un esempio perfetto di eleganza e stile. Con maniche a 3/4 e una gonna a strati di lunghezza alla caviglia, il design crea una silhouette fluida e femminile. Il corpo dell'abito, che ottiene un effetto snellente, è valorizzato da una cintura a pieghe lavorata meticolosamente. Il dettaglio più spettacolare è un bellissimo fiore in organza, tagliato e dipinto interamente a mano, che adorna il corpo lavorato a pieghe. Si chiude con una zip sul retro, assicurando una vestibilità perfetta e una finitura impeccabile. Questo abito sofisticato è ideale per occasioni speciali.", 
        'ca': "Aquest vestit afavoridor, confeccionat en crep lavanda que combina textures brillants i mates, és un exemple perfecte d'elegància i estil. Amb mànigues 3/4 i una faldilla de tall capa de llargada al tormell, el disseny crea una silueta fluida i femenina. El cos del vestit, que aconsegueix un efecte estilitzat, es realça amb un cinturó de plecs meticulosament treballat. El detall més destacat és una bella flor d'organza, tallada i pintada completament a mà, que adorna el cos també treballat amb plecs. Es tanca amb una cremallera al darrere, assegurant un ajust perfecte i un acabat impecable. Aquest vestit sofisticat és ideal per a ocasions especials.",
    },
    '3798': {
        'en': "This striking dress masterfully combines ash grey crepe with anthracite-colored French chantilly, creating an impactful visual effect. With a midi-length skirt, the dress features an asymmetrical, fanciful design and transparent chantilly sleeves, adding a touch of elegance and delicacy. The waist cut includes a faux transparency, lending a feminine and subtly sensual style. It fastens with an invisible zip at the back, ensuring a perfect fit and a neat finish. This dress is the ideal choice for those looking to stand out with a unique and daring outfit.",
        'es': "Este rompedor vestido combina con maestría el crepe ceniza y el chantilly francés en color antracita, creando un efecto visual impactante. Con una falda de longitud midi, el vestido presenta un cuerpo asimétrico de diseño fantasioso y mangas transparentes de chantilly, aportando un toque de elegancia y delicadeza. El corte en la cintura incluye una falsa transparencia que añade un estilo femenino y sutilmente sensual. Se abrocha con una cremallera invisible en la espalda, garantizando un ajuste perfecto y un acabado pulcro. Este vestido es la elección ideal para quienes buscan destacar con un atuendo único y atrevido.",
        'fr': "",
        'it': "Questo audace abito combina magistralmente il crepe cenere con il chantilly francese in colore antracite, creando un effetto visivo di grande impatto. Con una gonna di lunghezza midi, l'abito presenta un corpo asimmetrico di design fantasioso e maniche trasparenti in chantilly, aggiungendo un tocco di eleganza e delicatezza. Il taglio in vita include una falsa trasparenza, conferendo uno stile femminile e sottilmente sensuale. Si chiude con una zip invisibile sul retro, garantendo una vestibilità perfetta e una finitura pulita. Questo abito è la scelta ideale per chi cerca di distinguersi con un outfit unico e audace.", 
        'ca': "Aquest vestit impactant combina amb mestria el crep cendra amb el chantilly francès en color antracita, creant un efecte visual impactant. Amb una faldilla de longitud midi, el vestit presenta un cos asimètric de disseny fantasiós i mànigues transparents de chantilly, aportant un toc d'elegància i delicadesa. El tall a la cintura inclou una falsa transparència, afegint un estil femení i subtilment sensual. Es tanca amb una cremallera invisible al darrere, garantint un ajust perfecte i un acabat net. Aquest vestit és l'elecció ideal per a aquells que busquen destacar amb un atuendo únic i atrevit.",
    },
    '3799': {
        'en': "This unique dress in ash grey crepe stands out for its midi evasé skirt and an elegant opening at the neckline. Its distinctive design creates the impression of being two pieces: a strapless dress and a bolero. The shoulder part, along with the neck and Japanese sleeve made of shiny crepe, adds a distinctive touch to the ensemble. It fastens with an invisible zip at the back, ensuring a perfect fit and a neat finish. This design cleverly combines sophistication with a touch of originality, creating a garment that is both elegant and modern.",
        'es': "Este singular vestido en crepe ceniza se destaca por su falda evasé midi y una elegante abertura en el escote. Su diseño único da la impresión de ser dos piezas: un vestido palabra de honor y una torera. La parte de los hombros, junto con el cuello y la manga japonesa realizados en crepe brillante, añade un toque distintivo al conjunto. Se abrocha con una cremallera invisible en la espalda, garantizando un ajuste perfecto y un acabado pulcro. Este diseño combina de manera ingeniosa la sofisticación con un toque de originalidad, creando una prenda que es tanto elegante como moderna.",
        'fr': "",
        'it': "Questo singolare abito in crepe cenere si distingue per la sua gonna evasé midi e un'elegante apertura sullo scollo. Il suo design unico dà l'impressione di essere due pezzi: un abito senza spalline e un bolero. La parte delle spalle, insieme al collo e alla manica giapponese realizzata in crepe lucido, aggiunge un tocco distintivo all'insieme. Si chiude con una zip invisibile sul retro, garantendo una vestibilità perfetta e una finitura pulita. Questo design combina in modo ingegnoso la sofisticatezza con un tocco di originalità, creando un capo sia elegante che moderno.", 
        'ca': "Aquest singular vestit en crep cendra es destaca per la seva faldilla evasé midi i una elegant obertura a l'escot. El seu disseny únic dóna la impressió de ser dues peces: un vestit paraula d'honor i una torera. La part dels hombros, junt amb el coll i la màniga japonesa feta en crep brillant, afegeix un toc distintiu al conjunt. Es tanca amb una cremallera invisible al darrere, garantint un ajust perfecte i un acabat net. Aquest disseny combina de manera enginyosa la sofisticació amb un toc d'originalitat, creant una peça tant elegant com moderna.",
    },
    '3800': {
        'en': "This spectacular long dress, with off-the-shoulder design, is crafted in steel-colored piqué-mikado, enhanced with silver details. Featuring short sleeves, its body is wrapped in an intricate detail of crinkled fabric, reminiscent of a serpent coiled around the body. Its flattering cut subtly suggests the leg, adding a touch of sensuality. It fastens in the back with an invisible zip, ensuring a perfect fit. This design combines elegance with a touch of originality, ideal for those looking to stand out with a unique and refined style.",
        'es': "Este espectacular vestido largo, con los hombros descubiertos, está confeccionado en piqué-mikado color acero, realzado con detalles en plata. De manga corta, su cuerpo se envuelve en un elaborado detalle de tejido arrugado que evoca la imagen de una serpiente enroscada alrededor del cuerpo. Su favorecedor corte sugiere sutilmente la pierna, añadiendo un toque de sensualidad. Cierra en la espalda con una cremallera invisible, garantizando un ajuste perfecto. Este diseño combina elegancia con un toque de originalidad, ideal para aquellas que buscan destacar con un estilo único y limpio.",
        'fr': "",
        'it': "Questo spettacolare abito lungo, con le spalle scoperte, è realizzato in piqué-mikado color acciaio, impreziosito con dettagli in argento. Con maniche corte, il suo corpo è avvolto in un elaborato dettaglio di tessuto arricciato che ricorda l'immagine di un serpente avvolto intorno al corpo. Il suo taglio lusinghiero suggerisce sottilmente la gamba, aggiungendo un tocco di sensualità. Si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta. Questo design combina eleganza con un tocco di originalità, ideale per coloro che cercano di distinguersi con uno stile unico e raffinato.", 
        'ca': "Aquest espectacular vestit llarg, amb els hombros descoberts, està confeccionat en piqué-mikado color acer, realçat amb detalls en plata. De màniga curta, el seu cos s'embolica en un elaborat detall de teixit arrugat que evoca la imatge d'una serp envoltant el cos. El seu tall afavoridor suggereix subtilment la cama, afegint un toc de sensualitat. Es tanca al darrere amb una cremallera invisible, garantint un ajust perfecte. Aquest disseny combina elegància amb un toc d'originalitat, ideal per a aquelles que busquen destacar amb un estil únic i net.",
    },
    '3801': {
        'en': "This feminine dress, made in steel-colored piqué-mikado and enhanced with silver details, radiates elegance and sophistication. The skirt, ankle-length and cape shape, begins at the waist with a subtle silver bias. The body reveals the shoulders through the delicate transparency of flesh-colored tulle. The detail of the silver folded ruffles on the shoulders provides a stylising effect that visually elongates the figure. It fastens at the back with an invisible zip.",
        'es': "Este femenino vestido, confeccionado en piqué-mikado color acero y realzado con detalles en plata, irradia elegancia y sofisticación. La falda, con un largo hasta el tobillo y un corte en capa, nace en la cintura con un sutil bies en plata. El cuerpo deja al descubierto los hombros a través de la delicada transparencia del tul en color carne. El detalle de los volantes plegados en los hombros, en color plata, aporta un efecto estilizador que alarga visualmente la figura. Se abrocha en la espalda con una cremallera invisible.",
        'fr': "",
        'it': "Questo femminile abito, realizzato in piqué-mikado color acciaio e arricchito con dettagli in argento, irradia eleganza e sofisticazione. La gonna, lunga fino alla caviglia, inizia in vita con un sottile sbieco in argento. Il corpo lascia scoperte le spalle attraverso la delicata trasparenza del tulle color carne. Il dettaglio dei volant piegati sulle spalle, in colore argento, apporta un effetto stilizzante che allunga visivamente la figura. Si chiude sul retro con una zip invisibile.", 
        'ca': "Aquest femení vestit, confeccionat en piqué-mikado color acer i realçat amb detalls en plata, irradia elegància i sofisticació. La faldilla, llarga fins al turmell i a capa, neix a la cintura amb un subtil biaix en plata. El cos deixa al descobert els hombros a través de la delicada transparència del tul color carn. El detall dels volants plegats a les espatlles, en color plata, aporta un efecte estilitzador que allarga visualment la figura. Es tanca al darrere amb una cremallera invisible.",
    },
    '3802': {
        'en': "This elegant short dress, made in StGilian blue piqué-mikado, is characterised by its sober lines and refined style. It features a flattering Teresa Ripoll-style collar, situated just below the shoulders, covering the upper part of the arm and subtly enhancing the figure. The collar is gathered in the center with a decorative buckle, adding a touch of distinction. It fastens at the back with an invisible zip, ensuring a perfect fit. This elegant design is ideal for any occasion that requires a touch of distinction and class.",
        'es': "Este elegante vestido corto, confeccionado en piqué-mikado de color azul StGilian, se caracteriza por sus líneas sobrias y un estilo refinado. Destaca su favorecedor cuello al estilo Teresa Ripoll, situado justo por debajo de los hombros, cubriendo la parte alta del brazo y realzando la figura de manera sutil. El cuello se recoge en el centro con una hebilla decorativa, aportando un toque de distinción. Se cierra en la espalda con una cremallera invisible, garantizando un ajuste perfecto. Este elegante diseño es ideal para cualquier ocasión que requiera un toque de distinción y clase.",
        'fr': "",
        'it': "Questo elegante abito corto, realizzato in piqué-mikado di colore blu StGilian, si caratterizza per le sue linee sobrie e uno stile raffinato. Presenta un lusinghiero collo in stile Teresa Ripoll, situato appena sotto le spalle, che copre la parte alta del braccio ed esalta la figura in modo sottile. Il collo è raccolto al centro con una fibbia decorativa, che conferisce un tocco di distinzione. Si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta. Questo design elegante è ideale per qualsiasi occasione che richieda un tocco di distinzione e classe.", 
        'ca': "Aquest elegant vestit curt, confeccionat en piqué-mikado de color blau StGilian, es caracteritza per les seves línies sobries i un estil refinat. Destaca el seu afavoridor coll al estil Teresa Ripoll, situat just per sota dels hombros, cobrint la part alta del braç i realçant subtilment la figura. El coll es recull al centre amb una sivella decorativa, aportant un toc de distinció. Es tanca al darrere amb una cremallera invisible, garantint un ajust perfecte. Aquest disseny elegant és ideal per a qualsevol ocasió que requereixi un toc de distinció i classe.",
    },
    '3803': {
        'en': "This spectacular dress, made in Klein blue piqué-mikado, is a true masterpiece of design and style. It features a heart-shaped neckline and short Japanese sleeves, adding an elegant and feminine touch. The intricately pleated sash visually elongates the body, while the significant midi-length skirt adds sophistication and movement. It fastens at the back with an invisible zip, ensuring a perfect fit and a flawless finish. This dress is ideal for special occasions where a distinctive and elegant style is sought.",
        'es': "Este espectacular vestido, confeccionado en piqué-mikado azul Klein, es una verdadera obra de arte en diseño y estilo. Cuenta con un cuerpo con escote en forma de corazón y mangas cortas japonesas, aportando un toque de elegancia y feminidad. La faja trabajada con pliegues alarga visualmente el cuerpo, mientras que la importante falda de largo midi añade un aire de sofisticación y movimiento. Se abrocha en la espalda con una cremallera invisible, garantizando un ajuste perfecto y un acabado impecable. Este vestido es ideal para ocasiones especiales donde se busca un estilo distintivo y elegante.",
        'fr': "",
        'it': "Questo spettacolare abito, realizzato in piqué-mikado blu Klein, è un vero capolavoro di design e stile. Presenta un scollo a cuore e maniche corte giapponesi, aggiungendo un tocco di eleganza e femminilità. La fascia lavorata allunga visivamente il corpo, mentre la significativa gonna a pieghe di lunghezza midi aggiunge sofisticatezza e movimento. Si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta e una finitura impeccabile. Questo abito è ideale per occasioni speciali dove si cerca uno stile distintivo ed elegante.", 
        'ca': "Aquest espectacular vestit, confeccionat en piqué-mikado blau Klein, és una veritable obra mestra de disseny i estil. Compta amb un escot en forma de cor i mànigues curtes japoneses, aportant un toc d'elegància i feminitat. La faixa treballada amb plecs allarga visualment el cos, mentre que la important faldilla de llargada midi afegeix sofisticació i moviment. Es tanca al darrere amb una cremallera invisible, garantint un ajust perfecte i un acabat impecable. Aquest vestit és ideal per a ocasions especials on es busca un estil distintiu i elegant.",
    },
    '3804': {
        'en': "This ensemble of a short jacket and dress, in an elegant navy blue and white crepe, showcases sophistication and style. The navy jacket, with white buttons and facings, has a short cut that accentuates the waist beautifully. The sleeveless dress features decorative white elements that streamline the silhouette. It fastens at the back with an invisible zip, ensuring a perfect fit and a neat finish. This set is perfect for formal events where a look of elegance and refinement is desired.",
        'es': "Este conjunto de chaqueta corta y vestido, en una elegante combinación de azul marino y blanco, es una muestra de sofisticación y estilo. La chaqueta, en azul marino, con botones y vistas en blanco, tiene un corte corto que acentúa la cintura de forma afavoridora. El vestido, sin mangas, incorpora piezas decorativas en blanco que estilizan la silueta. Se cierra por la espalda con una cremallera invisible, garantizando un ajuste perfecto y un acabado pulcro. Este conjunto es perfecto para eventos formales donde se desea proyectar una imagen de elegancia y refinamiento.",
        'fr': "",
        'it': "Questo insieme di giacca corta e vestito, in un elegante crepe blu navy e bianco, dimostra sofisticazione e stile. La giacca, in blu navy con bottoni e profili in bianco, ha un taglio corto che sottolinea magnificamente la vita. Il vestito senza maniche presenta elementi decorativi bianchi che modellano la silhouette. Si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta e una finitura pulita. Questo set è perfetto per eventi formali dove si desidera un look di eleganza e raffinatezza.", 
        'ca': "Aquest conjunt de jaqueta curta i vestit, en un elegant crep blau marí i blanc, mostra sofisticació i estil. La jaqueta, en blau marí amb botons i vistes en blanc, té un tall curt que accentua la cintura de manera afavoridora. El vestit, sense mànigues, incorpora peces decoratives en blanc que estilitzen la silueta. Es tanca al darrere amb una cremallera invisible, garantint un ajust perfecte i un acabat net. Aquest conjunt és perfecte per a esdeveniments formals on es desitja projectar una imatge d'elegància i refinament.",
    },
    '3805': {
        'en': "This beautiful dress, in a classic navy blue and white combination, stands out for its elegance and femininity. It features 3/4 sleeves and a Dior-style neckline, adorned with a flattering white bow detail. The midi-length design, with a cut in the skirt, subtly suggests the leg, adding a touch of grace and refinement. It fastens at the back with an invisible zip, ensuring a perfect fit and a flawless finish. This dress is the ideal choice for those seeking a classic piece with a modern, sophisticated twist.",
        'es': "Este bonito vestido, en una combinación clásica de azul marino y blanco, destaca por su elegancia y feminidad. Presenta una manga 3/4 y un escote tipo Dior, adornado con un detalle de lazada en blanco que realza la figura. El diseño midi, con un corte en la falda, sugiere la pierna con una sutil sensualidad, aportando un toque de gracia y refinamiento. Se cierra en la espalda con una cremallera invisible, garantizando un ajuste perfecto y un acabado impecable. Este vestido es la elección ideal para aquellos que buscan una pieza clásica con un toque moderno y sofisticado.",
        'fr': "",
        'it': "Questo bel vestito, nella classica combinazione di blu navy e bianco, spicca per la sua eleganza e femminilità. Presenta maniche a 3/4 e uno scollo stile Dior, abbellito con un dettaglio di fiocco bianco molto lusinghiero. Il design midi, con un taglio nella gonna, suggerisce sottilmente la gamba, aggiungendo un tocco di grazia e raffinatezza. Si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta e una finitura impeccabile. Questo vestito è la scelta ideale per chi cerca un capo classico con un tocco moderno e sofisticato.", 
        'ca': "Aquest bonic vestit, en una combinació clàssica de blau marí i blanc, destaca per la seva elegància i feminitat. Presenta mànigues 3/4 i un escot estil Dior, adornat amb un detall de llaç blanc molt afavoridor. El disseny midi, amb un tall a la faldilla, insinua subtilment la cama, afegint un toc de gràcia i refinament. Es tanca al darrere amb una cremallera invisible, garantint un ajust perfecte i un acabat impecable. Aquest vestit és l'elecció ideal per a aquells que busquen una peça clàssica amb un toc modern i sofisticat.",
    },
    '3806': {
        'en': "This flattering short dress, made in petroleum-coloured crepe, is a perfect piece to enhance the figure. Its waist draping, without horizontal cuts, subtly stylises the silhouette, making it ideal for all sizes. Sleeveless, the dress is embellished with ruffle details on the shoulders and one side of the body, adding movement and lightness to the design. It fastens at the back with an invisible zip, ensuring a perfect fit and an elegant finish. This dress combines comfort and style, making it an excellent choice for various occasions.",
        'es': "Este favorecedor vestido corto, confeccionado en crepe color petróleo, es una pieza perfecta para realzar la figura. Su drapeado en la cintura, sin cortes horizontales, estiliza sutilmente la silueta, haciéndolo ideal para todas las tallas. Sin mangas, el vestido se adorna con un detalle de volantes en los hombros y en un lateral del cuerpo, aportando movimiento y ligereza al diseño. Se cierra cómodamente en la espalda con una cremallera invisible, garantizando un ajuste perfecto y un acabado elegante. Este vestido combina comodidad y estilo, siendo una opción excelente para diversas ocasiones.",
        'fr': "",
        'it': "Questo lusinghiero vestito corto, realizzato in crepe color petrolio, è un capo perfetto per valorizzare la figura. Il drappeggio in vita, senza tagli orizzontali, modella sottilmente la silhouette, rendendolo ideale per tutte le taglie. Senza maniche, il vestito è impreziosito da dettagli a volant sulle spalle e su un lato del corpo, conferendo movimento e leggerezza al design. Si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta e un'elegante finitura. Questo vestito unisce comfort e stile, rendendolo una scelta eccellente per diverse occasioni.", 
        'ca': "Aquest vestit curt afavoridor, confeccionat en crep color petroli, és una peça perfecta per realçar la figura. El drapejat a la cintura, sense talls horitzontals, estilitza subtilment la silueta, fent-lo ideal per a totes les talles. Sense mànigues, el vestit s'adorna amb un detall de volants a les espatlles i en un costat del cos, aportant moviment i lleugeresa al disseny. Es tanca còmodament al darrere amb una cremallera invisible, garantint un ajust perfecte i un acabat elegant. Aquest vestit combina comoditat i estil, sent una opció excel·lent per a diverses ocasions.",
    },
    '3807': {
        'en': "This refined midi dress, made in water green crepe, exudes elegance and sophistication. Its A-line skirt provides charming fluidity, while the pleated shoulders in shiny crepe add a distinctive touch to this fabulous dress. It fastens comfortably at the back with an invisible zip, ensuring a perfect fit. Ideal for special occasions, this dress stands out for its blend of classic style and contemporary details, making it a must-have in any elegant wardrobe.",
        'es': "Este refinado vestido midi, confeccionado en crepe de color verde agua, exuda elegancia y sofisticación. Su falda evasé brinda una fluidez encantadora, mientras que los hombros trabajados con pliegues en crepe brillante le añaden un toque distintivo y fabuloso. Cierra cómodamente en la espalda con una cremallera invisible, asegurando un ajuste perfecto. Este vestido, ideal para ocasiones especiales, destaca por su combinación de estilo clásico con detalles contemporáneos, convirtiéndolo en una prenda imprescindible en cualquier guardarropa elegante.",
        'fr': "",
        'it': "Questo raffinato abito midi, realizzato in crepe color verde acqua, irradia eleganza e sofisticazione. La sua gonna a trapezio offre una fluidità incantevole, mentre le spalle lavorate con pieghe in crepe lucido aggiungono un tocco distintivo a questo favoloso abito. Si chiude comodamente sul retro con una zip invisibile, garantendo una vestibilità perfetta. Ideale per occasioni speciali, questo abito si distingue per la sua combinazione di stile classico e dettagli contemporanei, rendendolo un capo indispensabile in qualsiasi guardaroba elegante.", 
        'ca': "Aquest refinat vestit midi, confeccionat en crep color verd aigua, exsuda elegància i sofisticació. La seva faldilla evasé proporciona una fluïdesa encantadora, mentre que els hombros treballats amb plecs en crep brillant li afegeixen un toc distintiu i fabulós. Es tanca còmodament al darrere amb una cremallera invisible, assegurant un ajust perfecte. Ideal per a ocasions especials, aquest vestit destaca per la seva combinació d'estil clàssic amb detalls contemporanis, convertint-lo en una peça imprescindible en qualsevol armari elegant.",
    },
    '3808': {
        'en': "This simple yet striking long dress, made in water green crepe, redefines elegance with a bold twist. Its highly flattering diagonal cuts, with shiny crepe lapels, play with the silhouette, subtly slimming and stylising the figure. The skirt finishes with a sensual leg slit, adding a touch of allure. Sleeveless, the dress fastens at the back with an invisible zip, ensuring a perfect fit. This design is the perfect choice for those seeking to combine simplicity with a bold and modern style.",
        'es': "Este sencillo pero impactante vestido largo, realizado en crepe de color verde agua, redefine la elegancia con un toque audaz. Sus cortes diagonales, sumamente favorecedores y con solapas de crepe brillante, juegan con la silueta, diluyendo y estilizando la figura de manera sutil. La falda termina en un corte sensual para la pierna, añadiendo un toque de seducción. Sin mangas, el vestido se cierra en la espalda con una cremallera invisible, garantizando un ajuste perfecto. Este diseño es la elección perfecta para aquellas que buscan combinar simplicidad con un estilo llamativo y moderno.",
        'fr': "",
        'it': "Questo semplice ma sorprendente vestito lungo, realizzato in crepe verde acqua, ridefinisce l'eleganza con un tocco audace. I suoi tagli diagonali molto lusinghieri, con risvolti in crepe lucido, giocano con la silhouette, snellendo e stilizzando sottilmente la figura. La gonna termina con un seducente spacco per la gamba, aggiungendo un tocco di seduzione. Senza maniche, l'abito si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta. Questo design è la scelta perfetta per coloro che cercano di combinare la semplicità con uno stile audace e moderno.", 
        'ca': "Aquest vestit llarg senzill però impactant, realitzat en crep verd aigua, redefineix l'elegància amb un gir audaç. Els seus talls diagonals molt afavoridors, amb solapes de crep brillant, juguen amb la silueta, aprimant i estilitzant subtilment la figura. La faldilla acaba amb una sensual obertura per a la cama, afegint un toc seductor. Sense mànigues, el vestit es tanca al darrere amb una cremallera invisible, garantint un ajust perfecte. Aquest disseny és l'elecció perfecta per a aquells que busquen combinar la simplicitat amb un estil atrevit i modern.",
    },
    '3809': {
        'en': "This striking long dress, made in lime-coloured organza and tone-on-tone mikado-sable, is a statement of style and originality. The upper part of the body, the sleeves, and the cape on the back, crafted in transparent organza, provide a sense of freshness and lightness to the dress. Delicate small bows at the waist add the finishing touch to this unique design. It fastens at the back with an invisible zip, ensuring a perfect fit. This dress is a bold and sophisticated choice, perfect for occasions where making an elegant impression is key.",
        'es': "Este rompedor vestido largo, confeccionado en una combinación de organza color lima y mikado-sable a juego, es una declaración de estilo y originalidad. La parte superior del cuerpo, las mangas y la capa en la espalda, realizadas en organza transparente, aportan una sensación de frescura y ligereza al vestido. Pequeños lazos detallados en la cintura añaden un toque final a este diseño especial. Se cierra con una cremallera invisible en la espalda, garantizando un ajuste perfecto. Este vestido es una elección audaz y sofisticada, perfecta para aquellas ocasiones en las que se busca destacar con elegancia.",
        'fr': "",
        'it': "Questo sorprendente abito lungo, realizzato in organza color lime e mikado-sable tono su tono, è una dichiarazione di stile e originalità. La parte superiore del corpo, le maniche e il mantello sul retro, realizzati in organza trasparente, conferiscono una sensazione di freschezza e leggerezza all'abito. Piccoli fiocchi delicati in vita aggiungono il tocco finale a questo design unico. Si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta. Questo abito è una scelta audace e sofisticata, perfetta per occasioni in cui fare un'impressione elegante è fondamentale.", 
        'ca': "Aquest impactant vestit llarg, confeccionat en organza color llima i mikado-sable del mateix to, és una declaració d'estil i originalitat. La part superior del cos, les mànigues i la capa al darrere, realitzades en organza transparent, proporcionen una sensació de frescor i lleugeresa al vestit. Petits llaços delicats a la cintura afegeixen el toc final a aquest disseny únic. Es tanca al darrere amb una cremallera invisible, garantint un ajust perfecte. Aquest vestit és una elecció audaç i sofisticada, perfecta per a ocasions on fer una impressió elegant és clau.",
    },
    '3810': {
        'en': "This flattering short dress, made in petroleum-coloured mikado-sable with lime details, is an elegant and contemporary piece. Sleeveless with a subtle detail on one shoulder, it combines style and comfort. The intricately worked bias detail on the front of the skirt enhances the silhouette, subtly disguising the body's more delicate areas. Accompanied by a shawl in both colors, featuring pleated work on the back, it provides a beautiful and original finish. The dress fastens at the back with an invisible zip, ensuring a perfect fit and a neat look. This ensemble is ideal for any special event where a distinguished and fashionable appearance is sought.",
        'es': "Este favorecedor vestido corto, confeccionado en mikado-sable en color petróleo y detalles en lima, es una pieza elegante y contemporánea. Sin mangas y con un pequeño detalle en uno de los hombros, este diseño combina estilo y comodidad. El detalle de bieses en la parte frontal de la falda realza la silueta, disimulando sutilmente las zonas más delicadas del cuerpo. Se complementa con un chal que combina ambos colores, con un trabajo de pliegues en la parte trasera, aportando un acabado hermoso y original. El vestido se cierra en la espalda con una cremallera invisible, garantizando un ajuste perfecto y un acabado pulcro. Este conjunto es ideal para cualquier evento especial, donde se busca un look distinguido y a la moda.",
        'fr': "",
        'it': "Questo lusinghiero vestito corto, realizzato in mikado-sable color petrolio con dettagli in lime, è un capo elegante e contemporaneo. Senza maniche e con un piccolo dettaglio su una spalla, combina stile e comfort. Il dettagliato lavoro di sbieco sulla parte anteriore della gonna valorizza la silhouette, nascondendo sottilmente le aree più delicate del corpo. Accompagnato da uno scialle nei due colori, con lavorazione a pieghe sul retro, offre una finitura bella e originale. Il vestito si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta e un aspetto ordinato. Questo insieme è ideale per qualsiasi evento speciale dove si cerca un look distinto e alla moda.", 
        'ca': "Aquest afavoridor vestit curt, confeccionat en mikado-sable color petroli amb detalls en llima, és una peça elegant i contemporània. Sense mànigues i amb un petit detall en una de les espatlles, combina estil i comoditat. El treballat detall de biaixos a la part frontal de la faldilla realça la silueta, disimulant subtilment les zones més delicades del cos. Acompanyat d'un xal en ambdós colors, amb un treball de plecs a la part del darrere, proporciona un acabat bonic i original. El vestit es tanca al darrere amb una cremallera invisible, garantint un ajust perfecte i un aspecte net. Aquest conjunt és ideal per a qualsevol esdeveniment especial, on es busca una aparença distingida i a la moda.",
    },
    '3811': {
        'en': "This beautiful long dress, made in petroleum-coloured mikado-sable, is the epitome of elegance and sophistication. Its Teresa Ripoll neckline, leaving the shoulders asymmetrically bare, adds subtle sensuality and stylises the silhouette. The flattering pleated work on the body makes it an ideal garment for all figures, adapting to and enhancing various shapes. With short sleeves, the dress closes at the back with an invisible zip, ensuring a perfect fit and a flawless finish. This design is perfect for special events where a unique and distinguished style is desired.",
        'es': "Este precioso vestido largo, confeccionado en mikado-sable color petróleo, es la definición de elegancia y sofisticación. Su cuello Teresa Ripoll, dejando los hombros al aire de forma asimétrica, aporta una sutil sensualidad y estiliza la figura. El favorecedor trabajo de pliegues en el cuerpo lo convierte en una prenda ideal para todas las siluetas, adaptándose y realzando diversas figuras. Con manga corta, el vestido se cierra en la espalda con una cremallera invisible, asegurando un ajuste perfecto y un acabado impecable. Este diseño es perfecto para eventos especiales, donde se desea lucir un estilo único y distinguido.",
        'fr': "",
        'it': "Questo bellissimo abito lungo, realizzato in mikado-sable color petrolio, è la definizione di eleganza e sofisticazione. Il suo collo Teresa Ripoll, che lascia le spalle scoperte in modo asimmetrico, aggiunge una sottile sensualità e modella la silhouette. Il favorevole lavoro di pieghe sul corpo lo rende un capo ideale per tutte le silhouette, adattandosi ed esaltando diverse figure. Con maniche corte, l'abito si chiude sul retro con una zip invisibile, garantendo una vestibilità perfetta e una finitura impeccabile. Questo design è perfetto per eventi speciali, dove si desidera sfoggiare uno stile unico e distinto.", 
        'ca': "Aquest preciós vestit llarg, confeccionat en mikado-sable color petroli, és la definició d'elegància i sofisticació. El seu coll Teresa Ripoll, deixant els hombros a l'aire de forma asimètrica, aporta una subtil sensualitat i estilitza la silueta. El treball de plecs afavoridor al cos el converteix en una peça ideal per a totes les siluetes, adaptant-se i realçant diverses figures. Amb màniga curta, el vestit es tanca al darrere amb una cremallera invisible, assegurant un ajust perfecte i un acabat impecable. Aquest disseny és perfecte per a esdeveniments especials, on es desitja lluir un estil únic i distingit.",
    },
    '6182': {
        'en': "This elegant coat and dress set, crafted in petroleum-coloured mikado-sable with lime details, is a testament to sophistication and style. The coat features a chimney collar, a special sleeve design that allows them to be folded into cuffs, fastened with jewel closures and lime-lined buttons. The dress, with an underbust cut and sweetheart neckline, combines a lime bodice with a petroleum-coloured skirt. It fastens at the back with a zip. This ensemble is a perfect blend of boldness and elegance, ideal for special occasions where standing out with a touch of distinction is desired.",
        'es': "Este elegante conjunto de abrigo y vestido, confeccionado en mikado-sable color petróleo y detalles en lima, es una muestra de sofisticación y estilo. El abrigo cuenta con un cuello chimenea, un corte especial en las mangas que permite doblarlas en forma de puño, abrochados con cierres joya y botones forrados en lima. El vestido, con un corte bajo el pecho y escote corazón, combina un cuerpo en color lima con una falda en color petróleo. Se cierra con una cremallera en la espalda. Este conjunto es una mezcla perfecta de audacia y elegancia, ideal para ocasiones especiales donde se busca destacar con un toque de distinción.",
        'fr': "",
        'it': "Questo elegante abito con cappotto, realizzato in mikado-sable color petrolio con dettagli in lime, è un esempio di sofisticazione e stile. Il cappotto ha un collo a camino, un taglio speciale sulle maniche che permette di piegarle a polsino, chiusure con gioielli e bottoni rivestiti in lime. L'abito, con taglio sotto il seno e scollo a cuore, combina un corpetto in lime con una gonna in petrolio. Si chiude con una zip sul retro. Questo insieme è una perfetta miscela di audacia ed eleganza, ideale per occasioni speciali dove si cerca di spiccare con un tocco di distinzione.", 
        'ca': "Aquest elegant conjunt de jaqueta i vestit, confeccionat en mikado-sable color petroli i detalls en llima, és una mostra de sofisticació i estil. La jaqueta compta amb un coll xemeneia, un tall especial a les mànigues que permet plegar-les en forma de puny, es corda amb tancaments joia i botons folrats en llima. El vestit, amb un tall sota el pit i escot cor, combina un cos en color llima amb una faldilla en color petroli. Es tanca amb una cremallera al darrera. Aquest conjunt és una barreja perfecta d'audàcia i elegància, ideal per a ocasions especials on es busca destacar amb un toc de distinció.",
    },
    '6183': {
        'en': "Introducing a spectacular coat and dress set in lime double satin, evoking the high fashion of the 1960s. The coat, adorned with delicate leaves around the collar and buttons, features a classic sack-style cut radiating elegance. The dress complements the coat with subtly sensual shoulder cut-outs and floral waist details that enhance the figure. With short sleeves and a back zip closure, this ensemble merges timeless sophistication with a modern twist, ideal for special occasions that call for a unique and distinguished style.",
        'es': "Este elegante conjunto de abrigo y vestido, confeccionado en mikado-sable color petróleo y detalles en lima, es una muestra de sofisticación y estilo. El abrigo cuenta con un cuello chimenea, un corte especial en las mangas que permite doblarlas en forma de puño, abrochados con cierres joya y botones forrados en lima. El vestido, con un corte bajo el pecho y escote corazón, combina un cuerpo en color lima con una falda en color petróleo. Se cierra con una cremallera en la espalda. Este conjunto es una mezcla perfecta de audacia y elegancia, ideal para ocasiones especiales donde se busca destacar con un toque de distinción.",
        'fr': "",
        'it': "Presentiamo un spettacolare insieme di cappotto e abito in doppio raso color lime, che evoca l'alta moda degli anni '60. Il cappotto, adornato con delicate foglie intorno al collo e sui bottoni, ha un taglio classico a sacco che irradia eleganza. L'abito, in armonia con il cappotto, si distingue per le aperture sottilmente sensuali sulle spalle e i dettagli floreali in vita che valorizzano la figura. Con maniche corte e chiusura a zip sul retro, questo insieme fonde la sofisticazione senza tempo con un tocco moderno, ideale per occasioni speciali che richiedono uno stile unico e distintivo.", 
        'ca': "Presentem un espectacular conjunt de jaqueta i vestit en doble ras color llima, evocant l'alta costura dels anys 60. La jaqueta, adornada amb fulles delicades al voltant del coll i els botons, té un tall clàssic d'estil sac que irradia elegància. El vestit complementa la jaqueta amb obertures subtilment sensuals als espatlles i detalls florals a la cintura que estilitzen la figura. Amb mànigues curtes i tancament amb cremallera al darrere, aquest conjunt fusiona la sofisticació atemporal amb un toc modern, ideal per a ocasions especials que requereixen un estil únic i distingit.",
    },
    '6184': {
        'en': "This fresh and elegant ensemble pairs a natural silk organza coat with a StGilian blue piqué-mikado strapless dress. The lightweight coat, draped at the waist and featuring stylish lapels, fastens with a hand-painted flower at the center, adding an artistic touch. The short dress stands out with its subtly folded strapless neckline, offering a chic and fine detail. Together, the coat and dress create a delicate, flowing ensemble, perfect for wearing on the warmest days of the year.",
        'es': "Este refrescante y elegante conjunto combina un abrigo en organza de seda natural con un vestido palabra de honor en piqué-mikado azul StGilian. El abrigo, ligero y drapeado en la cintura, presenta solapas elegantes y se cierra con una flor pintada a mano en el centro, añadiendo un toque artístico. El vestido corto, perfecto para los días calurosos, destaca por su escote palabra de honor con un sutil doblado, aportando un detalle fino y chic. Juntos, el abrigo y el vestido crean un conjunto delicado y con movimiento, ideal para lucir en los días más cálidos del año.",
        'fr': "",
        'it': "Questo fresco ed elegante insieme combina un cappotto in organza di seta naturale con un abito senza spalline in piqué-mikado blu StGilian. Il cappotto leggero, drappeggiato in vita e con eleganti risvolti, si chiude con un fiore dipinto a mano al centro, aggiungendo un tocco artistico. L'abito corto si distingue per il suo scollo senza spalline sottilmente piegato, offrendo un dettaglio chic e raffinato. Insieme, il cappotto e l'abito creano un insieme delicato e fluido, perfetto da indossare nei giorni più caldi dell'anno.", 
        'ca': "Aquest conjunt fresc i elegant combina un abric en organza de seda natural amb un vestit paraula d'honor en piqué-mikado blau StGilian. L'abric lleuger, drapejat a la cintura i amb solapes elegants, es tanca amb una flor pintada a mà al centre, afegint un toc artístic. El vestit curt destaca pel seu escot paraula d'honor subtilment plegat, oferint un detall xic i fi. Junts, l'abric i el vestit creen un conjunt delicat i amb moviment, ideal per lluir en els dies més calorosos de l’any.",
    },
    '6185': {
        'en': "This elegant ensemble pairs a coat and a short dress in red piqué-mikado and organza. The lightweight coat features a structured upper part on the shoulders and neck to maintain elegance, while the body and sleeves in organza add freshness and fluidity. The dress, subtly sensual, leaves the shoulders bare, perfectly complementing the coat and providing a delightful surprise when removed. With short sleeves and small straps, the dress is comfortable and fastens with a zip at the back, blending comfort and sophistication in an ideal outfit for special occasions.",
        'es': "Este elegante conjunto combina un abrigo y un vestido corto en piqué-mikado y organza rojos. El abrigo, ligero y elegante, tiene una parte superior estructurada en hombros y cuello para mantener la elegancia, mientras que el cuerpo y las mangas de organza aportan frescura y fluidez. El vestido, con su sutil sensualidad, deja los hombros al descubierto, complementando perfectamente al abrigo y ofreciendo una grata sorpresa al quitárselo. Con mangas cortas y pequeños tirantes, el vestido es cómodo y se abrocha con una cremallera en la espalda, uniendo comodidad y sofisticación en un conjunto ideal para ocasiones especiales.",
        'fr': "",
        'it': "Questo elegante insieme combina un cappotto e un abito corto in piqué-mikado e organza rossi. Il cappotto leggero ha una parte superiore strutturata su spalle e collo per mantenere l'eleganza, mentre il corpo e le maniche in organza aggiungono freschezza e fluidità. L'abito, sottilmente sensuale, lascia scoperte le spalle, complementando perfettamente il cappotto e offrendo una piacevole sorpresa quando viene tolto. Con maniche corte e piccole spalline, l'abito è comodo e si chiude con una zip sul retro, unendo comodità e sofisticazione in un outfit ideale per occasioni speciali.", 
        'ca': "Aquest elegant conjunt combina una jaqueta i un vestit curt en piqué-mikado i organza vermells. La jaqueta lleugera té una part superior estructurada a les espatlles i al coll per mantenir l'elegància, mentre que el cos i les mànigues d'organza aporten frescor i fluïdesa. El vestit, subtilment sensual, deixa els espatlles al descobert, complementant perfectament la jaqueta i oferint una agradable sorpresa en treure-se-la. Amb mànigues curtes i petits tirants, el vestit és còmode i es tanca amb una cremallera al darrere, unint comoditat i sofisticació en un conjunt ideal per a ocasions especials.",
    },
}
