import React from 'react'
import { lang } from '../i18n/i18n'
import { useMobile } from '../hooks/useMobile'

export const CardPromo = ({index, data}) => {
  const isMobile = useMobile()

  return (
    <div style={isMobile ? styles.cardMobile : {...styles.card, flexDirection: index % 2 == 0 ? "row" : "row-reverse" }}>
        <img src={data.image} alt={data.title[lang]} style={isMobile ? styles.imgMobile : styles.img}/>
        <div style={styles.content}>
            <h3  style={isMobile ? {paddingBottom: 15} : {paddingBottom: '5%'}}>{data.title[lang]}</h3>
            {
                data.subSections.map((el, index) => <div style={isMobile ? {} : {paddingBottom: '3%'}}>
                    <h5>{el.title[lang]}</h5>
                    <p>{el.text[lang]}</p>
                </div>)
            }
        </div>
    </div>
  )
}

const styles = {
    card: {
        display: "flex",
        /*position: "relative",*/
        marginTop: 35,
        marginBottom: 35,
    },
    cardMobile: {
      display: "block",
    },
    img: {
        width: `50%`,
        height: `50%`,
    },
    imgMobile: {
        width: `100%`,
        height: `100%`,
    },
    content: {
        display: "block",
        marginTop: 25,
        marginBottom: 25,
    }
}
