
export const preview2024 = [
    {
        title: '2225',
        light: '/img/preview2024/img/2225-bl-f-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/2225-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '2226',
        light: '/img/preview2024/img/2226-bl-pa-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/2226-bl-pa-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '2227',
        light: '/img/preview2024/img/2227-bl-f-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/2227-bl-f-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '2228',
        light: '/img/preview2024/img/2228-ch-bl-pa-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/2228-ch-bl-pa-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '2229',
        light: '/img/preview2024/img/2229-ch-f-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/2229-ch-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3788',
        light: '/img/preview2024/img/3788-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3788-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3789',
        light: '/img/preview2024/img/3789-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3789-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3790',
        light: '/img/preview2024/img/3790-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3790-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3791',
        light: '/img/preview2024/img/3791-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3791-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3792',
        light: '/img/preview2024/img/3792-ch-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3792-ch-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3793',
        light: '/img/preview2024/img/3793-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3793-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3794',
        light: '/img/preview2024/img/3794-ch-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3794-ch-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3795',
        light: '/img/preview2024/img/3795-ca-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3795-ca-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3796',
        light: '/img/preview2024/img/3796-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3796-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3797',
        light: '/img/preview2024/img/3797-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3797-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3798',
        light: '/img/preview2024/img/3798-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3798-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3799',
        light: '/img/preview2024/img/3799-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3799-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3800',
        light: '/img/preview2024/img/3800-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3800-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3801',
        light: '/img/preview2024/img/3801-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3801-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3802',
        light: '/img/preview2024/img/3802-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3802-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3802',
        light: '/img/preview2024/img/3802-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3802-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3804 CH/V',
        light: '/img/preview2024/img/3804-ch-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3804-ch-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3804 V',
        light: '/img/preview2024/img/3804-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3804-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3805',
        light: '/img/preview2024/img/3805-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3805-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3806',
        light: '/img/preview2024/img/3806-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3806-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3807',
        light: '/img/preview2024/img/3807-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3807-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3808',
        light: '/img/preview2024/img/3808-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3808-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3809',
        light: '/img/preview2024/img/3809-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3809-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3810',
        light: '/img/preview2024/img/3810-ca-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3810-ca-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '3811',
        light: '/img/preview2024/img/3811-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/3811-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '6182',
        light: '/img/preview2024/img/6182-a-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/6182-a-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '6183 AB',
        light: '/img/preview2024/img/6183-a-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/6183-a-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '6183 V',
        light: '/img/preview2024/img/6183-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/6183-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '6184',
        light: '/img/preview2024/img/6184-a-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/6184-a-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '6185',
        light: '/img/preview2024/img/6185-a-v-teresaripoll-ss24.jpg',
        video: '/img/preview2024/video/6185-a-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7028',
        light: '/img/preview2024/img/7028-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7028-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7029',
        light: '/img/preview2024/img/7029-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7029-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7030',
        light: '/img/preview2024/img/7030-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7030-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7031',
        light: '/img/preview2024/img/7031-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7031-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7032',
        light: '/img/preview2024/img/7032-bl-pa-teresaripoll.jpg',
        video: '/img/preview2024/video/7032-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7033',
        light: '/img/preview2024/img/7033-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7033-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7034',
        light: '/img/preview2024/img/7034-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7034-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7035',
        light: '/img/preview2024/img/7035-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7035-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7036',
        light: '/img/preview2024/img/7036-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7036-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7037',
        light: '/img/preview2024/img/7037-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7037-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7038',
        light: '/img/preview2024/img/7038-bl-pa-teresaripoll.jpg',
        video: '/img/preview2024/video/7038-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7039',
        light: '/img/preview2024/img/7039-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7039-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7040',
        light: '/img/preview2024/img/7040-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7040-v-teresaripoll-ss24-clip.mp4',
    },
    {
        title: '7041',
        light: '/img/preview2024/img/7041-v-teresaripoll.jpg',
        video: '/img/preview2024/video/7041-v-teresaripoll-ss24-clip.mp4',
    },
]