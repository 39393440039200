import React from 'react'
import * as FA from 'react-fontawesome'

export const BtnBack = ({onClose}) => {
  return (
    <div
      style={styles.btnBack}
      onMouseOver={(e) => e.target.style.backgroundColor = 'rgba(0,0,0,0.7)'}
      onMouseLeave={(e) => e.target.style.backgroundColor = 'rgba(0,0,0,0.3)'}
      onClick={onClose}
    ><FA name="arrow-left" style={{width:'100%', height:'100%', textAlign:'center', lineHeight:'300%'}}/></div>
  )
}

const styles = {
  btnBack: {
    position: 'fixed', //'absolute',
    top: 70,
    left: 13,
    backgroundColor: 'rgba(0,0,0,0.3)',
    width: 70,
    height: 50,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 5,
    //borderRadius: 10,
  },
}
