
// Enviament de correus
export const sendMail = (prefix, values) => {
  // DATA
  const formData  = new FormData();
  if (prefix === "contact") {
    // PHP distingeix si el formulari és contact o atelier per si hi ha camp: contact
    formData.append("contact", "true");
  }
  formData.append("name", values.name);
  formData.append("email", values.email);
  formData.append("phone", values.phone);
  formData.append("message", values.message);

  // POST
  fetch("/pcrphp/apis/send-atelier-email.php", {
    method: 'POST',
    body: formData
  })
  .then(res => {
    // RESPONSE: de fet el servidor no respon
    console.log("res", res);
  });
}