import React, { useState, useEffect } from 'react'
import { CardFilm } from '../components/CardFilm'
import { FilmPlayer } from '../components/FilmPlayer'
import { TextAnim } from '../components/TextAnim'
import { useMobile } from '../hooks/useMobile'
//import { TextWrite } from '../components/TextWrite'
import { filmsData } from '../models/filmsModel'


export const Films = () => {
  const [video, setVideo] = useState('')
  const mobile = useMobile()

  const onCloseVideoPlayer = () => setVideo('')

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div style={{ paddingTop: 100, paddingLeft: mobile ? 0 : 75, paddingRight: mobile ? 0 : 75}}>
      {/*<h1 style={styles.title}>Films</h1>
      <TextWrite text="Films i més" style={{fontSize:24, textAlign:'center'}}/>*/}
      <TextAnim 
        text="Fashion Films" 
        divStyle={{justifyContent:'center', marginBottom: 50}} 
        textStyle={{fontSize:40, fontWeight:'bold'}}
        colors={['#000', '#333', '#666', '#999', '#ccc']}
      />

      {
        video === ''
        ? <div style={styles.divGrid}>
            {filmsData.map(film =>
              <CardFilm
                name={film.name}
                poster={film.poster}
                onClick={() => setVideo(film.video)}
              />)}
          </div>
        : <FilmPlayer video={video} onClose={onCloseVideoPlayer}/>
      }
    </div>
  )
}


const styles = {
  divGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    // padding: 10,
  },
  title: { 
    color: '#666',
    paddingTop: 100, 
    paddingBottom: 35, 
    textAlign: 'center'
  }
}
