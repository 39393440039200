/*
    '': {
        'en': "",
        'es': "",
        'fr': "",
        'it': "", 
        'ca': "",
    },
*/

export const book_chroma_trans = {
    '7028': {
        'en': "This fluid dress in petrol-colored crepe, with 3/4 sleeves, features an elegant bow on a diagonal cut over the chest. A layer of crepe flows from this detail, enhancing the figure and adding movement. It fastens at the back with an invisible zip, blending style and comfort in a design that accentuates natural elegance.",
        'es': "Este fluido vestido en crepe color petróleo, con manga 3/4, presenta un elegante lazo en un corte diagonal sobre el pecho. De este detalle nace una capa de crepe que añade movimiento y embellece la figura. Cuenta con un cierre de cremallera invisible en la espalda, combinando estilo y comodidad en un diseño que realza la elegancia natural.",
        'fr': "",
        'it': "Questo fluido abito in crepe color petrolio, con maniche a 3/4, presenta un elegante fiocco su un taglio diagonale sul petto. Da questo dettaglio nasce un strato di crepe che dona movimento ed esalta la figura. Si chiude sul retro con una zip invisibile, unendo stile e comfort in un design che mette in risalto l'eleganza naturale.", 
        'ca': "Aquest fluid vestit en crepe color petroli, amb màniga 3/4, presenta un elegant llaç en un tall diagonal sobre el pit. D'aquest detall neix una capa de crepe que afegeix moviment i embelleix la figura. Es tanca al darrere amb una cremallera invisible, combinant estil i comoditat en un disseny que realça l'elegància natural.",
    },
    '7029': {
        'en': "This design features a short dress in olive green crepe, elegant and sophisticated. Its 3/4 sleeves and V-neckline add a classic and feminine touch. The peplum, accented with a bow detail, cinches and accentuates the waist, creating a flattering silhouette. An invisible zip closure at the back ensures a perfect fit, blending functionality with style. This dress is ideal for occasions where standing out with a touch of distinction and elegance is desired.",
        'es': "Este diseño describe un vestido corto en crepe verde aceituna, elegante y sofisticado. Su manga 3/4 y escote en V aportan un toque clásico y femenino. El peplum, con un detalle de lazo, marca y realza la cintura, creando una silueta favorecedora. El cierre con una cremallera invisible en la espalda asegura un ajuste perfecto, combinando funcionalidad con estilo. Este vestido es ideal para ocasiones donde se busca destacar con un toque de distinción y elegancia.",
        'fr': "",
        'it': "Questo design descrive un abito corto in crepe verde oliva, elegante e sofisticato. La sua manica a 3/4 e scollo a V conferiscono un tocco classico e femminile. Il peplo, accentuato da un dettaglio a fiocco, stringe ed esalta la vita, creando una silhouette lusinghiera. La chiusura con una zip invisibile sul retro assicura una vestibilità perfetta, combinando funzionalità con stile. Questo vestito è ideale per occasioni in cui si cerca di spiccare con un tocco di distinzione ed eleganza.", 
        'ca': "Aquest disseny descriu un vestit curt en crep verd oliva, elegant i sofisticat. La seva màniga 3/4 i escot en V aporten un toc clàssic i femení. El peplum, accentuat amb un detall de llaç, marca i realça la cintura, creant una silueta afavoridora. El tancament amb una cremallera invisible al darrere assegura un ajust perfecte, combinant funcionalitat amb estil. Aquest vestit és ideal per a ocasions on es busca destacar amb un toc de distinció i elegància.",
    },
    '7030': {
        'en': "Elegant short dress in olive green crepe with 3/4 sleeves and the signature Teresa Ripoll collar, which stylises and enhances the figure. Fastens at the back with an invisible zip, perfect for special occasions.",
        'es': "Elegante vestido corto en crepe verde aceituna con manga 3/4 y cuello Teresa Ripoll, que estiliza y realza la figura. Cierre trasero con cremallera invisible para un ajuste perfecto. Ideal para ocasiones especiales.",
        'fr': "",
        'it': "Elegante abito corto in crepe verde oliva con maniche a 3/4 e il caratteristico collo Teresa Ripoll, che modella e valorizza la figura. Si chiude sul retro con una zip invisibile, ideale per occasioni speciali.", 
        'ca': "Elegant vestit curt en crepe verd oliva amb màniga 3/4 i el característic coll Teresa Ripoll, que estilitza i realça la figura. Es tanca per darrere amb cremallera invisible, perfecte per a ocasions especials.",
    },
    '7031': {
        'en': "This design features a simple and flattering blue crepe A-line dress. Its 3/4 sleeves and invisible zip closure at the back provide comfort and an elegant fit, ideal for various occasions. The A-line silhouette naturally enhances the figure, offering a timeless and sophisticated look.",
        'es': "Este diseño presenta un vestido evasé sencillo y favorecedor en crepe de color azul. Su manga 3/4 y el cierre con cremallera invisible en la espalda aportan comodidad y un ajuste elegante, ideal para diversas ocasiones. La silueta evasé realza la figura de manera natural, ofreciendo un look atemporal y sofisticado.",
        'fr': "",
        'it': "Questo design presenta un vestito evasé semplice e lusinghiero in crepe blu. Le sue maniche a 3/4 e la chiusura con zip invisibile sul retro offrono comodità e una vestibilità elegante, ideale per diverse occasioni. La silhouette evasé valorizza naturalmente la figura, offrendo un look senza tempo e sofisticato.", 
        'ca': "Aquest disseny presenta un vestit evasé senzill i afavoridor en crepe de color blau. La seva màniga 3/4 i el tancament amb cremallera invisible a l'esquena aporten comoditat i un ajust elegant, ideal per a diverses ocasions. La silueta evasé realça la figura de manera natural, oferint un look atemporal i sofisticat.",
    },
    '7032': {
        'en': "This special ensemble, consisting of a blouse and trousers in blue crepe, stands out for its elegance and versatility. The blouse, with its asymmetric cut and slightly open design, along with the fantasy cuffs on the 3/4 sleeves, offers a unique and flattering touch for all figures. The long trousers add a level of sophistication, making this set an ideal choice for various events. Its balanced and stylish design makes it a perfect choice for those looking to stand out with elegance and comfort.",
        'es': "Este conjunto especial compuesto por una blusa y un pantalón en crepe azul, destaca por su elegancia y versatilidad. La blusa, de corte asimétrico y ligeramente abierta, junto con los puños de fantasía en la manga 3/4, brinda un toque único y favorecedor para todo tipo de figuras. El pantalón largo añade un nivel de sofisticación, haciendo de este conjunto una opción ideal para diversos eventos. Su diseño equilibrado y estiloso lo convierte en una elección perfecta para quienes buscan destacar con elegancia y confort.",
        'fr': "",
        'it': "Questo speciale insieme composto da una blusa e un pantalone in crepe blu, spicca per la sua eleganza e versatilità. La blusa, con il suo taglio asimmetrico e leggermente aperto, insieme ai polsini fantasia sulla manica 3/4, offre un tocco unico e lusinghiero per ogni tipo di figura. Il pantalone lungo aggiunge un livello di sofisticazione, rendendo questo set un'opzione ideale per diversi eventi. Il suo design equilibrato e stiloso lo rende una scelta perfetta per chi cerca di distinguersi con eleganza e comfort.", 
        'ca': "Aquest conjunt especial compost per una blusa i un pantaló en crepe blau, destaca per la seva elegància i versatilitat. La blusa, de tall asimètric i lleugerament oberta, juntament amb els punys de fantasia a la màniga 3/4, ofereix un toc únic i afavoridor per a tot tipus de figures. El pantaló llarg afegeix un nivell de sofisticació, fent d'aquest conjunt una opció ideal per a diversos esdeveniments. El seu disseny equilibrat i estilós el converteix en una elecció perfecta per a aquells que busquen destacar amb elegància i confort.",
    },
    '7033': {
        'en': "This modern dress in StGilian blue crepe is an elegant and contemporary piece. With its 3/4 sleeves and a folded bodice adorned with a pretty bow, this short dress becomes a versatile and sophisticated option for any occasion. Its carefully considered design offers both comfort and style, making it ideal for both formal and casual events.",
        'es': "Este moderno vestido en crepe azul StGilian es una pieza elegante y contemporánea. Con su manga 3/4 y un cuerpo plegado embellecido con un bonito lazo, este vestido corto se convierte en una opción versátil y sofisticada para cualquier ocasión. Su diseño cuidadosamente pensado ofrece tanto comodidad como estilo, haciéndolo ideal para eventos formales o casuales.",
        'fr': "",
        'it': "Questo moderno abito in crepe blu StGilian è un capo elegante e contemporaneo. Con le sue maniche a 3/4 e un corpo piegato abbellito con un grazioso fiocco, questo vestito corto diventa una scelta versatile e sofisticata per ogni occasione. Il suo design attentamente ponderato offre sia comfort che stile, rendendolo ideale sia per eventi formali che casual.", 
        'ca': "Aquest modern vestit en crepe blau StGilian és una peça elegant i contemporània. Amb les seves mànigues 3/4 i un cos plegat embellit amb un bonic llaç, aquest vestit curt es converteix en una opció versàtil i sofisticada per a qualsevol ocasió. El seu disseny cuidadosament pensat ofereix tant comoditat com estil, fent-lo ideal tant per a esdeveniments formals com casuals.",
    },
    '7034': {
        'en': "Elegant StGilian blue crepe dress, ideal for any event. Featuring the signature Teresa Ripoll collar that enhances the figure, this 3/4 sleeve dress is a wardrobe essential. The side seams in the bodice and a concealed zip at the back ensure comfort and ease of wear, making this design a versatile and sophisticated choice for any occasion.",
        'es': "Vestido elegante de crepe en tono azul StGilian, perfecto para eventos. Diseñado con el distintivo cuello Teresa Ripoll que favorece la silueta, este vestido de manga 3/4 es un imprescindible en tu armario. Los cortes laterales en los costadillos y una cremallera oculta en la espalda aportan comodidad y facilidad al vestirse, haciendo de este diseño una opción versátil y sofisticada para cualquier ocasión.",
        'fr': "",
        'it': "Abito elegante in crepe blu StGilian, ideale per qualsiasi evento. Caratterizzato dal distintivo colletto Teresa Ripoll che valorizza la figura, questo abito a maniche 3/4 è un essenziale nel guardaroba. Le cuciture laterali nel corpetto e una zip nascosta sul retro garantiscono comfort e facilità di indossare, rendendo questo design una scelta versatile e sofisticata per ogni occasione.", 
        'ca': "Vestit elegant de crep blau StGilian, ideal per a qualsevol esdeveniment. Amb el distintiu coll Teresa Ripoll que realça la figura, aquest vestit de màniga 3/4 és un imprescindible en el teu armari. Els talls laterals en costadet i una cremallera oculta al darrere asseguren comoditat i facilitat al vestir-se, fent d'aquest disseny una opció versàtil i sofisticada per a qualsevol ocasió.",
    },
    '7035': {
        'en': "Stunning Klein blue crepe dress, perfect for making a statement at any event. Its chimney neck and delicate bow details on the cuffs add a touch of elegance, while the flattering A-line cut of the midi skirt suits any figure. The dress is neatly fastened at the back with an invisible zip, making it both elegant and distinguished.",
        'es': "Vestido deslumbrante de crepe en un vibrante azul Klein, perfecto para destacar en cualquier ocasión. Su cuello chimenea y los delicados lazos en los puños añaden un toque de elegancia, mientras que la falda midi de corte evasé favorece cualquier figura. La cremallera invisible en la espalda asegura un acabado limpio y sofisticado, convirtiendo este vestido en una elección elegante y distinguida.",
        'fr': "",
        'it': "Spettacolare abito in crepe di colore blu Klein, ideale per distinguersi in qualsiasi occasione. Il colletto a camino e i delicati fiocchi sui polsini aggiungono un tocco di eleganza, mentre il taglio svasato della gonna midi valorizza ogni figura. L'abito si chiude sul retro con una zip invisibile, rendendolo elegante e raffinato.", 
        'ca': "Espectacular vestit de crep en color blau Klein, perfecte per destacar en qualsevol esdeveniment. El coll xemeneia i els delicats llaços als punys afegeixen un toc d'elegància, mentre que la forma evasé de la faldilla midi afavoreix qualsevol figura. Es corda per l'esquena amb una cremallera invisible, convertint-lo en una elecció elegant i distingida.",
    },
    '7036': {
        'en': "Stunning bougainvillea-colored crepe midi dress, epitomising elegance and femininity. Designed with a waist-enhancing cut and a flared bodice, it gracefully accentuates the figure. The skirt adds a touch of grace and movement. Featuring 3/4 sleeves and an invisible zip at the back, this dress blends comfort with style, making it a perfect choice for any significant event.",
        'es': "Este espectacular vestido midi en crepé color bugambilia es la definición de elegancia y femineidad. Diseñado con un corte que realza la cintura y un vuelo bajo el pecho, acentúa las formas de manera sofisticada. Su falda con vuelo añade un toque de gracia y movimiento. Con mangas 3/4 y una cremallera invisible en la espalda, este vestido combina comodidad y estilo, siendo una opción ideal para cualquier evento importante.",
        'fr': "",
        'it': "Spettacolare abito midi in crepe colore bougainvillea, simbolo di eleganza e femminilità. Progettato con un taglio che valorizza la vita e un corpetto svasato, accentua la figura in modo sofisticato. La gonna aggiunge un tocco di grazia e movimento. Con maniche a 3/4 e una zip invisibile sul retro, questo abito unisce comfort e stile, rendendolo perfetto per qualsiasi evento importante.", 
        'ca': "Espectacular vestit midi de crep color bugambilia, que afavoreix l'elegància i la feminitat. Dissenyat amb un tall que realça la cintura i vol sota el pit, acentua les formes amb gràcia. La faldilla afegeix un toc  de moviment. Amb mànigues 3/4 i una cremallera invisible a l'esquena, aquest vestit combina comoditat i estil, sent una opció perfecta per a qualsevol esdeveniment important.",
    },
    '7037': {
        'en': "This flattering crepe midi dress in a vibrant bougainvillea color is a tribute to clean cuts. The exaggerated lapels of the wrap bodice, in the style of the classic Teresa Ripoll collar, enhance the silhouette, while the waistband elegantly accentuates the shape. Featuring 3/4 sleeves and an invisible zip at the back, this dress perfectly blends sophistication with comfort, making it ideal for any special occasion.",
        'es': "Este favorecedor vestido midi de crepé en un vibrante color bugambilia es un canto a los cortes limpios. Las solapas exageradas del cuerpo cruzado, en el estilo del clásico cuello Teresa Ripoll, realzan la silueta, mientras que la pieza de cintura acentúa las formas con elegancia. Con mangas 3/4 y una cremallera invisible en la espalda, este vestido combina a la perfección sofisticación y comodidad, ideal para cualquier ocasión especial.",
        'fr': "",
        'it': "Questo lusinghiero abito midi in crepe di colore bougainvillea è un omaggio ai tagli netti. Le patta esagerate del corpino incrociato, nello stile del classico colletto Teresa Ripoll, valorizzano la silhouette, mentre il pezzo in vita accentua elegantemente la forma. Con maniche a 3/4 e una zip invisibile sul retro, questo abito combina perfettamente sofisticazione e comfort, rendendolo ideale per ogni occasione speciale.", 
        'ca': "Aquest afavoridor vestit midi en crep de color bugambilia és un cant als talls nets. Les solapes exagerades del cos creuat, en l'estil del clàssic coll Teresa Ripoll, realcen la silueta, mentre que la peça de cintura accentua elegantment les formes. Amb mànigues de 3/4 i una cremallera invisible al darrere, aquest vestit combina a la perfecció sofisticació i comoditat, essent ideal per a qualsevol ocasió especial.",
    },
    '7038': {
        'en': "A two-piece set featuring a blouse and trousers in cherry-toned crepe, embodying elegance and contemporary style. The blouse features an asymmetrical take on the flattering Teresa Ripoll collar, mirroring the asymmetrical hip cut, and fastens with an invisible zip at the back. The straight trousers perfectly complement the blouse, adding a modern spark to the ensemble. This outfit is ideal for those seeking a sophisticated and up-to-date look.",
        'es': "Este conjunto de dos piezas, compuesto por una blusa y un pantalón en crepé de tonos cereza, es una muestra de elegancia y estilo contemporáneo. La blusa, con un diseño asimétrico del favorecedor cuello Teresa Ripoll, sigue una línea de corte asimétrica en la cadera, cerrándose con una cremallera invisible en la espalda. El pantalón recto complementa perfectamente la blusa, aportando un toque moderno y sofisticado al conjunto. Este atuendo es ideal para quienes buscan un look elegante y actual.",
        'fr': "",
        'it': "Questo completo di due pezzi, composto da una blusa e un pantalone in crepé nei toni del ciliegia, è un esempio di eleganza e stile contemporaneo. La blusa, con un design asimmetrico del lusinghiero collo di Teresa Ripoll, segue una linea di taglio asimmetrica sui fianchi, chiudendosi con una zip invisibile sul retro. Il pantalone dritto complementa perfettamente la blusa, aggiungendo un tocco moderno e sofisticato all'insieme. Questo abito è ideale per coloro che cercano un look elegante e attuale", 
        'ca': "Aquest conjunt de dues peces, compost per una blusa i un pantaló en crepé de tons cirera, és una mostra d'elegància i estil contemporani. La blusa, amb un disseny asimètric de l’afavoridor coll de Teresa Ripoll, segueix una línia de tall asimètrica a la cadera, tancant-se amb una cremallera invisible a l'esquena. El pantaló recte complementa perfectament la blusa, aportant un toc modern i sofisticat al conjunt. Aquest vestit és ideal per a aquells que busquen un look elegant i actual.",
    },
    '7039': {
        'en': "This short dress, crafted in red crepe, exudes elegance and sophistication. Its unique design features a matching fabric cape that covers the arms and extends over the front above the waist, elegantly cascading down the back. The inclusion of the cape makes sleeves unnecessary, adding a modern touch. It fastens with a discreet invisible zip at the back, completing its chic and refined profile.",
        'es': "Este vestido corto, confeccionado en crepé rojo, exuda elegancia y sofisticación. Su diseño único incluye una capa del mismo tejido que cubre los brazos y se extiende sobre la parte delantera hasta la cintura, descendiendo con elegancia por la espalda. La inclusión de la capa elimina la necesidad de mangas, aportando un toque de modernidad. Se cierra con una discreta cremallera invisible en la espalda, completando su perfil chic y refinado.",
        'fr': "",
        'it': "Questo abito corto, realizzato in crepé rosso, irradia eleganza e sofisticazione. Il suo design unico comprende un mantello in tessuto abbinato che copre le braccia e si estende sulla parte anteriore sopra la vita, scendendo elegantemente sul retro. L'inclusione del mantello rende superflue le maniche, aggiungendo un tocco moderno. Si chiude con una discreta zip invisibile sul retro, completando il suo profilo chic e raffinato.", 
        'ca': "Aquest vestit curt, confeccionat en crepé vermell, exsuda elegància i sofisticació. El seu disseny únic inclou una capa del mateix teixit que cobreix els braços i s'estén sobre la part davantera fins a la cintura, caient elegantment per l'esquena. La inclusió de la capa fa innecessàries les mànigues, afegint un toc modern. Es tanca amb una discreta cremallera invisible al darrere, completant el seu perfil xic i refinat.",
    },
    '7040': {
        'en': "This spectacular short dress in coral crepe is a tribute to femininity and elegance. It features a unique Teresa Ripoll-style double collar, adding a distinctive touch to the design. The A-line skirt flatters all body types and is complemented by 3/4 sleeves, balancing sophistication with comfort. An invisible zip closure at the back ensures a sleek, contoured silhouette, making this dress an essential piece for events where style and grace are paramount.",
        'es': "Este espectacular vestido corto, elaborado en crepé coral, es un homenaje a la feminidad y la elegancia. Destaca por su cuello doble al estilo Teresa Ripoll, que añade un toque distintivo al diseño. La falda evasé, que favorece todo tipo de siluetas, se complementa con mangas 3/4, equilibrando sofisticación y confort. El cierre con cremallera invisible en la espalda asegura una silueta limpia y contorneada, haciendo de este vestido una pieza imprescindible para eventos donde el estilo y la gracia son primordiales.",
        'fr': "",
        'it': "Questo spettacolare abito corto in crepé color corallo è un omaggio alla femminilità ed eleganza. Presenta un collo doppio unico nello stile di Teresa Ripoll, che aggiunge un tocco distintivo al design. La gonna a campana, che valorizza ogni tipo di figura, è completata da maniche a 3/4, equilibrando sofisticazione e comfort. Una chiusura con zip invisibile sul retro assicura una silhouette elegante e contornata, rendendo questo vestito un pezzo essenziale per eventi dove stile e grazia sono fondamentali.", 
        'ca': "Aquest espectacular vestit curt en crepé color corall és un homenatge a la feminitat i l'elegància. Destaca per un coll doble únic estil Teresa Ripoll, afegint un toc distintiu al disseny. La faldilla evasé, que afavoreix tots els tipus de siluetes, es complementa amb mànigues 3/4, equilibrant sofisticació i confort. Una tancament amb cremallera invisible al darrere assegura una silueta neta i contornejada, fent d'aquest vestit una peça imprescindible per a esdeveniments on l'estil i la gràcia són primordials.",
    },
    '7041': {
        'en': "This feminine powder pink midi dress epitomises grace and femininity. Its 3/4 sleeves, adorned with ruched shoulders, add a touch of soft elegance. The neckline features a teardrop detail in the cleavage, highlighting the sweetness and delicacy of the look. The dress fastens with an invisible zip at the back, ensuring a neat and sophisticated silhouette, making it a perfect choice for any occasion that calls for subtle and refined style.",
        'es': "Este vestido midi en un delicado tono rosa empolvado es la definición de feminidad y gracia. Sus mangas 3/4, adornadas con frunces en los hombros, añaden un toque de elegancia suave. El cuello con detalle de lágrima en el escote enfatiza la dulzura y delicadeza del conjunto. Su cierre con cremallera invisible en la espalda asegura una silueta pulcra y sofisticada, convirtiéndolo en una elección perfecta para cualquier ocasión que requiera un estilo sutil y refinado.",
        'fr': "",
        'it': "Questo femminile abito midi in rosa cipria è la definizione di grazia e femminilità. Le sue maniche a 3/4, ornate con arricciature sulle spalle, aggiungono un tocco di eleganza delicata. Il collo con un dettaglio a lacrima sul décolleté sottolinea la dolcezza e la delicatezza del look. L'abito si chiude con una zip invisibile sul retro, garantendo una silhouette ordinata e sofisticata, rendendolo una scelta perfetta per ogni occasione che richiede uno stile sottile e raffinato.", 
        'ca': "Aquest vestit midi femení en rosa empolvat personifica la gràcia i la feminitat. Les seves mànigues 3/4, adornades amb arrugats als hombros, afegeixen un toc d'elegància suau. El coll presenta un detall de llàgrima l’escot, destacant la dolçor i delicadesa del look. El vestit es tanca amb una cremallera invisible al darrere, assegurant una silueta neta i sofisticada, fent-lo una elecció perfecta per a qualsevol ocasió que requereixi un estil subtil i refinat.",
    },
}
