import React, { useState, useEffect } from 'react'
import { CardContent } from '../components/CardContent'
import { MyCarousel } from '../components/MyCarousel'
import { SectionTitle } from '../components/SectionTitle'
import { useMobile } from '../hooks/useMobile'
import { BOOK_RAINBOW, BOOK_50_YEARS, BOOK_PARTY_ONE, BOOK_PARTY_TWO, BOOKN_ROARING20S, BOOKN_SWEET, galleries, 
         BOOK_PARIS, BOOK_DANUBE, BOOK_KALEIDOSCOPE, BOOK_CHROMA, BOOK_TIMELESS } from '../models/gelleriesModel'


export const Home = () => {
  const mobile = useMobile()

  const desktopImages = ['timeless-banner-ss24-ord-1.jpg', 'timeless-banner-ss24-ord-2.jpg', 'chroma-banner-ss24-ord-1.jpg', 'chroma-banner-ss24-ord-2.jpg']
  const mobileImages = ['timeless-banner-ss24-mob-1.jpg', 'timeless-banner-ss24-mob-2.jpg', 'chroma-banner-ss24-mob-1.jpg', 'chroma-banner-ss24-mob-2.jpg']
  const carouselTitles = ['Timeless Collection', 'Timeless Collection', 'Chroma Collection', 'Chroma Collection']
  const [images, setImages] = useState([])

  useEffect(() => {
    mobile ? setImages(mobileImages) : setImages(desktopImages)
    // eslint-disable-next-line
  }, [mobile])

  window.scrollTo({top: 0, behavior: "smooth"})
  
  return (
    <div>
      {/* C a r o u s e l */}
      <div style={styles.fixed}>
        <MyCarousel id="carouselHomeMain" images={images} titles={carouselTitles}/>
      </div>

      {/* Aquest div ocupa igual que el carousel, però no es mai visible, només és per colocar el contingut per sota */}
      <div style={{marginTop:55}}>
        <img src={`img/carousel/${images[0]}`} alt="" style={{width:'100%',visibility:'hidden',minHeight: 300,}}></img>
      </div>

      {/* b o d y */}
      <div style={styles.body}>
        <SectionTitle title="WE MAKE SEWING INTO AN ART FORM"/>
        <div style={styles.cardsDiv}>
          <CardContent 
            image="img/carousel/timeless-banner-ss24-mob-1.jpg" 
            title="Timeless Collection" 
            description="Description"
            route={`/gallery/${BOOK_TIMELESS}`} 
            routeName={galleries[BOOK_TIMELESS].title}
          />
          <CardContent 
            image="/img/carousel/chroma-banner-ss24-mob-1.jpg"
            title="Chrome Collection" 
            description="Description"
            route={`/gallery/${BOOK_CHROMA}`} 
            routeName={galleries[BOOK_CHROMA].title}
          />
          <CardContent 
            image="/img/2023/danube/banners/danube-mbl.jpg"
            title="Danube Collection" 
            description="Description"
            route={`/gallery/${BOOK_DANUBE}`} 
            routeName={galleries[BOOK_DANUBE].title}
          />
          <CardContent 
            image="/img/2023/kaleidoscope/banners/kaleidoscope-mbl.jpg" 
            title="Kaleidoscope Collection" 
            description="Description" 
            route={`/gallery/${BOOK_KALEIDOSCOPE}`} 
            routeName={galleries[BOOK_KALEIDOSCOPE].title}
          />
          <CardContent 
            image="/img/carousel/1-SS22-rosa-i-verd-portada-mobil-v1.jpg"
            title="Paris Collection" 
            description="Description"
            route={`/gallery/${BOOK_PARIS}`} 
            routeName={galleries[BOOK_PARIS].title}
          />
          <CardContent 
            image="img/carousel/rainbow1_mobil.jpg" 
            title="Rainbow Collection" 
            description="Description" 
            route={`/gallery/${BOOK_RAINBOW}`} 
            routeName={galleries[BOOK_RAINBOW].title}
          />
        </div>

        <SectionTitle title="WEDDING COLLECTIONS"/>
        <div style={styles.cardsDiv}>
          <CardContent 
            image={mobile ? "img/headers/roaring20s-mobile.jpg" : "img/headers/roaring20s-mobile.jpg"} // -transp
            title="Roaring 20s Collection" 
            description="Description"
            route={`/gallery/${BOOKN_ROARING20S}`} 
            routeName={galleries[BOOKN_ROARING20S].title}
          />
          <CardContent 
            image="img/headers/sweet-mobile.jpg" 
            title="Sweet Collection" 
            description="Description"
            route={`/gallery/${BOOKN_SWEET}`} 
            routeName={galleries[BOOKN_SWEET].title}
          />
        </div>

        <SectionTitle title="THE EXPERIENCE OF A LIFETIME IN EVERY STITCH"/>
        {mobile 
          ? <img src="img/headers/50-couture-mobile.jpg" alt="" style={{width:'100%'}}/>
          : <img src="img/headers/50-couture-desktop.jpg" alt="" style={{width:'100%'}}/>}
      </div>
    </div>
  )
}


const styles = {
  fixed: {
    position: 'fixed',
    width: '100%',
    top: 55,
    left: 0,
    zIndex: -1,  // <-- necessari
    minHeight: 300,
  },
  body: {
    backgroundColor: '#fff',
    boxShadow: '-3px -3px 10px #ccc',
  },
  cardsDiv: {
    display:'flex', 
    flexDirection:'row', 
    flexWrap:'wrap',
    backgroundColor:'#f9f9f9', 
    justifyContent:'space-around',
    padding: 10,
    paddingTop: 5,
    paddingBottom:15,
  }
}
