import React, { useEffect, useState } from 'react'
import { Navbar, Nav, Collapse, Dropdown } from 'bootstrap-4-react'
import { LinkContainer } from 'react-router-bootstrap'
import { 
  BOOK_TIMELESS, BOOK_CHROMA, BOOK_KALEIDOSCOPE, BOOK_DANUBE,
  BOOK_50_YEARS, BOOK_RAINBOW, BOOK_PARTY_ONE, BOOK_PARTY_TWO, BOOK_PARIS, BOOK_CITY, BOOK_CHARMING, 
  BOOKN_ROARING20S, BOOKN_SWEET, BOOKN_ELEGANCE, BOOKN_DREAMS, galleries 
} from '../models/gelleriesModel'
import { lang, selectLanguage } from '../i18n/i18n'
import { appBarT } from '../i18n/AppBar.trans'

export const AppBar = () => {
  const [menuRightMarginLeft, setMenuRightMarginLeft] = useState(window.innerWidth >= 768 ? -80 : 0)

  useEffect(() => {
    // Collapse nabBar on click: https://stackoverflow.com/questions/42401606/how-to-hide-collapsible-bootstrap-navbar-on-click
    const navbarColor1 = document.getElementById('navbarColor1')
    const navLinks = document.querySelectorAll('.nav-item')
    navLinks.forEach((l) => {
      l.addEventListener('click', () => { navbarColor1.classList.remove('show') })
    })

    // En escriptori el menú dret cal desplaçar-lo cap a l'esquerra. Mobile no.
    window.addEventListener('resize', () => {
      window.innerWidth >= 768 ? setMenuRightMarginLeft(-80) : setMenuRightMarginLeft(0)
    })    
  }, [])

  return (
    <div style={styles.container}>
      <Navbar expand="lg" light bg="light" >{/* dark bg="dark"   -    light bg="light"*/}
        <Navbar.Brand style={{color:'#333', cursor:'pointer', fontWeight:'bold', fontFamily:'Grotesque MT Bold'}}> {/* color:'#ccc', */}
          <LinkContainer to="/">
            <span style={{fontFamily:'Grotesque MT Bold'}}>TERESA RIPOLL</span>
          </LinkContainer>
        </Navbar.Brand>

        <Navbar.Toggler target="#navbarColor1" />

        <Collapse navbar id="navbarColor1">
          <Navbar.Nav mr="auto">

            {/* C o c k t a i l */}
            <Nav.Item dropdown>
              <Nav.Link dropdownToggle>{appBarT.cocktail[lang]}</Nav.Link>
              <Dropdown.Menu>
                {<LinkContainer to={`/gallery/${BOOK_TIMELESS}`}>
                  <Dropdown.Item>{galleries[BOOK_TIMELESS].title}</Dropdown.Item>
                </LinkContainer>}
                {<LinkContainer to={`/gallery/${BOOK_DANUBE}`}>
                  <Dropdown.Item>{galleries[BOOK_DANUBE].title}</Dropdown.Item>
                </LinkContainer>}
                {<LinkContainer to={`/gallery/${BOOK_PARIS}`}>
                  <Dropdown.Item>{galleries[BOOK_PARIS].title}</Dropdown.Item>
                </LinkContainer>}
                {/*<Dropdown.Divider />*/}
                <LinkContainer to={`/gallery/${BOOK_50_YEARS}`}>
                  <Dropdown.Item>{galleries[BOOK_50_YEARS].title}</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to={`/gallery/${BOOK_PARTY_ONE}`}>
                  <Dropdown.Item>{galleries[BOOK_PARTY_ONE].title}</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to={`/gallery/${BOOK_PARTY_TWO}`}>
                  <Dropdown.Item>{galleries[BOOK_PARTY_TWO].title}</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to={`/gallery/${BOOK_CITY}`}>
                  <Dropdown.Item>{galleries[BOOK_CITY].title}</Dropdown.Item>
                </LinkContainer>
                {/*<LinkContainer to={`/gallery/${BOOK_CHARMING}`}>
                  <Dropdown.Item>{galleries[BOOK_CHARMING].title}</Dropdown.Item>
  </LinkContainer>*/}
              </Dropdown.Menu>
            </Nav.Item>

            {/* R a i n b o w */}
            <Nav.Item dropdown>
              <Nav.Link dropdownToggle>{appBarT.rainbow[lang]}</Nav.Link>
              <Dropdown.Menu>
                {<LinkContainer to={`/gallery/${BOOK_CHROMA}`}>
                  <Dropdown.Item>{galleries[BOOK_CHROMA].title}</Dropdown.Item>
                </LinkContainer>}
                {<LinkContainer to={`/gallery/${BOOK_KALEIDOSCOPE}`}>
                  <Dropdown.Item>{galleries[BOOK_KALEIDOSCOPE].title}</Dropdown.Item>
                </LinkContainer>}
                <LinkContainer to={`/gallery/${BOOK_RAINBOW}`}>
                  <Dropdown.Item>{galleries[BOOK_RAINBOW].title}</Dropdown.Item>
                </LinkContainer>
              </Dropdown.Menu>
            </Nav.Item>


            {/* W e d d i n g */}
            <Nav.Item dropdown>
              <Nav.Link dropdownToggle>{appBarT.wedding[lang]}</Nav.Link>
              <Dropdown.Menu>
                <LinkContainer to={`/gallery/${BOOKN_ROARING20S}`}>
                  <Dropdown.Item>{galleries[BOOKN_ROARING20S].title}</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to={`/gallery/${BOOKN_SWEET}`}>
                  <Dropdown.Item>{galleries[BOOKN_SWEET].title}</Dropdown.Item>
                </LinkContainer>
                {/*<LinkContainer to={`/gallery/${BOOKN_ELEGANCE}`}>
                  <Dropdown.Item>{galleries[BOOKN_ELEGANCE].title}</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to={`/gallery/${BOOKN_DREAMS}`}>
                  <Dropdown.Item>{galleries[BOOKN_DREAMS].title}</Dropdown.Item>
                </LinkContainer>*/}
              </Dropdown.Menu>
            </Nav.Item>

            {/* F i l m s */}
            <LinkContainer to="/films">
              <Nav.ItemLink eventKey={2}>{appBarT.films[lang]}</Nav.ItemLink>
            </LinkContainer>            
            
            {/* H i s t o r y */}
            <LinkContainer to="/history">
              <Nav.ItemLink eventKey={2}>{appBarT.history[lang]}</Nav.ItemLink>
            </LinkContainer>            

            {/* C o n t a c t */}
            <LinkContainer to="/contact">
              <Nav.ItemLink eventKey={2}>{appBarT.contact[lang]}</Nav.ItemLink>
            </LinkContainer>            

            {/* S a l e s   P o i n t s */}
            <LinkContainer to="/sales">
              <Nav.ItemLink eventKey={2}>{appBarT.storeLocator[lang]}</Nav.ItemLink>
            </LinkContainer>            

            {/* A t e l i e r */}
            <LinkContainer to="/atelier">
              <Nav.ItemLink eventKey={2}>Atelier</Nav.ItemLink>
            </LinkContainer>            

          </Navbar.Nav>

          {/* L a n g u a g e */}
          <Navbar.Nav>
            <Nav.Item dropdown>
                <Nav.Link dropdownToggle>{appBarT.language[lang]}</Nav.Link>
                <Dropdown.Menu style={{marginLeft: menuRightMarginLeft}}>
                  {
                    Object.entries(appBarT.language).map( language => 
                      <Dropdown.Item key={ language[0] } onClick={() => selectLanguage(language[0])}>{language[1]}</Dropdown.Item>
                    )
                  }
                </Dropdown.Menu>
              </Nav.Item>
          </Navbar.Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    boxShadow: '3px 3px 10px #888',
    zIndex: 10,
  }
}

