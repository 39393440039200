import { historyT } from '../i18n/History.trans'
import { lang } from '../i18n/i18n'

/**
 * H I S T O R Y
 */
export const historyData = [
    { year:"1962", image:"img/history/maria.jpg",  alt: "maria",   text: historyT.maria_ripoll_in_paris[lang],    description: historyT.maria_ripoll_in_paris_desc[lang] },
    { year:"1968", image:"img/history/paris.jpg",  alt: "paris",   text: historyT.teresa_ripoll_in_paris[lang],   description: historyT.teresa_ripoll_in_paris_desc[lang] },
    { year:"1970", image:"img/history/monta.jpg",  alt: "monta",   text: historyT.they_create_the_company[lang],  description: historyT.they_create_the_company_desc[lang] },
    { year:"1998", image:"img/history/mariad.jpg", alt: "mariad",  text: historyT.death_of_maria_ripoll[lang],    description: historyT.death_of_maria_ripoll_desc[lang] },
    { year:"1999", image:"img/history/dest.jpg",   alt: "dest",    text: historyT.introduction_of_the_trb[lang],  description: ""},
    { year:"2002", image:"img/history/euro.jpg",   alt: "euro",    text: historyT.expansion_in_europe[lang],      description: ""},
    { year:"2010", image:"img/history/dis.jpg",    alt: "dis",     text: historyT.international_expansion[lang],  description: ""},
    { year:"2014", image:"img/history/discop.jpg", alt: "discop",  text: historyT.wedding_collection[lang],       description: ""},
    { year:"2015", image:"img/history/45.jpg",     alt: "45",      text: historyT.years_of_design_45[lang],       description: ""},
    { year:"2017", image:"img/history/botiga.jpg", alt: "botiga",  text: historyT.opening_of_the_atelier_in_barcelona[lang], description: ""},
    { year:"2020", image:"img/history/50.jpg",     alt: "50",      text: historyT.of_love_for_fashion_50[lang],   description: ""},
] 