
export const promoTrans = {
    title: {
        'en': "Essential Wedding Day Guide for the Mother of the Bride and Groom: Embracing Traditions",
        'es': "Guía para la Madre del Novio y de la Novia",
        'fr': "",
        'it': "Consigli Preziosi per le Madri degli Sposi: Guida al Matrimonio Perfetto", 
        'ca': "Guia per a la Mare del Nuvi i de la Núvia",
    },
    text: {
        'en': "",
        'es': "Te presento algunos consejos básicos de protocolo, vestuario, complementos y otros temas útiles que pueden considerarse en España:",
        'fr': "",
        'it': "Ecco alcuni consigli di base su protocollo, abbigliamento, accessori e altri argomenti utili da considerare in Italia:", 
        'ca': "Us presento alguns consells bàsics de protocol, vestuari, complements i altres temes útils que es poden considerar a Espanya:",
    },
    footer: {
        'en': "This guide embraces the shared joy and individual customs of weddings across the United Kingdom and Ireland, ensuring mothers of the bride and groom feel prepared and confident in their roles on this joyous occasion.",
        'es': "Recuerda que lo más importante es disfrutar del día y apoyar a los novios en este momento tan especial de sus vidas. La actitud positiva y el amor serán tus mejores complementos.",
        'fr': "",
        'it': "Ricordate, l'aspetto più importante è godervi la giornata e offrire il vostro sostegno agli sposi in questo momento così speciale delle loro vite. La vostra presenza amorevole è il miglior regalo che possiate offrire.", 
        'ca': "Recorda que el més important és gaudir del dia i donar suport als nuvis en aquest moment tan especial de les seves vides. L'actitud positiva i l'amor seran els teus millors complements.",
    },
    sections: [

        // P r o t o c o l   s e c t i o n

        {
            image: "/img/promo/1-3793v-teresaripoll.jpg",
            title: {
                'en': "Protocol",
                'es': "Protocolo",
                'fr': "",
                'it': "Protocollo", 
                'ca': "Protocol",
            },
            subSections: [

                // Role of Support

                {
                    title: {
                        'en': "Role of Support",
                        'es': "Respeto por los Protagonistas",
                        'fr': "",
                        'it': "Ruolo di Supporto", 
                        'ca': "Respecte pels Protagonistes",
                    },
                    text: {
                        'en': "Your main role is to support the couple and enhance their joy on this special day. Your presence should be comforting and encouraging.",
                        'es': "Recuerda que los verdaderos protagonistas del día son los novios. Tu papel es de apoyo y acompañamiento.",
                        'fr': "",
                        'it': "Ricordate che il vostro ruolo è di supporto e accompagnamento. La giornata appartiene agli sposi.", 
                        'ca': "Recorda que els veritables protagonistes del dia són els nuvis. El teu paper és de suport i acompanyament.",
                    },
                },

                // Family Coordination

                {
                    title: {
                        'en': "Family Coordination",
                        'es': "Comunicación con la Otra Familia",
                        'fr': "",
                        'it': "Comunicazione con l'Altra Famiglia", 
                        'ca': "Comunicació amb l'Altra Família",
                    },
                    text: {
                        'en': "Early coordination with the family of the other parent is crucial, especially for outfit planning to ensure harmony in style and color, respecting regional traditions.",
                        'es': "Es importante establecer una comunicación fluida y cordial con la familia del otro progenitor para coordinar detalles como el vestuario y evitar superposiciones o contrastes no deseados.",
                        'fr': "",
                        'it': "È importante mantenere una comunicazione aperta e cordiale con la famiglia dell'altro genitore per coordinare dettagli come l'abbigliamento.", 
                        'ca': "És important establir una comunicació fluïda i cordial amb la família de l'altre progenitor per coordinar detalls com el vestuari i evitar superposicions o contrastos no desitjats.",
                    },
                },

                // Ceremony Procession

                {
                    title: {
                        'en': "Ceremony Procession",
                        'es': "Entrada a la Ceremonia",
                        'fr': "",
                        'it': "Ingresso alla Cerimonia", 
                        'ca': "Entrada a la Cerimònia",
                    },
                    text: {
                        'en': "Depending on the region, there might be specific customs for the mothers' entrance. In general, mothers are among the first to arrive, ensuring they are seated before the ceremony begins.",
                        'es': "Tradicionalmente, la madre del novio sigue al novio en la entrada, y la madre de la novia entra justo antes de ella, marcando el comienzo de la ceremonia.",
                        'fr': "",
                        'it': "Tradizionalmente, la madre dello sposo entra poco prima di lui, mentre la madre della sposa può scegliere di entrare subito prima di lei o di accompagnarla all'altare.", 
                        'ca': "Tradicionalment, la mare del nuvi segueix al nuvi en l'entrada, i la mare de la núvia entra just abans d'ella, marcant el començament de la cerimònia.",
                    },
                },
            ]
        },

        // A t t i r e  s e c t i o n

        {
            image: "/img/promo/2-2229chf-2-teresaripoll.jpg",
            title: {
                'en': "Attire",
                'es': "Vestuario",
                'fr': "",
                'it': "Abbigliamento", 
                'ca': "Vestuari",
            },
            subSections: [

                // Outfit Choices

                {
                    title: {
                        'en': "Outfit Choices",
                        'es': "Estilo y Color",
                        'fr': "",
                        'it': "Stile e Colore", 
                        'ca': "Estil i Color",
                    },
                    text: {
                        'en': "Opt for an outfit that suits the formality of the occasion, avoiding white, cream, and overly bright shades. Regional attire, such as tartan elements for Scotland or traditional Irish lace, can add a meaningful touch.",
                        'es': "Elige un vestido elegante que complemente el color y estilo de la boda sin eclipsar a la novia. Evita el blanco, el marfil y, en algunos casos, el negro. Consulta con la novia y la madre del novio para coordinar.",
                        'fr': "",
                        'it': "Scegliete un abito elegante che si adatti allo stile e al colore del matrimonio, evitando il bianco o il nero. È consigliabile coordinarsi con la madre dell'altro genitore.", 
                        'ca': "Tria un vestit elegant que complemente el color i estil del casament sense eclipsar a la núvia. Evita el blanc, l'ivori i, en alguns casos, el negre. Consulta amb la núvia i la mare del nuvi per coordinar.",
                    },
                },

                // Headwear

                {
                    title: {
                        'en': "Headwear",
                        'es': "Largo del Vestido",
                        'fr': "",
                        'it': "Lunghezza dell'Abito", 
                        'ca': "Llarg del Vestit",
                    },
                    text: {
                        'en': "Hats or fascinators are common in England and Wales, while in Scotland, a tartan sash might be preferred. In Ireland, modesty and elegance guide the choice, with hats being optional.",
                        'es': "Los vestidos largos son más tradicionales para bodas nocturnas, mientras que para las bodas diurnas se pueden considerar trajes de cocktail.",
                        'fr': "",
                        'it': "Gli abiti lunghi sono più adatti per le cerimonie serali, mentre per quelle diurne si possono indossare abiti più corti o al ginocchio.", 
                        'ca': "Els vestits llargs són més tradicionals per a casaments nocturns, mentre que per a les bodas diürnes es poden considerar vestits de còctel.",
                    },
                },

                // Comfort and Practicality

                {
                    title: {
                        'en': "Comfort and Practicality",
                        'es': "Comodidad",
                        'fr': "",
                        'it': "Comfort", 
                        'ca': "Comoditat",
                    },
                    text: {
                        'en': "Given the varied locations and weather, choose comfortable and adaptable attire. Footwear should be elegant yet suitable for potentially long durations.",
                        'es': "Asegúrate de que tu atuendo sea cómodo, ya que será un día largo y posiblemente agitado.",
                        'fr': "",
                        'it': "Assicuratevi che il vostro outfit sia comodo, dato che sarà una giornata lunga.", 
                        'ca': "Assegura't que el teu atuendo sigui còmode, ja que serà un dia llarg i possiblement mogut.",
                    },
                },
            ]
        },

        // A c c e s s o r i e s  s e c t i o n

        {
            image: "/img/promo/3-6185av-teresaripoll.jpg",
            title: {
                'en': "Accessories",
                'es': "Complementos",
                'fr': "",
                'it': "Accessori", 
                'ca': "Complements",
            },
            subSections: [

                // Jewellery and Embellishments

                {
                    title: {
                        'en': "Jewellery and Embellishments",
                        'es': "Discreción",
                        'fr': "",
                        'it': "Sobrietà", 
                        'ca': "Discreció",
                    },
                    text: {
                        'en': "Keep jewellery elegant and understated. Consider incorporating Celtic designs in Scotland and Ireland for a personal touch.",
                        'es': "Los complementos deben ser elegantes pero discretos. Un sombrero o tocado puede ser apropiado para bodas diurnas, pero siempre considerando el lugar y estilo de la boda.",
                        'fr': "",
                        'it': "Gli accessori devono essere eleganti ma sobri. Un cappello o un tocado può essere appropriato per le cerimonie diurne.", 
                        'ca': "Els complements han de ser elegants però discrets. Un barret o tocado pot ser apropiat per a casaments diürnes, però sempre considerant el lloc i estil del casament.",
                    },
                },

                // Practical Accessories

                {
                    title: {
                        'en': "Practical Accessories",
                        'es': "Joyas",
                        'fr': "",
                        'it': "Gioielli", 
                        'ca': "Joies",
                    },
                    text: {
                        'en': "A small, stylish handbag or clutch for essentials. An umbrella might be wise, given the unpredictable weather across the regions.",
                        'es': "Opta por joyas sofisticadas pero sin excesos. Unas bonitas perlas o un conjunto sutil de joyería pueden ser perfectos.",
                        'fr': "",
                        'it': "Preferite gioielli raffinati ma non troppo appariscenti. Le perle o un semplice set di gioielli possono essere la scelta perfetta.", 
                        'ca': "Opta per joies sofisticades però sense excessos. Unes boniques perles o un conjunt subtil de joieria poden ser perfectes.",
                    },
                },

                // Cultural Elements

                {
                    title: {
                        'en': "Cultural Elements",
                        'es': "Bolsos y Zapatos",
                        'fr': "",
                        'it': "Borse e Scarpe", 
                        'ca': "Bosses i Sabates",
                    },
                    text: {
                        'en': "Embrace local accessories where appropriate, such as a Welsh daffodil brooch or Irish Claddagh ring, to honor heritage.",
                        'es': "Elige un bolso pequeño de mano y zapatos cómodos con los que puedas pasar todo el día. Considera la posibilidad de llevar zapatos de repuesto.",
                        'fr': "",
                        'it': "Optate per una piccola pochette e scarpe comode. Considerate l'idea di portare un secondo paio di scarpe più comode.", 
                        'ca': "Tria una bossa de mà petita i sabates còmodes amb les quals puguis passar tot el dia. Considera la possibilitat de portar sabates de recanvi.",
                    },
                },
            ]
        },

        // A d d i t i o n a l   C o n s i d e r a t i o n s   s e c t i o n

        {
            image: "/img/promo/4-3790v-2-teresaripoll.jpg",
            title: {
                'en': "Additional Considerations",
                'es': "Otros Temas Útiles",
                'fr': "",
                'it': "Altri Argomenti Utili", 
                'ca': "Altres Temes Útils",
            },
            subSections: [

                // Pre-Wedding Engagement

                {
                    title: {
                        'en': "Pre-Wedding Engagement",
                        'es': "Preparativos Previos",
                        'fr': "",
                        'it': "Preparativi", 
                        'ca': "Preparatius Previs",
                    },
                    text: {
                        'en': "Engage with the wedding planning within the bounds of the couple’s wishes. Offer support and be a source of calm.",
                        'es': "Participa en los preparativos de la boda según te soliciten los novios, ofreciendo ayuda pero sin imponer decisiones.",
                        'fr': "",
                        'it': "Partecipate ai preparativi del matrimonio offrendo aiuto quando richiesto, ma senza imporre le vostre decisioni.", 
                        'ca': "Participa en els preparatius del casament segons et sol·licitin els nuvis, oferint ajuda però sense imposar decisions.",
                    },
                },

                // Welcoming Guests

                {
                    title: {
                        'en': "Welcoming Guests",
                        'es': "Atención a los Invitados",
                        'fr': "",
                        'it': "Attenzione agli Ospiti", 
                        'ca': "Atenció als Convidats",
                    },
                    text: {
                        'en': "Ensure guests from your side feel welcomed and included, facilitating introductions and mingling.",
                        'es': "Asegúrate de que los invitados de tu lado de la familia se sientan bienvenidos y cómodos durante el evento.",
                        'fr': "",
                        'it': "Assicuratevi che gli ospiti della vostra famiglia si sentano accolti e a loro agio.", 
                        'ca': "Assegura't que els convidats del teu costat de la família se sentin benvinguts i còmodes durant l'esdeveniment.",
                    },
                },

                // Photography

                {
                    title: {
                        'en': "Photography",
                        'es': "Fotografías",
                        'fr': "",
                        'it': "Fotografie", 
                        'ca': "Fotografies",
                    },
                    text: {
                        'en': "Prepare for numerous photos with grace and patience, embracing these moments as cherished memories.",
                        'es': "Habrá muchas fotografías durante el día, así que prepara tu sonrisa y sé paciente. Es un recuerdo para toda la vida.",
                        'fr': "",
                        'it': "Ci saranno molte fotografie durante il giorno, quindi preparatevi a sorridere e mostrate pazienza.", 
                        'ca': "Hi haurà moltes fotografies durant el dia, així que prepara el teu somriure i sigues pacient. És un record per a tota la vida.",
                    },
                },

                // Speeches and Toasts

                {
                    title: {
                        'en': "Speeches and Toasts",
                        'es': "Discurso",
                        'fr': "",
                        'it': "Discorso", 
                        'ca': "Discurs",
                    },
                    text: {
                        'en': "If delivering a speech or toast, incorporate universal sentiments of joy, love, and well-wishing, possibly including a nod to cultural or regional significance.",
                        'es': "Si decides o te piden dar un discurso, prepara unas palabras breves que expresen tus mejores deseos para la pareja.",
                        'fr': "",
                        'it': "Se decidete o vi viene chiesto di fare un discorso, preparate alcune parole brevi che esprimano i vostri migliori auguri per la coppia.", 
                        'ca': "Si decideixes o et demanen fer un discurs, prepara unes paraules breus que expressin els teus millors desitjos per a la parella.",
                    },
                },
            ]
        },
    ]
}


/*
export const promoTrans = {
    title: {
        'en': "",
        'es': "",
        'fr': "",
        'it': "", 
        'ca': "",
    },
    text: {
        'en': "",
        'es': "",
        'fr': "",
        'it': "", 
        'ca': "",
    },
    sections: [
        {
            title: {
                'en': "",
                'es': "",
                'fr': "",
                'it': "", 
                'ca': "",
            },
            subSections: [
                {
                    title: {
                        'en': "",
                        'es': "",
                        'fr': "",
                        'it': "", 
                        'ca': "",
                    },
                    text: {
                        'en': "",
                        'es': "",
                        'fr': "",
                        'it': "", 
                        'ca': "",
                    },
                    image: ""
                },
            ]
        },
    ]
}
*/