import React, { useState, useEffect, useRef } from 'react'

export const TextWrite = ({text, interval=100, style={}}) => {
    const [textAnim, setTextAnim] = useState(text.substring(0,1))
    const index = useRef(1)

    const animLetter = () => {
        if (index.current < text.length ) {
            setTimeout(() => {
                animLetter()
            }, interval)
        }
        setTextAnim( text.substring(0, index.current) )
        index.current += 1
    }

    useEffect(() => {
        animLetter()
        // eslint-disable-next-line
    }, [text])

    return (
        <p style={{...style}}>{textAnim}</p>
    )
}
