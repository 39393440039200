import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, redirect, Navigate /*, Redirect */ } from 'react-router-dom'

import { AppBar } from './components/AppBar'
import { CookieAlert } from './components/CookieAlert'
import { Footer } from './components/Footer'
import { GotoTopBtn } from './components/GotoTopBtn'
import { About } from './screens/About'
import { Atelier } from './screens/Atelier'
import { Contact } from './screens/Contact'
import { CookiesPolicy } from './screens/CookiesPolicy'
import { Films } from './screens/Films'
import { GalleryScreen } from './screens/GalleryScreen'
import { History } from './screens/History'
import { Home } from './screens/Home'
import { LegalNotice } from './screens/LegalNotice'
import { PrivacyPolicy } from './screens/PrivacyPolicy'
import { SalesPoint } from './screens/SalesPoint'
import { Preview } from './screens/Preview'
import { preview2023 } from './models/preview/preview2023'
import { preview2024 } from './models/preview/preview2024'
import { preview2025 } from './models/preview/preview2025'
import { Promo } from './screens/Promo'

const App = () => {
  const [cookieAccepted, setCookieAccepted] = useState(false)
  let paramScreenValue = new URLSearchParams(window.location.search).get("screen")
  console.log("[ P A T H ] - ", window.location.pathname)

  return (
    <Router>
      <AppBar/>

      <Routes>
        <Route exact path="/" element={
          paramScreenValue === 'sales' && window.location.pathname === '/'
          ? <Navigate to="/sales" replace />  // <Redirect to="/sales" /> 
          : paramScreenValue === 'preview2023' && window.location.pathname === '/' 
            ? <Navigate to="/preview2023" replace />  // <Redirect to="/preview2023" replace />
            :  paramScreenValue === 'preview2024' && window.location.pathname === '/' 
              ? <Navigate to="/preview2024" replace />  // <Redirect to="/preview2024" replace />
              : paramScreenValue === 'preview2025' && window.location.pathname === '/' 
                ? <Navigate to="/preview2025" replace />  // <Redirect to="/preview2025" replace />
                : paramScreenValue === 'promo' && window.location.pathname === '/' 
                  ? <Navigate to="/promo" replace />  // <Redirect to="/promo" replace />
                  : <Home />
        }/> 
        <Route path="/about" element={<About />}></Route>
        <Route path="/gallery/:id" element={<GalleryScreen />}></Route>
        <Route path="/films" element={<Films />}></Route>
        <Route path="/history" element={<History />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/sales" element={<SalesPoint />}></Route>
        <Route path="/atelier" element={<Atelier />}></Route>
        <Route path="/legal" element={<LegalNotice />}></Route>
        <Route path="/cookiespolicy" element={<CookiesPolicy />}></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>

        <Route path="/preview2023" element={<Preview data={ preview2023 } title="Preview 2023" />} />
        <Route path="/preview2024" element={<Preview data={ preview2024 } title="Preview 2024" />} />
        <Route path="/preview2025" element={<Preview data={ preview2025 } title="Preview 2025" />} />
        <Route path="/promo" element={<Promo />} />
      </Routes>

      {/*<Route exact path="/:path">
        <Redirect to="/sales" />
      </Route>*/}

      <Footer/>

      <GotoTopBtn />

      { !document.cookie.includes('cookie-accepted') 
        && 
        <CookieAlert onAcceptCookie={ () => setCookieAccepted(!cookieAccepted) }/>}
    </Router>
  )
}

export default App