import React, { useState } from 'react'
import { i18n } from '../i18n/Atelier.trans'
import { lang } from '../i18n/i18n'
import { AtelierForm } from '../components/AtelierForm'

export const Atelier = () => {
    const [showForm, setShowForm] = useState(false)

    return (
        <div style={styles.container}>
            <div style={styles.bg}>
                <img src="/img/atelier/rambla.jpg" alt="" style={{height:'100%'}}/>
            </div>
            <div style={styles.divInfo}>
                <p style={styles.title}>Atelier</p>
                <div style={styles.address}>
                    <b>BALART NÚVIES</b><br/>
                    Rambla de Catalunya, 94, Entlo-1ª<br/>
                    08008 Barcelona<br/><br/>
                    <i class="fa fa-phone"></i> (+34) 932 50 93 15 - <i class="fa fa-whatsapp"></i> (+34) 630 34 47 19 <br/>
                    <i class="fa fa-envelope"></i>  info@balartnuvies.com
                </div>
                <div 
                    style={styles.button}
                    onClick={ () => setShowForm(true) }
                >
                    <img src="/img/atelier/btnBg.png" alt="" style={{width: '100%'}}/>
                    <p style={styles.btnText}>{i18n.BTN_ASK_FOR_A_DATE[lang]}</p>
                </div>
            </div>
            <AtelierForm isVisible={showForm} onClose={() => setShowForm(false)}/>
        </div>
    )
}

const styles = {
    container: {
        //width: window.innerWidth,
        height: window.innerHeight,
        backgroundColor: 'rgb(33, 37, 41)',
        //margin: -50,

    },
    bg: {
        position: 'absolute',
        top: 50,
        height: window.innerHeight,
        width: '100%',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
    },
    divInfo: {
        position: 'absolute',
        width: '100%',
        top: window.innerHeight * 0.5 - 175,
        height: 450,
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    title: {
        width: '100%',
        textAlign: 'center',
        fontSize: 100,

        // gradient: https://codeworkshop.dev/blog/2020-08-13-how-to-add-a-gradient-to-text-with-css/
        background: 'linear-gradient(45deg, gold, #af4261)', // #f3ec78
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        MozBackgroundClip: 'text',
        MozTextFillColor: 'transparent',
        WebkitTextFillColor: 'transparent',
    },
    address: {
        color: 'white',
        textAlign: 'center',
        width: '100%',
    },
    button: {
        position: 'absolute',
        bottom: 40,
        left: '50%',
        marginLeft: -125,
        width: 250,
        cursor: 'pointer',
    },
    btnText: {
        position: 'absolute',
        top: 17,
        width:'100%', 
        textAlign:'center', 
    }
}
