

export const book_city = [
    {
        title:"3556",
        light:"/img/cocktail/fiesta18/a/33-3556.jpg",
        hight:"/img/cocktail/fiesta18/c/33-3556.jpg",
    },
    {
        title:"3557",
        light:"/img/cocktail/fiesta18/a/29-3557.jpg",
        hight:"/img/cocktail/fiesta18/c/29-3557.jpg",
    },
    {
        title:"3558",
        light:"/img/cocktail/fiesta18/a/30A-3558.jpg",
        hight:"/img/cocktail/fiesta18/c/30A-3558.jpg",
    },
    {
        title:"3558",
        light:"/img/cocktail/fiesta18/a/30B-3558.jpg",
        hight:"/img/cocktail/fiesta18/c/30B-3558.jpg",
    },
    {
        title:"3560",
        light:"/img/cocktail/fiesta18/a/31B-3560.jpg",
        hight:"/img/cocktail/fiesta18/c/31B-3560.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/pink1.jpg",
        hight:"/img/cocktail/fiesta18/b/pink1.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/pink2.jpg",
        hight:"/img/cocktail/fiesta18/b/pink2.jpg",
    },
    {
        title:"3560",
        light:"/img/cocktail/fiesta18/a/31A-3560.jpg",
        hight:"/img/cocktail/fiesta18/c/31A-3560.jpg",
    },
    {
        title:"3541",
        light:"/img/cocktail/fiesta18/a/37-3541.jpg",
        hight:"/img/cocktail/fiesta18/c/37-3541.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/rojo1.jpg",
        hight:"/img/cocktail/fiesta18/b/rojo1.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/rojo2.jpg",
        hight:"/img/cocktail/fiesta18/b/rojo2.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/36-3542.jpg",
        hight:"/img/cocktail/fiesta18/c/36-3542.jpg",
    },
    {
        title:"3554",
        light:"/img/cocktail/fiesta18/a/35-3554.jpg",
        hight:"/img/cocktail/fiesta18/c/35-3554.jpg",
    },
    {
        title:"3553",
        light:"/img/cocktail/fiesta18/a/38-3553.jpg",
        hight:"/img/cocktail/fiesta18/c/38-3553.jpg",
    },
    {
        title:"3555",
        light:"/img/cocktail/fiesta18/a/32A-3555.jpg",
        hight:"/img/cocktail/fiesta18/c/32A-3555.jpg",
    },
    {
        title:"3552",
        light:"/img/cocktail/fiesta18/a/18-3552.jpg",
        hight:"/img/cocktail/fiesta18/c/18-3552.jpg",
    },
    {
        title:"3563",
        light:"/img/cocktail/fiesta18/a/13-3563.jpg",
        hight:"/img/cocktail/fiesta18/c/13-3563.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/roses1.jpg",
        hight:"/img/cocktail/fiesta18/b/roses1.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/roses2.jpg",
        hight:"/img/cocktail/fiesta18/b/roses2.jpg",
    },
    {
        title:"3562",
        light:"/img/cocktail/fiesta18/a/14-3562.jpg",
        hight:"/img/cocktail/fiesta18/c/14-3562.jpg",
    },
    {
        title:"3564",
        light:"/img/cocktail/fiesta18/a/15A-3564.jpg",
        hight:"/img/cocktail/fiesta18/c/15A-3564.jpg",
    },
    {
        title:"3564",
        light:"/img/cocktail/fiesta18/a/15B-3564.jpg",
        hight:"/img/cocktail/fiesta18/c/15B-3564.jpg",
    },
    {
        title:"3561a",
        light:"/img/cocktail/fiesta18/a/17-3561a.jpg",
        hight:"/img/cocktail/fiesta18/c/17-3561a.jpg",
    },
    {
        title:"3566",
        light:"/img/cocktail/fiesta18/a/19-3566.jpg",
        hight:"/img/cocktail/fiesta18/c/19-3566.jpg",
    },
    {
        title:"3581",
        light:"/img/cocktail/fiesta18/a/26-3581.jpg",
        hight:"/img/cocktail/fiesta18/c/26-3581.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/green1.jpg",
        hight:"/img/cocktail/fiesta18/b/green1.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/green2.jpg",
        hight:"/img/cocktail/fiesta18/b/green2.jpg",
    },
    {
        title:"3582",
        light:"/img/cocktail/fiesta18/a/27-3582.jpg",
        hight:"/img/cocktail/fiesta18/c/27-3582.jpg",
    },
    {
        title:"3565",
        light:"/img/cocktail/fiesta18/a/21-3565.jpg",
        hight:"/img/cocktail/fiesta18/c/21-3565.jpg",
    },
    {
        title:"3567",
        light:"/img/cocktail/fiesta18/a/20-3567.jpg",
        hight:"/img/cocktail/fiesta18/c/20-3567.jpg",
    },
    {
        title:"3544",
        light:"/img/cocktail/fiesta18/a/23-3544.jpg",
        hight:"/img/cocktail/fiesta18/c/23-3544.jpg",
    },
    {
        title:"3579",
        light:"/img/cocktail/fiesta18/a/25-3579.jpg",
        hight:"/img/cocktail/fiesta18/c/25-3579.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/gold1.jpg",
        hight:"/img/cocktail/fiesta18/b/gold1.jpg",
    },
    {
        title:"3584",
        light:"/img/cocktail/fiesta18/a/04-3584.jpg",
        hight:"/img/cocktail/fiesta18/c/04-3584.jpg",
    },
    {
        title:"3583",
        light:"/img/cocktail/fiesta18/a/22-3583.jpg",
        hight:"/img/cocktail/fiesta18/c/22-3583.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/gold2.jpg",
        hight:"/img/cocktail/fiesta18/b/gold2.jpg",
    },
    {
        title:"3573",
        light:"/img/cocktail/fiesta18/a/42-3573.jpg",
        hight:"/img/cocktail/fiesta18/c/42-3573.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/blue1.jpg",
        hight:"/img/cocktail/fiesta18/b/blue1.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/blue2.jpg",
        hight:"/img/cocktail/fiesta18/b/blue2.jpg",
    },
    {
        title:"3570",
        light:"/img/cocktail/fiesta18/a/41-3570.jpg",
        hight:"/img/cocktail/fiesta18/c/41-3570.jpg",
    },
    {
        title:"3574",
        light:"/img/cocktail/fiesta18/a/24-3574.jpg",
        hight:"/img/cocktail/fiesta18/c/24-3574.jpg",
    },
    {
        title:"3574",
        light:"/img/cocktail/fiesta18/a/06-3578.jpg",
        hight:"/img/cocktail/fiesta18/c/06-3578.jpg",
    },
    {
        title:"3580",
        light:"/img/cocktail/fiesta18/a/05-3580.jpg",
        hight:"/img/cocktail/fiesta18/c/05-3580.jpg",
    },
    {
        title:"3575",
        light:"/img/cocktail/fiesta18/a/11-3575.jpg",
        hight:"/img/cocktail/fiesta18/c/11-3575.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/heaven1.jpg",
        hight:"/img/cocktail/fiesta18/b/heaven1.jpg",
    },
    {
        title:"3577",
        light:"/img/cocktail/fiesta18/a/07-3577.jpg",
        hight:"/img/cocktail/fiesta18/c/07-3577.jpg",
    },
    {
        title:"3576",
        light:"/img/cocktail/fiesta18/a/10-3576.jpg",
        hight:"/img/cocktail/fiesta18/c/10-3576.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/heaven2.jpg",
        hight:"/img/cocktail/fiesta18/b/heaven2.jpg",
    },
    {
        title:"6174",
        light:"/img/cocktail/fiesta18/a/01-6174.jpg",
        hight:"/img/cocktail/fiesta18/c/01-6174.jpg",
    },
    {
        title:"6174",
        light:"/img/cocktail/fiesta18/a/01B-6174.jpg",
        hight:"/img/cocktail/fiesta18/c/01B-6174.jpg",
    },
    {
        title:"6174",
        light:"/img/cocktail/fiesta18/a/01C-6174.jpg",
        hight:"/img/cocktail/fiesta18/c/01C-6174.jpg",
    },
    {
        title:"3568",
        light:"/img/cocktail/fiesta18/a/45-3568.jpg",
        hight:"/img/cocktail/fiesta18/c/45-3568.jpg",
    },
    {
        title:"3569",
        light:"/img/cocktail/fiesta18/a/47A-3569.jpg",
        hight:"/img/cocktail/fiesta18/c/47A-3569.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/marine1.jpg",
        hight:"/img/cocktail/fiesta18/b/marine1.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/marine2.jpg",
        hight:"/img/cocktail/fiesta18/b/marine2.jpg",
    },
    {
        title:"3587",
        light:"/img/cocktail/fiesta18/a/48B-3587.jpg",
        hight:"/img/cocktail/fiesta18/c/48B-3587.jpg",
    },
    {
        title:"3569",
        light:"/img/cocktail/fiesta18/a/47B-3569.jpg",
        hight:"/img/cocktail/fiesta18/c/47B-3569.jpg",
    },
    {
        title:"3587",
        light:"/img/cocktail/fiesta18/a/48A-3587.jpg",
        hight:"/img/cocktail/fiesta18/c/48A-3587.jpg",
    },
    {
        title:"3537",
        light:"/img/cocktail/fiesta18/a/16-3537.jpg",
        hight:"/img/cocktail/fiesta18/c/16-3537.jpg",
    },
    {
        title:"3543",
        light:"/img/cocktail/fiesta18/a/46A-3543.jpg",
        hight:"/img/cocktail/fiesta18/c/46A-3543.jpg",
    },
    {
        title:"3543",
        light:"/img/cocktail/fiesta18/a/46B-3543.jpg",
        hight:"/img/cocktail/fiesta18/c/46B-3543.jpg",
    },
    {
        title:"3539",
        light:"/img/cocktail/fiesta18/a/12-3539.jpg",
        hight:"/img/cocktail/fiesta18/c/12-3539.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/oro1.jpg",
        hight:"/img/cocktail/fiesta18/b/oro1.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/oro2.jpg",
        hight:"/img/cocktail/fiesta18/b/oro2.jpg",
    },
    {
        title:"3549",
        light:"/img/cocktail/fiesta18/a/02A-3549.jpg",
        hight:"/img/cocktail/fiesta18/c/02A-3549.jpg",
    },
    {
        title:"3549",
        light:"/img/cocktail/fiesta18/a/02B-3549.jpg",
        hight:"/img/cocktail/fiesta18/c/02B-3549.jpg",
    },
    {
        title:"6173",
        light:"/img/cocktail/fiesta18/a/08A-6173.jpg",
        hight:"/img/cocktail/fiesta18/c/08A-6173.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/celeste1.jpg",
        hight:"/img/cocktail/fiesta18/b/celeste1.jpg",
    },
    {
        title:"",
        light:"/img/cocktail/fiesta18/a/celeste2.jpg",
        hight:"/img/cocktail/fiesta18/a/celeste2.jpg",
    },
    {
        title:"6173",
        light:"/img/cocktail/fiesta18/a/08B-6173.jpg",
        hight:"/img/cocktail/fiesta18/c/08B-6173.jpg",
    },
    {
        title:"6173",
        light:"/img/cocktail/fiesta18/a/09A-3548.jpg",
        hight:"/img/cocktail/fiesta18/c/09A-3548.jpg",
    },
    {
        title:"3548",
        light:"/img/cocktail/fiesta18/a/09B-3548.jpg",
        hight:"/img/cocktail/fiesta18/c/09B-3548.jpg",
    }

]