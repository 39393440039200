export const preview2025 = [
    {
        title: '2230',
        light: '/img/preview2025/img/2230-bl-pa-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/2230-bl-pa-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '2231',
        light: '/img/preview2025/img/2231-bl-pa-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/2231-bl-pa-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3812',
        light: '/img/preview2025/img/3812-ch-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3812-ch-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3813',
        light: '/img/preview2025/img/3813-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3813-v-teresaripoll-ss25-clip.mp4',
    },   
    {
        title: '3814',
        light: '/img/preview2025/img/3814-ch-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3814-ch-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3814',
        light: '/img/preview2025/img/3814-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3814-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3815',
        light: '/img/preview2025/img/3815-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3815-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3816',
        light: '/img/preview2025/img/3816-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3816-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3817',
        light: '/img/preview2025/img/3817-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3817-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3818',
        light: '/img/preview2025/img/3818-ch-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3818-ch-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3818',
        light: '/img/preview2025/img/3818-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3818-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3819',
        light: '/img/preview2025/img/3819-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3819-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3820',
        light: '/img/preview2025/img/3820-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3820-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3821',
        light: '/img/preview2025/img/3821-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3821-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3822',
        light: '/img/preview2025/img/3822-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3822-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3823',
        light: '/img/preview2025/img/3823-ch-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3823-ch-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3824',
        light: '/img/preview2025/img/3824-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3824-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3825',
        light: '/img/preview2025/img/3825-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3825-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3826',
        light: '/img/preview2025/img/3826-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3826-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3827',
        light: '/img/preview2025/img/3827-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3827-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3828',
        light: '/img/preview2025/img/3828-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3828-v-teresaripoll-ss25-clip.mp4',
    },    
    {
        title: '3829',
        light: '/img/preview2025/img/3829-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3829-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3830',
        light: '/img/preview2025/img/3830-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3830-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3831',
        light: '/img/preview2025/img/3831-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3831-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3832',
        light: '/img/preview2025/img/3832-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3832-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3833',
        light: '/img/preview2025/img/3833-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3833-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3834',
        light: '/img/preview2025/img/3834-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3834-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3835',
        light: '/img/preview2025/img/3835-ch-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3835-ch-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3836',
        light: '/img/preview2025/img/3836-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3836-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3837',
        light: '/img/preview2025/img/3837-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3837-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3838',
        light: '/img/preview2025/img/3838-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3838-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3839',
        light: '/img/preview2025/img/3839-bl-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3839-bl-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3840',
        light: '/img/preview2025/img/3840-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3840-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3841',
        light: '/img/preview2025/img/3841-ch-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3841-ch-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3842',
        light: '/img/preview2025/img/3842-bl-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3842-po-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3843',
        light: '/img/preview2025/img/3843-ch-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3843-ch-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3843',
        light: '/img/preview2025/img/3843-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3843-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3844',
        light: '/img/preview2025/img/3844-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3844-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3845',
        light: '/img/preview2025/img/3845-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3845-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '3846',
        light: '/img/preview2025/img/3846-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/3846-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '6186',
        light: '/img/preview2025/img/6186-a-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/6186-a-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '6186',
        light: '/img/preview2025/img/6186-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/6186-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '6187',
        light: '/img/preview2025/img/6187-a-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/6187-a-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '6188',
        light: '/img/preview2025/img/6188-a-m-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/6188-a-m-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '6188',
        light: '/img/preview2025/img/6188-m-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/6188-m-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7042',
        light: '/img/preview2025/img/7042-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7042-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7043',
        light: '/img/preview2025/img/7043-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7043-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7044',
        light: '/img/preview2025/img/7044-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7044-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7045',
        light: '/img/preview2025/img/7045-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7045-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7046',
        light: '/img/preview2025/img/7046-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7046-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7047',
        light: '/img/preview2025/img/7047-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7047-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7048',
        light: '/img/preview2025/img/7048-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7048-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7049',
        light: '/img/preview2025/img/7049-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7049-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7050',
        light: '/img/preview2025/img/7050-ch-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7050-ch-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7051',
        light: '/img/preview2025/img/7051-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7051-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7052',
        light: '/img/preview2025/img/7052-bl-pa-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7052-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7053',
        light: '/img/preview2025/img/7053-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7053-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7054',
        light: '/img/preview2025/img/7054-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7054-v-teresaripoll-ss25-clip.mp4',
    },
    {
        title: '7055',
        light: '/img/preview2025/img/7055-v-teresaripoll-ss25.jpg',
        video: '/img/preview2025/video/7055-v-teresaripoll-ss25-clip.mp4',
    },
]