

export const book_party_two = [
      // DORAT
    {
        title:"6177-V",
        light:"/img/cocktail/fiesta19/a/47A_19-6177.jpg",
        hight:"/img/cocktail/fiesta19/c/47A_19-6177.jpg",
    },
    {
        title:"6177-A",
        light:"/img/cocktail/fiesta19/a/47B_19-6177.jpg",
        hight:"/img/cocktail/fiesta19/c/47B_19-6177.jpg",
    },

      // PLATA
    {
        title:"2214",
        light:"/img/cocktail/fiesta19/a/17_19-2214.jpg", 
        hight:"/img/cocktail/fiesta19/c/17_19-2214.jpg",
    },
    {
        title:"3599",
        light:"/img/cocktail/fiesta19/a/46_19-3599.jpg", 
        hight:"/img/cocktail/fiesta19/c/46_19-3599.jpg",
    },
    {
        title:"3652",
        light:"/img/cocktail/fiesta19/a/22_19-3652.jpg", 
        hight:"/img/cocktail/fiesta19/c/22_19-3652.jpg",
    },

    {
        title:"3653",
        light:"/img/cocktail/fiesta19/a/44_19-3653.jpg", 
        hight:"/img/cocktail/fiesta19/c/44_19-3653.jpg",
    },
    {
        title:"3662",
        light:"/img/cocktail/fiesta19/a/16_19-3662.jpg", 
        hight:"/img/cocktail/fiesta19/c/16_19-3662.jpg",
    },

      // BLAU BROCAT
    {
        title:"3655",
        light:"/img/cocktail/fiesta19/a/23_19-3655.jpg", 
        hight:"/img/cocktail/fiesta19/c/23_19-3655.jpg",
    },
    {
        title:"3656",
        light:"/img/cocktail/fiesta19/a/28_19-3656.jpg", 
        hight:"/img/cocktail/fiesta19/c/28_19-3656.jpg",
    },
    {
        title:"3655",
        light:"/img/cocktail/fiesta19/a/32_19-3655.jpg", 
        hight:"/img/cocktail/fiesta19/c/32_19-3655.jpg",
    },

      // AIGUA MARINA BROCAT
    {
        title:"3657",
        light:"/img/cocktail/fiesta19/a/21_19-3657.jpg", 
        hight:"/img/cocktail/fiesta19/c/32_19-3655.jpg",
    },
    {
        title:"3659",
        light:"/img/cocktail/fiesta19/a/20_19-3659.jpg", 
        hight:"/img/cocktail/fiesta19/c/20_19-3659.jpg",
    },
    {
        title:"3660",
        light:"/img/cocktail/fiesta19/a/19_19-3660.jpg", 
        hight:"/img/cocktail/fiesta19/c/19_19-3660.jpg",
    },

    {
        title:"3651",
        light:"/img/cocktail/fiesta19/a/18_19-3651.jpg", 
        hight:"/img/cocktail/fiesta19/c/18_19-3651.jpg",
    },
    {
        title:"3644",
        light:"/img/cocktail/fiesta19/a/54_19-3644.jpg", 
        hight:"/img/cocktail/fiesta19/c/54_19-3644.jpg",
    },
    {
        title:"3643",
        light:"/img/cocktail/fiesta19/a/30_19-3643.jpg", 
        hight:"/img/cocktail/fiesta19/c/30_19-3643.jpg",
    },
    {
        title:"3620",
        light:"/img/cocktail/fiesta19/a/34_19-3620.jpg", 
        hight:"/img/cocktail/fiesta19/c/34_19-3620.jpg",
    },
    {
        title:"3619",
        light:"/img/cocktail/fiesta19/a/33_19-3619.jpg", 
        hight:"/img/cocktail/fiesta19/c/33_19-3619.jpg",
    },

    {
        title:"3609",
        light:"/img/cocktail/fiesta19/a/31_19-3609.jpg", 
        hight:"/img/cocktail/fiesta19/c/31_19-3609.jpg",
    },
    {
        title:"3612",
        light:"/img/cocktail/fiesta19/a/38_19-3612.jpg", 
        hight:"/img/cocktail/fiesta19/c/38_19-3612.jpg",
    },
    {
        title:"2212",
        light:"/img/cocktail/fiesta19/a/29_19-2212.jpg", 
        hight:"/img/cocktail/fiesta19/c/29_19-2212.jpg",
    },
    {
        title:"3640",
        light:"/img/cocktail/fiesta19/a/45_19-3640.jpg", 
        hight:"/img/cocktail/fiesta19/c/45_19-3640.jpg",
    },
    {
        title:"3646",
        light:"/img/cocktail/fiesta19/a/53_19-3646.jpg", 
        hight:"/img/cocktail/fiesta19/c/53_19-3646.jpg",
    },

      // VERD
    {
        title:"3650",
        light:"/img/cocktail/fiesta19/a/48_19-3650.jpg", 
        hight:"/img/cocktail/fiesta19/c/48_19-3650.jpg",
    },
    {
        title:"3591",
        light:"/img/cocktail/fiesta19/a/39_19-3591.jpg", 
        hight:"/img/cocktail/fiesta19/c/39_19-3591.jpg",
    },
    {
        title:"3596",
        light:"/img/cocktail/fiesta19/a/40_19-3596.jpg", 
        hight:"/img/cocktail/fiesta19/c/40_19-3596.jpg",
    },

    {
        title:"3663",
        light:"/img/cocktail/fiesta19/a/26_19-3663.jpg", 
        hight:"/img/cocktail/fiesta19/c/26_19-3663.jpg",
    },
    {
        title:"3648",
        light:"/img/cocktail/fiesta19/a/56_19-3648.jpg", 
        hight:"/img/cocktail/fiesta19/c/56_19-3648.jpg",
    },
    {
        title:"3634",
        light:"/img/cocktail/fiesta19/a/57_19-3634.jpg", 
        hight:"/img/cocktail/fiesta19/c/57_19-3634.jpg",
    },
    {
        title:"3633",
        light:"/img/cocktail/fiesta19/a/58_19-3633.jpg", 
        hight:"/img/cocktail/fiesta19/c/58_19-3633.jpg",
    },
    {
        title:"3647",
        light:"/img/cocktail/fiesta19/a/55_19-3647.jpg", 
        hight:"/img/cocktail/fiesta19/c/55_19-3647.jpg",
    }

]