

export const book_50_years = [
    {
        title:"2217",
        light:"/img/cocktail/years50/a/01-2217-CH-PA-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/01-2217-CH-PA-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3699",
        light:"/img/cocktail/years50/a/02-3699-CH-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/02-3699-CH-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3699",
        light:"/img/cocktail/years50/a/03-3699-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/03-3699-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3697",
        light:"/img/cocktail/years50/a/04-3697-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/04-3697-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3694",
        light:"/img/cocktail/years50/a/05-3694-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/05-3694-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3693",
        light:"/img/cocktail/years50/a/06-3693-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/06-3693-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3670",
        light:"/img/cocktail/years50/a/07-3670-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/07-3670-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3665",
        light:"/img/cocktail/years50/a/08-3665-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/08-3665-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"2216",
        light:"/img/cocktail/years50/a/09-2216-BL-F-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/09-2216-BL-F-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3678",
        light:"/img/cocktail/years50/a/10-3678-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/10-3678-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3679",
        light:"/img/cocktail/years50/a/11-3679-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/11-3679-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3722",
        light:"/img/cocktail/years50/a/12-3722-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/12-3722-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3686",
        light:"/img/cocktail/years50/a/13-3686-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/13-3686-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3687",
        light:"/img/cocktail/years50/a/14-3687-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/14-3687-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3683",
        light:"/img/cocktail/years50/a/15-3683-SF-M-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/15-3683-SF-M-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3684",
        light:"/img/cocktail/years50/a/16-3684-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/16-3684-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3685",
        light:"/img/cocktail/years50/a/17-3685-CH-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/17-3685-CH-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3688",
        light:"/img/cocktail/years50/a/18-3688-CH-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/18-3688-CH-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3668",
        light:"/img/cocktail/years50/a/19-3668-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/19-3668-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3673",
        light:"/img/cocktail/years50/a/20-3673-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/20-3673-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3709",
        light:"/img/cocktail/years50/a/21-3709-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/21-3709-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3708",
        light:"/img/cocktail/years50/a/22-3708-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/22-3708-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"6179",
        light:"/img/cocktail/years50/a/23-6179-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/23-6179-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"6179",
        light:"/img/cocktail/years50/a/24-6179-A-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/24-6179-A-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3714",
        light:"/img/cocktail/years50/a/25-3714-M-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/25-3714-M-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3714",
        light:"/img/cocktail/years50/a/26-3714-CH-M-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/26-3714-CH-M-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3714",
        light:"/img/cocktail/years50/a/27-3714-PO-M-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/27-3714-PO-M-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"2218",
        light:"/img/cocktail/years50/a/28-2218-BL-PA-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/28-2218-BL-PA-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3715",
        light:"/img/cocktail/years50/a/29-3715-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/29-3715-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3717",
        light:"/img/cocktail/years50/a/31-3717-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/31-3717-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"6178",
        light:"/img/cocktail/years50/a/32-6178-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/32-6178-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"6178",
        light:"/img/cocktail/years50/a/33-6178-A-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/33-6178-A-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3672",
        light:"/img/cocktail/years50/a/34-3672-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/34-3672-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3666",
        light:"/img/cocktail/years50/a/35-3666-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/35-3666-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3716",
        light:"/img/cocktail/years50/a/36-3716-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/36-3716-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3718",
        light:"/img/cocktail/years50/a/37-3718-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/37-3718-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3720",
        light:"/img/cocktail/years50/a/38-3720-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/38-3720-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3681",
        light:"/img/cocktail/years50/a/39-3681-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/39-3681-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3690",
        light:"/img/cocktail/years50/a/40-3690-TO-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/40-3690-TO-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3680",
        light:"/img/cocktail/years50/a/41-3680-PO-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/41-3680-PO-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3674",
        light:"/img/cocktail/years50/a/42-3674-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/42-3674-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3701",
        light:"/img/cocktail/years50/a/43-3701-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/43-3701-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3703",
        light:"/img/cocktail/years50/a/44-3703-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/44-3703-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3704",
        light:"/img/cocktail/years50/a/45-3704-PO-M-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/45-3704-PO-M-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3705",
        light:"/img/cocktail/years50/a/46-3705-CH-3704-M-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/46-3705-CH-3704-M-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3707",
        light:"/img/cocktail/years50/a/47-3707-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/47-3707-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3706",
        light:"/img/cocktail/years50/a/48-3706-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/48-3706-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3702",
        light:"/img/cocktail/years50/a/49-3702-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/49-3702-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3712",
        light:"/img/cocktail/years50/a/50-3712-PO-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/50-3712-PO-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3711",
        light:"/img/cocktail/years50/a/51-3711-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/51-3711-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3710",
        light:"/img/cocktail/years50/a/52-3710-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/52-3710-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3667",
        light:"/img/cocktail/years50/a/53-3667-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/53-3667-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3721",
        light:"/img/cocktail/years50/a/54-3721-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/54-3721-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3721",
        light:"/img/cocktail/years50/a/55-3721-V-Back-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/55-3721-V-Back-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3669",
        light:"/img/cocktail/years50/a/56-3669-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/56-3669-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3695",
        light:"/img/cocktail/years50/a/57-3695-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/57-3695-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3689",
        light:"/img/cocktail/years50/a/58-3689-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/58-3689-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3692",
        light:"/img/cocktail/years50/a/59-3692-V-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/59-3692-V-TERESA-RIPOLL-SS20.jpg",
    },
    {
        title:"3691",
        light:"/img/cocktail/years50/a/60-3691-CH-BL-F-TERESA-RIPOLL-SS20.jpg",
        hight:"/img/cocktail/years50/c/60-3691-CH-BL-F-TERESA-RIPOLL-SS20.jpg",
    }

]