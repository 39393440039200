import {useState, useEffect} from 'react'

export const useWindowResize = () => {
    const [size, setSize] = useState({width: window.innerWidth, height: window.innerHeight})

    useEffect(() => {
      window.addEventListener('resize', () => {
        var width = window.innerWidth
        if (width < document.documentElement.clientWidth) width = document.documentElement.clientWidth
        var height = window.innerHeight
        if (height < document.documentElement.clientHeight) height = document.documentElement.clientHeight
        setSize({width: width, height: height})
      })
      // eslint-disable-next-line
    }, [])

    return size 
}