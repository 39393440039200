import React, {useState, useEffect} from 'react'
import { historyData } from '../models/historyModel'
import { TextAnim } from '../components/TextAnim'
import * as FA from 'react-fontawesome'
import { useMobile } from '../hooks/useMobile'

export const History = () => {
  const [, setActiveCard] = useState(-1)
  const mobile = useMobile()

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div style={styles.container}>

      <TextAnim 
        text="History" 
        divStyle={{justifyContent:'center'}} 
        textStyle={{fontSize:40, fontWeight:'bold'}}
        colors={['#000', '#333', '#666', '#999', '#ccc']}
      />

      <div style={{...styles.divGrid, paddingLeft: mobile ? 10 : 75, paddingRight: mobile ? 10 : 75}}>
        {
          historyData.map((el, index) => {
              if (!('left' in el)) el.left = '-100%'
              return (
                <div style={{...styles.card, width: mobile ? '98%' : '23%'}}>
                  <h6 style={{fontWeight:'bold', fontSize: 25, paddingTop:5, paddingLeft:5}}>{el.year}</h6>

                  {/* Photo Card */}
                  <figure style={styles.imgFigure}>
                    <img src={el.image} alt={el.alt} style={styles.image}/>

                    <div style={{...styles.imageColorDiv, left:el.left}}>
                      <img src={`img/history/${el.alt}1.jpg`} alt={el.alt} style={styles.image}/>

                      {/* Color Photo */}
                      <div 
                        style={styles.arrowDiv} 
                        onClick={()=>{
                          el.left='0%'
                          setActiveCard(index)
                        }}
                      ><FA name="chevron-right"/></div>
                    </div>

                  </figure>
                  <h5 style={{color:'#444'}}>{el.text}</h5>
                  <p style={{color:'#777'}}>{el.description}</p>
                </div>)
            }
          )
        }
      </div>
    </div>
  )
}


const styles = {
  container: {
    paddingTop: 100,
  },
  divGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 auto',
    //backgroundColor: 'yellow',
    paddingTop: 50,
  },
  card: {
    margin: 8,
    overflow: 'hidden',
    //boxShadow: '1px 1px 3px #ddd',
  },
  image: {
    width: '100%',
  },
  imgFigure: {
    position: 'relative',
  },
  imageColorDiv: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top:0,
    left:'-100%',
    transition: 'left 1s',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  arrowDiv: {
    position: 'absolute',
    top: '50%',
    right: -32,
    backgroundColor: '#eee',
    width: 30,
    height: 30,
    borderRadius: 50,
    boxShadow: '1px 3px 5px #888',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#666',
  },
}
