import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ImageGallery } from '../components/ImageGallery'
import { SectionTitle } from '../components/SectionTitle'
import { BOOK_RAINBOW, BOOK_PARIS, galleries, BOOK_KALEIDOSCOPE, BOOK_DANUBE, BOOK_CHROMA, BOOK_TIMELESS } from '../models/gelleriesModel'
import { ImageZoom } from '../components/ImageZoom'
import { useMobile } from '../hooks/useMobile'
import { DetailScreen } from './DetailScreen'
import { LoadingImg } from '../components/LoadingImg'

export const GalleryScreen = () => {
  const { id } = useParams()
  const gallery = galleries[id]
  const mobile = useMobile()
  const [image, setImage] = useState() // gallery.imgMobile
  const [zoomCard, setZoomCard] = useState('')
  const scroll = useRef({ x: window.scrollX, y: window.scrollY })
  const [loading, setLoading] = useState(true)
  const [animateScreenImage, setAnimateScreenImage] = useState(true)

  console.log(`id: ${id} - BOOK_PARIS: ${BOOK_PARIS} - image: ${image}`)

  useEffect(() => {
    setZoomCard('')
    if (!mobile) window.scrollTo(0,150)
    // change image on change id
    const screenImage = document.getElementById('galleryScreenImage')
    //if (screenImage) screenImage.classList.remove('animate__fadeIn')
    mobile ? setImage(gallery.imgMobile) : setImage(gallery.imgDesktop)
    setAnimateScreenImage(true)
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    const newImage = mobile ? gallery.imgMobile : gallery.imgDesktop
    if (image !== newImage) setImage(newImage)
  }, [mobile, gallery])

  useEffect(() => {
    setLoading(true)
  }, [image])

  const onZoomImage = (card) => {
    scroll.current = { x: window.scrollX, y: window.scrollY }
    setAnimateScreenImage(false)
    setZoomCard(card)
  }

  const onImageLoaded = (ev) => {
    console.log("onImageLoaded", ev.target)
    setLoading(false)
  }
  
  return (  //   animate__delay-1s
    zoomCard === ''
      ? <div style={styles.container}>
        {loading  && <LoadingImg/>}
        <div style={styles.fixed}>
          <img
            id="galleryScreenImage"
            style={{ width: '100%', visibility: loading ? "visible" : "visible" }}
            src={image}
            alt={gallery.title}
            onLoad={ onImageLoaded}
            className={ animateScreenImage  ? "animate__animated animate__pulse" : "" }
          />
        </div>

        <img style={{ width: '100%', visibility: 'hidden' }} src={image} alt={gallery.title} onLoad={ onImageLoaded}/>

        <div style={styles.body}>
          <SectionTitle title={gallery.title} />
          {!loading && <ImageGallery book={gallery.book} setZoomImageUrl={onZoomImage} />}
        </div>
      </div>
      : `${id}` === `${BOOK_PARIS}` || 
        `${id}` === `${BOOK_RAINBOW}` || 
        `${id}` === `${BOOK_KALEIDOSCOPE}` || 
        `${id}` === `${BOOK_DANUBE}` || 
        `${id}` === `${BOOK_CHROMA}` || 
        `${id}` === `${BOOK_TIMELESS}`
        ? <DetailScreen card={zoomCard} scroll={scroll.current}  onClose={() => {setZoomCard('')}}/>
        : <ImageZoom card={zoomCard} scroll={scroll.current} onClick={() => setZoomCard('')} />
  )
}


const styles = {
  container: {
    paddingTop: 55,
  },
  fixed: {
    position: 'fixed',
    width: '100%',
    top: 55,
    left: 0,
    zIndex: -1,  // <-- necessari
  },
  body: {
    backgroundColor: '#fff',
    boxShadow: '-3px -3px 10px #ccc',
    minHeight: 500,
  }
}