import React from 'react'
import { useMobile } from '../hooks/useMobile'
import { DetailDesktop } from '../components/DetailDesktop'
import { DetailMobileSwipe } from '../components/DetailMobileSwipe'

export const DetailScreen = ({card, scroll, onClose}) => {
    const mobile = useMobile()

    const localOnClose = () => {
        onClose()
        setTimeout(() => {
            console.log(`x: ${scroll.x}, y: ${scroll.y}`)
            window.scrollTo(scroll.x, scroll.y)
        }, 200)
    }

    return (
        <div style={{marginTop:50}}>
            {
                mobile 
                ? <DetailMobileSwipe card={card} onClose={localOnClose} />
                : <DetailDesktop card={card} onClose={localOnClose}/>
            }
        </div>
    )
}

