import React, { useState } from 'react'
import { useMobile } from '../hooks/useMobile'
import { lang, getCountryByLang } from '../i18n/i18n'
import { salesPointT } from '../i18n/SalesPoint.trans'
import { salesPointModel } from '../models/salesPointModel'

export const SalesPoint = () => {
    const mobile = useMobile()
    const [country, setCountry] = useState( getCountryByLang() )
    const [region, setRegion] = useState(0)

    return (
        <div style={{
            ...styles.container,
            padding: mobile ? 10 : 75,
            flexDirection: mobile ? 'column' : 'row',
            alignItems: mobile ? 'center' : 'stretch',
        }}>

            {/* L e f t   P a n e l */}
            <div style={{
                ...styles.panelLeft,
                width: mobile ? 300 : '30%',
            }}>
                <div style={{width:'100%'}}>
                    <p style={{marginTop:100, fontSize:30,}}>{salesPointT.SALES_POINTS[lang]}</p>

                    <p style={styles.label}>Country</p>

                    <select style={styles.combo} onChange={ (e) => {setRegion(0); setCountry(e.target.value)} } value={country}>
                        {
                            Object.keys(salesPointModel).map( country=> <option value={country}>{country}</option>)
                        }
                    </select>

                    <p style={styles.label}>Region</p>

                    {
                        country !== '' && 
                        <select style={styles.combo} onChange={ (e) => setRegion(parseInt(e.target.value)) }>
                            {
                                salesPointModel[country].map( (region, index) => <option value={index}>{region.NAME}</option>)
                            }
                        </select>
                    }

                    <p style={styles.label}>Shops</p>

                    {
                        region >= 0 &&
                        salesPointModel[country][region]["SHOPS"].map( shop => 
                            <div style={styles.shopCard}>
                                <p style={styles.shopCardTitle}>{shop.NAME}</p>
                                {
                                    shop.ADDRESS.split('<br>').map( el => <p style={styles.shopCardBody}>{el}</p>)
                                }
                            </div>
                        )
                    }
                </div>
            </div>


            {/* R i g h t   p a n e l */}
            <div style={{
                ...styles.panelRight,
                padding: mobile ? 10 : 75,
                paddingRight: mobile ? 10 : 0,
                width: mobile ? '95%': '70%',
            }}>
                <iframe 
                    src={salesPointModel[country][region]["MAP"]} 
                    title="map"
                    style={{
                        ...styles.iframe,
                        height: mobile ? 300 : 500
                    }} 
                ></iframe>
            </div>

        </div>
    )
}


const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        //backgroundColor: 'pink',
        maxWidth: 1500,
        margin: '0 auto',
    },

    panelLeft: {
        display: 'flex',
        justifyContent: 'center',
        //backgroundColor: 'orange',
    },
    combo: {
        width: '100%',
        //lineHeight: '80px',
        height: 40,
        fontSize: 15,
        webkitAppearance: 'menulist-button',
    },
    shopCard: {
        boxShadow: "1px 1px 3px #bbb",
        padding: 10,
        marginBottom: 10,
    },
    shopCardTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        margin: 0,
        color: '#333',
    },
    shopCardBody: {
        fontSize: 13,
        margin: 0,
        padding: 0,
        color: '#666',
    },

    panelRight: {
        display: 'flex',
        justifyContent: 'center',
        //backgroundColor: 'green',
    },
    iframe: {
        width: '100%',
    },
    label: {
        marginTop: 20
    }
}
