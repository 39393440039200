export const cookiesPolicyT = {
    "COOKIES_POLICY": {
      "ca": "Política de cookies", 
      "es": "Política de cookies", 
      "en": "Cookies policy",
      "fr": "Politique de cookies",
      "it": "Politica sui cookie"
    },
    "COOKIES_POLICY_DESC": {
      "ca": "Una galeta és un petit fitxer de text que s'emmagatzema en el seu navegador quan visita gairebé qualsevol pàgina web. La seva utilitat és que el web sigui capaç de recordar la seva visita quan torni a navegar per aquesta pàgina. Les galetes solen emmagatzemar informació de caràcter tècnic, preferències personals, personalització de continguts, estadístiques d'ús, enllaços a xarxes socials, accés a comptes d'usuari, etc. L'objectiu de la galeta és adaptar el contingut de la web al seu perfil i necessitats, sense cookies els serveis oferts per qualsevol pàgina es veurien minvats notablement. Si voleu consultar més informació sobre què són les galetes, què s'emmagatzemen, com eliminar-les, desactivar-les, etc., li preguem es dirigeixi a aquest enllaç.", 
      "es": "Una cookie es un pequeño fichero de texto que se almacena en su navegador cuando visita casi cualquier página web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa página. Las cookies suelen almacenar información de carácter técnico, preferencias personales, personalización de contenidos, estadísticas de uso, enlaces a redes sociales, acceso a cuentas de usuario, etc. El objetivo de la cookie es adaptar el contenido de la web a su perfil y necesidades, sin cookies los servicios ofrecidos por cualquier página se verían mermados notablemente. Si desea consultar más información sobre qué son las cookies, qué almacenan, cómo eliminarlas, desactivarlas, etc., le rogamos se dirija a este enlace.", 
      "en": "A cookie is a small text file that is stored in your browser when you visit almost any web page. Its usefulness is that the web is able to remember your visit when you return to browse that page. Cookies usually store information of a technical nature, personal preferences, personalization of content, usage statistics, links to social networks, access to user accounts, etc. The purpose of the cookie is to adapt the content of the web to your profile and needs, without cookies the services offered by any page would be significantly reduced. If you wish to consult more information about what cookies are, what they store, how to eliminate them, deactivate them, etc., please go to this link.",
      "fr": "Un cookie est un petit fichier texte qui est stock&eacute; dans votre navigateur lorsque vous visitez presque toutes les pages Web. Son utilit&eacute; est que le Web est capable de m&eacute;moriser votre visite lorsque vous revenez sur cette page. Les cookies stockent g&eacute;n&eacute;ralement des informations de nature technique, pr&eacute;f&eacute;rences personnelles, personnalisation de contenu, statistiques d'utilisation, liens vers des r&eacute;seaux sociaux, acc&egrave;s &agrave; des comptes d'utilisateurs, etc. Le but du cookie est d'adapter le contenu du Web &agrave; votre profil et &agrave; vos besoins, sans les cookies, les services offerts par toutes les pages seraient consid&eacute;rablement r&eacute;duits. Si vous souhaitez consulter plus d'informations sur ce que sont les cookies, ce qu'ils stockent, comment les supprimer, les d&eacute;sactiver, etc., veuillez cliquer sur ce lien.",
      "it": "Un cookie è un piccolo file di testo che viene memorizzato nel tuo browser quando visiti quasi tutte le pagine web. La sua utilità è che il web è in grado di ricordare la tua visita quando torni a sfogliare quella pagina. I cookie di solito memorizzano informazioni di natura tecnica, preferenze personali, personalizzazione del contenuto, statistiche di utilizzo, collegamenti ai social network, accesso agli account utente, ecc. Lo scopo del cookie è di adattare il contenuto del web al tuo profilo e alle tue esigenze, senza i cookie i servizi offerti da qualsiasi pagina verrebbero notevolmente ridotti. Se desideri consultare maggiori informazioni su cosa sono i cookie, cosa memorizzano, come eliminarli, disattivarli, ecc., Vai a questo link."
    },
    "COOKIES_USED": {
      "ca": "Cookies utilizades en aquesta pàgina web", 
      "es": "Cookies utilizadas en este sitio web", 
      "en": "Cookies used on this website",
      "fr": "Cookies utilis&eacute;s sur ce site",
      "it": "Cookie utilizzati su questo sito"
    },   
    "COOKIES_USED_DESC": {
      "ca": "Seguint les directrius de l'Agència Espanyola de Protecció de Dades procedim a detallar l'ús de galetes que fa aquesta web per tal d'informar-lo amb la màxima exactitud possible. Aquest lloc web utilitza les galetes següents pròpies: Galetes de sessió, per garantir que els usuaris que escriguin comentaris al blog siguin humans i no aplicacions automatitzades. D'aquesta manera es combat el correu brossa. Aquest lloc web utilitza les següents galetes de tercers: Google Analytics: Emmagatzema cookies per poder elaborar estadístiques sobre el trànsit i volum de visites d'aquest web. En utilitzar aquest lloc web està consentint el tractament d'informació sobre vostè per Google. Per tant, l'exercici de qualsevol dret en aquest sentit haurà de fer comunicant directament amb Google. Xarxes socials: Cada xarxa social utilitza les seves pròpies galetes per tu punxar en botons del tipus M'agrada o Compartir.", 
      "es": "Siguiendo las directrices de la Agencia Española de Protección de Datos procedemos a detallar el uso de cookies que hace esta web con el fin de informarle con la máxima exactitud posible. Este sitio web utiliza las siguientes cookies propias: Cookies de sesión, para garantizar que los usuarios que escriban comentarios en el blog sean humanos y no aplicaciones automatizadas. De esta forma se combate el spam. Este sitio web utiliza las siguientes cookies de terceros: Google Analytics: Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deberá hacerlo comunicando directamente con Google. Redes sociales: Cada red social utiliza sus propias cookies para que usted pueda pinchar en botones del tipo Me gusta o Compartir.", 
      "en": "Following the guidelines of the Spanish Agency for Data Protection we proceed to detail the use of cookies made by this website in order to inform you as accurately as possible. This website uses the following own cookies: Session cookies, to ensure that users who write comments on the blog are human and not automated applications. In this way, spam is fought. This website uses the following third-party cookies: Google Analytics: Stores cookies to be able to compile statistics on the traffic and volume of visits to this website. By using this website you are consenting to the processing of information about you by Google. Therefore, the exercise of any right in this sense must do so by communicating directly with Google. Social networks: Each social network uses its own cookies so that you can click on Like or Share buttons.",
      "fr": "Conform&eacute;ment aux directives de l'Agence espagnole pour la protection des donn&eacute;es, nous d&eacute;taillons l'utilisation des cookies de ce site Web afin de vous informer le plus pr&eacute;cis&eacute;ment possible. Ce site utilise les propres cookies suivants: Les cookies de session, afin de garantir que les utilisateurs qui &eacute;crivent des commentaires sur le blog sont des applications humaines et non automatis&eacute;es. De cette fa&ccedil;on, le spam est combattu. Ce site Web utilise les cookies tiers suivants: Google Analytics: stocke les cookies pour pouvoir &eacute;tablir des statistiques sur le trafic et le volume de visites sur ce site. En utilisant ce site Web, vous consentez express&eacute;ment au traitement des informations vous concernant par Google. Par cons&eacute;quent, l'exercice de tout droit en ce sens doit le faire en communiquant directement avec Google. R&eacute;seaux sociaux: chaque r&eacute;seau social utilise ses propres cookies pour que vous puissiez cliquer sur les boutons J'aime ou Partager.",
      "it": "Seguendo le linee guida dell'Agenzia spagnola per la protezione dei dati, procediamo a dettagliare l'uso dei cookie effettuati da questo sito Web per informarti il più accuratamente possibile. Questo sito Web utilizza i seguenti cookie: Cookie di sessione, per garantire che gli utenti che scrivono commenti sul blog siano applicazioni umane e non automatizzate. In questo modo si combatte lo spam. Questo sito Web utilizza i seguenti cookie di terze parti: Google Analytics: memorizza i cookie per essere in grado di compilare statistiche sul traffico e sul volume delle visite a questo sito web. Utilizzando questo sito web, acconsenti all'elaborazione di informazioni su di te da parte di Google. Pertanto, l'esercizio di qualsiasi diritto in tal senso deve farlo comunicando direttamente con Google. Social network: ogni social network utilizza i propri cookie in modo da poter fare clic sui pulsanti Mi piace o Condividi."
    }, 
    "ADDITIONAL_NOTES": {
      "ca": "Notes addicionals", 
      "es": "Notas adicionales", 
      "en": "Additional notes",
      "fr": "Notes compl&eacute;mentaires",
      "it": "Note aggiuntive"
    },      
    "ADDITIONAL_NOTES_DESC": {
      "ca": "Ni aquest web ni els seus representants legals es fan responsables ni del contingut ni de la veracitat de les polítiques de privacitat que puguin tenir els tercers esmentats en aquesta política de galetes. Els navegadors web són les eines encarregades d'emmagatzemar les galetes i des d'aquest lloc d'efectuar el seu dret a eliminació o desactivació de les mateixes. Ni aquest web ni els seus representants legals poden garantir la correcta o incorrecta manipulació de les galetes per part dels esmentats navegadors. En alguns casos és necessari instal·lar cookies perquè el navegador no oblidi la seva decisió de no acceptació de les mateixes. En el cas de les galetes de Google Analytics, aquesta empresa emmagatzema les galetes en els servidors als Estats Units i es compromet a no compartir-la amb tercers, excepte en els casos en què sigui necessari per al funcionament del sistema o quan la llei obligui a aquest efecte. Segons Google no guarda la seva adreça IP. Google Inc és una companyia adherida a l'Acord de Port Segur que garanteix que totes les dades transferides seran tractades amb un nivell de protecció d'acord amb la normativa europea. Podeu consultar informació detallada referent a això en aquest enllaç. Si desitja informació sobre l'ús que Google dóna a les galetes li adjuntem aquest altre enllaç. Per a qualsevol dubte o consulta sobre aquesta política de galetes no dubti en comunicar-se amb nosaltres a través de la secció de contacte.", 
      "es": "Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies. Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores. En algunos casos es necesario instalar cookies para que el navegador no olvide su decisión de no aceptación de las mismas. En el caso de las cookies de Google Analytics, esta empresa almacena las cookies en servidores ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. Según Google no guarda su dirección IP. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. Puede consultar información detallada a este respecto en este enlace. Si desea información sobre el uso que Google da a las cookies le adjuntamos este otro enlace. Para cualquier duda o consulta acerca de esta política de cookies no dude en comunicarse con nosotros a través de la sección de contacto.", 
      "en": "Neither this website nor its legal representatives are responsible for the content or the veracity of the privacy policies that may be held by the third parties mentioned in this cookie policy. Web browsers are the tools in charge of storing cookies and from this place you must exercise your right to eliminate or deactivate them. Neither this website nor its legal representatives can guarantee the correct or incorrect manipulation of cookies by the aforementioned browsers. In some cases it is necessary to install cookies so that the browser does not forget its decision of not accepting them. In the case of Google Analytics cookies, this company stores cookies on servers located in the United States and agrees not to share it with third parties, except in cases where it is necessary for the operation of the system or when the law requires such effect. According to Google does not save your IP address. Google Inc. is a company adhering to the Safe Harbor Agreement that guarantees that all transferred data will be treated with a level of protection in accordance with European regulations. You can consult detailed information in this regard on this link. If you want information about the use that Google gives to cookies we attach this other link. For any questions or queries about this cookie policy, please do not hesitate to contact us through the contact section.",
      "fr": "Ni ce site Web ni ses repr&eacute;sentants l&eacute;gaux ne sont responsables du contenu ou de la v&eacute;racit&eacute; des politiques de confidentialit&eacute; pouvant &ecirc;tre appliqu&eacute;es par les tiers mentionn&eacute;s dans cette politique de cookie. Les navigateurs Web sont les outils charg&eacute;s de stocker les cookies. &Agrave; partir de cet endroit, vous devez exercer votre droit de les &eacute;liminer ou de les d&eacute;sactiver. Ni ce site ni ses repr&eacute;sentants l&eacute;gaux ne peuvent garantir la manipulation correcte ou incorrecte des cookies par les navigateurs susmentionn&eacute;s. Dans certains cas, il est n&eacute;cessaire d'installer des cookies pour que le navigateur n'oublie pas sa d&eacute;cision de ne pas les accepter. Dans le cas des cookies de Google Analytics, cette soci&eacute;t&eacute; stocke des cookies sur des serveurs situ&eacute;s aux &Eacute;tats-Unis et s'engage &agrave; ne pas les partager avec des tiers, sauf dans les cas o&ugrave; cela est n&eacute;cessaire au fonctionnement du syst&egrave;me ou lorsque la loi l'exige. Selon Google ne pas enregistrer votre adresse IP. Google Inc. est une soci&eacute;t&eacute; adh&eacute;rant au Safe Harbor Agreement qui garantit que toutes les donn&eacute;es transf&eacute;r&eacute;es seront trait&eacute;es avec un niveau de protection conforme &agrave; la r&eacute;glementation europ&eacute;enne. Vous pouvez consulter des informations d&eacute;taill&eacute;es &agrave; cet &eacute;gard sur ce lien. Si vous souhaitez des informations sur l'utilisation que Google donne aux cookies, nous joignons cet autre lien. Pour toute question ou demande concernant cette politique en mati&egrave;re de cookies, n'h&eacute;sitez pas &agrave; nous contacter via la section contact.",
      "it": "Né questo sito Web, né i suoi rappresentanti legali sono responsabili per il contenuto o la veridicità delle politiche sulla privacy che possono essere detenute dalle terze parti menzionate in questa cookie policy. I browser Web sono gli strumenti per la memorizzazione dei cookie e da questo luogo è necessario esercitare il diritto di eliminarli o disattivarli. Né questo sito Web né i suoi rappresentanti legali possono garantire la corretta o errata manipolazione dei cookie da parte dei suddetti browser. In alcuni casi è necessario installare i cookie in modo che il browser non dimentichi la decisione di non accettarli. Nel caso dei cookie di Google Analytics, questa società memorizza i cookie sui server situati negli Stati Uniti e accetta di non condividerli con terze parti, tranne nei casi in cui è necessario per il funzionamento del sistema o quando la legge richiede tale effetto. Secondo Google non salva il tuo indirizzo IP. Google Inc. è una società aderente all'accordo Safe Harbor che garantisce che tutti i dati trasferiti saranno trattati con un livello di protezione conforme alle normative europee. È possibile consultare informazioni dettagliate in merito a questo link. Se desideri informazioni sull'uso che Google dà ai cookie, alleghiamo questo altro link. Per qualsiasi domanda o domanda riguardante questa politica sui cookie, non esitare a contattarci attraverso la sezione contatti."
    },      
  }