
export const bookn_elegance = [
    {
        light:"/img/wedding/novia17/a/8029.jpg",   
        hight:"/img/wedding/novia17/c/8029.jpg",   
        title:"8029"
    },
    {
        light:"/img/wedding/novia17/a/8029A.jpg",  
        hight:"/img/wedding/novia17/c/8029A.jpg",  
        title:"8029"
    },
    {
        light:"/img/wedding/novia17/a/8030.jpg",   
        hight:"/img/wedding/novia17/c/8030.jpg",   
        title:"8030"
    },
    {
        light:"/img/wedding/novia17/a/8030A.jpg",  
        hight:"/img/wedding/novia17/c/8030A.jpg",  
        title:"8030"
    },
    {
        light:"/img/wedding/novia17/a/8031.jpg",   
        hight:"/img/wedding/novia17/c/8031.jpg",   
        title:"8031"
    },
    {
        light:"/img/wedding/novia17/a/8031A.jpg",  
        hight:"/img/wedding/novia17/c/8031A.jpg",  
        title:"8031"
    },
    {
        light:"/img/wedding/novia17/a/b.jpg",      
        hight:"/img/wedding/novia17/b/b.jpg",      
        title:""
    },
    {
        light:"/img/wedding/novia17/a/8032.jpg",   
        hight:"/img/wedding/novia17/c/8032.jpg",   
        title:"8032"
    },
    {
        light:"/img/wedding/novia17/a/8032A.jpg",  
        hight:"/img/wedding/novia17/c/8032A.jpg",  
        title:"8032"
    },
    {
        light:"/img/wedding/novia17/a/8033.jpg",   
        hight:"/img/wedding/novia17/c/8033.jpg",   
        title:"8033"},
    {
        light:"/img/wedding/novia17/a/8033A.jpg",  
        hight:"/img/wedding/novia17/c/8033A.jpg",  
        title:"8033"
    },
    {
        light:"/img/wedding/novia17/a/8033B.jpg",  
        hight:"/img/wedding/novia17/c/8033B.jpg",  
        title:"8033"
    },
    {
        light:"/img/wedding/novia17/a/8035.jpg",   
        hight:"/img/wedding/novia17/c/8035.jpg",   
        title:"8035"
    },
    {
        light:"/img/wedding/novia17/a/d.jpg",      
        hight:"/img/wedding/novia17/b/d.jpg",      
        title:""
    },
    {
        light:"/img/wedding/novia17/a/8035A.jpg",  
        hight:"/img/wedding/novia17/c/8035A.jpg",  
        title:"8035"
    },
    {
        light:"/img/wedding/novia17/a/8035B.jpg",  
        hight:"/img/wedding/novia17/c/8035B.jpg",  
        title:"8035"
    },
    {
        light:"/img/wedding/novia17/a/8034.jpg",   
        hight:"/img/wedding/novia17/c/8034.jpg",   
        title:"8034"
    },
    {
        light:"/img/wedding/novia17/a/8034A.jpg",  
        hight:"/img/wedding/novia17/c/8034A.jpg",  
        title:"8034"
    },
    {
        light:"/img/wedding/novia17/a/8036.jpg",   
        hight:"/img/wedding/novia17/c/8036.jpg",   
        title:"8036"
    },
    {
        light:"/img/wedding/novia17/a/8036A.jpg",  
        hight:"/img/wedding/novia17/c/8036A.jpg",  
        title:"8036"
    },
    {
        light:"/img/wedding/novia17/a/8037.jpg",   
        hight:"/img/wedding/novia17/c/8037.jpg",   
        title:"8037"
    },
    {
        light:"/img/wedding/novia17/a/8037B.jpg",  
        hight:"/img/wedding/novia17/c/8037B.jpg",  
        title:"8037"
    },
    {
        light:"/img/wedding/novia17/a/a.jpg",      
        hight:"/img/wedding/novia17/b/a.jpg",      
        title:""
    },
    {
        light:"/img/wedding/novia17/a/8037A.jpg",  
        hight:"/img/wedding/novia17/c/8037A.jpg",  
        title:"8037"
    },
    {
        light:"/img/wedding/novia17/a/8038.jpg",   
        hight:"/img/wedding/novia17/c/8038.jpg",   
        title:"8038"
    },
    {
        light:"/img/wedding/novia17/a/8038A.jpg",  
        hight:"/img/wedding/novia17/c/8038A.jpg",  
        title:"8038"
    },
    {
        light:"/img/wedding/novia17/a/8038B.jpg",  
        hight:"/img/wedding/novia17/c/8038B.jpg",  
        title:"8038"
    },
    {
        light:"/img/wedding/novia17/a/8041.jpg",   
        hight:"/img/wedding/novia17/c/8041.jpg",   
        title:"8041"
    },
    {
        light:"/img/wedding/novia17/a/8041A.jpg",  
        hight:"/img/wedding/novia17/c/8041A.jpg",  
        title:"8041"
    },
    {
        light:"/img/wedding/novia17/a/c.jpg",      
        hight:"/img/wedding/novia17/b/c.jpg",      
        title:""
    },
    {
        light:"/img/wedding/novia17/a/8040.jpg",   
        hight:"/img/wedding/novia17/c/8040.jpg",   
        title:"8040"
    },
    {
        light:"/img/wedding/novia17/a/8040A.jpg",  
        hight:"/img/wedding/novia17/c/8040A.jpg",  
        title:"8040"
    },
    {
        light:"/img/wedding/novia17/a/8040B.jpg",  
        hight:"/img/wedding/novia17/c/8040B.jpg",  
        title:"8040"
    },
    {
        light:"/img/wedding/novia17/a/8042.jpg",   
        hight:"/img/wedding/novia17/c/8042.jpg",   
        title:"8042"
    },
    {
        light:"/img/wedding/novia17/a/8042A.jpg",  
        hight:"/img/wedding/novia17/c/8042A.jpg",  
        title:"8042"
    },
    {
        light:"/img/wedding/novia17/a/8043.jpg",   
        hight:"/img/wedding/novia17/c/8043.jpg",   
        title:"8043"
    },
    {
        light:"/img/wedding/novia17/a/8043A.jpg",  
        hight:"/img/wedding/novia17/c/8043A.jpg",  
        title:"8043"
    },
    {
        light:"/img/wedding/novia17/a/8044.jpg",   
        hight:"/img/wedding/novia17/c/8044.jpg",   
        title:"8044"
    },
    {
        light:"/img/wedding/novia17/a/8044A.jpg",  
        hight:"/img/wedding/novia17/c/8044A.jpg",  
        title:"8044"
    },
    {
        light:"/img/wedding/novia17/a/e.jpg",      
        hight:"/img/wedding/novia17/b/e.jpg",      
        title:"8044"
    }

]