import React, { useEffect } from 'react'
import { legalNoticeT } from '../i18n/LegalNotice.trans'
import { lang } from '../i18n/i18n'

export const LegalNotice = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <div style={styles.container}>
            <br/><br/>
            <h3 className={styles.legalbig}>{legalNoticeT.AVISO_LEGAL_SITE[lang]}</h3><br/><br/>
            <p>{legalNoticeT.WELCOME[lang]}</p><br/><br/>
            <h3 className={styles.legalmedium}>{legalNoticeT.IDENTIFICATION[lang]}</h3><br/>
            <p>{legalNoticeT.IDENTIFICATION_DESC[lang]}</p><br/><br/>
            <h3 className={styles.legalmedium}>{legalNoticeT.NORMAS[lang]}</h3><br/>
            <p>{legalNoticeT.NORMAS_DESC1[lang]}<br/> 
            {legalNoticeT.NORMAS_DESC2[lang]}</p><br/><br/>
            <h3 className={styles.legalmedium}>{legalNoticeT.INTELECTUAL_PROPERTY[lang]}</h3><br/>
            <p>{legalNoticeT.INTELECTUAL_PROPERTY_DESC1[lang]}<br/> 
            {legalNoticeT.INTELECTUAL_PROPERTY_DESC2[lang]}</p><br/><br/>
            <h3 className={styles.legalmedium}>{legalNoticeT.RESPONSABILITY[lang]}</h3><br/>
            <p>${legalNoticeT.RESPONSABILITY_DESC1[lang]}<br/>
            {legalNoticeT.RESPONSABILITY_DESC2[lang]}<br/>
            {legalNoticeT.RESPONSABILITY_DESC3[lang]}<br/>
            {legalNoticeT.RESPONSABILITY_DESC4[lang]}<br/>
            {legalNoticeT.RESPONSABILITY_DESC5[lang]}<br/>

            {legalNoticeT.RESPONSABILITY_DESC6[lang]}<br/>

            {legalNoticeT.RESPONSABILITY_DESC7[lang]}<br/>

            {legalNoticeT.RESPONSABILITY_DESC8[lang]}<br/> 
            {legalNoticeT.RESPONSABILITY_DESC9[lang]}<br/>
            {legalNoticeT.RESPONSABILITY_DESC10[lang]}</p><br/><br/>
            <h3 className={styles.legalmedium}>{legalNoticeT.PRIVACITY[lang]}</h3><br/>
            <p>{legalNoticeT.PRIVACITY_DESC1[lang]}<br/>
            {legalNoticeT.PRIVACITY_DESC2[lang]}<br/>
            {legalNoticeT.PRIVACITY_DESC3[lang]}<br/>
            {legalNoticeT.PRIVACITY_DESC4[lang]}<br/>
            {legalNoticeT.PRIVACITY_DESC5[lang]}<br/>
            {legalNoticeT.PRIVACITY_DESC6[lang]}</p><br/><br/>
            <h3 className={styles.legalmedium}>{legalNoticeT.COOKIE_USAGE[lang]}</h3><br/>
            <p>{legalNoticeT.COOKIE_USAGE_DESC[lang]}</p><br/><br/>
            <h3 className={styles.legalmedium}>{legalNoticeT.LEGISLATION[lang]}</h3><br/>
            <p>{legalNoticeT.LEGISLATION_DESC[lang]}</p>
        </div>
    )
}


const styles = {
    container: {
        paddingTop: 75,
        paddingLeft: 50,
        paddingRight: 50,
    },
    legalbig: {
    },
    legalmedium: {
    },
}
