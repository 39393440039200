
export const preview2023 = [
    {
        title: '2221',
        light: '/img/preview2023/img/2221-bl-f-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/2221-bl-f-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '2222',
        light: '/img/preview2023/img/2222-bl-pa-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/2222-bl-pa-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '2223',
        light: '/img/preview2023/img/2223-bl-pa-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/2223-bl-pa-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '2224',
        light: '/img/preview2023/img/2224-ch-pa-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/2224-ch-pa-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3758',
        light: '/img/preview2023/img/3758-ch-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3758-ch-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3759',
        light: '/img/preview2023/img/3759-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3759-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3760',
        light: '/img/preview2023/img/3760-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3760-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3761',
        light: '/img/preview2023/img/3761-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3761-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3762',
        light: '/img/preview2023/img/3762-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3762-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3763',
        light: '/img/preview2023/img/3763-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3763-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3764',
        light: '/img/preview2023/img/3764-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3764-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3765',
        light: '/img/preview2023/img/3765-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3765-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3767',
        light: '/img/preview2023/img/3767-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3767-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3768',
        light: '/img/preview2023/img/3768-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3768-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3770',
        light: '/img/preview2023/img/3770-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3770-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3771',
        light: '/img/preview2023/img/3771-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3771-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3772',
        light: '/img/preview2023/img/3772-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3772-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3773',
        light: '/img/preview2023/img/3773-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3773-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3774',
        light: '/img/preview2023/img/3774-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3774-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3775',
        light: '/img/preview2023/img/3775-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3775-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3777',
        light: '/img/preview2023/img/3777-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3777-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3778',
        light: '/img/preview2023/img/3778-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3778-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3780',
        light: '/img/preview2023/img/3780-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3780-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3781',
        light: '/img/preview2023/img/3781-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3781-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3782',
        light: '/img/preview2023/img/3782-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3782-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3783',
        light: '/img/preview2023/img/3783-ch-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3783-ch-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3784',
        light: '/img/preview2023/img/3784-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3784-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3785',
        light: '/img/preview2023/img/3785-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3785-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3786',
        light: '/img/preview2023/img/3786-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3786-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '3787',
        light: '/img/preview2023/img/3787-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/3787-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7014',
        light: '/img/preview2023/img/7014-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7014-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7015',
        light: '/img/preview2023/img/7015-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7015-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7016',
        light: '/img/preview2023/img/7016-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7016-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7017',
        light: '/img/preview2023/img/7017-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7017-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7018',
        light: '/img/preview2023/img/7018-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7018-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7019',
        light: '/img/preview2023/img/7019-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7019-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7020',
        light: '/img/preview2023/img/7020-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7020-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7021',
        light: '/img/preview2023/img/7021-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7021-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7022',
        light: '/img/preview2023/img/7022-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7022-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7023',
        light: '/img/preview2023/img/7023-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7023-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7024',
        light: '/img/preview2023/img/7024-ch-pa-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7024-ch-pa-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7025',
        light: '/img/preview2023/img/7025-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7025-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7026',
        light: '/img/preview2023/img/7026-v-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7026-v-teresaripoll-ss23-clip.mp4',
    },
    {
        title: '7027',
        light: '/img/preview2023/img/7027-bl-pa-teresaripoll-ss23.jpg',
        video: '/img/preview2023/video/7027-bl-pa-teresaripoll-ss23-clip.mp4',
    },

]