

export const book_paris_trans = {
    '2219': {
        'en': 'Flattering two pieces composed by a mikado wrap neck jacket in 3/4 sleeve, and a light ankle length chiffon skirt. The jacket fastens crossover at the front and can be worn as a blouse.',
        'es': 'Favorecedor dos piezas compuesto por una chaqueta de cuello cruzado en mikado y manga 3/4, y una falda ligera de gasa hasta los tobillos. La chaqueta se abrocha cruzado en la parte delantera y se puede usar como blusa.',
        'fr': "Deux pièces flatteuses composées d'une veste en mikado à col cache-cœur à manches 3/4 et d'une jupe en mousseline légère longueur cheville. La veste se ferme en croisé sur le devant et peut être portée comme une blouse.",
        'it': 'Due pezzi lusinghieri composti da una giacca con collo a portafoglio in mikado con manica a 3/4 e una gonna di chiffon leggera alla caviglia. La giacca si chiude ad incrocio sul davanti e può essere indossata come una blusa.', 
        'ca': 'Dues peces compostes per una jaqueta de coll embolcallat de mikado amb màniga 3/4 i una faldilla de gasa lleugera fins al turmell. La jaqueta es corda de forma creuada per la part davantera i es pot portar com a brusa.',
    },
    '2220': {
        'en': 'Two pieces composed by an elegant embroidered crepe blouse in 3/4 sleeve, and crepe trousers. The blouse has hand embroidered stones on the sleeves and at the front, and it is fastened with a zip at the back.',
        'es': 'Dos piezas compuestas por una elegante blusa de crepé bordada en manga 3/4, y un pantalón de crepé. La blusa tiene piedras bordadas a mano en las mangas y en la parte delantera, y se abrocha con una cremallera en la espalda.',
        'fr': "Deux pièces composées d'une élégante blouse en crêpe brodée à manches 3/4, et d'un pantalon en crêpe. La blouse est ornée de pierres brodées à la main sur les manches et sur le devant, et celle ci se ferme dans la dos avec una fermeture eclair invisible.",
        'it': "Due pezzi composti da un'elegante blusa in crepe ricamata con manica a 3/4 e pantaloni in crepe. La blusa ha pietre ricamate a mano sulle maniche e sul davanti, ed è chiusa con zip sul retro.", 
        'ca': "Dues peces compostes per una elegant brusa de crepé brodada de màniga 3/4 i uns pantalons de crep. La brusa té pedres brodades a mà a les mànigues i al davant, i es tanca amb una cremallera a l'esquena.",
    },
    '3725': {
        'en': 'Flattering short pique mikado dress in bougainvillea tones with a wrap-around neckline. This collar is a variation of the iconic Teresa Ripoll collar that highlights the female silhouette, with an embroidered stones detail on it. It has short sleeves and fastens at the back with an invisible zip.',
        'es': 'Bonito vestido corto de pique micado en tonos buganvillas con cuello envolvente. Este cuello es una variación del icónico cuello Teresa Ripoll que resalta la silueta femenina, con un detalle de piedra bordada. Se presenta en manga corta y se abrocha en la espalda con cremallera invisible.',
        'fr': "Robe courte flatteuse en pique mikado dans des tons bougainvilliers avec une encolure cache-cœur fendue. Ce col est une déclinaison de l'iconique col Teresa Ripoll qui met en valeur la silhouette féminine, avec un détail de pierres brodées dessus. Cette robe a des manches courtes et se ferme dans la dos avec una fermeture eclair invisible.",
        'it': "Lusinghiero abito corto in mikado in piquet nei toni del bouganville con scollo avvolgente. Questo colletto è una variante dell'iconico colletto Teresa Ripoll che mette in risalto la silhouette femminile, con un dettaglio di pietre ricamate su di esso. Ha le maniche corte e si chiude sul retro con una zip invisibile.", 
        'ca': "Vestit curt de mikado de piqué afavoridor en tons buganvíl·lies amb coll envolvent. Aquest coll és una variació de l'icònic coll Teresa Ripoll que destaca la silueta femenina, amb un detall de pedres brodades. Té mànigues curtes i es tanca a l'esquena amb una cremallera invisible.",
    },
    '3728': {
        'en': 'Stylish crepe dress draped at the waist in bougainvillea tones. The dress consists of a stole that starts from the shoulder and can be used as a stole, draped pendant or small cloak. It is sleeveless and fastens at the back with an invisible zip.',
        'es': 'Estiloso vestido de crepe drapeado en cintura en tonos buganvillas. El vestido consta de una estola que parte del hombro y puede utilizarse como estola, colgante del drapeado o pequeña capa. Se presenta sin mangas y se abrocha por la espalda con cremallera invisible.',
        'fr': "Élégante robe en crêpe drapée à la taille dans des tons bougainvilliers. La robe se compose d'une étole qui part de l'épaule et peut être utilisée comme étole, pendentif drapé ou petite cape. Il est sans manches et se ferme dans le dos par un zip invisible.",
        'it': "Elegante abito in crepe drappeggiato in vita nei toni del bouganville. L'abito è composto da una stola che parte dalla spalla e può essere utilizzata come stola, pendente drappeggiato o mantella. È senza maniche e si chiude sul retro con una zip invisibile.", 
        'ca': "Estilós vestit de crepe drapejat en cintura en tons buguenvíl·lies. El vestit consta d'una estola que parteix de l'espatlla i es pot utilitzar com a estola, penjant del drapejat o petita capa. Es presenta sense mànigues i es corda per l'esquena amb cremallera invisible.",
    },
    '3729': {
        'en': 'Two-piece cape and dress set. The cape is made up of an upper part that sits on the shoulders in a fantasy embroidered mesh and the rest in fuchsia natural silk chiffon. The strapless dress is made up of a central strip of embroidered mesh and the sides in fuchsia crepe.',
        'es': 'Conjunto de dos piezas de capa y vestido. La capa esta formada por una parte superior que se sienta en los hombros en una malla bordada en fantasía y el resto en gasa de seda natural fucsia. El vestido palabra de honor esta formado por una franja central de malla bordada y los laterales en crepe fucsia.',
        'fr': "Ensemble cape et robe deux pièces. La cape est composée d'une partie supérieure qui repose sur les épaules en maille fantaisie brodée et le reste en mousseline de soie naturelle fuchsia. La robe bustier est composée d'une bande centrale en résille brodée et les côtés en crêpe fuchsia.",
        'it': "Completo in due pezzi con mantello e vestito. La mantella è composta da una parte superiore che si appoggia sulle spalle in una fantasia a rete ricamata e il resto in chiffon di seta naturale fucsia. L'abito senza spalline è composto da una fascia centrale di rete ricamata e dai fianchi in crepe fucsia.", 
        'ca': "Conjunt de dues peces de capa i vestit. La capa està formada per una part superior que s'asseu a les espatlles en una malla brodada en fantasia i la resta en gasa de seda natural fúcsia. El vestit paraula d'honor està format per una franja central de malla brodada i els laterals en crep fúcsia.",
    },
    '3730': {
        'en': 'Sophisticated short dress in coral crepe. The iconic Teresa Ripoll collar and hand-embroidered stone details on the sides and sleeves provide a unique elegance to this dress. The sleeve is 3/4 length and fastens at the back with an invisible zip.',
        'es': 'Sofisticado vestido corto en crepé color coral. El icónico cuello Teresa Ripoll y los detalles de piedras bordadas a mano en costados y mangas proporcionan una elegancia única a este vestido. La manga es de largo 3/4 y se abrocha en la espalda con una cremallera invisible.',
        'fr': "Robe courte raffinée en crêpe corail. Le col emblématique Teresa Ripoll et les détails de pierres brodées à la main sur les côtés et les manches confèrent une élégance unique à cette robe. La manche est de longueur 3/4 et se ferme dans le dos par une fermeture éclair invisible.",
        'it': "Raffinato abito corto in crepe corallo. L'iconico colletto Teresa Ripoll e i dettagli in pietra ricamati a mano sui lati e sulle maniche conferiscono a questo abito un'eleganza unica. La manica è lunga 3/4 e si chiude sul retro con una zip invisibile.", 
        'ca': "Sofisticat vestit curt de crepè color coral. L'icònic coll Teresa Ripoll i els detalls de pedres brodades a mà a costats i mànigues proporcionen una elegància única a aquest vestit. La màniga és de llarg 3/4 i es corda a l'esquena amb una cremallera invisible.",
    },
    '3731': {
        'en': 'Simple long dress with the important Teresa Ripoll neck that covers the shoulders. It comes in short sleeves and red with the detail of the belt and the hanging handkerchief in black.',
        'es': 'Sencillo vestido largo con el importante cuello Teresa Ripoll que cubre los hombros. Se presenta en manga corta y color rojo con el detalle del cinturón y el pañuelo colgante en negro.',
        'fr': "Robe longue simple avec l'important col Teresa Ripoll qui couvre les épaules. Il se décline en manches courtes et rouge avec le détail de la ceinture et le mouchoir suspendu en noir.",
        'it': "Abito lungo semplice con l'importante scollo Teresa Ripoll che copre le spalle. Si presenta a maniche corte e rosso con il dettaglio della cintura e il fazzoletto appeso in nero.", 
        'ca': "Senzill vestit llarg amb l'important coll Teresa Ripoll que cobreix les espatlles. Es presenta en màniga curta i color vermell amb el detall del cinturó i el mocador penjant en negre.",
    },
    '3732': {
        'en': 'Long dress in Pique Mikado with an important wraparound neckline in the shape of a large bow, covering the upper part of the arms. They come in two striking and flattering colors and the skirt has a slight mermaid shape. It fastens at the back with closures at the neck and an invisible zip on the dress.',
        'es': 'Vestido largo en Pique Mikado con importante cuello envolvente en forma de gran lazo, cubriendo la parte alta de los brazos. Se presentan en dos impactantes y favorecedores colores y la falda tiene una ligera forma de sirena. Se abrocha por la espalda con unos cierres en el cuello y cremallera invisible en el vestido.',
        'fr': "Robe longue en Pique Mikado avec un important décolleté cache-cœur en forme de gros nœud, recouvrant la partie supérieure des bras. Ils viennent en deux couleurs frappantes et flatteuses et la jupe a une légère forme de sirène. Elle se ferme dans le dos avec des fermetures au niveau du cou et une fermeture éclair invisible sur la robe.",
        'it': "Abito lungo in Pique Mikado con un importante scollo avvolgente a forma di grande fiocco, che copre la parte superiore delle braccia. Sono disponibili in due colori sorprendenti e lusinghieri e la gonna ha una leggera forma a sirena. Si allaccia sul retro con chiusure al collo e zip invisibile sull'abito.", 
        'ca': "Vestit llarg en Pique Mikado amb important coll envoltant en forma de gran llaç, cobrint la part alta dels braços. Es presenten en dos impactants i afavoridors colors i la faldilla té una lleugera forma de sirena. Es corda per l'esquena amb uns tancaments al coll i cremallera invisible en el vestit.",
    },
    '3733': {
        'en': 'Long crepe dress with gold and silver embroidered appliqués on the shoulders and on the spectacular V-neckline on the back. He fastens at the back with an invisible zip.',
        'es': 'Vestido largo en crepe con aplicaciones bordadas oro y plata en hombros y en el espectacular escote en V de la espalda. Se abrocha por la espalda con una cremallera invisible.',
        'fr': "Robe longue en crêpe avec applications brodées or et argent sur les épaules et sur le spectaculaire décolleté en V dans le dos. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito lungo in crepe con applicazioni ricamate oro e argento sulle spalle e sullo spettacolare scollo a V sulla schiena. Si chiude sul retro con una zip invisibile.", 
        'ca': "Vestit llarg en crep amb aplicacions brodades or i plata a les espatlles i a l'espectacular escot a V de l'esquena. Es corda per l'esquena amb una cremallera invisible.",
    },
    '3734': {
        'en': 'Midi-length dress in turquoise crepe and a transparent mesh embroidered in circles on the top and sleeves. The vertical seams of the dress open up in a fun flared shape at the bottom of the skirt. It fastens at the back with an invisible zip.',
        'es': 'Vestido de largo midi en crepe turquesa y una transparente red bordada en círculos en la parte superior y mangas. Las costuras verticales del vestido se abren en una divertida forma acampanada en la parte inferior de la falda. Se abrocha por la espalda con una cremallera invisible.',
        'fr': "Robe mi-longue en crêpe turquoise et résille transparente brodée de cercles sur le haut et les manches. Les coutures verticales de la robe s'évasent dans une forme évasée amusante au bas de la jupe. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito midi in crepe turchese e rete trasparente ricamata a cerchi sulla parte superiore e sulle maniche. Le cuciture verticali dell'abito si allargano in una divertente forma svasata sul fondo della gonna. Si chiude sul retro con una zip invisibile.", 
        'ca': "Vestit de llarg midí en crep turquesa i una transparent xarxa brodada en cercles a la part superior i mànigues. Les costures verticals del vestit s'obren en una divertida forma acampanada a la part inferior de la falda. Es corda per l'esquena amb una cremallera invisible.",
    },
    '3735': {
        'en': 'Long turquoise crepe dress with gold and silver embroidered appliqués accentuating the waist. Dramatic V-neckline and side slit skirt. It fastens at the back with an invisible zip.',
        'es': 'Vestido largo en crepe turquesa y aplicaciones bordadas en oro y plata acentuando la cintura. Dramático escote en V y corte lateral en la falda. Se abrocha por la parte posterior con una cremallera invisible.',
        'fr': "Robe longue en crêpe turquoise avec applications brodées or et argent soulignant la taille. Encolure en V spectaculaire et jupe fendue sur le côté. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito lungo in crepe turchese con applicazioni ricamate in oro e argento che accentuano la vita. Drammatico scollo a V e gonna con spacco laterale. Si chiude sul retro con una zip invisibile.", 
        'ca': "Vestit llarg en crep turquesa i aplicacions brodades en or i plata accentuant la cintura. Dramàtic escot en V i tall lateral a la falda. Es corda per la part posterior amb una cremallera invisible.",
    },
    '3736': {
        'en': 'Flattering long dress in crepe with a draped front part of the body. Important sweetheart neckline and dropped shoulder that complete the dress exquisitely. It fastens at the back with an invisible zip.',
        'es': 'Favorecedor vestido largo en crepe con la parte delantera del cuerpo drapeada. Importante escote corazón y hombro caido que completan el vestido de forma exquisita. Se abrocha por la parte posterior con una cremallera invisible.',
        'fr': "Robe longue flatteuse en crêpe avec partie avant drapée du corps. Décolleté en cœur important et épaule tombante qui complètent la robe de manière exquise. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito lungo in crêpe con drappeggio sulla parte anteriore del corpo. Importante scollo a cuore e spalla scesa che completano squisitamente l'abito. Si chiude sul retro con una zip invisibile.", 
        'ca': "Afavoridor vestit llarg en crep amb la part davantera del cos drapejada. Important escot  en forma de cor i espatlla caiguda que completen el vestit de forma exquisida. Es corda per la part posterior amb una cremallera invisible.",
    },
    '3737': {
        'en': 'Light and elegant ankle-length dress that combines a crepe and beaded bodice, with natural silk chiffon skirt and sleeves. It fastens at the back with an invisible zip.',
        'es': 'Ligero y elegante vestido tobillero que combina un cuerpo de crepe y pedrería, con la falda y las mangas de gasa de seda natural. Se abrocha por la espalda con una cremallera invisible.',
        'fr': "Robe légère et élégante de longueur cheville qui combine un corsage en crêpe et perles, avec une jupe et des manches en mousseline de soie naturelle. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito leggero ed elegante alla caviglia che abbina un corpetto in crepe e perline, con gonna e maniche in chiffon di seta naturale. Si chiude sul retro con una cerniera invisibile.", 
        'ca': "Lleuger i elegant vestit al turmeller que combina un cos de crep i pedreria, amb la faldilla i les mànigues de gasa de seda natural. Es corda per l'esquena amb una cremallera invisible.",
    },
    '3738': {
        'en': 'Important long dress with the body in mica and the skirt in natural silk chiffon. The greenish tones of the dress are very flattering, as is its iconic Teresa Ripoll neckline with an embroidered stone detail. It is presented in short sleeves and fastens at the back with an invisible zip.',
        'es': 'Importante vestido vestido largo con el cuerpo en micado y la falda en gasa de seda natural. Los tonos verdosos del vestido son muy favorecedores al igual que su icónico cuello Teresa Ripoll con un detalle de piedra bordada. Se presenta en manga corta y se abrocha en la espalda con cremallera invisible.',
        'fr': "Importante robe longue avec le corps en mica et la jupe en mousseline de soie naturelle. Les tons verdâtres de la robe sont très flatteurs, tout comme son encolure emblématique Teresa Ripoll avec un détail de pierres brodées. Il est doté de manches courtes et se ferme dans le dos par une fermeture éclair invisible.",
        'it': "Importante abito lungo con il corpo in mica e la gonna in chiffon di seta naturale. I toni verdastri dell'abito sono molto lusinghieri, così come l'iconica scollatura Teresa Ripoll con un dettaglio di pietre ricamate. Viene fornito con maniche corte e si chiude sul retro con una cerniera invisibile.", 
        'ca': "Important vestit vestit llarg amb el cos en mica i la faldilla en gasa de seda natural. Els tons verdosos del vestit són molt afavoridors igual que el seu icònic coll Teresa Ripoll amb un detall de pedra brodada. Es presenta en màniga curta i es corda a l'esquena amb cremallera invisible.",
    },
    '3739': {
        'en': 'Spectacular midi-length dress in lime-colored mikado piqué. The Teresa Ripoll crossover neckline enhances the neckline and figure, and is finished off with a fantasy flower made of the same fabric. She comes in short sleeves and fastens at the back with an invisible zip.',
        'es': 'Espectacular vestido de largo midi en piqué mikado color lima. El cuello cruzado Teresa Ripoll realza el escote y la figura, y está rematado con una flor de fantasía hecha en el mismo tejido. Se presenta en manga corta y se abrocha por la espalda con una cremallera invisible.',
        'fr': "Spectaculaire robe mi-longue en mikado piqué vert citron. L'encolure croisée Teresa Ripoll met en valeur le décolleté et la silhouette, et se termine par une fleur fantaisie réalisée dans le même tissu. Il est doté de manches courtes et se ferme dans le dos par une fermeture éclair invisible.",
        'it': "Spettacolare abito midi in mikado piqué color lime. Lo scollo incrociato Teresa Ripoll valorizza lo scollo e la figura, ed è rifinito con un fiore fantasia realizzato nello stesso tessuto. Viene fornito con maniche corte e si chiude sul retro con una zip invisibile.", 
        'ca': "Espectacular vestit de llarg midi en piqué mikado color llima. El coll creuat Teresa Ripoll realça l'escot i la figura, i està rematat amb una flor de fantasia feta del mateix teixit. Es presenta en màniga curta i es corda per l'esquena amb una cremallera invisible.",
    },
    '3740': {
        'en': 'Beautiful three-piece set of dress, overskirt and belt made in pink mikado piqué with lime green details. The bodice has a covered shoulder and an important sweetheart neckline. It fastens at the back with an invisible zip.',
        'es': 'Hermoso conjunto tres piezas de vestido, sobrefalda y cinturón hechos en piqué mikado rosa con detalles en verde lima. El cuerpo es de hombro cubierto e importante escote corazón. Se abrocha por la espalda con una cremallera invisible.',
        'fr': "Bel ensemble trois pièces composé d'une robe, d'une surjupe et d'une ceinture en piqué mikado rose avec des détails vert anis. Le corsage a une épaule couverte et un important décolleté en cœur. Il se ferme dans le dos par un zip invisible.",
        'it': "Bellissimo set tre pezzi composto da abito, sopragonna e cintura realizzati in mikado piqué rosa con dettagli verde lime. Il corpetto ha una spalla coperta e un'importante scollatura a cuore. Si chiude sul retro con una cerniera invisibile.", 
        'ca': "Bonic conjunt tres peces de vestit, sobrefaldilla i cinturó fets en piqué mikado rosa amb detalls en verd llima. El cos és d'espatlla coberta i important escot cor. Es corda per l'esquena amb una cremallera invisible.",
    },
    '3741': {
        'en': 'Dress with raglan sleeves and puffed cuffs. Hand-embroidered shoulders with stones in a fantasy reminiscent of tree branches. He fastens at the back with an invisible zip.',
        'es': 'Vestido con manga ranglan y abullonada en puño. Hombros bordados con piedras a mano en una fantasía que recuerda a las ramas de un árbol. Se abrocha por la espalda con una cremallera invisible.',
        'fr': "Robe à manches raglan et poignets bouffants. Epaules brodées de pierres à la main dans une fantaisie rappelant les branches d'un arbre. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito con maniche raglan e polsini a sbuffo. Spalle ricamate con pietre a mano in una fantasia che ricorda i rami di un albero. Si chiude sul retro con una cerniera invisibile.", 
        'ca': "Vestit amb màniga ranglan i abullonada al puny. Espatlles brodades amb pedres a mà en una fantasia que recorda les branques d'un arbre. Es corda per l'esquena amb una cremallera invisible.",
    },
    '3742': {
        'en': 'Green mikado pique dress and jacket set. The Japanese sleeve jacket has a flattering fantasy collar. The simple and elegant cut dress has deep necklines on the front and back, and a pretty belt. The jacket fastens at the front with hooks and the dress at the back with an invisible zip.',
        'es': 'Conjunto de chaqueta y vestido en piqué mikado verde. La chaqueta de manga japonesa tiene un favorecedor cuello de fantasía. El vestido de corte sencillo y elegante tiene escotes importantes delante y en espalda, y un bonito cinturón. La chaqueta se abrocha por la parte delantera con unos corchetes y el vestido por la espalda con una cremallera invisible.',
        'fr': "Ensemble robe et veste en mikado piqué vert. La veste à mancherons a un col fantaisie flatteur. La robe à la coupe simple et élégante possède de profonds décolletés devant et dos, et une jolie ceinture. La veste se ferme à l'avant avec des crochets et la robe à l'arrière avec une fermeture éclair invisible.",
        'it': "Completo abito e giacca in piquet mikado verde. La giacca con maniche giapponesa ha un colletto fantasia lusinghiero. L'abito dal taglio semplice ed elegante ha scollature profonde nella parte anteriore e posteriore e una bella cintura. La giacca si chiude sul davanti con ganci e l'abito sul retro con una cerniera invisibile.", 
        'ca': "Conjunt de jaqueta i vestit amb piqué mikado verd. La jaqueta de màniga japonesa té un afavoridor coll de fantasia. El vestit de tall senzill i elegant té escots importants davant i en esquena, i un bonic cinturó. La jaqueta es corda per la part davantera amb uns gafets i el vestit per l'esquena amb una cremallera invisible.",
    },
    '3743': {
        'en': 'Fresh and glamorous set of blouse and dress. The blouse is a jewel made of embroidered tulle and natural marabou feathers at the bottom and sleeves. The dress is a strapless midi length made in crepe. The blouse fastens at the back with a hook and the dress at the back with an invisible zipper.',
        'es': 'Fresco y glamuroso conjunto de blusa y vestido. La blusa es una joya hecha en tul bordado y pluma de marabú natural en la parte inferior y las mangas. El vestido es un palabra de honor en largo midí hecho en crep. La blusa se abrocha por la espalda con un corchete y el vestido por la espalda con una cremallera invisible.',
        'fr': "Ensemble chemisier et robe frais et glamour. La blouse est un bijou composé de tulle brodé et de plumes de marabout naturel au bas et aux manches. La robe est une longueur midi sans bretelles en crêpe. La blouse se ferme au dos avec un crochet et la robe au dos avec une fermeture éclair invisible.",
        'it': "Fresco e glamour set di camicetta e vestito. La blusa è un gioiello realizzato in tulle ricamato e piume di marabù naturali sul fondo e sulle maniche. L'abito è un midi senza spalline realizzato in crepe.  La blusa si chiude sul retro con un gancio e l'abito sul retro con una cerniera invisibile.", 
        'ca': "Fresc i glamurós conjunt de brusa i vestit. La brusa és una joia feta en tul brodat i ploma de marabú natural a la part inferior i les mànigues. La brusa es corda per la part de l’esquena amb un gafet i el vestit amb una cremallera invisible.",
    },
    '3744': {
        'en': 'Flattering short mikado dress with embroidered appliqués and transparent tulle top and sleeves. The embroidered applications on the waist and upper part of the body are made in floral motifs in a gold tone. The upper part of tulle is fastened at the back with a hook and an invisible zip on the dress.',
        'es': 'Favorecedor vestido corto de mikado con aplicaciones bordadas y parte superior y mangas en tul transparente. Las aplicaciones bordadas e cintura y parte superior del cuerpo estan hechas en motivos florales en tonalidad oro. Se abrocha por la espalda con un corchete la parte superior de tul y con una cremallera invisible en el vestido.',
        'fr': "Robe courte flatteuse en mikado avec applications brodées et haut et manches en tulle transparent. Les applications brodées sur la taille et la partie supérieure du corps sont réalisées en motifs floraux dans un ton doré. La partie supérieure en tulle est fermée dans le dos par un crochet et une fermeture éclair invisible sur la robe.",
        'it': "Lusinghiero abito corto in mikado con applicazioni ricamate e parte superiore e maniche in tulle trasparente. Le applicazioni ricamate sulla vita e sulla parte superiore del corpo sono realizzate con motivi floreali in tonalità oro. La parte superiore in tulle è fissata sul retro con un gancio e una cerniera invisibile sull'abito.", 
        'ca': "Afavoridor vestit curt de mikado amb aplicacions brodades i part superior i mànigues a tul transparent. Les aplicacions brodades a cintura i part superior del cos estan fetes en motius florals en tonalitat or. Es corda per l'esquena amb un gafet a la part superior de tul i amb una cremallera invisible al vestit.",
    },
    '3745': {
        'en': 'Short dress in the shape of a coat with a tie collar Teresa Ripoll. Made in gold-colored mikado piqué, with three-quarter length sleeves with rolled-up cuffs and an evassé skirt, it is a real delight for lovers of elegant cuts. It fastens at the back with an invisible zipper.',
        'es': 'Vestido corto en forma de abrigo con cuello de lazada Teresa Ripoll. Hecho en mikado piqué color oro, manga francesa con puño vuelto y con falda evassé es una autentica delicia para las amantes de los cortes elegantes. Se abrocha por la espalda con una cremallera invisible.',
        'fr': "Robe courte en forme de manteau avec un col cravate Teresa Ripoll. Confectionné en mikado piqué doré, avec des manches trois-quarts à revers retroussés et une jupe évasée, il ravira les amateurs de coupes élégantes. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito corto a forma di cappotto con collo annodato Teresa Ripoll. Realizzata in mikado piqué color oro, con maniche a tre quarti con polsini arrotolati e gonna svasata, è una vera delizia per le amanti dei tagli eleganti. Si chiude sul retro con una cerniera invisibile.", 
        'ca': "Vestit curt en forma d´abric amb coll de llaçada Teresa Ripoll. Fet en mikado piqué color or, màniga francesa amb puny tornat i amb faldilla evassé és una autèntica delícia per a les amants dels talls elegants. Es corda per l'esquena amb una cremallera invisible.",
    },
    '3746': {
        'en': 'Class and simplicity are expressed in this long dress in three-tone crepe. The bodice is crossed, sleeveless and ends in a belt under the chest over a long skirt with a slight shape and a slit on one side. It closes at the back with an invisible zip.',
        'es': 'La clase y la sencillez se expresan en este vestido largo en crepe de tres colores. El cuerpo es cruzado, sin mangas y termina en un cinturón bajo pecho sobre una falda larga con ligera forma y una apertura a un lado. Se cierra por la espalda con una cremallera invisible.',
        'fr': "Classe et simplicité s'expriment dans cette robe longue en crêpe tricolore. Le corsage est croisé, sans manches et se termine par une ceinture sous la poitrine sur une longue jupe légèrement galbée et fendue sur un côté. Il se ferme dans le dos par un zip invisible.",
        'it': "Classe e semplicità si esprimono in questo abito lungo in crepe tricolore. Il corpetto è incrociato, smanicato e termina con una cintura sotto il petto sopra una gonna lunga dalla forma leggera e uno spacco su un lato. Si chiude sul retro con una cerniera invisibile.", 
        'ca': "La classe i la senzillesa s'expressen en aquest vestit llarg en crep de tres colors. El cos és creuat, sense mànigues i acaba en un cinturó sota pit sobre una faldilla llarga amb lleugera forma i una obertura on acaba el creuat del cos. Es tanca per l'esquena amb una cremallera invisible.",
    },
    '3747': {
        'en': 'Feminine long mikado dress with two-tone appliqués imitating water lilies and leaves. The shape of the sides of the body enhances the figure. She closes at the back with an invisible zipper.',
        'es': 'Femenino vestido largo en mikado con aplicaciones en dos tonos imitando lirios de agua y hojas. La forma de los costados del cuerpo realza la figura. Se cierra por la espalda con una cremallera invisible.',
        'fr': "Robe longue féminine en mikado avec applications bicolores imitant les nénuphars et les feuilles. La forme des côtés du corps met en valeur la silhouette. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito lungo in mikado femminile con applicazioni bicolore che imitano ninfee e foglie. La forma dei lati del corpo esalta la figura. Si chiude sul retro con zip invisibile.", 
        'ca': "Femení vestit llarg en mikado amb aplicacions en dos tons imitant lliris daigua i fulles. La forma dels costats del cos realça la figura. Es tanca per l'esquena amb una cremallera invisible.",
    },
    '3748': {
        'en': 'Long dress in pink and printed mikado piqué. The bodice is formed by an important sweetheart neckline and a flattering dropped shoulder. The belt separates the body and a skirt with pleats in the front and pockets. It closes at the back with an invisible zipper.',
        'es': 'Vestido largo en piqué mikado rosa y estampado. El cuerpo esta formado por un importante escote corazón y un favorecedor hombro caido. El cinturón separa el cuerpo y una falda con pliegues en la parte frontal y bolsillos. Se cierra por la espalda con una cremallera invisible.',
        'fr': "Robe longue en piqué de mikado rose et imprimé. Le corsage est formé par un important décolleté en cœur et une épaule tombante flatteuse. La ceinture sépare le corps et une jupe avec des plis à l'avant et des poches. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito lungo in mikado piqué rosa e stampato. Il corpetto è formato da un'importante scollatura a cuore e da una lusinghiera spalla scesa. La cintura separa il corpo e una gonna con pieghe sul davanti e tasche. Si chiude sul retro con zip invisibile.", 
        'ca': "Vestit llarg en piqué mikado rosa i estampat. El cos està format per un important escot cor i un afavoridor espatlla caiguda. El cinturó separa el cos i una faldilla amb plecs a la part frontal i butxaques. Es tanca per l'esquena amb una cremallera invisible.",
    },
    '3750': {
        'en': 'Pink and printed mikado piqué jacket and dress set. The elegant cut and the substantial collar of the jacket enhance the feminine silhouette. The dress is made up of an important sash and a skirt with a puffed fold. The jacket fastens at the front with rhinestone fasteners and the dress at the back with an invisible zip.',
        'es': 'Conjunto de chaqueta y vestido en piqué mikado rosa y estampado. El elegante corte y el importante cuello de la chaqueta realzan la silueta femenina. El vestido está formado por un importante fajín y una falda con pliegue abullonada. La chaqueta se abrocha por la parte delantera con unos cierres de brillantes y el vestido por la espalda con una cremallera invisible.',
        'fr': "Ensemble veste et robe en piqué de mikado rose et imprimé. La coupe élégante et le col important de la veste mettent en valeur la silhouette féminine. La robe est composée d'une importante ceinture et d'une jupe à pli bouffant. La veste se ferme à l'avant avec des attaches en strass et la robe à l'arrière avec une fermeture éclair invisible.",
        'it': "Completo giacca e vestito in mikado piqué rosa e stampato. Il taglio elegante e il collo importante della giacca esaltano la silhouette femminile. L'abito è composto da una fusciacca importante e da una gonna con piega a sbuffo. La giacca si chiude sul davanti con chiusure di strass e l'abito sul retro con una cerniera invisibile.", 
        'ca': "Conjunt de jaqueta i vestit amb piqué mikado rosa i estampat. El tall elegant i l'important coll de la jaqueta realcen la silueta femenina. El vestit està format per un important faixí i una faldilla amb plec abullonada. La jaqueta es corda per la part davantera amb uns tancaments de brillants i el vestit per lʻesquena amb una cremallera invisible.",
    },
    '3751': {
        'en': 'Fantabulous midi dress in pink mikado piqué with natural marabou feather appliqués. The feather and the evasé shape of the skirt with its cuts make it a youthful dress with movement. It fastens at the back with an invisible zip.',
        'es': 'Fantasioso vestido midí en piqué mikado rosa y aplicaciónes de pluma de marabú natural. La pluma y la forma evasé de la falda con sus cortes hacen que sea un vestido juvenil y con movimiento. Se abrocha por la espalda con una cremallera invisible.',
        'fr': "Fantastique robe midi en piqué de mikado rose et applications de plumes de marabout naturel. La plume et la forme évasée de la jupe avec ses découpes en font une robe jeune avec du mouvement. Il se ferme dans le dos par un zip invisible.",
        'it': "Fantastico abito midi in mikado piqué rosa e applicazioni di piume di marabù naturale. La piuma e la forma svasata della gonna con i suoi tagli ne fanno un abito giovanile con movimento. Si chiude sul retro con una cerniera invisibile.", 
        'ca': "Fantasiós vestit midí en piqué mikado rosa i aplicació de ploma de marabú natural. La ploma i la forma evasé de la falda amb els seus talls fan que sigui un vestit juvenil i amb moviment. Es corda per l'esquena amb una cremallera invisible.",
    },
    '3752': {
        'en': 'Incredible short dress in peach crepe. It consists of a short sleeve below a fantasy raglan sleeve embroidered by hand with stones on the shoulders. He fastens at the back with an invisible zip.',
        'es': 'Increible vestido corto en crepe color melocotón. Consta de una manga corta por debajo de una manga ranglan de fantasía bordada a mano con piedras en los hombros. Se abrocha por la espalda con una cremallera invisible.',
        'fr': "Incroyable robe courte en crêpe pêche. Il se compose d'une manche courte en dessous d'une manche raglan fantaisie brodée à la main de pierres sur les épaules. Il se ferme dans le dos par un zip invisible.",
        'it': "Incredibile abito corto in crêpe color pesca. Si compone di una manica corta sotto una manica raglan fantasia ricamata a mano con pietre sulle spalle. Si chiude sul retro con una zip invisibile.", 
        'ca': "Increïble vestit curt en crep color préssec. Consta d'una màniga curta per sota una màniga ranglan de fantasia brodada a mà amb pedres a les espatlles. Es corda per l'esquena amb una cremallera invisible.",
    },
    '3753': {
        'en': 'Jacket and dress set in peach crepe with delicious gold and silver embroidery appliqués. The jacket has a flattering funnel neck and a pleated skirt that highlights the waist. The dress has short sleeves made of embroidered floral motifs and appliqués on the hips. The jacket fastens at the front with rhinestone fasteners and the dress at the back with an invisible zipper.',
        'es': 'Conjunto de chaqueta y vestido en crepe color melocotón con deliciosas aplicaciones de bordado oro y plata. La chaqueta tiene un favorecedor cuello chimenea y un faldón con pliegues que resalta la cintura. El vestido cuenta con unas mangas cortas hechas de motivos florales bordados y unas aplicaciones en la cadera. La chaqueta se abrocha por la parte delantera con unos cierres de brillantes y el vestido por la espalda con una cremallera invisible.',
        'fr': "Ensemble veste et robe en crêpe pêche avec de délicieuses applications de broderies dorées et argentées. La veste a un col cheminée flatteur et une jupe plissée qui souligne la taille. La robe a des manches courtes composées de motifs floraux brodés et d'applications sur les hanches. La veste se ferme à l'avant avec des attaches en strass et la robe à l'arrière avec une fermeture éclair invisible.",
        'it': "Parure giacca e abito in crepe pesca con deliziose applicazioni di ricami dorati e argentati. La giacca ha un lusinghiero collo a imbuto e una gonna a pieghe che mette in risalto la vita. L'abito ha le maniche corte realizzate con motivi floreali ricamati e applicazioni sui fianchi. La giacca si chiude sul davanti con chiusure di strass e l'abito sul retro con una cerniera invisibile.", 
        'ca': "Conjunt de jaqueta i vestit en crep color préssec amb delicioses aplicacions de brodat or i plata. La jaqueta té un afavoridor coll xemeneia i un faldó amb plecs que ressalta la cintura. El vestit compta amb unes mànigues curtes fetes de motius florals brodats i unes aplicacions al maluc. La jaqueta es corda per la part davantera amb uns tancaments de brillants i el vestit per lʻesquena amb una cremallera invisible.",
    },
    '3754': {
        'en': 'Long dress in peach-colored crepe and embroidered appliqués in gold and silver floral motifs. In the part of the waist it has a flattering drape and together with the embroidered applications they stylize the figure. It closes at the back with an invisible zip',
        'es': 'Vestido largo en crepe color melocotón y aplicaciones de bordado en motivos florales oro y plata. En la parte de la cintura tiene un favorecedor drapeado y junto con las aplicaciones bordadas estilizan la figura. Se cierra por la espalda con una cremallera invisible.',
        'fr': "Robe longue en crêpe couleur pêche et applications brodées de motifs floraux dorés et argentés. Dans la partie de la taille, il a un drapé flatteur et, avec les applications brodées, ils stylisent la silhouette. Il se ferme dans le dos par un zip invisible.",
        'it': "Abito lungo in crepe color pesca e applicazioni ricamate a motivi floreali oro e argento. Nella parte della vita ha un drappeggio lusinghiero e insieme alle applicazioni ricamate stilizzano la figura. Si chiude sul retro con zip invisibile.", 
        'ca': "Vestit llarg en crep color préssec i aplicacions de brodat en motius florals or i plata. A la part de la cintura té un afavoridor drapejat i juntament amb les aplicacions brodades estilitzen la figura. Es tanca per l'esquena amb una cremallera invisible.",
    },
    '6180': {
        'en': 'Pink and printed mikado piqué coat and dress set. The coat has a stylish double-breasted cut with printed piqué covered buttons as well as on the cuffs and inside view. The dress consists of a printed sleeveless bodice, a small belt and a plain skirt with stylish double open darts. The coat fastens at the front with rhinestone fasteners and the dress at the back with an invisible zip.',
        'es': 'Conjunto de abrigo y vestido en piqué mikado rosa y estampado. El abrigo tiene un estiloso corte cruzado con botones forrados del piqué estampado como en puños y vista interior. El vestido está formado por un cuerpo sin mangas estampado, un pequeño cinturón y la falda lisa con estilosas pinzas dobles abiertas. El abrigo se abrocha por la parte delantera con unos cierres de brillantes y el vestido por la espalda con una cremallera invisible.',
        'fr': "Ensemble manteau et robe en piqué de mikado rose et imprimé. Le manteau a une coupe croisée élégante avec des boutons recouverts de piqué imprimé ainsi que sur les poignets et la vue intérieure. La robe se compose d'un corsage sans manches imprimé, d'une petite ceinture et d'une jupe unie avec d'élégantes pinces doubles ouvertes. Le manteau se ferme à l'avant avec des attaches à strass et la robe à l'arrière avec une fermeture éclair invisible.",
        'it': "Completo cappotto e vestito rosa e mikado piqué stampato. Il cappotto ha un elegante taglio doppiopetto con bottoni stampati ricoperti in piqué così come sui polsini e vista interna. L'abito è composto da un corpetto stampato senza maniche, una piccola cintura e una gonna semplice con eleganti doppie pinces aperte. Il cappotto si chiude sul davanti con chiusure di strass e l'abito sul retro con una cerniera invisibile.", 
        'ca': "Conjunt d'abric i vestit de piqué mikado rosa i estampat. L'abric té un estilós tall creuat amb botons folrats del piqué estampat com a punys i vista interior. El vestit està format per un cos sense mànigues estampat, un petit cinturó i la faldilla llisa amb pinces dobles obertes. L'abric es corda per la part davantera amb uns tancaments de brillants i el vestit per l'esquena amb una cremallera invisible.",
    }
}

/*
    '': {
        'en': '',
        'es': '',
        'fr': "",
        'it': "", 
        'ca': "",
    },
*/