import React, { useEffect, useState, useRef } from 'react'
import { ImageCardWithLoading } from './ImageCardWithLoading'

export const ImageGallery = ({ book, setZoomImageUrl }) => {
  const [containerWidth, setContainerWidth] = useState(window.innerWidth >= 768 ? '25%' : '50%')
  const padding = useRef(window.innerWidth >= 768 ? '10%' : 10)

  useEffect(() => {
    // change images on resize if need
    window.addEventListener('resize', () => {
      window.innerWidth >= 768 ? padding.current = '10%' : padding.current = 10
      window.innerWidth >= 768 ? setContainerWidth('25%') : setContainerWidth('50%')
    })
    // eslint-disable-next-line
  }, [])

  return (
    <div style={{ ...styles.container, paddingLeft: padding.current, paddingRight: padding.current }}>
      {
        book.map(card => <ImageCardWithLoading key={card.title} card={card} containerWidth={containerWidth} setZoomImageUrl={setZoomImageUrl}/>)
      }
    </div>

  )
}


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
}
