
export var salesPointModel = {

  // UNITED KINGDOM //

  'UNITED KINGDOM': [
    {
      'NAME': 'ENGLAND', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1fnaDjZEZLwxyqXvSLAEvOFIVxZ81qIDi',
      'SHOPS': [
        /*{'NAME': 'Ambers of Amersham', 'ADDRESS': '49, London Road<br>Amersham HP7 9DA<br>Tel. 01494 725623'},
        {'NAME': 'Chameleon', 'ADDRESS': '904 Christchurch Road<br> Boscombe<br> Bournemouth BH7 6DL<br>01202 425721'},
        {'NAME': 'Marys of Enfield', 'ADDRESS': '27 High Street<br> Hoddesdon EN11 8SX<br> 01992 466066'} 
        {'NAME': 'Norma and June', 'ADDRESS': 'Low Row<br>Easington Village SR8 3AU<br>Tel. 0191 5273945'},*/
        {'NAME': 'Gladrags', 'ADDRESS': '57 High Street<br>Ingatestone CM4 0AT<br>Tel. 01277 356633'},
        {'NAME': 'Flamingo', 'ADDRESS': '67 Liverpool Road Penwortham<br>Preston PR1 9XD<br>Tel. 01772 744277'},
        {'NAME': 'Nigel Rayment Boutique', 'ADDRESS': '7-9 Old Bank Street<br>Manchester M2 7PE<br>Tel.0161 834 4633'},
        {'NAME': 'Laura B (Couture) Ltd.', 'ADDRESS': '14-16 Arundel Court Jubilee Place<br>London SW3 3TJ<br>Tel.020 7376 4647'},
        {'NAME': 'Nigel Rayment Boutique', 'ADDRESS': '13 New Quebec Street Marble Arch<br>London W1H 7RR<br>Tel. 020 7258 3331'},
        {'NAME': 'Snooty Frox', 'ADDRESS': '34-36 Hookstone Road<br>Harrogate HG2 8BW<br>Tel. 01423 815320'},
        {'NAME': 'Helen Sykes Fashions Ltd.', 'ADDRESS': '217-219, Stanningley Road<br>Leeds LS12 3PL<br>Tel. 0113 2310610'},
        {'NAME': 'Moda Rosa', 'ADDRESS': '35,West Street<br> Alresford SO24 9AB<br>01962 733277'},
        {'NAME': 'Kim Vine', 'ADDRESS': '92 High Street<br>Marlborough SN8 1HD<br>01672 519937'},
        {'NAME': 'The Wedding Shop', 'ADDRESS': '4 High Street<br>Colchester CO1 1DA<br>01206 767359'},
      ]
    },
    {
      'NAME': 'SCOTLAND', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=17IBiRII8yuODa4wcMIxQb4hRDB96T-GV&ehbc=2E312F',
      'SHOPS': [
        /*{'NAME': 'Celebrations', 'ADDRESS': 'Main Street<br>Turrif AB53 4AD<br>Tel.01888 568111'},
        {'NAME': 'Brodie Countryfare', 'ADDRESS': 'Brodie By Forres<br>Forres IV36 2TD<br>01309 641 555'} */
        {'NAME': 'Frox of Falkirk', 'ADDRESS': '16-18 Newmarket Street<br>Falkirk FK1 1JQ<br>Tel.01324 611350'},
        {'NAME': 'Catherine´s of Partick', 'ADDRESS': '106-114, Dumbarton Rd Partick<br>Glasgow G11 6NY <br>Tel.0141 339 1351'},
      ]
    },
    {
      'NAME': 'WALES', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1E9HNS13SqHoRJEAmKFtk90fV9-A0qI4&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Alison Tod', 'ADDRESS': '13 & 14 Cross Street <br>Abergavenny Monmouthshire NP7 5EH <br>Tel. 01873 855923'},
        {'NAME': 'Pretty Perfect Boutique', 'ADDRESS': '31 Morgan Arcade <br>CF10 1AF Cardiff <br>Tel. 02920 220392'},
        {'NAME': 'Rowberrys', 'ADDRESS': '154 Port Tennant Road<br>Swansea SA1 8JQ <br>Tel.01792 651028'},
        {'NAME': 'Steil Pleser', 'ADDRESS': '26 St Teilo Street<br>Pontarddulais Swansea SA4 8SZ<br>Tel.01792 885208'}  
      ]
    }
  ],  // UNITED KINGDOM //

  // BELGIUM //

  'BELGIUM': [
    /*{
      'NAME': 'Roselare', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1zHpbKwmP_zpiuRL-lGODCvHIsHdbUkJW',
      'SHOPS': [
        {'NAME': 'La Sposa', 'ADDRESS': 'Stationsdreef 182<br>8800 Roselare<br>0032 51222289'}  
      ]
    },*/
    {
      'NAME': 'Antwerpen', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1rtjAq5g683EozLG1uFxWr2QUgGBQTb5A',
      'SHOPS': [
        {'NAME': 'Le Chapeau', 'ADDRESS': 'Frankijklei 72<br>2000 Antwerpen<br>0032 32335045'}  
      ]
    }
  ], // BELGIUM //

  // FRANCE //

  'FRANCE': [
    {
      'NAME': 'La Bretagne', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1yfVyi619K70jH7CcpB2BIWV0F1lXrP8O&usp',
      'SHOPS': [
        {'NAME': 'Le Cachet Royal', 'ADDRESS': '1 avenue Jean Janvier<br>35000 Rennes, La Bretagne<br>+33 2 23 25 65 01'}  
      ]
    },
    {
      'NAME': 'Île-de-France', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1lsCO2QgD5A4Ss1U8VGWjerw3FsuyJoeX&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Sonia L', 'ADDRESS': '66 Rue d\'Amsterdam<br>75009 Paris<br>+33 142 81 03 10'}  
      ]
    }
  ], // FRANCE //
  
  // HOLLAND //

  'HOLLAND': [
    {
      'NAME': 'Borne', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1YiG1PYtufs469dgQb_xol5i48G2nbEtj',
      'SHOPS': [
        {'NAME': 'Leurink Mode', 'ADDRESS': 'Dorsetplein 3<br>7622 CH Borne<br>Tel. 0031 74 2661632'}  
      ]
    }
  ], // HOLLAND //

  // ITALY //

  'ITALY': [
    {
      'NAME': 'Calabria', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=19Ns0TvqKYNMhnpVJpYfDZZQnHckarRkX&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Gaia le Spose', 'ADDRESS': 'Via T. Gulli,13<br>89127 Reggio Calabria<br>Tel. 0965/24222'},  
        {'NAME': 'Corso Mazzini Lab Sposa', 'ADDRESS': 'Via Paul Harris, 7<br>87100 Cosenza<br>Tel. 09/84791602'}  
      ]
    },
    {
      'NAME': 'Campania', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1rstcON0FBNNjnfrgmKgwnIc5qWpypl71',
      'SHOPS': [
        //{'NAME': 'Cherie Mode', 'ADDRESS': 'Via S. Ciro,3  <br>80069 Vico Equense ( Napoli)  <br>Tel. 081/8015595'},  
        {'NAME': 'Passaro Donna', 'ADDRESS': 'C.so Umberto 1, 152 <br>84013 Cava dei Tirreni (Salerno) <br>Tel. 089/442252'},  
        {'NAME': 'Passaro Sposa', 'ADDRESS': 'Emanuele,148 <br>84122 Salerno <br>Tel. 089/224870'},
        {'NAME': 'Parente Moda Stile', 'ADDRESS': 'Via Europa 236-238  <br>80047 San Giuseppe Vesuviano (NAPOLI) <br>Tel. 081/8283555'},
        //{'NAME': 'Atelier Ponzo', 'ADDRESS': 'Piazza Piedigrotta,12 <br>80122 Napoli <br>Tel. 081/7612587'}
        {'NAME': 'Bove', 'ADDRESS': 'Via Copelliano, 18  <br>82030 Torrecuso, Benevento <br>Telf. 082/4874900'},
        //{'NAME': 'Lilli Boutique', 'ADDRESS': 'Via Fontanelle, 109  <br>80053 Castellmmare di Stabia <br>Telf. 081/8743612'},
        {'NAME': 'De Matteo Boutique', 'ADDRESS': 'P.za Nicola Amore, 14-16  <br>80138 Napoli <br>Tel. 081/260582'}
      ]
    },
    /*{
      'NAME': 'Lazio', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=10_zP0biJzqgW1Ed5L9R0UL-p1jQ',
      'SHOPS': [
        {'NAME': 'Mampieri', 'ADDRESS': 'V.le V. Veneto,9<br>00035 Olevano Romano ( ROMA)<br>Tel. 06/9564224'}  
      ]
    },*/
    {
      'NAME': 'Lombardia', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1lilMOAgaoy7oBIrUSMxQZSb-nvZHoSM&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Le Spose di Milano', 'ADDRESS': 'Piazzale Principessa Clotilde, 8<br>20121 Milano<br>Tel. 02/36727248'}  
      ]
    },
    {
      'NAME': 'Puglia', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=10c1xtJRlrRG7GN2BThoCRxxDd1FDDGyQ',
      'SHOPS': [
        //{'NAME': 'Pitti per le Spose', 'ADDRESS': 'Corso Vittorio Emanuele, 3,<br>70122 Bari BA<br>Tel. 080/5244074'},
        //{'NAME': 'Simeone', 'ADDRESS': 'Corso Messapia,49<br> 74015 Martina Franca (Taranto)<br> Tel. 080/4807236'},
        {'NAME': 'Atelier Graffiti', 'ADDRESS': 'Via Ruvo,21 <br>70033 Corato (BA) <br>Tel. 080/8981812'}
      ]
    },
    {
      'NAME': 'Sicilia', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1SOrw0xvyvvQA5kStL9CwHx9Ib2nfaqPM&ehbc=2E312F',
      'SHOPS': [
        /*{'NAME': 'Laros', 'ADDRESS': 'Via Liberta, 33/E<br>90139 Palermo<br>Tel. 091/323363'},*/  
        {'NAME': 'Bonini', 'ADDRESS': 'Via XI Maggio,142<br>91025 Marsala (Trapani)<br>Tel. 0923/951043'},  
        {'NAME': 'Rainbow', 'ADDRESS': 'Via Roma, 200<br>97100 Ragusa (Ragusa)<br>Tel. +39 349 067 5780'},
        {'NAME': 'Helmè', 'ADDRESS': 'Largo dei Vespri,13 <br>95128 Catania <br>Tel. 095/7434861'},
        {'NAME': 'Boutique Timmy', 'ADDRESS': 'C.so Calatafimi, 621/A <br>90129 Palermo <br>Tel. 091/485130'},
      ]
    },
    {
      'NAME': 'Toscana', 
      'MAP': 'https://www.google.com/maps/d/u/1/embed?mid=1Nephssz8-v5ERbfbnvGLZXr4AJNm7sDf&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Le Spose di Mori', 'ADDRESS': 'Via Tosco Romagnola Est, 815 <br>56028 San Miniato PI <br>Tel. +39 0571 418540'},
      ]
    }
  ], // ITALY //

  // IRELAND //

  'IRELAND': [
    {
      'NAME': 'REPUBLIC OF IRELAND', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1Bntxj6-c78wYOIHk67pqjGjwR1z9k7tv',
      'SHOPS': [
        /*{'NAME': 'McElhinney´s Stores', 'ADDRESS': 'Main Street<br>Balleybofey<br>Tel. 00353 74 9131217'},  
        {'NAME': 'Jenny´s Boutique', 'ADDRESS': 'Main Street<br>Dunshaughlin<br>Tel. 00353 1 824 0058'},
        {'NAME': 'Jenny´s the Occasion Specialists', 'ADDRESS': '6 Strand Street<br>Malahide, County Dublin<br>Tel. 00353 1 845 5186'},
        {'NAME': 'MacBees', 'ADDRESS': '25 New Street<br>Killarney, County Kerry<br>Tel. 00353 64 6633622'}
        {'NAME': 'Vanity Fair', 'ADDRESS': 'Unit 3 Castlecourt Shopping Centre<br>Newbridge<br>Tel.00353 866089833'},*/  
        {'NAME': 'Ela Maria', 'ADDRESS': 'The Square House<br>Newcastle West<br>Tel. 00353 69 62855'},  
        {'NAME': 'Don\'t Call Me Dear Boutique', 'ADDRESS': '8 Mill Street<br>Galway, H91 Y0T3<br>Tel. 0035391530760'}
      ]
    },
    {
      'NAME': 'NORTHERN IRELAND', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1NcnbGCLmOcACtv_j1tKVBBns6BsnBeQz&ehbc=2E312F',
      'SHOPS': [
        /*{'NAME': 'Jourdan', 'ADDRESS': '733 Lisburn Road<br>Belfast BT9 7GU<br>Tel. 02890 682944'},*/  
        {'NAME': 'The Fashion House', 'ADDRESS': '1-3 Market Street<br>Magherafelt BT45 6EE<br>Tel. 028 7963 1753'},  
        //{'NAME': 'The Snooty Fox', 'ADDRESS': '55-57 Scotch Street<br>Dungannon BT70 1BD<br>Tel. 028 8772 4502'}
      ]
    }
  ], // IRELAND //

  // LUXEMBOURG //

  'LUXEMBOURG': [
    {
      'NAME': 'Esch sur Alzette', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1k9ZtVF8TkPqvSPBwcDIg91qVP04hh5Gu',
      'SHOPS': [
        {'NAME': 'Maison Felgen', 'ADDRESS': '22 rue Xavier Brasseur<br>4040 Esch sur Alzette<br>Tel.00352531285'}  
      ]
    }
  ], // LUXEMBOURG //

  // PORTUGAL //

  'PORTUGAL': [
    {
      'NAME': 'Gondomar', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1FqPT6qwvD5E6cuBOdDTCN7iZO4FXeeln',
      'SHOPS': [
        {'NAME': 'Boutique Isabel Neves Pinto', 'ADDRESS': 'Plza. Rep., 151 Souto Center, Loja 17<br>4420-294 Gondomar<br>Tel.+351 966 066 549'}  
      ]
    }
  ], // PORTUGAL //

  // SPAIN //

  'SPAIN': [
    {
      'NAME': 'Atelier', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=12rr9k4ozeyXHA5HFWtiwTl8FuihCDpuY',
      'SHOPS': [
        {'NAME': 'BALART NÚVIES - Atelier', 'ADDRESS': 'Rambla de Catalunya, 94, Entlo 1a.<br>Barcelona 08008<br> +34 932 509 315'}  
      ]
    },
    /*{
      'NAME': 'Albacete', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1gxNABN0jbjxvZl07Ui_jPYWGwSeJy6gV&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'MARIETTA NOVIAS', 'ADDRESS': 'C/ Tesifonte Gallego 2<br>Albacete 02002<br>+34 967 52 40 06'}  
      ]
    },*/
    {
      'NAME': 'Alicante', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1tsPH3qmWF8O7-u5JMhPu8vBAlXAY36BR&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Mamen Gonzalez Novias', 'ADDRESS': 'C. Susana Llaneras, 13<br>03001 Alicante<br>+34 965 20 05 91<br>+34 673 35 97 57'}  
      ]
    },
    {
      'NAME': 'Alava', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=14HOj5bGsDwbzJww0JMtqQZZEDp_hwbq6&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Borgia Novias', 'ADDRESS': 'C.C. Dendaraba, Calle Independencia, 3, <br>01005 Vitoria-Gasteiz, Álava<br>+34 945 23 43 92'}  
      ]
    },
    {
      'NAME': 'Asturias', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1SzYPBtmv-iAlQajJAc88R5XBJmF9jrdt',
      'SHOPS': [
        {'NAME': 'Carmen  Molero Novias', 'ADDRESS': 'C/ Gonzalez del Valle 9<br>33003  Oviedo <br>+34 984 08 59 34<br>'}  
      ]
    },
    {
      'NAME': 'Badajoz', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1GSLNODWflkLUReU1Dq34XwP9bhYymqo&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Boutique Paola', 'ADDRESS': 'Plaza Conquistadores 7-8<br>6005 Badajoz<br>+34 924 24 54 27'}  
      ]
    },
    {
      'NAME': 'Barcelona', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1YC8DuhJibeXd8WHIRCVbhnjxzs8Xl04F&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'BALART NÚVIES - Atelier', 'ADDRESS': 'Rambla de Catalunya, 94, Entlo 1a.<br>Barcelona 08008<br> +34 932 509 315'}  
        //{'NAME': '21botons by Boutique Paquita', 'ADDRESS': 'C/ de la Palanca,13<br>08202 Sabadell<br> +34 937 262 574'}  
      ]
    },
    {
      'NAME': 'Bizkaia', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1YqWL6xFxiidE0vbU8I7cMa3hceSfgH4&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'El Bosque', 'ADDRESS': 'Ctra Enekuri-Artxanda N.10 KM 7 <br>48015 Bilbao-Artxanda <br>+34 94 476 35 99<br>'}  
      ]
    },
    {
      'NAME': 'Burgos', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1HHe2rJB2Ob4wAjw9zKPwvpX9Ve85cJbF&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'For You', 'ADDRESS': 'C. Federico García Lorca, 1<br>09007 Burgos<br>+34 947 47 09 18<br>'}  
      ]
    },
    {
      'NAME': 'Cantabria', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1zA99HNHTUJA1_fHErmCMvCs0PczBA5I&ehbc=2E312F',
      'SHOPS': [
        //{'NAME': 'Carmen Cuesta', 'ADDRESS': 'Avda Puente Carlos III 45<br>39200 Reinosa, Santander<br>+34 942 75 30 60'},
        {'NAME': 'Alma de Boda', 'ADDRESS': 'C/ Cadiz 6<br>39002 Santander <br>+34 942 22 63 91'}  
      ]
    },
    {
      'NAME': 'Castellon', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1ABSTxv47Q3uRtvIcn5T3ghEgc0JfbNw&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Tiara Ceremonias', 'ADDRESS': 'C/ Alloza 5 <br>12001 Castellon de la Plana<br>+34 964 21 04 46'},
        {'NAME': 'Ad Hoc Boutique', 'ADDRESS': 'C/ Benigafull 52<br>12600  La Vall d´Uixo, Castellon<br>+34 964 69 73 09'}  
      ]
    },
    {
      'NAME': 'Ciudad Real',
      'MAP': 'https://www.google.com/maps/d/embed?mid=1ylrIR-G5w1T2tYyKzCM_IUvWEXZDlwc&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Montserrat Baos', 'ADDRESS': 'C/Mayour de Carnicerías, 20 <br>13270 Almagro, Ciudad Real <br>Tel. 653934715'}  
      ]
    },
    /*{
      'NAME': 'Córdoba', //'Córdoba', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1i01raV4N3pPJXgf_PORLB5l1iuq__ruJ',
      'SHOPS': [
        {'NAME': 'Smart Moda Córdoba', 'ADDRESS': 'C/ San Alvaro 6 <br>14103 Córdoba <br>+34 633 143 043'}  
      ]
    },*/
    {
      'NAME': 'Coruña', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1J1Jgn4uISmbxWRp6KxtSOhJZW4vo9GI&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Boutique Pilar', 'ADDRESS': 'Calle Teresa Herrera, 1, Primera Planta<br>15004 A Coruñ;a <br>+34 981 225 610'}  
      ]
    },
    {
      'NAME': 'Girona', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1wfhrUY-2R3dEDLKuwNQCL7CqPuZJfdk&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Corredera Nuvia-Festa', 'ADDRESS': 'Plaça de Catalunya 5<br>17800 Olot,Girona<br>+34 972 26 09 81<br>'}  
      ]
    },
    /*{
      'NAME': 'Granada', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1HnhbVfwJWO2ueJir8hByUvWpCV025LcK',
      'SHOPS': [
        {'NAME': 'Isamar Atelier', 'ADDRESS': 'Calle Sierpe Baja, 10<br>18001 Granada<br>+34 958 52 26 03'},
      ]
    },*/
    {
      'NAME': 'Islas Baleares', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1Rb-PW1fPWbkfLvg10Xo-_RlNf4t0KLM&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Boutique Cachet', 'ADDRESS': 'C/ San Miguel 46<br>07002 Palma de Mallorca<br>+34 971 72 38 94'}  
      ]
    },
    {
      'NAME': 'La Rioja', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1KR7ZG_TzNK7TD3kiD-vFGNK6U6RTuoY&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Novias Carmen Serrano', 'ADDRESS': 'C/ Doctor Mugica 1 <br>26002 Logroñ;o <br>+34 941 20 69 17'}  
      ]
    },
    {
      'NAME': 'Leon', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1_6U0SFo1EAs_tduEUc3CQfUhknGIl8M&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Alicia Novias', 'ADDRESS': 'Gran Via de San Marcos 6<br>24002 Leon<br>+34 987 23 26 28'}  
      ]
    },
    {
      'NAME': 'Lerida', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1DWHaAOsk_4nWS6icSR7g9jovaTkPn78&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Gala García Novias', 'ADDRESS': 'Av. Dr. Fleming, 13<br> 25006 Lleida<br> +34 873 49 03 19'}  
      ]
    },
    {
      'NAME': 'Madrid', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1bjHHZ2ytnvxyZIsjCEJEfOg3UO54PqQb&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Jesus Peiro Madrid', 'ADDRESS': 'C/ Eduardo Dato 2 bis<br> 28010 Madrid<br> +34 91 446 99 70'}
        /*{'NAME': 'Lunas de Boda', 'ADDRESS': 'Calle Don Ramon De la Cruz, 46, bajo D<br>28001  Madrid <br> +34 91 521 11 27'} */
      ]
    },
    {
      'NAME': 'Malaga', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1v5oYybVzR6Nbsk9tqji2ziFjLCkrR3M&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'La Gioconda Novias y Fiesta', 'ADDRESS': 'C/ Compañ;ia 21<br>29005 Malaga<br>+34 952 60 95 10'}  
      ]
    },
    /*{
      'NAME': 'Murcia', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1OZR1DyUwgpVhbOPCHosVp_Q8W3M',
      'SHOPS': [
        {'NAME': 'Isabel Barba', 'ADDRESS': 'C/ Alejandro Seiquer 8<br>30001 Murcia<br>+34 968 21 44 52'}  
      ]
    },*/
    {
      'NAME': 'Navarra', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1g0cPGoXosmxCoLxyQOLnwZuSQu6OF7YN&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Organza', 'ADDRESS': 'Carlos III - Nº 12, 1º Derecha<br>31002 Pamplona <br>+34 948 15 08 82'}
      ]
    },
    {
      'NAME': 'Orense', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1QuYTJ26BMeW6AO1U1mMVtCwmwSZExHA&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Centro Comercial Sol', 'ADDRESS': 'Avda de Calvo Sotelo 1<br>32500 Carballiñ;o, Orense<br>+34 988 2711 56'}  
      ]
    },
    {
      'NAME': 'Salamanca', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1MpURymeKPovbfbkaM_1YLN4jdlTSJN0&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Cadenet Novias', 'ADDRESS': 'Calle Calle José; Jáuregui, 8<br>37002 Salamanca<br>+34 923 053 129'}  
      ]
    },
    /*{
      'NAME': 'Sevilla', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1eY0M3P7xVtzKlXIxnkzt1CcL3Kw',
      'SHOPS': [
        {'NAME': 'La Fiancé', 'ADDRESS': 'Calle Mendez Nuñez 3<br>41001 Sevilla<br>+34 660 39 00 10'}  
      ]
    },*/
    {
      'NAME': 'Toledo', // 32
      'MAP': 'https://www.google.com/maps/d/embed?mid=1GSMh1dtnv7qc6mi6PxoP5rGkrljctaE&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Teresa Justel', 'ADDRESS': 'Travesia Colombia 6<br> 45004 Toledo<br>+34 646 97 86 63'}  
      ]
    },
    {
      'NAME': 'Valencia',  // 33
      'MAP': 'https://www.google.com/maps/d/embed?mid=1JSbkPmoGnlx9QPG3hMeKQsScRChl238&ehbc=2E312F',
      'SHOPS': [
        //{'NAME': 'Boutique Amparo Ferrando', 'ADDRESS': 'C/ San Francisco de Asis 9<br>46740 Carcaixent, Valencia<br>+34 96 243 05 90'},  
        {'NAME': 'Pilar Valarino', 'ADDRESS': 'C/ Monjas de Santa Catalina, 4 <br>46002 Valencia<br>+34 96 351 94 34'},  
        {'NAME': 'Jesus Peiro Valencia', 'ADDRESS': 'C/ Padilla 5<br>46001 Valencia<br>+34 618 09 95 14'}  
      ]
    },
    {
      'NAME': 'Valladolid', // 34
      'MAP': 'https://www.google.com/maps/d/embed?mid=1AxHkLe9cVV0-mV9ZN9Vyd8UILsTJhSE&ehbc=2E312F&noprof=1',
      'SHOPS': [
        {'NAME': 'Asun Casado', 'ADDRESS': 'C/ Maria de Molina 8<br>47001 Valladolid<br>+34 983 33 78 59'}  
      ]
    },
    /*{
      'NAME': 'Zaragoza', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1IXig87Ajm-5PNA9SyBwr0pLJWNkf3iqY&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Marengo Moda', 'ADDRESS': 'C. Felipe Sanclemente, 11<br>50001 Zaragoza<br>+34 976 37 84 81'}  
      ]
    }*/
  ], // SPAIN //

  // SWITZERLAND //

  /*'SWITZERLAND': [
    {
      'NAME': 'Zurich', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1NFkqegWyH5le1mm_koY9oZVVgIkehXcs',
      'SHOPS': [
        {'NAME': 'LILUCA', 'ADDRESS': 'Werdmuehleplatz, 4<br>CH-8001 Zurich<br>Switzerland<br>Telf. +41(0) 44 221 23 90'}  
      ]
    }
  ], // SWITZERLAND //*/

  'USA': [
    /*{
      'NAME': 'New York', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=13XBMcpxscZYPLHAITUaIWWUXqqX7rJtv',
      'SHOPS': [
        {'NAME': 'The Sample Room NY', 'ADDRESS': '40 W 17th St # 2B, <br>NY 10011, New York<br>+1 212-929-8868'}  
      ]
    },*/
    {
      'NAME': 'New Jersey', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1WfxoXRUrFZCOuy-Djo4eK6ot3_QuIQyd&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Elizabeth Johns', 'ADDRESS': '1099 Mt. Kemble Avenue, <br>NJ 07960, Morristown<br>+1 973-425-0600'}  
      ]
    },
    {
      'NAME': 'Pennsilvania', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1__ze-y7Ni5omtEDhYxxeaH_gDOTcB58&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'The Wedding Shoppe', 'ADDRESS': '503 West Lancaster Avenue, Eagle Village Shops, <br>19087 Wayne<br>Tel. +16102931299'}
      ]
    }
  ],

  'JAPAN': [
    {
      'NAME': 'Tokyo', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=1l5bV9gsHaTJXK_2NwBtwg2jSGO2jJWA&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'Micie Prive', 'ADDRESS': '4-2-35 Vort Roppongui Dual\'s, 303, <br>106-0032 Minato-ku Roppongui, Tokyo<br>Tel. (+81) 03-6812-9140'},  
        {'NAME': 'Micie', 'ADDRESS': '3-4-26, B1, <br>106-0046 Minato-ku Motoazabu, Tokyo<br>Tel. (+81) 03-5475-5120'}            
      ]
    }
  ],

  'ISRAEL': [
    {
      'NAME': 'North District', 
      'MAP': 'https://www.google.com/maps/d/embed?mid=18sbeEgpkxTgzzMF5TjwAQPFMQ1Pcn74&ehbc=2E312F',
      'SHOPS': [
        {'NAME': 'La Sposa Boutique', 'ADDRESS': 'Haifa Road, 45, <br>16000 Nazareth<br>Tel. 0525999210 '}      
      ]
    }
  ]

} // THE END

