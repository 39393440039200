import React, { useState, useEffect } from 'react'
import * as FA from 'react-fontawesome'

export const CardFilm = ({name, poster, onClick}) => {
    const [mobile, setMobile] = useState(window.innerWidth < 768)

    useEffect(() => {
      window.addEventListener('resize', () => {
        setMobile(window.innerWidth < 768)
      })
      // eslint-disable-next-line
    }, [])

    return (
        <div style={{...styles.container, width: mobile ? '95%' : '30%'}} onClick={onClick}>
            <img src={poster} alt={name} style={styles.image} class="animate__animated animate__fadeInDown"/>
            <p style={styles.title} class="animate__animated animate__fadeIn">
                {name} <span style={{float:'right'}}><FA name="play"/></span>
            </p>
        </div>
    )
}

const styles = {
    container: {
        backgroundColor: '#333',
        margin: 10,
        boxShadow: '5px 5px 10px #ccc',
        border: '1px solid #ccc',
        borderRadius: 5,
        overflow: 'hidden',
        cursor: 'pointer',
    },
    image: {
        width: '100%',
    },
    title: {
        marginBottom: -1,
        padding: 10,
        color: '#eee',
        fontWeight: 'bold',
    }
}
