
export const book_danube_trans = {
    '2221': {
        'en': "Vaporous two-piece blouse and skirt in Klein blue. Wrap neck mikado blouse, 3/4 Chantilly sleeves and a two-piece silk chiffon cape on the back. Light long natural silk chiffon skirt. The blouse fastens at the back with an invisible zip.",
        'es': "Vaporoso dos piezas de blusa y falda en azul Klein. Blusa de cuello envolvente en mikado, manga 3/4 de chantilly y una capa de dos piezas en gasa de seda en la espalda. Falda larga ligera de gasa de seda natural. La blusa se abrocha en la espalda con cremallera invisible.",
        'fr': "",
        'it': "Blusa e gonna morbida in due pezzi in blu Klein. Blusa in mikado con collo a portafoglio, maniche 3/4 chantilly e mantella in chiffon di seta in due pezzi sul retro. Gonna lunga in chiffon di seta naturale leggera. La blusa si chiude dietro con una zip invisibile.", 
        'ca': "Vaporós dues peces de brusa i faldilla en blau Klein. Brusa de coll envoltant en mikado, màniga 3/4 de chantilly i una capa de dues peces de gasa de seda a l'esquena. Faldilla llarga lleugera de gasa de seda natural. La brusa es corda a l'esquena amb cremallera invisible.",
    },
    '2222': {
        'en': "Two pieces comprising an elegant stone-coloured lace blouse in the shape of a cape finished with a knot detail at the front, a crepe base and straight crepe pants. The lace of the blouse comes from the famous area of Calais in France, it fastens at the back with an invisible zip.",
        'es': "Dos piezas compuestas por una elegante blusa de blonda color piedra en forma de capa terminada en un detalle de nudo delante, base de crepé y un pantalón recto de crepé. La blonda de la blusa procede de la famosa zona de Calais en Francia, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Due pezzi composti da un'elegante blusa in pizzo color pietra a forma di mantella rifinita con dettaglio nodo sul davanti, base in cady e pantaloni dritti in cady. Il pizzo della blusa proviene dalla famosa zona di Calais in Francia, si allaccia sul retro con una zip invisibile.", 
        'ca': "Dues peces compostes per una elegant brusa de blonda color pedra en forma de capa acabada en un detall de nus davant, base de crepè i uns pantalons rectes de crepè. La blonda de la brusa procedeix de la famosa zona de Calais a França, es corda per l'esquena amb cremallera invisible.",
    },
    '2223': {
        'en': "Modern combination of French lace from the Calais area in shades of pink, with matching crepe in this two-piece set of 3/4-sleeved blouse and pants. The blouse has a small draped detail on the upper part of the lace, and the base is made of crepe only covering the part of the body. It fastens at the back with an invisible zip. The crepe pants have an opening at the bottom that allows you to show off the detail of the lace finish.",
        'es': "Moderna combinación de blonda francesa de la zona de Calais en tonos rosa, con crepé a tono en este conjunto de dos piezas de blusa en manga 3/4 y pantalón. La blusa tiene un pequeño detalle drapeado en la parte superior de la blonda, y la base está hecha en crepe sólo cubriendo la parte del cuerpo. Se abrocha por la espalda con cremallera invisible. El pantalón de crepé tiene una abertura en la parte inferior que permite lucir el detalle de la terminación en blonda.",
        'fr': "",
        'it': "Combinazione moderna di pizzo francese della zona di Calais nei toni del rosa, con cady abbinato in questo set di due pezzi composto da blusa e pantaloni con maniche a 3/4. La casacca ha un piccolo dettaglio drappeggiato sulla parte superiore del pizzo, e la base è in cady che copre solo la parte del corpo. Si chiude dietro con una zip invisibile. I pantaloni in crêpe hanno un'apertura sul fondo che permette di mettere in mostra il dettaglio della rifinitura in pizzo.", 
        'ca': "Moderna combinació de blonda francesa de la zona de Calais en tons rosa, amb crepè a to en aquest conjunt de dues peces de brusa en màniga 3/4 i pantalons. La brusa té un petit detall drapejat a la part superior de la blonda, i la base està feta en crep només cobrint la part del cos. Es corda per l'esquena amb cremallera invisible. Els pantalons de crepè tenen una obertura a la part inferior que permet lluir el detall de la terminació en blonda.",
    },
    '2224': {
        'en': "Crepe jacket and pants set. The jacket functions as a blouse due to the way of fastening at the front. Detail in the shape of flowers made in the same matt and shiny crepe on the shoulder, waist and cuff.",
        'es': "Conjunto de chaqueta y pantalón en crepé. La chaqueta hace función de blusa por su forma de abrochar en la parte delantera. Detalle en forma de flores hechas en el mismo crepé en mate y brillo en hombro, cintura y puño.",
        'fr': "",
        'it': "Completo giacca e pantalone in cady. La giacca funziona come una camicia grazie al suo modo di allacciarsi sul davanti. Dettaglio a forma di fiore realizzato nello stesso crêpe opaco e lucido su spalla, vita e polsino.", 
        'ca': "Conjunt de jaqueta i pantalons en crepè. La jaqueta fa funció de brusa per la seva forma de cordar a la part davantera. Detall en forma de flors fetes al mateix crepè en mat i brillant a l'espatlla, cintura i puny.",
    },
    '3758': {
        'en': "Navy blue jacket and dress set, made of crepe and lace from the famous French region of Calais. The jacket is a jewel due to its combination of one of the characteristic Teresa Ripoll collars, 3/4 transparent lace sleeves and the back also in lace with some folds in the pure 60s style. The crepe dress is strapless, it goes with straps and has a lace detail in the form of a knot on the upper part of the chest.",
        'es': "Conjunto en azul marino de chaqueta y vestido, hechos de crepé y blonda de la famosa región francesa de Calais. La chaqueta es una joya por su combinación de uno de los característicos cuellos Teresa Ripoll, mangas 3/4 transparentes de blonda y la espalda también en blonda con unos pliegues al puro estilo años 60. El vestido de crepe es palabra de honor, va con tirantes y tiene un detalle de blonda en forma de nudo en la parte superior del pecho.",
        'fr': "",
        'it': "Completo giacca e abito blu navy, realizzato in crêpe e pizzo della famosa regione francese di Calais. La giacca è un gioiello per la sua combinazione di uno dei caratteristici colletti Teresa Ripoll, maniche 3/4 in pizzo trasparente e la schiena anch'essa in pizzo con alcune pieghe in puro stile anni 60. L'abito in crêpe è senza spalline, si abbina con spalline e ha un dettaglio in pizzo a forma di nodo sulla parte superiore del petto.", 
        'ca': "Conjunt de blau marí de jaqueta i vestit, fets de crepè i blonda de la famosa regió francesa de Calais. La jaqueta és una joia per la seva combinació d'un dels colls característics Teresa Ripoll, mànigues 3/4 transparents de blonda i l'esquena també en blonda amb uns plecs al pur estil anys 60. El vestit de crepè és paraula d'honor, va amb tirants i té un detall de blonda en forma de nus a la part superior del pit.",
    },
    '3759': {
        'en': "Amusing long navy blue crepe dress with a gathered bodice in contrasting fantasy embroidered tulle and off the shoulder. The tulle is embroidered entirely by hand and has a part with rubber on the shoulders for a better adaptation to the body. It fastens at the back with an invisible zip.",
        'es': "Divertido vestido largo azul marino de crepé con cuerpo fruncido de tul bordado fantasía a contraste y hombro descubierto. El tul está bordado de forma totalmente artesanal y tiene una parte con goma en los hombros para mejor adaptación al cuerpo. Se abrocha en la espalda con cremallera invisible.",
        'fr': "",
        'it': "Divertente abito lungo in crêpe blu navy con corpetto arricciato in tulle ricamato fantasia a contrasto e una spalla scoperta. Il tulle è ricamato interamente a mano e presenta una parte con gomma sulle spalle per un migliore adattamento al corpo. Si chiude dietro con una zip invisibile.", 
        'ca': "Divertit vestit llarg blau marí de crepè amb cos arrufat de tul brodat fantasia a contrast i espatlla descoberta. El tul està brodat de forma totalment artesanal i té una part amb goma a les espatlles per a millor adaptació al cos. Es corda a l'esquena amb cremallera invisible.",
    },
    '3760': {
        'en': "Elegant Klein blue long dress in a combination of mikado bodice and top and 3/4 sleeves in transparent Chantilly. The noble Chantilly originates from the historic French region of Calais. The back is low-cut in the shape of a V and with the semi-transparency of Chantilly, fastening with buttons and the rest with an invisible zip.",
        'es': "Elegante vestido largo azul Klein en combinación de cuerpo de mikado y parte superior y manga 3/4 en chantillí transparente. El noble chantillí procede de la histórica región francesa de Calais. La espalda queda escotada en forma de pico y con la semi transparencia del chantillí, abrochando con botones y el resto con cremallera invisible.",
        'fr': "",
        'it': "Elegante abito lungo blu Klein in combinazione di corpetto e top in mikado e maniche a 3/4 in chantilly trasparente. Chantilly nobile proviene dalla storica regione francese di Calais. La schiena è scollata a forma di V e con la semitrasparenza di Chantilly, chiusura con bottoni e il resto con zip invisibile.", 
        'ca': "Elegant vestit llarg blau Klein en combinació de cos de mikado i part superior i màniga 3/4 en xantillí transparent. El noble xantillí procedeix de la històrica regió francesa de Calais. L'esquena queda escotada en forma de V i amb la semi transparència del xantillí, cordant amb botons i la resta amb cremallera invisible.",
    },
    '3761': {
        'en': "Long blue Klein dress with the flattering Teresa Ripoll wrap-around neckline. Short sleeves and waist finished at the front with a shiny brooch. It fastens at the back with an invisible zipper.",
        'es': "Vestido largo azul Klein con el favorecedor cuello envolvente Teresa Ripoll. Manga corta y cintura terminada en la parte delantera con broche de brillantes. Se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito lungo blu Klein con un importante scollo avvolgente di Teresa Ripoll. Maniche corte e punto vita rifinito davanti da una spilla lucida. Si chiude dietro con una zip invisibile.", 
        'ca': "Vestit llarg blau Klein amb l'afavoridor coll envoltant Teresa Ripoll. Màniga curta i cintura acabada al davant amb fermall de brillants. Es corda per l'esquena amb cremallera invisible.",
    },
    '3762': {
        'en': "Light long blue Klein dress in natural silk chiffon. Bodice draped in two directions; significant neckline and cape-shaped sleeves that leave the shoulders visible. Detail of diamonds at the waist and fastens at the back with an invisible zip.",
        'es': "Ligero vestido largo azul Klein en gasa de seda natural. Cuerpo drapeado en dos direcciones, importante escote y mangas en forma de capa que dejan el hombro a la vista. Detalle de brillantes en cintura y se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito lungo e fluido di Klein blu in chiffon di seta naturale. Corpetto drappeggiato in due direzioni, scollo importante e maniche a mantella che lasciano intravedere le spalle. Dettaglio di losanghe in vita e chiusura dietro con zip invisibile.", 
        'ca': "Lleuger vestit llarg blau Klein de gasa de seda natural. Cos drapejat en dues direccions, important escot i mànigues en forma de capa que deixen l'espatlla a la vista. Detall de brillants a la cintura i es corda per l'esquena amb cremallera invisible.",
    },
    '3763': {
        'en': "Fantastic long asymmetrical Klein blue dress with one bare shoulder, a flattering mikado flower on the bodice and a light natural silk chiffon skirt. Plain back following the asymmetrical neckline where it is fastened with an invisible zip.",
        'es': "Fantástico vestido largo azul Klein asimétrico con un hombro al aire, favorecedora flor de mikado en el cuerpo y ligera falda en gasa de seda natural. Espalda lisa siguiendo el escote asimétrico por donde se abrocha con cremallera invisible.",
        'fr': "",
        'it': "Fantastico abito blu Klein asimmetrico lungo con una spalla scoperta, un allettante fiore in mikado sul corpetto e una leggera gonna in chiffon di seta naturale. Schiena liscia che segue la scollatura asimmetrica dove è chiusa da una zip invisibile.", 
        'ca': "Fantàstic vestit llarg blau Klein asimètric amb una espatlla a l'aire, afavoridora flor de mikado al cos i lleugera faldilla en gasa de seda natural. Esquena llisa seguint l'escot asimètric per on es corda amb cremallera invisible.",
    },
    '3764': {
        'en': "Pretty short dress in steel colored pique mikado, overlaid wrap neck and overskirt with falling down the back. Short sleeve and hand painted tone flower detail at waist. It clasps at the back with invisible zip.",
        'es': "Bonito vestido corto en piqué mikado color acero, cuello envolvente sobrepuesto y sobrefalda con caída por la espalda. Manga corta y detalle de flor pintada a mano a tono en cintura. Se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Grazioso abito corto in mikado piquet color metallico, collo incrociato sovrapposto e sopragonna con ricadute sulla schiena. Manica corta e dettaglio fiore tono su tono dipinto a mano in vita. Si chiude sul retro con zip invisibile.", 
        'ca': "Preciós vestit curt de mica de piqué color acer, coll embolcallant i sobrefaldilla amb caiguda per l'esquena. Màniga curta i detall de flors pintades a mà a la cintura. Es tanca a la part posterior amb cremallera invisible.",
    },
    '3765': {
        'en': "Important long dress in steel tones, with a beaded bodice and pleated skirt at the front in mikado piqué. The back has a deep V-neckline and no pleats in the skirt. It is sleeveless, and fastens at the back with an invisible zip.",
        'es': "Importante vestido largo en tonos acero, con cuerpo de pedrería y falda de pliegues en la parte delantera en piqué mikado. Espalda con escote a pico y sin pliegues en la falda. Se presenta con profundo escote y sin mangas, y se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito lungo importante nei toni azzurro metallico, con corpetto di perline e gonna plissettata sul davanti in mikado piqué. Schiena con scollo a V e gonna senza pieghe. Si presenta con una scollatura profonda e senza maniche, e si chiude sul retro con una zip invisibile.", 
        'ca': "Important vestit llarg en tons acer, amb cos de pedreria i faldilla de plecs a la part davantera en piqué mikado. Esquena amb escot a bec i sense plecs a la falda. Es presenta amb profund escot i sense mànigues, i es corda per l'esquena amb cremallera invisible.",
    },
    '3767': {
        'en': "Precious midi-length dress in petroleum-colored mikado piqué. Teresa Ripoll's flattering 60s-style neckline and the one-way pleats of the skirt provide a sobriety and elegance. It comes with 1/2 sleeves and fastens at the back with an invisible zip.",
        'es': "Precioso vestido de largo midi en piqué mikado color petróleo. El favorecedor cuello estilo años 60 de Teresa Ripoll y los pliegues en una sola dirección de la falda le proporcionan sobriedad y elegancia. Se presenta con 1/2 manga y se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Prezioso abito midi in mikado piqué color petrolio. Il scollo anni '60 di Teresa Ripoll e le pieghe unidirezionali della gonna gli conferiscono sobrietà ed eleganza. Viene fornito con maniche a 1/2 e si chiude sul retro con una zip invisibile.", 
        'ca': "Preciós vestit de llarg midi en piqué mikado color petroli. L'afavoridor coll estil anys 60 de Teresa Ripoll i els plecs en una sola direcció de la falda li proporcionen sobrietat i elegància. Es presenta amb 1/2 màniga i es corda per l'esquena amb cremallera invisible.",
    },
    '3768': {
        'en': "Petrol-colored midi dress in mikado piqué with details on the sleeve cuffs and matching beaded skirt pieces. The sleeve is above the elbow, boat neckline and fastens at the back with an invisible zip.",
        'es': "Vestido midi de color petróleo en piqué mikado con detalles en el puño de la manga y piezas de la falda en pedrería al tono. La manga es sobre el codo, escote barco y se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito midi color petrolio in mikado piqué con dettagli sui polsini delle maniche e gonna abbinata con perline. La manica è sopra il gomito, scollo a barca e si chiude dietro con zip invisibile.", 
        'ca': "Vestit midi de color petroli en piqué mikado amb detalls al puny de la màniga i peces de la faldilla en pedreria al to. La màniga és sobre el colze, escot vaixell i es corda per l'esquena amb cremallera invisible.",
    },
    '3770': {
        'en': "Pretty midi dress in silver mikado piqué and important Teresa Ripoll wrap-around neck. Fantasy bow detail on neckline and flared skirt. It comes with short sleeves and fastens at the back with an invisible zip.",
        'es': "Bonito vestido midi en piqué mikado plata e importante cuello envolvente Teresa Ripoll. Detalle de lazo fantasía en cuello y falda evasé. Se presenta en manga corta y se abrocha por espalda con cremallera invisible.",
        'fr': "",
        'it': "Grazioso abito midi in piqué mikado argento e importante scollo avvolgente Teresa Ripoll. Dettaglio fiocco fantasia su scollo e gonna svasata. È disponibile a maniche corte e si chiude sul retro con una zip invisibile.", 
        'ca': "Bonic vestit midi en piqué mikado plata i important coll envoltant Teresa Ripoll. Detall de llaç fantasia al coll i faldilla evasé. Es presenta en màniga curta i es corda per esquena amb cremallera invisible.",
    },
    '3771': {
        'en': "Charming dress in silver tones with an important wrap-around Teresa Ripoll neckline, peplum and light ankle-length skirt. The body of the dress has short sleeves in mikado and the skirt in natural silk chiffon. It fastens at the back with an invisible zip.",
        'es': "Encantador vestido en tonos plata con importante cuello cruzado envolvente Teresa Ripoll, peplum y ligera falda de largo tobillero.El cuerpo se presenta en manga corta, en mikado y la falda en gasa de seda natural. Se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Affascinante abito nei toni dell'argento con importante scollo aavvolgente Teresa Ripoll, peplo e gonna leggera alla caviglia. Il corpetto è presentato a maniche corte, in mikado e la gonna in chiffon di seta naturale. Si chiude dietro con una zip invisibile.", 
        'ca': "Encantador vestit en tons plata amb important coll creuat envoltant Teresa Ripoll, pèplum i lleugera faldilla de llarg al turmell. El cos es presenta en màniga curta, en mikado i la faldilla en gasa de seda natural. Es corda per l'esquena amb cremallera invisible.",
    },
    '3772': {
        'en': "Fabulous long silver dress with sweetheart neckline, leaf-shaped detail on the shoulder and waist and flowing skirt. Body and short sleeves in Japanese cut Mikado and skirt in natural silk. It fastens at the back with an invisible zip.",
        'es': "Fabuloso vestido largo plata escote corazón, detalle en forma de hojas en hombro y cintura, y vaporosa falda. Cuerpo y manga corta en mikado de corte japonés, y falda en seda natural. Se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Favoloso abito lungo argentato con scollo a cuore, dettaglio a forma di foglia sulla spalla e in vita e gonna fluida. Corpo e maniche corte in mikado taglio giapponese e gonna in seta naturale. Si chiude dietro con una zip invisibile.", 
        'ca': "Fabulós vestit llarg plata escot cor, detall en forma de fulles a espatlla i cintura, i vaporosa faldilla. Cos i màniga curta en mikado de tall japonès, i faldilla en seda natural. Es corda per l'esquena amb cremallera invisible.",
    },
    '3773': {
        'en': "Fantasy dress in silver mikado piqué with crossed bodice and Chantilly sleeves. Midi-length cape skirt. Chantilly comes from the famous and traditional French region of Calais. It fastens at the back with an invisible zip.",
        'es': "Vestido fantasía en piqué mikado plata con cuerpo cruzado y mangas de chantillí. Falda a capa de largo midi. El chantillí procede de la famosa y tradicional región francesa de Calais. Se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito fantasia in piquet di mikado argento con corpetto incrociato e maniche chantilly. Gonna a strati di lunghezza midi. Chantilly proviene dalla famosa e tradizionale regione francese di Calais. Si chiude dietro con una zip invisibile.", 
        'ca': "Vestit fantasia en piqué mikado plata amb cos creuat i mànigues de xantillí. Faldilla a capa de llarg midi. El xantillí procedeix de la famosa i tradicional regió francesa de Calais. Es corda per l'esquena amb cremallera invisible.",
    },
    '3774': {
        'en': "Dress with the enveloping Teresa Ripoll neckline in gold mikado piqué combined with pink details. Precious flower at the waist that develops into a midi-length evasé skirt. It comes with short sleeves and fastens at the back with an invisible zip.",
        'es': "Vestido con el envolvente cuello Teresa Ripoll en piqué mikado oro combinado con detalles en rosa. Preciosa flor en cintura que se desarrolla en una falda evasé de largo midi. Se presenta con manga corta y se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito con l'avvolgente scollo Teresa Ripoll in mikado piqué color oro abbinato a dettagli rosa. Prezioso fiore in vita che si sviluppa in una gonna midi svasata. Ha le maniche corte e si chiude dietro con una zip invisibile.", 
        'ca': "Vestit amb l'envoltant coll Teresa Ripoll en piqué mikado or combinat amb detalls de rosa. Preciosa flor a la cintura que es desenvolupa en una faldilla evasé de llarg midi. Es presenta amb màniga curta i es corda per l'esquena amb cremallera invisible.",
    },
    '3775': {
        'en': "Flattering crepe coat dress in peach tones with glitter details. The pieces on the sides that simulate pockets lengthen the body, stylising the figure. It comes in 3/4 sleeves and a long skirt. It fastens at the back with an invisible zip.",
        'es': "Favorecedor vestido-abrigo de crepé en tonos melocotón con detalles en brillo. Las piezas de los costados que simulan bolsillos alargan el cuerpo estilizando la figura. Se presenta en manga 3/4 y falda larga. Se abrocha en espalda con cremallera invisible.",
        'fr': "",
        'it': "Importante abito-cappotto in crêpe nei toni pesca con dettagli glitterati. I pezzi sui lati che simulano le tasche allungano il corpo, stilizzando la figura. È disponibile con maniche a 3/4 e gonna lunga. Si chiude dietro con una zip invisibile.", 
        'ca': "Afavoridor vestit-abric de crepè en tons préssec amb detalls en brillant. Les peces dels costats que simulen butxaques allarguen el cos estilitzant la figura. Es presenta en màniga 3/4 i faldilla llarga. Es corda a l'esquena amb cremallera invisible.",
    },
    '3777': {
        'en': "Precious red mikado piqué dress with an enveloping Teresa Ripoll neckline and a large decorative bow. A-line skirt, midi length and longer at the back. Shown with  short sleeves, it fastens at the back with an invisible zip.",
        'es': "Precioso vestido rojo en piqué mikado con envolvente cuello Teresa Ripoll y gran lazo de adorno. Corte de la falda en A de largo midi y más largo en espalda. Presentado en manga corta, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Prezioso abito in mikado piqué rosso con avvolgente scollo Teresa Ripoll e grande fiocco decorativo. Gonna a trapezio, lunghezza midi e più lunga dietro. Presentato a maniche corte, si chiude sul retro con una zip invisibile.", 
        'ca': "Preciós vestit vermell de piqué mikado amb envoltant coll Teresa Ripoll i gran llaç d'ornament. Tall de la faldilla a A de llarg midi i més llarg a l'esquena. Presentat en màniga curta, corda per l'esquena amb cremallera invisible.",
    },
    '3778': {
        'en': "Vaporous long red dress. Large flower pattern with folds in mikado on the front of the body. Long natural silk skirt. Sleeveless, fastens at the back with an invisible zip.",
        'es': "Vaporoso vestido largo en rojo. Fantasía de gran flor con pliegues en mikado en la parte delantera del cuerpo. Falda larga de seda natural. Sin mangas, se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito rosso lungo vaporoso. Fantasia a fiori grandi con pieghe in mikado sul davanti del body. Gonna lunga in seta naturale. Senza maniche, si chiude dietro con una zip invisibile.", 
        'ca': "Vaporós vestit llarg en vermell. Fantasia de gran flor amb plecs en mikado a la part davantera del cos. Faldilla llarga de seda natural. Sense mànigues, es corda per l'esquena amb cremallera invisible.",
    },
    '3780': {
        'en': "Spectacular short coat dress in coral mikado piqué. 3/4 sleeves with turned-up cuffs and flared skirt. It shows off a spectacular Teresa Ripoll lapel collar. It is fastened in the back with an invisible zip.",
        'es': "Espectacular vestido-abrigo corto en piqué mikado color coral.  Manga 3/4 con puño vuelto y falda evasé. Luce un espectacular cuello solapa Teresa Ripoll. Se abrocha por la espalda con una cremallera invisible.",
        'fr': "",
        'it': "Spettacolare abito-cappotto corto in piqué di mikado corallo. Maniche 3/4 con risvolto e gonna svasata. Sfoggia uno spettacolare colletto a revers Teresa Ripoll. Si chiude dietro con una cerniera invisibile.", 
        'ca': "Espectacular vestit-abric curt en piqué mikado color coral. Màniga 3/4 amb puny tornat i faldilla evasé. Fa un espectacular coll solapa Teresa Ripoll. Es corda per l'esquena amb una cremallera invisible.",
    },
    '3781': {
        'en': "Long fuchsia crepe dress with detail of lime mikado piqué leaves embroidered with hand beading. The sleeve over the elbow is also reminiscent of leaves wrapping the arm. Fastened at the back with an invisible zip.",
        'es': "Vestido largo de crepe fucsia con detalle de hojas de piqué mikado lima bordadas con pedrería de forma manual. La manga sobre codo recuerda también a hojas envolviendo el brazo. Abrochado por la espalda con cremallera invisible. ",
        'fr': "",
        'it': "Abito lungo in crêpe fucsia con dettaglio di foglie in piqué di mikado lime ricamate a mano con perline. Anche la manica sopra il gomito ricorda le foglie che avvolgono la vita. Chiuso dietro con zip invisibile.", 
        'ca': "Vestit llarg de crep fúcsia amb detall de fulles de piqué mikado llima brodades amb pedreria de forma manual. La màniga sobre colze recorda també fulles embolicant el braç. Cordat per l'esquena amb cremallera invisible.",
    },
    '3782': {
        'en': "Rich dress in bougainvillea tones with beaded bodice and skirt in light natural silk chiffon. Short cape-shaped fantasy sleeves and peplum that lengthen the silhouette of the body. It is fastened at the back with an invisible zip.",
        'es': "Rico vestido a tonos bugambilia con cuerpo en pedrería y falda en ligera gasa de seda natural. Manga corta fantasía en forma de capa y peplum que alarga la silueta del cuerpo. Se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Ricco abito nei toni della buganvillea con corpetto decorato con perline e gonna in leggero chiffon di seta naturale. Maniche corte con fantasia e cappa e peplo che allungano la silhouette del corpo. Si chiude dietro con una zip invisibile.", 
        'ca': "Ric vestit a tons bugambilia amb cos en pedreria i faldilla en lleugera gasa de seda natural. Màniga curta fantasia en forma de capa i pèplum que allarga la silueta del cos. Es corda per l'esquena amb cremallera invisible.",
    },
    '3783': {
        'en': "Red carpet jacket and dress set in mikado piqué and bougainvillea tones. Jacket with funnel collar, Japanese sleeve cut in a spectacular fashion to form a cape. Long strapless dress. Both dress and jacket are decorated with rich pieces of golden embroidery. Dress fastened at the back with an invisible zipper.",
        'es': "Conjunto de chaqueta y vestido para alfombra roja en piqué mikado y tonos bugambilia. Chaqueta con cuello chimenea, corte de manga japonés y con unas espectaculares mangas que hacen de capa. Vestido largo palabra de honor. Tanto vestido como chaqueta son decorados con unas ricas piezas de bordado dorado. Vestido abrochado por espalda con cremallera invisible.",
        'fr': "",
        'it': "Completo giacca e abito da red carpet in mikado piqué e toni bouganvillea. Giacca con collo a camino, taglio manica giapponese e maniche spettacolari che fungono da mantello. Abito lungo senza spalline. Sia l'abito che la giacca sono decorati con ricchi ricami dorati. Abito chiuso dietro con cerniera invisibile.", 
        'ca': "Conjunt de jaqueta i vestit per a catifa vermella en piqué mikado i tons bugambilia. Jaqueta amb coll xemeneia, tall de màniga japonès i amb unes espectaculars mànigues que fan de capa. Vestit llarga paraula d'honor. Tant vestit com jaqueta són decorats amb unes riques peces de brodat daurat. Vestit cordat per esquena amb cremallera invisible.",
    },
    '3784': {
        'en': "Pink mikado dress with rich leaf decoration in the same fabric, and details on one sleeve and hip in matching natural silk chiffon. Midi-length skirt fastened at the back with an invisible zip.",
        'es': "Vestido en mikado rosa con rico adorno de hojas en el mismo tejido, y detalles en una manga y cadera en gasa de seda natural a tono. Falda de largo midi y abrochado en espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito in mikado rosa con ricca decorazione a foglie nello stesso tessuto, e dettagli su una manica e sul fianco in chiffon di seta naturale in tinta. Gonna midi chiusa dietro con zip invisibile.", 
        'ca': "Vestit en mikado rosa amb ric adorn de fulles al mateix teixit, i detalls en una màniga i maluc en gasa de seda natural a to. Faldilla de llarg midi i cordat a l'esquena amb cremallera invisible.",
    },
    '3785': {
        'en': "Wrapped and draped dress in beautiful pink crepe. The cape covers the sleeves and back, and is finished with beautiful embroidered details in dark silver tones. Long dress that fastens at the back with an invisible zip.",
        'es': "Vestido cruzado y drapeado en precioso crepe rosa. La capa cubre las mangas y espalda, y se termina con unos bonitos detalles bordados en tonos plata oscura. Vestido largo que se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito fasciato e drappeggiato in bellissima crêpe rosa. Il mantello copre le maniche e la schiena ed è rifinito con bellissimi dettagli ricamati nei toni dell'argento scuro. Abito lungo che si chiude sulla schiena con una zip invisibile.", 
        'ca': "Vestit creuat i drapejat en preciós crep rosa. La capa cobreix les mànigues i l'esquena, i s'acaba amb uns bonics detalls brodats en tons plata fosca. Vestit llarg que es corda per l'esquena amb cremallera invisible.",
    },
    '3786': {
        'en': "Mikado pique dress with bougainvillea tones. Evasé skirt, short at the front and falling at the back. Ruffles in the same fabric stylise and decorate the shoulders and hips asysmetrically. It fastens at the back with an invisible zip.",
        'es': "Vestido de piqué mikado con tonos bugambilia. Falda evasé, corto por delante y con caída por la espalda. Unos volantes en el mismo tejido estilizan y decoran hombro y cadera de forma asimétrica. Se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Abito in mikado piqué con toni bouganvillea. Gonna svasata, corta davanti e cadente dietro. Volant nello stesso tessuto stilizzano e decorano in modo asimmetrico spalle e fianchi. Si chiude dietro con una zip invisibile.", 
        'ca': "Vestit de piqué mikado amb tons bugambilia. Faldilla evasé, curt per davant i amb caiguda per l'esquena. Uns volants al mateix teixit estilitzen i decoren espatlla i maluc de forma asimètrica. Es corda per l'esquena amb cremallera invisible.",
    },
    '3787': {
        'en': "Spectacular silver mikado dress with large fancy flower detail on the bodice. Asymmetric shoulder leaving one side exposed and midi skirt length. It fastens at the back with an invisible zip.",
        'es': "Espectacular vestido en mikado plata con detalle de gran flor en fantasía en cuerpo. Hombro asimétrico dejando un lado al aire y largo de falda midi. Se abrocha por la espalda con cremallera invisible.",
        'fr': "",
        'it': "Spettacolare abito in mikado argento con dettaglio di grandi fiori fantasia sul corpetto. Spalla asimmetrica che lascia scoperto un lato e lunghezza della gonna midi. Si chiude dietro con una zip invisibile.", 
        'ca': "Espectacular vestit amb mikado plata amb detall de gran flor en fantasia en cos. Espatlla asimètrica deixant un hombro a l'aire i llarg de faldilla midi. Es corda per l'esquena amb cremallera invisible.",
    },
}
